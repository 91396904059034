
import { defineComponent, PropType } from "vue";
import { Meeting} from "@liveswitch/sdk";
import Message from "@liveswitch/sdk/chat/Message";

const defaultDisplayName = "Chat User";
export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      default: null,
    },
    meeting: {
      type: Object as PropType<Meeting>,
      default: null,
    },
    allowDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayName: defaultDisplayName
    } 
  },
  methods: {
    deleteChatMessage() {
        this.$emit("deleteChatMessage", this.message);
    },
    downloadFile() {
      this.$emit("downloadFile", this.message);
    }
  },
  computed: {
    attendeeDisplayName() : string {
      return this.message.attendee ? this.message.attendee?.displayName : defaultDisplayName;
    },
    canDeleteMessage() : Boolean {
      if (!this.allowDelete) return false;
      if (this.message.attendee.id == this.meeting.localAttendee.id) return true;
      return this.meeting.localAttendee.hasPermission("DeleteMessage");
    }
  },
  watch: {
    attendeeDisplayName: {
      immediate: true,
      handler() {
        if (this.message.attendee) {
          this.displayName = this.attendeeDisplayName;
        }
      }
    }
  }
});
