import Log from "../logging/Log";

export default class EventOwnerAsync<T> {
  private _handlers: Array<(args: T) => Promise<any>> = [];
  private static _handleUncaughtExceptions: boolean = true;

  public static get handleUncaughtExceptions(): boolean { return this._handleUncaughtExceptions; }
  public static set handleUncaughtExceptions(value: boolean) { this._handleUncaughtExceptions = value; }

  /** @internal */
  public get handlerCount(): number { return this._handlers.length; }

  /** @internal */
  public async dispatch(args: T): Promise<any> {
    for (const handler of this._handlers) {
      try {
        await handler(args);
      } catch (error: unknown) {
        const errorMessage = error instanceof Error ? error.message : error;
        const handlerInfo = handler.name || 'anonymous handler';
        Log.error(`Error caught in event handler '${handlerInfo}': ${errorMessage}`);

        if (!EventOwnerAsync._handleUncaughtExceptions) {
          throw error;
        }
      }
    }
  }
    
  public bind(handler: (args: T) => Promise<any>) {
    this._handlers.push(handler);
  }

  public unbind(handler: (args: T) => Promise<any>) {
    this._handlers = this._handlers.filter(h => h != handler);
  }
}