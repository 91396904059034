import FakeAudio from "./FakeAudio";
import FakeVideo from "./FakeVideo";
import { DisplayMedia } from "@liveswitch/sdk";

export default class FakeDisplayMedia extends DisplayMedia {
  private _audio?: FakeAudio = undefined;
  private _video?: FakeVideo = undefined;

  public constructor(audio: boolean, video: boolean) {
    super(audio, video);
  }

  protected async getStream(constraints: MediaStreamConstraints): Promise<MediaStream> {
    const stream = new MediaStream();
    if (constraints.audio) {
      let clockRate = 48000;
      const audioConstraints = constraints.audio as MediaTrackConstraints;
      if (audioConstraints) {
        const clockRateConstraint = audioConstraints.sampleRate as ConstrainULongRange;
        if (clockRateConstraint) {
          if (clockRateConstraint.exact) {
            clockRate = clockRateConstraint.exact;
          } else {
            if (clockRateConstraint.ideal) clockRate = clockRateConstraint.ideal;
            if (clockRateConstraint.max) clockRate = Math.min(clockRate, clockRateConstraint.max);
            if (clockRateConstraint.min) clockRate = Math.max(clockRate, clockRateConstraint.min);
          }
        }
      }
      this._audio = new FakeAudio(440, 0.5, clockRate);
      await this._audio.start();
      stream.addTrack(this._audio.streamTrack!);
    }

    if (constraints.video) {
      let width = 1280;
      let height = 720;
      let frameRate = 25;
      const videoConstraints = constraints.video as MediaTrackConstraints;
      if (videoConstraints) {
        const widthConstraint = videoConstraints.width as ConstrainULongRange;
        if (widthConstraint) {
          if (widthConstraint.exact) {
            width = widthConstraint.exact;
          } else {
            if (widthConstraint.ideal) width = widthConstraint.ideal;
            if (widthConstraint.max) width = Math.min(width, widthConstraint.max);
            if (widthConstraint.min) width = Math.max(width, widthConstraint.min);
          }
        }
        const heightConstraint = videoConstraints.height as ConstrainULongRange;
        if (heightConstraint) {
          if (heightConstraint.exact) {
            height = heightConstraint.exact;
          } else {
            if (heightConstraint.ideal) height = heightConstraint.ideal;
            if (heightConstraint.max) height = Math.min(height, heightConstraint.max);
            if (heightConstraint.min) height = Math.max(height, heightConstraint.min);
          }
        }
        const frameRateConstraint = videoConstraints.frameRate as ConstrainDoubleRange;
        if (frameRateConstraint) {
          if (frameRateConstraint.exact) {
            frameRate = frameRateConstraint.exact;
          } else {
            if (frameRateConstraint.ideal) frameRate = frameRateConstraint.ideal;
            if (frameRateConstraint.max) frameRate = Math.min(frameRate, frameRateConstraint.max);
            if (frameRateConstraint.min) frameRate = Math.max(frameRate, frameRateConstraint.min);
          }
        }
      }
      this._video = new FakeVideo(width, height, frameRate);
      await this._video.start();
      stream.addTrack(this._video.streamTrack!);
    }

    this.stopped.bind(async e => {
      if (this._audio) await this._audio.stop();
      if (this._video) await this._video.stop();
    })

    return stream;
  }
}