import ApiResponse from "../api/models/ApiResponse";
import ClientInit from "./models/ClientInit";
import Credentials from "./models/Credentials";
import GetCredentialsResponse from "./models/GetCredentialsResponse";
import Guard from "../core/Guard";
import HttpClient from "../core/HttpClient";
import IceInfo from "./models/IceInfo";

export default class Client {
  private readonly _httpClient: HttpClient;
  private readonly _init: ClientInit;

  public get maxRetries(): number { return this._httpClient.maxRetries; }
  public set maxRetries(value: number) { this._httpClient.maxRetries = value; }
  public get requestTimeout(): number { return this._httpClient.requestTimeout; }
  public set requestTimeout(value: number) { this._httpClient.requestTimeout = value; }

  public constructor(init: ClientInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.meetingSession, "init.meetingSession");
    Guard.isNotNullOrUndefined(init.turnServerUrl, "init.turnServerUrl");
    this._init = init;
    this._httpClient = HttpClient.withTokenFactory(async () => {
      const token = await this._init.meetingSession.token();
      return {
          baseUrl: new URL(this._init.turnServerUrl).toString().replace(/\/{0,2}$/, ""),
        value: token.value
      }
    });
  }

  public async getCredentials(abortSignal?: AbortSignal): Promise<Credentials> {
    return (<ApiResponse<GetCredentialsResponse>>await this._httpClient.get("credentials?munge=true", abortSignal)).value.credentials;
  }

  public async setIceInfo(iceInfo: IceInfo, abortSignal?: AbortSignal): Promise<void> {
    await this._httpClient.post("ice", {
      localPassword: iceInfo.localPassword,
      localUsernameFragment: iceInfo.localUsernameFragment,
      relayPorts: iceInfo.relayPorts,
      remoteIPAddress: iceInfo.remoteIPAddress,
      remotePassword: iceInfo.remotePassword,
      remoteUsernameFragment: iceInfo.remoteUsernameFragment,
    }, abortSignal);
  }
}