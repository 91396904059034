import Channel from "./Channel";
import ControlConnection from "../control/Connection";
import Guard from "../core/Guard";
import Member from "./Member";
import MemberCollectionInit from "./models/MemberCollectionInit";
import ReadOnlyCollection from "../core/ReadOnlyCollection";
import SubscribedView from "../SubscribedView";
import ApiClient from "../api/Client";

export default class MemberCollection extends ReadOnlyCollection<Member> {
  private _channel: Channel;
  private _controlConnection: ControlConnection;
  private _subscribedView: SubscribedView;
  private _apiClient: ApiClient;

  /** @internal */
  constructor(apiClient: ApiClient) {
    super((member) => member.id);
    this._apiClient = apiClient;
  }

  /** @internal */
  public async load(init: MemberCollectionInit): Promise<void> {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.channel, "init.channel");
    Guard.isNotNullOrUndefined(
      init.controlConnection,
      "init.controlConnection"
    );
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._channel = init.channel;
    this._controlConnection = init.controlConnection;
    this._subscribedView = init.subscribedView;

    const response = await this._apiClient.listChatChannelAttendeesByChannelId(
      this._channel.id
    );
    if (response == null || response.values == null)
      throw new Error("Failed to load members");

    const memberModels = response.values;
    const attendees = await Promise.all(
      memberModels.map((mm) =>
        this._subscribedView.subscribeToAttendee(
          mm.attendeeId,
          "chatMemberPaging"
        )
      )
    );
    
    const members = attendees
      .filter((a) => !!a)
      .map(
        (a, i) =>
          new Member({
            attendee: a,
            channel: this._channel,
            apiClient: this._apiClient,
            controlConnection: this._controlConnection,
            model: memberModels[i],
          })
      );
    for (const member of members) this.tryAdd(member);
  }
}
