import AudioLevelEvent from "./models/AudioLevelEvent";
import AudioTrackEvent from "./models/AudioTrackEvent";
import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Media from "./Media";
import StatisticAudio from "../models/StatisticAudio";
import StatisticConnection from "../models/StatisticConnection";
import Log from "../logging/Log";
import Bitrate from "../core/Bitrate";
import DeltaCounter from "../core/DeltaCounter";

export default abstract class AudioTrack {
  private readonly _levelChanged = new EventOwner<AudioLevelEvent>();
  private readonly _speechStarted = new EventOwner<AudioTrackEvent>();
  private readonly _speechStopped = new EventOwner<AudioTrackEvent>();

  private _statAudio: StatisticAudio;
  private _statPair: StatisticConnection;

  //public get Stats(): StatisticAudio { return this._statAudio; }

  //private _bitrate: number = null;
  //private _bytesReceived: number = null;
  //private _bytesSent: number = null;
  //private _candidateAddress: string = null;
  //private _candidateRelayProtocol: string = null;
  //private _candidateType: string = null;
  private _isSpeaking: boolean = null;
  //private _jitter: number = null;
  //private _keepAliveRequestsReceived: number;
  //private _keepAliveRequestsSent: number;
  //private _keepAliveResponsesReceived: number;
  //private _keepAliveResponsesSent: number;
  private _level: number = null;
  //private _networkQuality: number = null;
  //private _packetLoss: number = null;
  //private _packetsLost: number = null;
  //private _packetsReceived: number = null;
  //private _packetsSent: number = null;
  //private _remoteCandidateAddress: string = null;
  //private _remoteCandidateType: string = null;
  //private _roundTripTime: number = null;
  private _speechLevel: number = Math.pow(10, -26 / 20.0); // -26 dBov per ITU-T Rec.P.56

  public get bitrate(): number { return this._statAudio?.BitrateActual; }
  public get bytesReceived(): number { return this._statAudio?.Rtp_BytesReceived; }
  public get bytesSent(): number { return this._statAudio?.Rtp_BytesSent; }
  public get candidateAddress(): string { return this._statPair?.Lcl_Ip; }
  public get candidateRelayProtocol(): string { return this._statPair?.Lcl_Protocol; }
  public get candidateType(): string { return this._statPair?.Lcl_CandidateType; }
  public get isLocal(): boolean { return !this.isRemote; }
  public abstract get isMuted(): boolean;
  public abstract get isPaused(): boolean;
  public abstract get isRemote(): boolean;
  public get isSpeaking(): boolean { return this._isSpeaking; }
  public get jitter(): number { return this._statAudio?.Rtp_Jitter; }
  public get keepAliveRequestsReceived(): number { return this._statPair?.Pair_RequestsReceived; }
  public get keepAliveRequestsSent(): number { return this._statPair?.Pair_RequestsSent; }
  public get keepAliveResponsesReceived(): number { return this._statPair?.Pair_ResponsesReceived; }
  public get keepAliveResponsesSent(): number { return this._statPair?.Pair_ResponsesSent; }
  public get level(): number { return this._level; }
  public abstract get media(): Media;
  // public get networkQuality(): number { return this._networkQuality; }
  //public get packetLoss(): number { return this._statAudio.Rtp_PacketsLost; }
  public get packetsLost(): number { return this._statAudio?.Rtp_PacketsLost; }
  public get packetsReceived(): number { return this._statAudio?.Rtp_PacketsReceived; }
  public get packetsSent(): number { return this._statAudio?.Rtp_PacketsSent; }
  public get remoteCandidateAddress(): string { return this._statPair?.Rmt_Ip; }
  public get remoteCandidateType(): string { return this._statPair?.Rmt_CandidateType; }
  public get roundTripTime(): number { return this._statPair?.Pair_RoundTripTimeCurrent; }
  public get speechLevel(): number { return this._speechLevel; }
  public set speechLevel(value: number) { this._speechLevel = value; }
  public abstract get stream(): MediaStreamTrack;

  /** @event */
  public get levelChanged(): EventOwner<AudioLevelEvent> { return this._levelChanged; }
  /** @event */
  public get speechStarted(): EventOwner<AudioTrackEvent> { return this._speechStarted; }
  /** @event */
  public get speechStopped(): EventOwner<AudioTrackEvent> { return this._speechStopped; }
  /** @event */
  public abstract get streamBound(): EventOwnerAsync<AudioTrackEvent>;
  /** @event */
  public abstract get streamUnbound(): EventOwner<AudioTrackEvent>;

  /** @internal */
  public updateLevel(level: number): void {
    if (level == this._level) return;
    const previousLevel = this._level;
    this._level = level;
    this._levelChanged.dispatch({
      level: level,
      previousLevel: previousLevel,
      track: this,
    });

    const isSpeaking = level >= this._speechLevel;
    if (isSpeaking == this._isSpeaking) return;
    this._isSpeaking = isSpeaking;
    if (this._isSpeaking) {
      this._speechStarted.dispatch({
        track: this
      });
    }
    else {
      this._speechStopped.dispatch({
        track: this
      });
    }
  }

  public updateStats(statAudio: StatisticAudio, statPair: StatisticConnection): void {
    this._statAudio = statAudio;
    if (statPair != null) { this._statPair = statPair };
  }

}