import { deflateRaw } from "pako";
import { version } from "../Version";
import ApiResponse from "../api/models/ApiResponse";
import Base64 from "../core/Base64";
import ClientInit from "./models/ClientInit";
import ClientModel from "./models/Client";
import Guard from "../core/Guard";
import HttpClient from "../core/HttpClient";
import NegotiateRequest from "./models/NegotiateRequest";
import NegotiateResponse from "./models/NegotiateResponse";
import Utility from "../core/Utility";
import RenegotiateRequest from "./models/RenegotiateRequest";
import RenegotiateResponse from "./models/RenegotiateResponse";

export default class Client implements ClientModel {
  private readonly _httpClient: HttpClient;
  private readonly _init: ClientInit;

  public get maxRetries(): number { return this._httpClient.maxRetries; }
  public set maxRetries(value: number) { this._httpClient.maxRetries = value; }
  public get requestTimeout(): number { return this._httpClient.requestTimeout; }
  public set requestTimeout(value: number) { this._httpClient.requestTimeout = value; }

  public constructor(init: ClientInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.edgeServerUrl, "init.edgeServerUrl");
    Guard.isNotNullOrUndefined(init.meetingSession, "init.meetingSession");
    this._init = init;
    this._httpClient = HttpClient.withTokenFactory(async () => {
      const token = await this._init.meetingSession.token();
      return {
          baseUrl: new URL(this._init.edgeServerUrl).toString().replace(/\/{0,2}$/, ""),
        value: token.value
      }
    });
  }

  private static deflate(offer: string): string {
    return Base64.encode(deflateRaw(new TextEncoder().encode(offer)));
  }

  public async negotiate(request: NegotiateRequest, abortSignal?: AbortSignal): Promise<NegotiateResponse> {
    Guard.isNotNullOrUndefined(request, "request");
    Guard.isNotNullOrUndefined(request.offer, "request.offer");
    return (<ApiResponse<NegotiateResponse>>await this._httpClient.post("negotiate", {
      audioLevelIntervalDisplay: request.audioLevelIntervalDisplay,
      audioLevelIntervalUser: request.audioLevelIntervalUser,
      compatibilityMode: request.compatibilityMode,
      degradationPreference: request.degradationPreference,
      discardAudio: request.discardAudio,
      discardVideo: request.discardVideo,
      localLoopbackAudio: request.localLoopbackAudio,
      localLoopbackVideo: request.localLoopbackVideo,
      maxAudibleDisplay: request.maxAudibleDisplay,
      maxAudibleUser: request.maxAudibleUser,
      maxVisibleDisplay: request.maxVisibleDisplay,
      maxVisibleUser: request.maxVisibleUser,
      offerDeflate: Client.deflate(request.offer),
      sdkVersion: version,
      synchronizeAudioMetadata: true,
    }, abortSignal)).value;
  }

  public reassign(edgeServerUrl: string): void {
    Guard.isNotNullOrUndefined(edgeServerUrl, "edgeServerUrl");
    this._init.edgeServerUrl = edgeServerUrl;
  }

  public async renegotiate(request: RenegotiateRequest, abortSignal?: AbortSignal): Promise<RenegotiateResponse> {
    Guard.isNotNullOrUndefined(request, "request");
    Guard.isNotNullOrUndefined(request.offer, "request.offer");
    return (<ApiResponse<RenegotiateResponse>>await this._httpClient.post("renegotiate", {
      offerDeflate: Client.deflate(request.offer),
    }, abortSignal)).value;
  }
}