import ConnectionInit from "./models/ConnectionInit";
import State from "./models/ConnectionState";
import StateMachine from "./ConnectionStateMachine";

import DispatchQueue from "./core/DispatchQueue";
import Guard from "./core/Guard";
import PromiseCompletionSource from "./core/PromiseCompletionSource";
import Reactive from "./core/Reactive";

import Log from "./logging/Log";
import EventLoggerModel from "./event/models/Logger";
import EventOwner from "./core/EventOwner";
import EventOwnerAsync from "./core/EventOwnerAsync";

import Event from "./models/ConnectionEvent";
import AnswerEvent from "./models/ConnectionAnswerEvent";
import IceCandidateEvent from "./models/ConnectionIceCandidateEvent";
import IceCandidateFailedEvent from "./models/ConnectionIceCandidateFailedEvent";
import OfferEvent from "./models/ConnectionOfferEvent";
import StateChangeEvent from "./models/ConnectionStateChangeEvent";

import Message from "./models/ConnectionMessage";
import TrackType from "./media/models/TrackType";

import TurnSession from "./turn/models/Session";

import Utility from "./core/Utility";
import { parseIPAddressFromSdp, parseIcePasswordFromSdp, parseRelayPortFromIceCandidate, parseSdpUserNameFragment } from "./SdpParser";

interface Transaction<TMessage> {
  performanceStart: number;
  reject: (reason?: any) => void;
  request: TMessage;
  resolve: (value: any | PromiseLike<any>) => void;
  timeoutId: any;
}

export default abstract class Connection<TMessage extends Message> {
  private readonly _answerReceived: EventOwnerAsync<AnswerEvent> = new EventOwnerAsync<AnswerEvent>();
  private readonly _attendeeId: string;
  private readonly _controlChannel: RTCDataChannel;
  private readonly _controlChannelStateChanged: EventOwner<Event> = new EventOwner<Event>();
  private readonly _connection: RTCPeerConnection;
  private readonly _eventLogger: EventLoggerModel;
  private readonly _eventQueue = new DispatchQueue();
  private readonly _iceCandidateFailed: EventOwner<IceCandidateFailedEvent> = new EventOwner<IceCandidateFailedEvent>();
  private readonly _iceCandidateGathered: EventOwner<IceCandidateEvent> = new EventOwner<IceCandidateEvent>();
  private readonly _iceConnectionStateChanged: EventOwner<Event> = new EventOwner<Event>();
  private readonly _iceGatheringStateChanged: EventOwner<Event> = new EventOwner<Event>();
  private readonly _iceRestartEnabled: boolean;
  private readonly _meetingId: string;
  private readonly _negotiationNeeded: EventOwner<Event> = new EventOwner<Event>();
  private readonly _offerCreated: EventOwnerAsync<OfferEvent> = new EventOwnerAsync<OfferEvent>();
  private readonly _onControlChannelClose: () => void;
  private readonly _onControlChannelClosing: () => void;
  private readonly _onControlChannelError: (ev: any) => void;
  private readonly _onControlChannelMessage: (ev: MessageEvent<any>) => any;
  private readonly _onControlChannelOpen: () => void;
  private readonly _onDtlsTransportError: (ev: any) => void;
  private readonly _onDtlsTransportStateChange: () => void;
  private readonly _onIceCandidate: (ev: RTCPeerConnectionIceEvent) => void;
  private readonly _onIceCandidateError: (ev: any) => void;
  private readonly _onIceConnectionStateChange: () => void;
  private readonly _onIceGatheringStateChange: () => void;
  private readonly _onIceTransportGatheringStateChange: () => void;
  private readonly _onIceTransportSelectedCandidatePairChange: () => void;
  private readonly _onIceTransportStateChange: () => void;
  private readonly _onNegotiationNeeded: () => void;
  private readonly _onPeerConnectionStateChange: () => void;
  private readonly _onSignalingStateChange: () => void;
  private readonly _onWindowUnload: () => void;
  private readonly _peerConnectionStateChanged: EventOwner<Event> = new EventOwner<Event>();
  private readonly _pendingMessages: TMessage[] = [];
  private readonly _relayPorts: number[] = [];
  private readonly _signalingStateChanged: EventOwner<Event> = new EventOwner<Event>();
  private readonly _stateChanged: EventOwner<StateChangeEvent> = new EventOwner<StateChangeEvent>();
  private readonly _stateEvents = new Map<State, EventOwner<StateChangeEvent>>();
  private readonly _stateMachine = new StateMachine();
  private readonly _transactions = new Map<number, Transaction<TMessage>>();
  private readonly _turnSession: TurnSession | undefined;
  private readonly _type: string;

  private _controlChannelBytesReceived = 0;
  private _controlChannelBytesSent = 0;
  private _dtlsTransport: RTCDtlsTransport = null;
  private _iceRestarting: boolean = false;
  private _iceTransport: RTCIceTransport = null;
  private _localIcePassword: string = null;
  private _localIceUsernameFragment: string = null;
  private _opened: PromiseCompletionSource<Error> = null;
  private _reconnectedTimeoutId: any = null;
  private _remoteIcePassword: string = null;
  private _remoteIceUsernameFragment: string = null;
  private _remoteIPAddress: string = null;
  private _requestTimeout = 15000;
  private _terminatedReason = "";
  private _transactionId = 0;

  protected get eventLogger(): EventLoggerModel { return this._eventLogger; }
  protected get connection(): RTCPeerConnection { return this._connection; }
  protected get eventQueue(): DispatchQueue { return this._eventQueue; }

  /** @internal */
  public get pendingMessages(): TMessage[] { return this._pendingMessages; }

  public get attendeeId(): string { return this._attendeeId; }
  public get answerReceived(): EventOwnerAsync<AnswerEvent> { return this._answerReceived; }
  public get controlChannelState(): RTCDataChannelState { return this._controlChannel.readyState; }
  public get controlChannelStateChanged(): EventOwner<Event> { return this._controlChannelStateChanged; }
  public get closed(): EventOwner<StateChangeEvent> { return this._stateEvents.get("closed"); }
  public get connected(): EventOwner<StateChangeEvent> { return this._stateEvents.get("connected"); }
  public get connecting(): EventOwner<StateChangeEvent> { return this._stateEvents.get("connecting"); }
  public get failed(): EventOwner<StateChangeEvent> { return this._stateEvents.get("failed"); }
  public get iceCandidateFailed(): EventOwner<IceCandidateFailedEvent> { return this._iceCandidateFailed; }
  public get iceCandidateGathered(): EventOwner<IceCandidateEvent> { return this._iceCandidateGathered; }
  public get iceConnectionStateChanged(): EventOwner<Event> { return this._iceConnectionStateChanged; }
  public get iceConnectionState(): RTCIceConnectionState { return this._connection.iceConnectionState; }
  public get iceGatheringStateChanged(): EventOwner<Event> { return this._iceGatheringStateChanged; }
  public get iceGatheringState(): RTCIceGatheringState { return this._connection.iceGatheringState; }
  public get isClosed(): boolean { return this.state == "closed"; }
  public get isConnected(): boolean { return this.state == "connected"; }
  public get isConnecting(): boolean { return this.state == "connecting"; }
  public get isFailed(): boolean { return this.state == "failed"; }
  public get isReconnecting(): boolean { return this.state == "reconnecting"; }
  public get isTerminated(): boolean { return this.isClosed || this.isFailed; }
  public get meetingId(): string { return this._meetingId; }
  public get offerCreated(): EventOwnerAsync<OfferEvent> { return this._offerCreated; }
  public get negotiationNeeded(): EventOwner<Event> { return this._negotiationNeeded; }
  public get peerConnectionStateChanged(): EventOwner<Event> { return this._peerConnectionStateChanged; }
  public get peerConnectionState(): RTCPeerConnectionState { return this._connection.connectionState; }
  public get reconnecting(): EventOwner<StateChangeEvent> { return this._stateEvents.get("reconnecting"); }
  public get requestTimeout(): number { return this._requestTimeout; }
  public set requestTimeout(value: number) { this._requestTimeout = value; }
  public get signalingState(): RTCSignalingState { return this._connection.signalingState; }
  public get signalingStateChanged(): EventOwner<Event> { return this._signalingStateChanged; }
  public get state(): State { return this._stateMachine.state; }
  public get stateChanged(): EventOwner<StateChangeEvent> { return this._stateChanged; }

  public constructor(init: ConnectionInit) {
    this._attendeeId = init.attendeeId;
    this._eventLogger = init.eventLogger;
    this._iceRestartEnabled = init.iceRestartEnabled;
    this._meetingId = init.meetingId;
    this._turnSession = init.turnSession;
    this._type = init.type;

    this._stateEvents.set("closed", new EventOwner<StateChangeEvent>());
    this._stateEvents.set("connected", new EventOwner<StateChangeEvent>());
    this._stateEvents.set("connecting", new EventOwner<StateChangeEvent>());
    this._stateEvents.set("failed", new EventOwner<StateChangeEvent>());
    this._stateEvents.set("reconnecting", new EventOwner<StateChangeEvent>());

    this._onControlChannelClose = this.onControlChannelClose.bind(Reactive.wrap(this));
    this._onControlChannelClosing = this.onControlChannelClosing.bind(Reactive.wrap(this));
    this._onControlChannelError = this.onControlChannelError.bind(Reactive.wrap(this));
    this._onControlChannelMessage = this.onControlChannelMessage.bind(Reactive.wrap(this));
    this._onControlChannelOpen = this.onControlChannelOpen.bind(Reactive.wrap(this));
    this._onDtlsTransportError = this.onDtlsTransportError.bind(Reactive.wrap(this));
    this._onDtlsTransportStateChange = this.onDtlsTransportStateChange.bind(Reactive.wrap(this));
    this._onIceCandidate = this.onIceCandidate.bind(Reactive.wrap(this));
    this._onIceCandidateError = this.onIceCandidateError.bind(Reactive.wrap(this));
    this._onIceConnectionStateChange = this.onIceConnectionStateChange.bind(Reactive.wrap(this));
    this._onIceGatheringStateChange = this.onIceGatheringStateChange.bind(Reactive.wrap(this));
    this._onIceTransportGatheringStateChange = this.onIceTransportGatheringStateChange.bind(Reactive.wrap(this));
    this._onIceTransportSelectedCandidatePairChange = this.onIceTransportSelectedCandidatePairChange.bind(Reactive.wrap(this));
    this._onIceTransportStateChange = this.onIceTransportStateChange.bind(Reactive.wrap(this));
    this._onNegotiationNeeded = this.onNegotiationNeeded.bind(Reactive.wrap(this));
    this._onPeerConnectionStateChange = this.onPeerConnectionStateChange.bind(Reactive.wrap(this));
    this._onSignalingStateChange = this.onSignalingStateChange.bind(Reactive.wrap(this));
    this._onWindowUnload = this.onWindowUnload.bind(Reactive.wrap(this));

    this._connection = new RTCPeerConnection({
      bundlePolicy: "max-bundle",
      iceTransportPolicy: init.turnRequired ? "relay" : "all",
      rtcpMuxPolicy: "require",
    });
    this._controlChannel = this._connection.createDataChannel("control");
    this._controlChannel.binaryType = "arraybuffer";
  }

  private fail(reason: string): void {
    this.terminate("failed", reason);
  }

  private getDtlsTransport(): RTCDtlsTransport {
    for (const sender of this._connection.getSenders()) {
      if (sender.transport) return sender.transport;
    }
    for (const receiver of this._connection.getReceivers()) {
      if (receiver.transport) return receiver.transport;
    }
    return null;
  }

  private onControlChannelClose(): void {
    const message = `${this._type} connection control channel has closed.`;
    void this._eventLogger.debug("onControlChannelClose", message);
    this.onControlChannelStateChange();
    this.fail(message);
  }

  private onControlChannelClosing(): void {
    const message = `${this._type} connection control channel is closing.`;
    void this._eventLogger.debug("onControlChannelClosing", message);
    this.onControlChannelStateChange();
  }

  private onControlChannelError(ev: RTCErrorEvent): void {
    const message = `${this._type} connection control channel has failed. ${ev.error?.errorDetail ?? ev.error ?? null}`.trimEnd();
    void this._eventLogger.debug("onControlChannelError", message);
    this.onControlChannelStateChange();
    this.fail(message);
  }

  private onControlChannelMessage(ev: MessageEvent<any>): void {
    const messageJson: string = ev.data;
    this._controlChannelBytesReceived += messageJson.length;
    const message = JSON.parse(messageJson) as TMessage;
    if (message.batch) {
      for (const m of message.batch) {
        this.processMessage(m as TMessage);
      }
    } else {
      this.processMessage(message);
    }
  }

  private onControlChannelOpen(): void {
    const message = `${this._type} connection control channel has opened.`;
    void this._eventLogger.debug("onControlChannelOpen", message);
    this.onControlChannelStateChange();
    this._opened?.resolve(null);
    this._opened = null;
    this.trySetState("connected");
    for (const message of this._pendingMessages) {
      this.send(message);
    }
    this._pendingMessages.length = 0;
  }

  private onControlChannelStateChange(): void {
    void this._eventLogger.debug("onControlChannelStateChange", `${this._type} connection control channel ready-state is now '${this._controlChannel.readyState}'.`);
    this._controlChannelStateChanged.dispatch({
      connection: this,
    });
  }

  private onDtlsTransportError(ev: RTCErrorEvent): void {
    const errDetail = ev.error?.errorDetail ?? ev.error ?? "";
    void this._eventLogger.debug("onDtlsTransportError", `${this._type} connection DTLS transport has failed. ${errDetail}`.trimEnd());
  }

  private onDtlsTransportStateChange(): void {
    void this._eventLogger.debug("onDtlsTransportStateChange", `${this._type} connection DTLS transport state is now '${this._dtlsTransport.state}'.`);
  }

  private onIceCandidate(ev: RTCPeerConnectionIceEvent) {
    if (!ev.candidate) {
      void this._eventLogger.debug("onIceCandidate", `${this._type} connection ICE candidate gathering complete.`);
      return;
    }
    if (!ev.candidate.candidate) {
      void this._eventLogger.debug("onIceCandidate", `${this._type} connection ICE candidate generation gathering complete.`);
      return;
    }

    const iceCandidate = {
      sdpCandidate: `a=${ev.candidate.candidate}`,
      sdpMid: ev.candidate.sdpMid,
      sdpMLineIndex: ev.candidate.sdpMLineIndex,
    };

    // discard TCP candidates
    if (iceCandidate.sdpCandidate.toLowerCase().indexOf(" tcp ") != -1) {
      void this._eventLogger.debug("onIceCandidate", `${this._type} connection ICE candidate discarded with mid ${iceCandidate.sdpMid} and m-line index ${iceCandidate.sdpMLineIndex}: ${iceCandidate.sdpCandidate}`);
      return;
    }

    void this._eventLogger.debug("onIceCandidate", `${this._type} connection ICE candidate gathered with mid ${iceCandidate.sdpMid} and m-line index ${iceCandidate.sdpMLineIndex}: ${iceCandidate.sdpCandidate}`);
    this._iceCandidateGathered.dispatch({
      connection: this,
      iceCandidate: iceCandidate,
    });

    this.sendNotification({
      iceCandidate: iceCandidate,
      type: "iceCandidate",
    } as any);

    const relayPort = parseRelayPortFromIceCandidate(iceCandidate.sdpCandidate);
    if (relayPort) {
      if (this._remoteIPAddress) {
        void this.setIceInfo([relayPort]);
      } else {
        this._relayPorts.push(relayPort);
      }
    }
  }

  private onIceCandidateError(ev: RTCPeerConnectionIceErrorEvent) {
    if (this.state != "connecting") return;
    void this._eventLogger.debug("onIceCandidateError", `${this._type} connection ICE candidate failed.`, null, {
      address: ev.address,
      errorCode: ev.errorCode,
      errorText: ev.errorText,
      port: ev.port,
      url: ev.url,
    });
    this._iceCandidateFailed.dispatch({
      address: ev.address,
      connection: this,
      errorCode: ev.errorCode,
      errorText: ev.errorText,
      port: ev.port,
      url: ev.url,
    });
  }

  private onIceConnectionStateChange(): void {
    void this._eventLogger.debug("onIceConnectionStateChange", `${this._type} connection ICE-connection-state is now '${this._connection.iceConnectionState}'.`);
    this._iceConnectionStateChanged.dispatch({
      connection: this,
    });
    switch (this._connection.iceConnectionState) {
      case "connected":
        if (!this.trySetState("connected")) break;
        if (!this._reconnectedTimeoutId) break;
        clearTimeout(this._reconnectedTimeoutId);
        this._reconnectedTimeoutId = null;
        break;
      case "disconnected":
        if (!this.trySetState("reconnecting")) break;
        if (this._reconnectedTimeoutId) clearTimeout(this._reconnectedTimeoutId);
        this._reconnectedTimeoutId = setTimeout(() => {
          this.fail("Network failed (timeout).");
        }, 10000); // 10 seconds by browser convention
        void this.iceRestart();
        break;
      case "failed":
        this.fail("Network failed.");
        break;
    }
  }

  private onIceGatheringStateChange(): void {
    void this._eventLogger.debug("onIceGatheringStateChange", `${this._type} connection ICE-gathering-state is now '${this._connection.iceGatheringState}'.`);
    this._iceGatheringStateChanged.dispatch({
      connection: this,
    });
  }

  private onIceTransportGatheringStateChange(): void {
    void this._eventLogger.debug("onIceTransportGatheringStateChange", `${this._type} connection ICE transport gathering state is now '${this._iceTransport.gatheringState}'.`);
  }

  private onIceTransportSelectedCandidatePairChange(): void {
    let localSdpCandidate: string = null;
    let remoteSdpCandidate: string = null;
    if ((this._iceTransport as any).getSelectedCandidatePair) {
      const selectedCandidatePair = (this._iceTransport as any).getSelectedCandidatePair();
      if (selectedCandidatePair) {
        if (selectedCandidatePair.local) localSdpCandidate = `a=${selectedCandidatePair.local.candidate}`;
        if (selectedCandidatePair.remote) remoteSdpCandidate = `a=${selectedCandidatePair.remote.candidate}`;
      }
    }
    void this._eventLogger.debug("onIceTransportSelectedCandidatePairChange", `${this._type} connection ICE transport selected candidate pair has changed.`, null, {
      localSdpCandidate: localSdpCandidate,
      remoteSdpCandidate: remoteSdpCandidate,
    });
  }

  private onIceTransportStateChange(): void {
    void this._eventLogger.debug("onIceTransportStateChange", `${this._type} connection ICE transport state is now '${this._iceTransport.state}'.`);
  }

  private onNegotiationNeeded(): void {
    void this._eventLogger.debug("onNegotiationNeeded", `${this._type} connection needs negotiation.`);
    this._negotiationNeeded.dispatch({
      connection: this,
    });
  }

  private onPeerConnectionStateChange(): void {
    void this._eventLogger.debug("onPeerConnectionStateChange", `${this._type} connection peer-connection-state is now '${this._connection.connectionState}'.`);
    this._peerConnectionStateChanged.dispatch({
      connection: this,
    });
  }

  private onSignalingStateChange(): void {
    if (this._connection.signalingState == "have-local-offer") {
      this._dtlsTransport = this.getDtlsTransport();
      if (this._dtlsTransport) {
        this._dtlsTransport.addEventListener("error", this._onDtlsTransportError);
        this._dtlsTransport.addEventListener("statechange", this._onDtlsTransportStateChange);
        this._iceTransport = this._dtlsTransport.iceTransport ?? null;
        if (this._iceTransport) {
          this._iceTransport.addEventListener("gatheringstatechange", this._onIceTransportGatheringStateChange);
          this._iceTransport.addEventListener("selectedcandidatepairchange", this._onIceTransportSelectedCandidatePairChange);
          this._iceTransport.addEventListener("statechange", this._onIceTransportStateChange);
        }
      }
    }
    void this._eventLogger.debug("onSignalingStateChange", `${this._type} connection signaling-state is now '${this._connection.signalingState}'.`);
    this._signalingStateChanged.dispatch({
      connection: this,
    });
  }

  private onWindowUnload(): void {
    this.close("Window is being unloaded.");
  }

  private processMessage(message: TMessage): void {
    if ("transactionId" in message) {
      const transaction = this._transactions.get(message.transactionId);
      if (transaction) {
        void this._eventLogger.debug("onControlChannelMessage", `Received ${message.type} response (${message.transactionId}) from ${this._type} server.`,
          Math.round(performance.now() - transaction.performanceStart), {
          "response": JSON.stringify(message)
        });
      }
      this.processResponse(message);
    } else {
      void this._eventLogger.debug("onControlChannelMessage", `Received ${message.type} notification from ${this._type} server.`, undefined, {
        "notification": JSON.stringify(message)
      });
      if (message.type == "connectionClosed") {
        this.close(`Server connection has closed. ${message.description ?? ''}`.trimEnd());
      } else if (message.type == "connectionFailed") {
        this.fail(`Server connection has failed. ${message.description ?? ''}`.trimEnd());
      } else if (message.type == "operationFailed" || message.type == "permissionDenied") {
        void this._eventLogger.error("onControlChannelMessage", `Received ${message.type} notification from ${this._type} server.`);
      } else {
        this.processNotification(message);
      }
    }
  }

  private processResponse(response: TMessage): void {
    const transaction = this._transactions.get(response.transactionId);
    if (transaction) {
      this._transactions.delete(response.transactionId);
      if (transaction.timeoutId) clearTimeout(transaction.timeoutId);
      if (response.type == "operationFailed" || response.type == "permissionDenied") {
        transaction.reject(new Error(response.description));
      } else {
        transaction.resolve(response);
      }
    } else {
      void this._eventLogger.warning("processResponse", `Discarding late ${response.type} response (${response.transactionId}) from ${this._type} server.`, undefined, {
        "response": JSON.stringify(response)
      });
    }
  }
 
  private async setIceInfo(relayPorts: number[]): Promise<void> {
    try {
      await this._turnSession?.setIceInfo({
        localPassword: this._localIcePassword,
        localUsernameFragment: this._localIceUsernameFragment,
        relayPorts: relayPorts,
        remoteIPAddress: this._remoteIPAddress,
        remotePassword: this._remoteIcePassword,
        remoteUsernameFragment: this._remoteIceUsernameFragment,
      });
    } catch (error) {
      const ports = relayPorts.join(", ");
      void this._eventLogger.error(<Error>error, "onIceCandidate", `${this._type} connection relay port(s) (${ports}) could not be signalled to the server.`);
    }
  }

  private setState(state: State): void {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    void this._eventLogger.debug("setState", `${this._type} connection state is now '${state}'.`);
    const e = <StateChangeEvent>{
      connection: this,
      previousState: previousState,
      previousStateDuration: this._stateMachine.previousStateDuration,
      state: state,
    };
    this._stateEvents.get(state).dispatch(e);
    this._stateChanged.dispatch(e);
  }

  private terminate(state: "closed" | "failed", reason: string): void {
    if (!this.trySetState(state)) return;
    this._terminatedReason = reason;
    this.tryRejectOpen(`Connection ${state}. (${reason})`);
    if (this._controlChannel.readyState == "open") this.send({ type: state == "closed" ? "connectionClosed" : "connectionFailed" });
    this.onTerminating();
    this.detachEventHandlers();
    this._controlChannel.close();
    this._connection.close();
    for (const transaction of this._transactions.values()) transaction.reject(new Error(`Connection ${state}. (${this._terminatedReason}) Could not receive ${transaction.request.type} response (${transaction.request.transactionId}).`));
    this._transactions.clear();
    this.onTerminated();
    void this._eventLogger.debug("terminate", `${this._type} connection ${state}. (${reason})`);
  }

  private tryRejectOpen(reason: string): void {
    this._opened?.resolve(new Error(reason));
    this._opened = null;
  }

  private trySetState(state: State): boolean {
    const previousState = this._stateMachine.state;
    if (!this._stateMachine.trySetState(state)) return false;
    void this._eventLogger.debug("setState", `${this._type} connection state is now '${state}'.`);
    const e = <StateChangeEvent>{
      connection: this,
      previousState: previousState,
      previousStateDuration: this._stateMachine.previousStateDuration,
      state: state,
    };
    this._stateEvents.get(state).dispatch(e);
    this._stateChanged.dispatch(e);
    return true;
  }

  private iceRestart(): Promise<void> {
    if (!this._iceRestartEnabled) return;
    if (this.state != "connected" && this.state != "reconnecting") return;
    if (this._iceRestarting) return;
    this._iceRestarting = true;
    return this._eventQueue.dispatch(async () => {
      try {
        void this._eventLogger.debug("iceRestart", `${this._type} connection ICE restarting...`);
        await this.onOpening();

        if (this._turnSession) {
          const iceServer = await this._turnSession.iceServer();
          const configuration = this._connection.getConfiguration();
          configuration.iceServers = [iceServer];
          this._connection.setConfiguration(configuration);
        }

        let offer = (await this._connection.createOffer({ iceRestart: true })).sdp;
        this._localIcePassword = parseIcePasswordFromSdp(offer);
        this._localIceUsernameFragment = parseSdpUserNameFragment(offer);
        offer = await this.mungeOffer(offer);

        Log.debug(`${this._type} connection ICE restart SDP offer:\n${offer}`);

        await this._connection.setLocalDescription({ sdp: offer, type: "offer" });
        let answer = await this.renegotiate(offer, null);
        this._remoteIcePassword = parseIcePasswordFromSdp(answer);
        this._remoteIceUsernameFragment = parseSdpUserNameFragment(answer);
        this._remoteIPAddress = parseIPAddressFromSdp(answer);
        answer = await this.mungeAnswer(answer);

        Log.debug(`${this._type} connection ICE restart SDP answer:\n${answer}`);

        if (this.isClosed) {
          void this._eventLogger.debug("iceRestart", `${this._type} connection ICE restart failed. Connection closed.`);
          return;
        }

        if (this.isFailed) {
          void this._eventLogger.warning("iceRestart", `${this._type} connection ICE restart failed. Connection failed.`);
          return;
        }
        if (this._relayPorts.length) void this.setIceInfo(this._relayPorts.splice(0));
        await this._connection.setRemoteDescription({ sdp: answer, type: "answer" });

        await this.onOpened();
        void this._eventLogger.debug("iceRestart", `${this._type} connection ICE restart succeeded.`);
      } catch (error: any) {
        void this._eventLogger.error(<Error>error, "iceRestart", `${this._type} connection ICE restart failed.`);
      } finally {
        this._iceRestarting = false;
      }
    });
  }

  protected attachEventHandlers(): void {
    this._connection.addEventListener("connectionstatechange", this._onPeerConnectionStateChange);
    this._connection.addEventListener("icecandidate", this._onIceCandidate);
    this._connection.addEventListener("icecandidateerror", this._onIceCandidateError);
    this._connection.addEventListener("iceconnectionstatechange", this._onIceConnectionStateChange);
    this._connection.addEventListener("icegatheringstatechange", this._onIceGatheringStateChange);
    this._connection.addEventListener("negotiationNeeded", this._onNegotiationNeeded);
    this._connection.addEventListener("signalingstatechange", this._onSignalingStateChange);
    this._controlChannel.addEventListener("close", this._onControlChannelClose);
    this._controlChannel.addEventListener("closing", this._onControlChannelClosing);
    this._controlChannel.addEventListener("error", this._onControlChannelError);
    this._controlChannel.addEventListener("message", this._onControlChannelMessage);
    this._controlChannel.addEventListener("open", this._onControlChannelOpen);
    globalThis.window?.addEventListener("unload", this._onWindowUnload);
  }

  protected detachEventHandlers(): void {
    this._connection.removeEventListener("connectionstatechange", this._onPeerConnectionStateChange);
    this._connection.removeEventListener("icecandidate", this._onIceCandidate);
    this._connection.removeEventListener("icecandidateerror", this._onIceCandidateError);
    this._connection.removeEventListener("iceconnectionstatechange", this._onIceConnectionStateChange);
    this._connection.removeEventListener("icegatheringstatechange", this._onIceGatheringStateChange);
    this._connection.removeEventListener("negotiationNeeded", this._onNegotiationNeeded);
    this._connection.removeEventListener("signalingstatechange", this._onSignalingStateChange);
    this._controlChannel.removeEventListener("close", this._onControlChannelClose);
    this._controlChannel.removeEventListener("closing", this._onControlChannelClosing);
    this._controlChannel.removeEventListener("error", this._onControlChannelError);
    this._controlChannel.removeEventListener("message", this._onControlChannelMessage);
    this._controlChannel.removeEventListener("open", this._onControlChannelOpen);
    globalThis.window?.removeEventListener("unload", this._onWindowUnload);
  }

  protected async mungeAnswer(answer: string): Promise<string> {
    const answerEvent: AnswerEvent = {
      answer: answer,
      connection: this,
    };
    await this._answerReceived.dispatch(answerEvent);
    return answerEvent.answer;
  }

  protected async mungeOffer(offer: string): Promise<string> {
    const offerEvent: OfferEvent = {
      connection: this,
      offer: offer,
    };
    await this._offerCreated.dispatch(offerEvent);
    return offerEvent.offer;
  }

  protected async onOpened(): Promise<void> { }
  protected async onOpening(): Promise<void> { }
  protected onTerminated(): void { }
  protected onTerminating(): void { }
  protected processReceiverStats(receiverStats: Map<number, Map<string, any>>): void { }
  protected processSenderStats(senderStats: Map<TrackType, Map<string, any>>): void { }

  protected abstract negotiate(offer: string, abortSignal?: AbortSignal): Promise<string>;
  //protected abstract processConnectionStats(connectionStats: Map<string, any>): void;
  protected abstract processNotification(notification: TMessage): void;
  protected abstract renegotiate(offer: string, abortSignal?: AbortSignal): Promise<string>;

  public close(reason: string): void {
    this.terminate("closed", reason);
  }

  public open(abortSignal?: AbortSignal): Promise<void> {
    if (this.state == "connected" || this.state == "reconnecting") return;
    this.setState("connecting");
    this.onControlChannelStateChange();
    const opened = this._opened = new PromiseCompletionSource(abortSignal);
    return this._eventQueue.dispatch(async () => {
      await this.onOpening();
      const performanceStart = performance.now();
      try {
        if (this._turnSession) {
          const [iceServerDuration, iceServer] = await Utility.time(() => this._turnSession.iceServer());
          const configuration = this._connection.getConfiguration();
          configuration.iceServers = [iceServer];
          this._connection.setConfiguration(configuration);
          void this._eventLogger.information("open.iceServers", `${this._type} connection set ICE server.`, iceServerDuration);
        }

        // negotiation phase
        const [offerDuration, result] = await Utility.time(() => this._connection.createOffer());
        let offer = result.sdp;
        this._localIcePassword = parseIcePasswordFromSdp(offer);
        this._localIceUsernameFragment = parseSdpUserNameFragment(offer);
        void this._eventLogger.information("open.offerCreated", `${this._type} connection created offer.`, offerDuration);

        const [mungeOfferDuration, mungedOffer] = await Utility.time(() => this.mungeOffer(offer));
        offer = mungedOffer;
        void this._eventLogger.information("open.offerMunged", `${this._type} connection munged offer.`, mungeOfferDuration);

        Log.debug(`${this._type} connection SDP offer:\n${offer}`);

        const [setLocalDescriptionDuration] = await Utility.time(() => this._connection.setLocalDescription({ sdp: offer, type: "offer" }));
        void this._eventLogger.information("open.localDescriptionSet", `${this._type} connection set local description.`, setLocalDescriptionDuration);

        let [negotationDuration, answer] = await Utility.time(() => this.negotiate(offer, abortSignal));
        this._remoteIcePassword = parseIcePasswordFromSdp(answer);
        this._remoteIceUsernameFragment = parseSdpUserNameFragment(answer);
        this._remoteIPAddress = parseIPAddressFromSdp(answer);
        const rmAddr = this._remoteIPAddress ? this._remoteIPAddress : "unknown address";
        void this._eventLogger.information("open.negotiated", `${this._type} connection negotiated with ${rmAddr}.`, negotationDuration);

        const [mungeAnswerDuration, mungedAnswer] = await Utility.time(() => this.mungeAnswer(answer));
        answer = mungedAnswer;
        void this._eventLogger.information("open.answerMunged", `${this._type} connection munged answer.`, mungeAnswerDuration);

        Log.debug(`${this._type} connection SDP answer:\n${answer}`);

        if (this._relayPorts.length) void this.setIceInfo(this._relayPorts.splice(0));

        const [setRemoteDescriptionDuration] = await Utility.time(() => this._connection.setRemoteDescription({ sdp: answer, type: "answer" }));
        void this._eventLogger.information("open.remoteDescriptionSet", `${this._type} connection set remote description.`, setRemoteDescriptionDuration);
        void this._eventLogger.information("open.negotationCompleted", `${this._type} connection completed negotation phase.`, Math.round(performance.now() - performanceStart));

        // connection phase
        let dtlsConnectionDuration: number | undefined;
        let whenConnectionStateConnected: number | undefined;
        let whenIceStateConnected: number | undefined;
        let whenNegotationComplete = performance.now();

        this._connection.addEventListener("iceconnectionstatechange", () => {
          if (this._connection.iceConnectionState === 'connected') {
            whenIceStateConnected = performance.now();
            void this._eventLogger.information("open.iceConnected", `${this._type} connection completed ICE connection.`, Math.round(performance.now() - whenNegotationComplete));
          }
        });

        this._connection.addEventListener("connectionstatechange", () => {
          if (this._connection.connectionState === 'connected') {
            // FF does not currently support connection states, thus this event will never fire. DTLS duration will remain undefined.
            if (whenIceStateConnected) {
              dtlsConnectionDuration = Math.round(performance.now() - whenIceStateConnected);
              whenConnectionStateConnected = performance.now();
              void this._eventLogger.information("open.connected", `${this._type} connection completed DTLS handshake.`, dtlsConnectionDuration);
            }
          }
          
        });

        const error = await opened.promise;
        const connDuration = dtlsConnectionDuration ? " ${dtlsConnectionDuration}ms" : "";
        void this._eventLogger.information("open.controlChannelOpened", `${this._type} connection opened control channel (including DTLS handshake${connDuration}).`, Math.round(performance.now() - whenIceStateConnected));
        void this._eventLogger.information("open.connectionCompleted", `${this._type} connection completed connection phase.`, Math.round(performance.now() - whenNegotationComplete));

        if (error) throw error;
        void this._eventLogger.debug("open.succeeded", `${this._type} connection opened.`, Math.round(performance.now() - performanceStart));
      } catch (error) {
        void this._eventLogger.warning("open.failed", `${this._type} connection failed.`, Math.round(performance.now() - performanceStart));
        throw error;
      }
      await this.onOpened();
    });
  }

  private send(message: Message): void {
    //NOTE: leaving for easy local debugging
    //if (Utility.isNullOrUndefined(message.transactionId)) void this._eventLogger.debug("send", "Sending ${message.type} notification to ${this._type} server...", undefined, {
    //  "notification": JSON.stringify(message)
    //});
    //else void this._eventLogger.debug("send", "Sending ${message.type} request (${message.transactionId}) to ${this._type} server...", undefined, {
    //  "request": JSON.stringify(message)
    //});
    const messageJson = JSON.stringify(message);
    this._controlChannelBytesSent += messageJson.length;
    this._controlChannel.send(messageJson);
  }

  public sendNotification(notification: TMessage): void {
    Guard.isNotNullOrUndefined(notification, "notification");
    switch (this.state) {
      case "new":
      case "connecting":
        this._pendingMessages.push(notification);
        break;
      case "connected":
      case "reconnecting":
        this.send(notification);
        break;
      case "closed":
      case "failed":
        throw new Error(`Connection ${this.state}. (${this._terminatedReason}) Could not send ${notification.type} notification.`);
    }
  }

  public sendRequest(request: TMessage): Promise<TMessage> {
    Guard.isNotNullOrUndefined(request, "request");
    request.transactionId = this._transactionId++;
    const promise: Promise<TMessage> = new Promise((resolve, reject) => {
      if (this.isTerminated) return;
      const requestTimeout = this._requestTimeout;
      const timeoutId = Utility.isNullOrUndefined(requestTimeout) ? 0 : setTimeout(() => {
        this._transactions.delete(request.transactionId);
        reject(new Error(`Transaction timed out for ${request.type} request (${request.transactionId}) from ${this._type} server after ${requestTimeout.toLocaleString()}ms.`));
      }, requestTimeout);
      this._transactions.set(request.transactionId, { reject, request, resolve, timeoutId, performanceStart: performance.now() });
    });
    switch (this.state) {
      case "new":
      case "connecting":
        this._pendingMessages.push(request);
        break;
      case "connected":
      case "reconnecting":
        this.send(request);
        break;
      case "closed":
      case "failed":
        throw new Error(`Connection ${this.state}. (${this._terminatedReason}) Could not send ${request.type} request.`);
    }
    return promise;
  }

  public abstract updateStats(): Promise<void>;

  
}