import ApiClient from "../api/Client";
import Attendee from "../Attendee";
import Chat from "./Chat";
import Channel from "./Channel";
import ChannelCollectionInit from "./models/ChannelCollectionInit";
import ControlConnection from "../control/Connection";
import Guard from "../core/Guard";
import ReadOnlyCollection from "../core/ReadOnlyCollection";
import SubscribedView from "../SubscribedView";
import { UploadClient } from "@liveswitch/storage";

export default class ChannelCollection extends ReadOnlyCollection<Channel> {
  private _apiClient: ApiClient;
  private _chat: Chat;
  private _controlConnection: ControlConnection;
  private _defaultChannel: Channel;
  private _localAttendee: Attendee;
  private _subscribedView: SubscribedView;
  private _uploadClient: UploadClient;
  
  /** @internal */
  constructor() {
    super(chatChannel => chatChannel.id);
  }

  /** @internal */
  public async load(init: ChannelCollectionInit) : Promise<void> {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(init.chat, "init.chat");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.defaultChannel, "init.defaultChannel");
    Guard.isNotNullOrUndefined(init.localAttendee, "init.localAttendee");
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._apiClient = init.apiClient;
    this._chat = init.chat;
    this._controlConnection = init.controlConnection;
    this._defaultChannel = init.defaultChannel;
    this._localAttendee = init.localAttendee;
    this._subscribedView = init.subscribedView;
    this._uploadClient = init.uploadClient;

    const response = await this._apiClient.listAttendeeChatChannels({
      attendeeId: this._controlConnection.attendeeId
    });

    if (response == null || response.values == null)
      throw new Error("Failed to get chat channels");

    const channelModels = response.values;
    const channels = channelModels.map(cm => new Channel({
      apiClient: this._apiClient,
      chat: this._chat,
      controlConnection: this._controlConnection,
      localAttendee: this._localAttendee,
      model: cm,
      subscribedView: this._subscribedView,
      uploadClient: this._uploadClient
    }));
    await this._defaultChannel.load();
    await Promise.all(channels.map(c => c.load()));

    this.tryAdd(this._defaultChannel);
    for (const channel of channels) this.tryAdd(channel);
  }
}