
import { DisplayMedia, MediaDeviceManager, Log, Meeting, UserMedia, Identity } from "@liveswitch/sdk";
import { defineComponent } from "vue";

const getParams = () => {
  return new URLSearchParams(
    [...new URLSearchParams(window.location.search)].map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  );
};

type State = "joining" | "joined" | "stopping" | "stopped";

interface Options {
  useAttendeeList: boolean;
  useChat: boolean
}

export default defineComponent({
  data(): {
    userMedia: UserMedia | null;
    displayMedia: DisplayMedia | null;
    deviceManager: MediaDeviceManager;
    apiKey: string;
    durationAttendeeListLoad: number;
    durationChatLoad: number;
    durationLocalMediaLoad: number;
    durationRemoteMediaLoad: number;
    fallbackIfDeviceNotAvailable: boolean;
    identityServiceUrl: string;
    isExactAudioDeviceRequired: boolean;
    isExactVideoDeviceRequired: boolean;
    joinStart: number;
    joinEnd: number;
    localStorageAudioDeviceId: string | null;
    localStorageVideoDeviceId: string | null;
    meeting: Meeting | undefined;
    messages: string[];
    playLocalAudio: boolean;
    state: State;
    useAudio: boolean;
    useVideo: boolean;
    options: Options
  } {
    const params = getParams();
    const defaultApiKey = params.get("apikey") ?? process.env.VUE_APP_API_KEY!;
    const defaultIdentityServiceUrl =
      params.get("identityserviceurl") ??
      process.env.VUE_APP_IDENTITY_SERVICE_URL!;
    if (!defaultApiKey)
      throw new Error("VUE_APP_API_KEY is missing and required.");
    if (!defaultIdentityServiceUrl)
      throw new Error("VUE_APP_IDENTITY_SERVICE_URL is missing and required.");
    return {
      userMedia: null,
      displayMedia: null,
      deviceManager: MediaDeviceManager.shared as MediaDeviceManager,
      apiKey: defaultApiKey,
      durationAttendeeListLoad: 0,
      durationChatLoad: 0,
      durationLocalMediaLoad: 0,
      durationRemoteMediaLoad: 0,
      fallbackIfDeviceNotAvailable: true,
      identityServiceUrl: defaultIdentityServiceUrl,
      isExactAudioDeviceRequired: false,
      isExactVideoDeviceRequired: false,
      joinStart: 0,
      joinEnd: 0,
      localStorageAudioDeviceId: "",
      localStorageVideoDeviceId: "",
      meeting: undefined,
      messages: [],
      playLocalAudio: false, 
      state: "stopped",
      useAudio: true,
      useVideo: true,
      options: {
        useAttendeeList: true,
        useChat: true
      }
    };
  },
  async mounted() {
    Log.level = "debug";
    await this.deviceManager.start();
    this.localStorageAudioDeviceId = localStorage.getItem("audioDeviceId");
    this.localStorageVideoDeviceId = localStorage.getItem("videoDeviceId");

    this.userMedia = new UserMedia(this.useAudio, this.useVideo, { fallbackIfDeviceNotAvailable: this.fallbackIfDeviceNotAvailable });
    await this.userMedia.videoTrack.setFrameSize(false,{ width: 1920, height: 1080 });

    if (this.localStorageAudioDeviceId) await this.userMedia.setAudioDevice(this.localStorageAudioDeviceId);
    if (this.localStorageVideoDeviceId) await this.userMedia.setVideoDevice(this.localStorageVideoDeviceId);
     
    this.userMedia.audioTrack.ended.bind(async () => {
      let permissionStatus = undefined;
      try { permissionStatus = await navigator.permissions.query(<any>{name: 'microphone'}); } catch { /*best effort -- not supported on firefox */ }
      this.log(`Audio track ended. Microphone permission: ${permissionStatus?.state}`);
    });

    this.userMedia.videoTrack.ended.bind(async (e) => {
      let permissionStatus = undefined;
      try { permissionStatus = await navigator.permissions.query(<any>{name: 'camera'}); } catch { /*best effort -- not supported on firefox */ }
      this.log(`Video track ended. Camera permission: ${permissionStatus?.state}`);
    });

    this.userMedia.stateChanged.bind(async (e) => {
      this.log(`Local Media state to ${e.state}.`);
    });

    this.deviceManager.audioInputsUpdated.bind(async (e) => {
      this.log(`Audio Inputs list updated`);
      if (e.added.length) e.added.forEach(d => this.log(`${d.label} audio input added.`));
      if (e.removed.length) e.removed.forEach(d => this.log(`${d.label} audio input removed.`));
      if (e.updated?.length) e.updated.forEach(d => 
        {
          if (d.id == 'default') this.log(`"Default" audio input device changed to '${d.label}'`);
        });
    });

    this.deviceManager.videoInputsUpdated.bind(async (e) => {
      this.log(`Video Inputs list updated`);
      if (e.added.length) e.added.forEach(d => this.log(`${d.label} video input added.`));
      if (e.removed.length) e.removed.forEach(d => this.log(`${d.label} video input removed.`));
      if (e.updated?.length) e.updated.forEach(d => 
        {
          if (d.id == 'default') this.log(`"Default" video input device changed to '${d.label}'`);
        });
    });

    this.deviceManager.audioOutputsUpdated.bind(async (e) => {
      this.log(`Audio Outputs list updated`);
      if (e.added.length) e.added.forEach(d => this.log(`${d.label} audio output added.`));
      if (e.removed.length) e.removed.forEach(d => this.log(`${d.label} audio output removed.`));
      if (e.updated?.length) e.updated.forEach(d => 
        {
          if (d.id == 'default') this.log(`"Default" audio output device changed to '${d.label}'`);
        });
    });
    //@ts-ignore
    globalThis.deviceManager = this.deviceManager;
    //@ts-ignore
    globalThis.userMedia = this.userMedia;
    //@ts-ignore
    globalThis.meeting = this.meeting;

  },
  methods: {
    log(message: string) {
      this.messages.push(message);
    },
    clearLog() {
      this.messages = [];
    },
    
    async toggleLocalMedia() {
      try {
        !this.userMedia?.isStarted ? 
          await this.userMedia?.start() : 
          await this.userMedia?.stop();
      } catch (error: any) {
        this.log(error)
      }
    },
    async toggleAudio() {
      try {
        !this.userMedia?.audioTrack?.isStarted ? 
          await this.userMedia?.startAudio() : 
          await this.userMedia?.stopAudio();
      } catch (error: any) {
        this.log(error)
      }
    },
    async toggleVideo() {
      try {
        !this.userMedia?.videoTrack?.isStarted ? 
          await this.userMedia?.startVideo() : 
          await this.userMedia?.stopVideo();
      } catch (error: any) {
        this.log(error)
      }
    },
    async reset() {
      try {
        this.userMedia = new UserMedia(this.useAudio, this.useVideo, { fallbackIfDeviceNotAvailable: this.fallbackIfDeviceNotAvailable });
      } catch (error: any) {
        this.log(error)
      }
    },
    saveDeviceIds() {
      if (this.userMedia?.audioDeviceId) {
        localStorage.setItem("audioDeviceId", this.userMedia?.audioDeviceId);
        this.localStorageAudioDeviceId = localStorage.getItem("audioDeviceId");
      }
      if (this.userMedia?.videoDeviceId) {
        localStorage.setItem("videoDeviceId", this.userMedia?.videoDeviceId);
        this.localStorageVideoDeviceId = localStorage.getItem("videoDeviceId");
      }
    },
    clearDeviceIds() {
      localStorage.removeItem("audioDeviceId");
      localStorage.removeItem("videoDeviceId");
    },
  }
});
