
import { MediaDeviceManager, Log, EventOwnerAsync } from "@liveswitch/sdk";
import { defineComponent } from "vue";

const getParams = () => {
  return new URLSearchParams(
    [...new URLSearchParams(window.location.search)].map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  );
};

export default defineComponent({
  data(): {
    deviceManager: MediaDeviceManager,
    handleExceptions: boolean,
    messages: string[]
  } {
    return {
      deviceManager: MediaDeviceManager.shared,
      handleExceptions: false,
      messages: []
    };
  },

  async mounted() {
    Log.level = "debug";
    
    EventOwnerAsync.handleUncaughtExceptions = true;

    this.deviceManager.audioInputsUpdated.bind(async (e) => {
      this.log(`Throwing error on audio inputs updated...`);
      throw new Error('Test error');
    });
 
    //@ts-ignore
    globalThis.deviceManager = this.deviceManager;
  },

  methods: {
    log(message: string) {
      this.messages.push(message);
    },
    clearLog() {
      this.messages = [];
    },
    async startDeviceManager() {
      await this.deviceManager.start();
    },
    async stopDeviceManager() {
      await this.deviceManager.stop();
    }
  },
  watch: {
    handleExceptions: {
      immediate: true,
      handler(value: boolean) {
        console.log('Setting uncaught exceptions to', value);
        EventOwnerAsync.handleUncaughtExceptions = value;
      }
    }
  }
});
