import ApiClient from "../api/Client";
import Attendee from "../Attendee";
import Channel from "./Channel";
import ControlConnection from "../control/Connection";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import MessageEvent from "./models/MessageEvent";
import MessageInit from "./models/MessageInit";
import MessageModel from "../api/models/ChatMessage";
import MessagePriority from "./models/MessagePriority";
import MessageType from "./models/MessageType";
import MessageUpdateOptions from "./models/MessageUpdateOptions";
import { UploadClient, FileInfo } from "@liveswitch/storage";

export default class Message {
  private readonly _apiClient: ApiClient;
  private readonly _attendee: Attendee;
  private readonly _channel: Channel;
  private readonly _controlConnection: ControlConnection;
  private readonly _updated = new EventOwnerAsync<MessageEvent>();
  private readonly _uploadClient: UploadClient;

  //private _file?: File;
  private _fileInfo?: FileInfo;
  private _model: MessageModel;

  public get attendee(): Attendee {
    return this._attendee;
  }
  public get channel(): Channel {
    return this._channel;
  }
  public get file(): File {
    return this._fileInfo?.Content;
  }
  public get id(): string {
    return this._model.id;
  }
  public get isAudio(): boolean {
    return this._model.messageType == "AUDIO";
  }
  public get isCard(): boolean {
    return this._model.messageType == "CARD";
  }
  public get isImage(): boolean {
    return this._model.messageType == "IMAGE";
  }
  public get isFile(): boolean {
    return this._model.messageType == "FILE";
  }
  public get isFlagged(): boolean {
    return !!this._model.flagged;
  }
  public get inlineContent(): string | undefined {
    if(this._model.messageContent.startsWith("data:"))
      return this._model.messageContent;
    return undefined;
  }
  public get fileName(): string {
    return this._fileInfo?.FileName || "";
  }
  public get isDownloadable(): boolean{
    return !!this._model.mediaId;
  }
  public get isText(): boolean {
    return this._model.messageType == "TEXT";
  }
  public get isVideo(): boolean {
    return this._model.messageType == "VIDEO";
  }
  public get priority(): MessagePriority {
    return this._model.messagePriority;
  }
  public get text(): string {
    return this._model.messageContent;
  }
  public get timestamp(): Date {
    return this._model.created;
  }
  public get type(): MessageType {
    return this._model.messageType;
  }
  /** @event */
  public get updated(): EventOwnerAsync<MessageEvent> {
    return this._updated;
  }

  /** @internal */
  constructor(init: MessageInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(init.attendee, "init.attendee");
    Guard.isNotNullOrUndefined(init.channel, "init.channel");
    Guard.isNotNullOrUndefined(
      init.controlConnection,
      "init.controlConnection"
    );
    Guard.isNotNullOrUndefined(init.model, "init.model");
    this._apiClient = init.apiClient;
    this._attendee = init.attendee;
    this._channel = init.channel;
    this._controlConnection = init.controlConnection;
    this._model = init.model;
    this._uploadClient = init.uploadClient;
  }

  /** @internal */
  public async load(): Promise<void> {
    await this.download();
  }

  /** @internal */
  public async refreshModel(messageModel?: MessageModel): Promise<void> {
    if (!messageModel) {
      const response = await this._apiClient.getChatMessage(this._model.id);
      if (response == null || response.value == null)
        throw new Error("Failed to get chat message");

      messageModel = response.value;
    }
    if (!messageModel) return;

    this._model = messageModel;
    await this.download();
    await this._updated.dispatch({
      message: this,
    });
  }

  public async delete(): Promise<void> {
    const response = await this._controlConnection.sendChatMessageNotification({
      channelId: this.channel.id,
      messageId: this._model.id,
      messageStatus: "DELETED",
      sentBy: this._attendee.id,
    });

    if (response == null || response.chatMessageNotification == null)
      throw new Error("Failed to delete chat message");
    await this._channel.removeMessageInternal(this._model.id);
  }

  public async download(): Promise<void> {
    if (this._model.mediaId) {
      try{
        const content = JSON.parse(this._model.messageContent);
        if(content)
        {
          const fileInfo = await this._uploadClient.GetMediaFile(this._model.mediaId, "ATTENDEE");
          this._fileInfo = fileInfo;
        }
      }catch(ex){ /* empty */ }
    }
  }

  public async flag(): Promise<void> {
    await this._controlConnection.sendChatMessageNotification({
      channelId: this._channel.id,
      messageId: this._model.id,
      messageStatus: "FLAGGED",
      sentBy: this._attendee.id,
    });
    await this.refreshModel();
  }
  
  public async update(options: MessageUpdateOptions) {
    Guard.isNotNullOrUndefined(options, "options");
    const response = await this._controlConnection.sendChatChannelNotification({
      channelId: this._channel.id,
      channelName: this._channel.name,
      channelStatus: this._channel.status,
      channelType: this._channel.type,
    });

    if (response == null || response.chatChannelNotification == null)
      throw new Error("Failed to update chat channel");

    //clone _model and update the content
    const model = { ...this._model };
    model.messageContent = options.content;
    model.messagePriority = options.priority;
    await this.refreshModel(model);
  }
}
