
export default class StatisticAudio  {
    // TODO: Use javascript naming conventions
  public AttendeeId : string = null;
  public OriginAttendeeId : string = null;
  public DeviceId : number = null;
  public ServerId: number = null;

  public MediaType : string = null;
  public TrackIndex : number = null;
  public TrackMid: number = null;
  public TrackIdentifier: string = null;
  public TrackPriority: string = null;

  public IsClient : boolean = null;
  public IsDisabled: boolean = null;
  public IsMuted: boolean = null;
  public CreatedOn: Date = null;

  public Json: string = null;

  //constraints
  public BitrateActual : number = null;
  public BitrateAllocation : number = null;
  public BitrateConstraint : number = null;
  public BitrateEstimated : number = null;
  public BitrateServer : number = null;
  public FramerateActual : number = null;
  public FramerateConstraint : number = null;
  public FramerateEstimated : number = null;
  public FramerateServer : number = null;
  
  //internal is omitted
  public Int_FramesLost: number = null;
  public Int_PacketLoss: number = null;

  //media-source
  public Src_AudioLevel : number = null;
  public Src_TotalAudioEnergy : number = null;
  public Src_TotalSamplesDuration : number = null;

  //inbound-rtp
  public Rtp_Kind: string = null;
  public Rtp_SSRC : number = null;
  public Rtp_IsRemote: boolean = null;
  public Rtp_FecPacketsDiscarded : number = null;
  public Rtp_FecPacketsReceived : number = null;
  public Rtp_PacketsDiscarded: number = null;
  public Rtp_PacketsLost: number = null;
  public Rtp_PacketsReceived : number = null;
  public Rtp_BytesReceived : number = null;
  public Rtp_HeaderBytesReceived : number = null;
  public Rtp_Jitter : number = null;

  //outbound-rtp
  public Rtp_PacketsSent : number = null;
  public Rtp_RetransmittedPacketsSent : number = null;
  public Rtp_BytesSent : number = null;
  public Rtp_HeaderBytesSent : number = null;
  public Rtp_RetransmittedBytesSent : number = null;
  public Rtp_TotalPacketSendDelay: number = null;
  public Rtp_NackCount: number = null;

  //remote-inbound-rtp
  public Rtp_Timestamp: DOMHighResTimeStamp = null;
  public Rtp_RoundTripTime : number = null;
  public Rtp_RoundTripTimeMeasurements: number = null;
  public Rtp_RoundTripTimeTotal: number = null;

  //remote-outbound-rtp
  public Rtp_ReportsSent: number = null;

  //track
  public Track_Kind: string = null;
  public Track_IsEnded: boolean = null;
  public Track_IsDetached: boolean = null;
  public Track_IsRemote: boolean = null;
  public Track_JitterBufferDelay : number = null;
  public Track_JitterBufferFlushes : number = null;
  public Track_JitterBufferEmittedCount : number = null;
  public Track_JitterBufferMinimumDelay: number = null;
  public Track_JitterBufferTargetDelay : number = null;
  public Track_TotalAudioEnergy : number = null;
  public Track_TotalSamplesReceived : number = null;
  public Track_TotalSamplesDuration : number = null;
  public Track_ConcealedSamples : number = null;
  public Track_SilentConcealedSamples : number = null;
  public Track_ConcealmentEvents : number = null;
  public Track_InsertedSamples : number = null;
  public Track_RemovedSamples : number = null;
  public Track_DelayedPacketOutageSamples : number = null;
  public Track_RelativePacketArrivalDelay : number = null;
  public Track_InterruptionCount : number = null;
  public Track_TotalInterruptionDuration : number = null;

  //transport
  public Transport_State: string = null;
  public Transport_Timestamp: DOMHighResTimeStamp = null;
  public Transport_BytesSent : number = null;
  public Transport_BytesReceived : number = null;
  public Transport_PacketsSent: number = null;
  public Transport_PacketsReceived: number = null;

  //codec
  public Codec_MimeType: string = null;
  public Codec_Channels: number = null;
  public Codec_ClockRate: number = null;

  //media-playout
  public Mpo_SynthSamplesDuration: number = null;
  public Mpo_SynthSamplesEvents: number = null;
  public Mpo_TotalPlayoutDelay: number = null;
  public Mpo_TotalSamplesCount: number = null;
  public Mpo_TotalSamplesDuration: number = null;


}