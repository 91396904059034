
export default class StatisticVideo  {
      // TODO: Use javascript naming conventions
  public AttendeeId : string = null;
  public OriginAttendeeId : string = null;
  public DeviceId : number = null;
  public ServerId : number = null;
  public MediaType : string = null;
  public TrackIndex : number = null;
  public TrackMid: number = null;
  public TrackIdentifier: string = null;
  public TrackPriority: string = null;

  public SpatialLayerIndex: number = null;
  public TemporalLayerIndex : number = null;
  public ResolutionHeight : number = null;
  public ResolutionWidth : number = null;

  public IsClient : boolean = null;
  public IsDeactivated: boolean = null;
  public IsDisabled: boolean = null;
  public IsMuted: boolean = null;
  public IsPaused: boolean = null;
  public CreatedOn: Date = null;

  public Json: string = null;

  //constraints
  public BitrateActual : number = null;
  public BitrateAllocation : number = null;
  public BitrateConstraint : number = null;
  public BitrateEstimated : number = null;
  public BitrateServer : number = null;
  public FramerateActual : number = null;
  public FramerateConstraint : number = null;
  public FramerateEstimated : number = null;
  public FramerateServer : number = null;
  public PixelCountActual : number = null;
  public PixelCountConstraint : number = null;
  public PixelCountEstimated : number = null;
  public PixelCountServer : number = null;

  //internal omitted

  public Int_FramesLost: number = null;
  public Int_PacketLoss: number = null;
  public Int_NackPercentage: number = null;

  //media-source
  public Src_FramesPerSecond : number = null;
  public Src_Frames: number = null;

  //inbound-rtp
  public Rtp_Kind : string = null;
  public Rtp_SSRC : number = null;
  public Rtp_IsRemote : boolean = null;
  public Rtp_FirCount : number = null;
  public Rtp_PliCount : number = null;
  public Rtp_NackCount : number = null;
  public Rtp_PacketsReceived : number = null;
  public Rtp_BytesReceived : number = null;
  public Rtp_HeaderBytesReceived : number = null;
  public Rtp_PacketsLost : number = null;
  public Rtp_FramesAssembled: number = null;
  public Rtp_FramesDecoded : number = null;
  public Rtp_KeyFramesDecoded : number = null;
  public Rtp_TotalAssemblyTime: number = null;
  public Rtp_TotalDecodeTime : number = null;
  public Rtp_TotalInterframeDelay : number = null;
  public Rtp_TotalProcessingDelay: number = null;
  public Rtp_DecoderImplementation : string = null;

  //remote-inbound-rtp
  public Rtp_Timestamp: DOMHighResTimeStamp = null;
  public Rtp_Jitter : number = null;
  public Rtp_RoundTripTime : number = null;
  public Rtp_RoundTripTimeMeasurements: number = null;
  public Rtp_RoundTripTimeTotal: number = null;

  //outbound-rtp
  public Rtp_PacketsSent : number = null;
  public Rtp_RetransmittedPacketsSent : number = null;
  public Rtp_BytesSent : number = null;
  public Rtp_HeaderBytesSent : number = null;
  public Rtp_RetransmittedBytesSent : number = null;
  public Rtp_FramesEncoded : number = null;
  public Rtp_KeyFramesEncoded : number = null;
  public Rtp_TotalEncodeTime : number = null;
  public Rtp_TotalEncodedBytesTarget : number = null;
  public Rtp_FramesPerSecond : number = null;
  public Rtp_FramesSent : number = null;
  public Rtp_HugeFramesSent : number = null;
  public Rtp_TotalPacketSendDelay : number = null;
  public Rtp_QualityLimitationChanges : number = null;
  public Rtp_QualityLimitationDurations: string = null;
  public Rtp_QualityLimitationReason: string = null;
  public Rtp_EncoderImplementation : string = null;
  public Rtp_QpSum: number = null;
  public Rtp_IsPowerEfficient: boolean = null;
  public Rtp_ScalabilityMode: string = null;

  //track
  public Track_Kind: string = null;
  public Track_IsEnded: boolean = null;
  public Track_IsDetached: boolean = null;
  public Track_IsRemote: boolean = null;
  public Track_JitterBufferDelay : number = null;
  public Track_JitterBufferEmittedCount : number = null;
  public Track_JitterBufferMinimumDelay: number = null;
  public Track_JitterBufferTargetDelay: number = null;
  public Track_FramesReceived : number = null;
  public Track_FramesDecoded : number = null;
  public Track_FramesDelivered: number = null;
  public Track_FramesDiscarded: number = null;
  public Track_FramesDropped : number = null;
  public Track_FramesTotal: number = null;
  public Track_FreezeCount : number = null;
  public Track_PauseCount : number = null;
  public Track_TotalFreezesDuration : number = null;
  public Track_TotalPausesDuration : number = null;
  public Track_TotalFramesDuration : number = null;
  public Track_FrameHeight : number = null;
  public Track_FrameWidth : number = null;
  public Track_FramesSent : number = null;
  public Track_HugeFramesSent : number = null;

  //transport
  public Transport_State: string = null;
  public Transport_Timestamp: DOMHighResTimeStamp = null;
  public Transport_BytesSent: number = null;
  public Transport_BytesReceived: number = null;
  public Transport_PacketsSent: number = null;
  public Transport_PacketsReceived: number = null;

  //codec
  public Codec_MimeType: string = null;
  public Codec_Channels: number = null;
  public Codec_ClockRate: number = null;
}