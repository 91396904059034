import UserMediaOptions from "./UserMediaOptions";
import LocalMedia from "./LocalMedia";
import UserAudioTrack from "./UserAudioTrack";
import UserVideoTrack from "./UserVideoTrack";

export default class UserMedia extends LocalMedia {
  public get audioDeviceId(): string { return this.audioTrack?.deviceId ?? null; }
  public get audioTrack(): UserAudioTrack { return <UserAudioTrack>super.audioTrack; }
  public get requestedAudioDeviceId(): string { return this.audioTrack?.requestedDeviceId ?? null; }
  public get requestedAudioDeviceRequired(): boolean { return this.audioTrack?.requestedDeviceRequired ?? false; }
  public get requestedVideoDeviceId(): string { return this.videoTrack?.requestedDeviceId ?? null; }
  public get requestedVideoDeviceRequired(): boolean { return this.videoTrack?.requestedDeviceRequired ?? false; }
  public get videoDeviceId(): string { return this.videoTrack?.deviceId ?? null; }
  public get videoFacingMode(): string { return this.videoTrack?.facingMode ?? null; }
  public get videoTrack(): UserVideoTrack { return <UserVideoTrack>super.videoTrack; }

  public constructor(audio: boolean, video: boolean, deviceOptions: UserMediaOptions = {}) {
    super(audio ? new UserAudioTrack() : null, "user", video ? new UserVideoTrack() : null, deviceOptions);
  }

  protected getStream(constraints: MediaStreamConstraints): Promise<MediaStream> {
    return globalThis.navigator.mediaDevices.getUserMedia(constraints);
  }

  /** @internal */
  public muteAudioInternal(): void {
    this.audioTrack?.muteInternal();
  }

  /** @internal */
  public muteVideoInternal(): void {
    this.videoTrack?.muteInternal();
  }

  /** @internal */
  public unmuteAudioInternal(): void {
    this.audioTrack?.unmuteInternal();
  }

  /** @internal */
  public unmuteVideoInternal(): void {
    this.videoTrack?.unmuteInternal();
  }

  public muteAudio(): Promise<void> {
    return this.audioTrack?.mute();
  }

  public muteVideo(): Promise<void> {
    return this.videoTrack?.mute();
  }

  public async setAudioDevice(deviceId?: string, required?: boolean): Promise<void> {
    await this.audioTrack?.setDevice(deviceId, required);
  }
  
  public async setVideoDevice(deviceId?: string, required?: boolean): Promise<void> {
    await this.videoTrack?.setDevice(deviceId, required);
  }

  public unmuteAudio(): Promise<void> {
    return this.audioTrack?.unmute();
  }

  public unmuteVideo(): Promise<void> {
    return this.videoTrack?.unmute();
  }
  
  public async useNextAudioDevice(): Promise<void> {
    await this.audioTrack?.useNextDevice();
  }
  
  public async useNextVideoDevice(): Promise<void> {
    await this.videoTrack?.useNextDevice();
  }
  
  public async usePreviousAudioDevice(): Promise<void> {
    await this.audioTrack?.usePreviousDevice();
  }
  
  public async usePreviousVideoDevice(): Promise<void> {
    await this.videoTrack?.usePreviousDevice();
  }
}
