import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "chat-bubble" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.canDeleteMessage)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteChatMessage && _ctx.deleteChatMessage(...args))),
          class: "delete-message"
        }, "x"))
      : _createCommentVNode("", true),
    _createElementVNode("h4", null, _toDisplayString(_ctx.displayName), 1),
    (_ctx.message.isText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message.text), 1))
      : _createCommentVNode("", true),
    (_ctx.message.isImage && _ctx.message.inlineContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.message.inlineContent
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.message.isAudio && _ctx.message.inlineContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Mock Audio Controls "))
      : _createCommentVNode("", true),
    (_ctx.message.isVideo && _ctx.message.inlineContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Mock Video Controls "))
      : _createCommentVNode("", true),
    (_ctx.message.isDownloadable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createTextVNode(_toDisplayString(_ctx.message.fileName) + " ", 1),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.downloadFile && _ctx.downloadFile(...args)))
          }, "Download")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h6", null, _toDisplayString(_ctx.message.timestamp), 1)
  ]))
}