
// Display Values

import TenantSetting from "../../api/models/TenantSetting";
import Log from "../../logging/Log";
import MediaType from "../../media/models/MediaType";

//TODO: these are inconsistently named, should all be thresholds and conform to naming pattern

// Video
export const VideoOriginFramerateDisplayMin = "ORIGIN:FRAMERATE:DISPLAY:MIN";
export const VideoOriginPixelCountDisplayMin = "ORIGIN:PIXELCOUNT:DISPLAY:MIN";
export const VideoOriginJitterDisplayLow = "ORIGIN:JITTER:DISPLAY:LOW";
export const VideoOriginJitterDisplayMedium = "ORIGIN:JITTER:DISPLAY:MEDIUM";
export const VideoOriginJitterDisplayHigh = "ORIGIN:JITTER:DISPLAY:HIGH";
export const VideoOriginPacketLossDisplayLow = "ORIGIN:PACKETLOSS:DISPLAY:LOW";
export const VideoOriginPacketLossDisplayMedium = "ORIGIN:PACKETLOSS:DISPLAY:MEDIUM";
export const VideoOriginPacketLossDisplayHigh = "ORIGIN:PACKETLOSS:DISPLAY:HIGH";
// Audio
export const AudioOriginJitterThresholdDisplayLow =  "ORIGIN:AUDIO:JITTERTHRESHOLD:DISPLAY:LOW";
export const AudioOriginJitterThresholdDisplayMedium =  "ORIGIN:AUDIO:JITTERTHRESHOLD:DISPLAY:MEDIUM";
export const AudioOriginJitterThresholdDisplayHigh =  "ORIGIN:AUDIO:JITTERTHRESHOLD:DISPLAY:HIGH";
export const AudioOriginPacketLossThresholdDisplayLow = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:DISPLAY:LOW";
export const AudioOriginPacketLossThresholdDisplayMedium = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:DISPLAY:MEDIUM";
export const AudioOriginPacketLossThresholdDisplayHigh = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:DISPLAY:HIGH";


// User Values
// Video
export const VideoOriginFramerateUserMin = "ORIGIN:FRAMERATE:USER:MIN";
export const VideoOriginPixelCountUserMin = "ORIGIN:PIXELCOUNT:USER:MIN";
export const VideoOriginJitterUserLow = "ORIGIN:JITTER:USER:LOW";
export const VideoOriginJitterUserMedium = "ORIGIN:JITTER:USER:MEDIUM";
export const VideoOriginJitterUserHigh = "ORIGIN:JITTER:USER:HIGH";
export const VideoOriginPacketLossUserLow = "ORIGIN:PACKETLOSS:USER:LOW";
export const VideoOriginPacketLossUserMedium = "ORIGIN:PACKETLOSS:USER:MEDIUM";
export const VideoOriginPacketLossUserHigh = "ORIGIN:PACKETLOSS:USER:HIGH";
// Audio
export const AudioOriginJitterThresholdUserLow =  "ORIGIN:AUDIO:JITTERTHRESHOLD:USER:LOW";
export const AudioOriginJitterThresholdUserMedium =  "ORIGIN:AUDIO:JITTERTHRESHOLD:USER:MEDIUM";
export const AudioOriginJitterThresholdUserHigh =  "ORIGIN:AUDIO:JITTERTHRESHOLD:USER:HIGH";
export const AudioOriginPacketLossThresholUserLow = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:USER:LOW";
export const AudioOriginPacketLossThresholdUserMedium = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:USER:MEDIUM";
export const AudioOriginPacketLossThresholdUserHigh = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:USER:HIGH";

export default class ImpairmentThreshold {

  public audio = new class{
  public jitterThresholdLow: number = 30;
  public jitterThresholdMedium: number = 79;
  public jitterThresholdHigh: number = 120;
  public nackThresholdLow: number = 0.01;
  public nackThresholdMedium: number = 0.03;
  public nackThresholdHigh: number = 0.05;
  public packetLossThresholdLow: number = 0.01;
  public packetLossThresholdMedium: number = 0.03;
  public packetLossThresholdHigh: number = 0.05;

}
  public video = new class {
    public jitterThresholdLow: number = 30;
    public jitterThresholdMedium: number = 79;
    public jitterThresholdHigh: number = 120;
    public nackThresholdLow: number = 0.01;
    public nackThresholdMedium: number = 0.03;
    public nackThresholdHigh: number = 0.05;
    public packetLossThresholdLow: number = 0.01;
    public packetLossThresholdMedium: number = 0.03;
    public packetLossThresholdHigh: number = 0.05;
    public pliThresholdLow: number = 0.01;
    public pliThresholdMedium: number = 0.03;
    public pliThresholdHigh: number = 0.05;
  }

  public parseSettings(settings: TenantSetting[], mediaType: MediaType) {
    settings.forEach(setting => {
      try {
        if (mediaType === "display") {
          switch (setting.settingName) {
            //KB pulled from room not tenant setting

            case VideoOriginJitterDisplayLow: {
              this.video.jitterThresholdLow = Number.parseInt(setting.settingValue);
              break;
            }
            case VideoOriginJitterDisplayMedium: {
              this.video.jitterThresholdMedium = Number.parseInt(setting.settingValue);
              break;
            }
            case VideoOriginJitterDisplayHigh: {
              this.video.jitterThresholdHigh = Number.parseInt(setting.settingValue);
              break;
            }
            //TODO: missing video nack thresholds
            case VideoOriginPacketLossDisplayLow: {
              this.video.packetLossThresholdLow = Number.parseFloat(setting.settingValue);
              break;
            }
            case VideoOriginPacketLossDisplayMedium: {
              this.video.packetLossThresholdMedium = Number.parseFloat(setting.settingValue);
              break;
            }
            case VideoOriginPacketLossDisplayHigh: {
              this.video.packetLossThresholdHigh = Number.parseFloat(setting.settingValue);
              break;
            }
            //TODO: missing video PLI thresholds

            // Audio Impairment Thresholds - (Display)
            case AudioOriginJitterThresholdDisplayLow: {
              this.audio.jitterThresholdLow = Number.parseInt(setting.settingValue);
              break;
            }
            case AudioOriginJitterThresholdDisplayMedium: {
              this.audio.jitterThresholdMedium = Number.parseInt(setting.settingValue);
              break;
            }
            case AudioOriginJitterThresholdDisplayHigh: {
              this.audio.jitterThresholdHigh = Number.parseInt(setting.settingValue);
              break;
            }
            //TODO: missing audio nack thresholds
            case AudioOriginPacketLossThresholdDisplayLow: {
              this.audio.packetLossThresholdLow = Number.parseFloat(setting.settingValue);
              break;
            }
            case AudioOriginPacketLossThresholdDisplayMedium: {
              this.audio.packetLossThresholdMedium = Number.parseFloat(setting.settingValue);
              break;
            }
            case AudioOriginPacketLossThresholdDisplayHigh: {
              this.audio.packetLossThresholdHigh = Number.parseFloat(setting.settingValue);
              break;
            }
            // END Audio Settings
          }
        } else if (mediaType === "user") {
          switch (setting.settingName) {
            //KB pulled from room not tenant setting

            case VideoOriginJitterUserLow: {
              this.video.jitterThresholdLow = Number.parseInt(setting.settingValue);
              break;
            }
            case VideoOriginJitterUserMedium: {
              this.video.jitterThresholdMedium = Number.parseInt(setting.settingValue);
              break;
            }
            case VideoOriginJitterUserHigh: {
              this.video.jitterThresholdHigh = Number.parseInt(setting.settingValue);
              break;
            }
            //TODO: missing video nack thresholds
            case VideoOriginPacketLossUserLow: {
              this.video.packetLossThresholdLow = Number.parseFloat(setting.settingValue);
              break;
            }
            case VideoOriginPacketLossUserMedium: {
              this.video.packetLossThresholdMedium = Number.parseFloat(setting.settingValue);
              break;
            }
            case VideoOriginPacketLossUserHigh: {
              this.video.packetLossThresholdHigh = Number.parseFloat(setting.settingValue);
              break;
            }
            //TODO: missing video PLI thresholds

            // Audio Impairment Thresholds - Settings (User)
            case AudioOriginJitterThresholdUserLow: {
              this.audio.jitterThresholdLow = Number.parseInt(setting.settingValue);
              break;
            }
            case AudioOriginJitterThresholdUserMedium: {
              this.audio.jitterThresholdMedium = Number.parseInt(setting.settingValue);
              break;
            }
            case AudioOriginJitterThresholdUserHigh: {
              this.audio.jitterThresholdHigh = Number.parseInt(setting.settingValue);
              break;
            }
            //TODO: missing audio nack thresholds
            case AudioOriginPacketLossThresholUserLow: {
              this.audio.packetLossThresholdLow = Number.parseFloat(setting.settingValue);
              break;
            }
            case AudioOriginPacketLossThresholdUserMedium: {
              this.audio.packetLossThresholdMedium = Number.parseFloat(setting.settingValue);
              break;
            }
            case AudioOriginPacketLossThresholdUserHigh: {
              this.audio.packetLossThresholdHigh = Number.parseFloat(setting.settingValue);
              break;
            }
            // END Audio Settings
          }
        }

      } catch (err: any) {
        //TODO: change to eventLogger
        Log.error("Error parsing TenantSetting: ${setting.settingName}=${setting.settingValue} Type=${setting.settingType}", err);
      }
    });

  }

}