import ClientModel from "./models/Client";
import ConnectionBase from "../Connection";
import ConnectionInit from "./models/ConnectionInit";
import Constraints from "./models/Constraints";
import Guard from "../core/Guard";
import PromiseCompletionSource from "../core/PromiseCompletionSource";
import Reactive from "../core/Reactive";

import DispatchQueue from "../core/DispatchQueue";
import Log from "../logging/Log";
import EventLogger from "../event/Logger";
import EventOwnerAsync from "../core/EventOwnerAsync";

import Message from "./models/Message";

import LocalMedia from "../media/LocalMedia";
import LocalMediaOptions from "../models/LocalMediaOptions";
import LocalTrackPriority from "../models/LocalTrackPriority";


import Track from "../models/Track";
import TrackType from "../media/models/TrackType";
//import StepType from "./models/StepType";
import MediaEvent from "./models/MediaEvent";
import MediaType from "../media/models/MediaType";

import StatisticConnection from "../models/StatisticConnection";
import StatisticAudio from "../models/StatisticAudio";
import StatisticVideo from "../models/StatisticVideo";

import Bitrate from "../core/Bitrate";
import Framerate from "../core/Framerate";
import DeltaCounter from "../core/DeltaCounter";

import ImpairmentThreshold from "./models/TenantSettings";
import ImpairmentLevel from "../models/ImpairmentLevel";


//TODO: move to shared constants
const trackTypes: TrackType[] = ["audio", "video"];

export default class Connection extends ConnectionBase<Message> {
  private readonly _audioLevelInterval?: number;
  private readonly _client: ClientModel;
  private readonly _mediaChannel: RTCDataChannel;
  private readonly _mediaNotificationQueue = new DispatchQueue();
  private readonly _mediaOptions: LocalMediaOptions;
  private readonly _mediaRejected = new EventOwnerAsync<MediaEvent>();
  private readonly _mediaReplaced = new EventOwnerAsync<MediaEvent>();
  private readonly _mediaType: MediaType;
  private readonly _onAudioTrackStreamBound: () => Promise<void>;
  private readonly _onAudioTrackStreamUnbound: () => void;
  private readonly _onMediaChannelClose: () => void;
  private readonly _onMediaChannelClosing: () => void;
  private readonly _onMediaChannelError: (ev: any) => void;
  private readonly _onMediaChannelMessage: (ev: MessageEvent<any>) => any;
  private readonly _onMediaChannelOpen: () => void;
  private readonly _onMediaStateChanged: () => Promise<void>;
  //private readonly _onVideoTrackFrameSizeChanged: () => void;
  private readonly _onVideoTrackStreamBound: () => Promise<void>;
  private readonly _onVideoTrackStreamUnbound: () => void;
  private readonly _replicationCount?: number;

  private readonly _transceivers = new Map<TrackType, Track>();

  //TODO: magic numbers
  private _jitterHigh = 120;
  private _jitterMedium = 79;
  private _jitterLow = 30;
  private _packetLossHigh = .28;
  private _packetLossMedium = .08;
  private _packetLossLow = .03;

  private _answerUpdated: PromiseCompletionSource<Message>;
  private _encodingEnabled = true;
  private _media: LocalMedia = null;

  private _minAudioBitrate = 12;
  private _minVideoBitrate = 20;
  private _minVideoFramerate = 12;
  private _minVideoHeight = 240;
  private _minVideoWidth = 320;

  //TODO: need to pull impairment range from room definition, or calculate?
  private _maxAudioBitrate = 96;
  private _audioBitrateImpairment: ImpairmentLevel = ImpairmentLevel.unimpaired;
  private _lastMaxAudioBitrate:number = null;

  private _maxVideoBitrate = 1400;
  private _videoBitrateImpairment: ImpairmentLevel = ImpairmentLevel.unimpaired;
  private _lastMaxVideoBitrate:number = null;

  private _maxVideoFramerate = 25;
  private _videoFramerateImpairment: ImpairmentLevel = ImpairmentLevel.unimpaired;
  private _lastMaxVideoFramerate:number = null;

  private _maxVideoHeight = 1080;
  private _maxVideoWidth = 1920;
  private _videoResolutionImpairment: ImpairmentLevel = ImpairmentLevel.unimpaired;
  private _lastResolutionScale:number = null;
  
  private _maxRecordHeight = 720;
  private _maxRecordWidth = 1280;

  private _thresholds: ImpairmentThreshold = new ImpairmentThreshold();

  private _priorityAudio: LocalTrackPriority = "high";
  private _priorityVideo: LocalTrackPriority = "high";
  private _priorityVideoImpairment: ImpairmentLevel = ImpairmentLevel.unimpaired;

  private _packetLoss: DeltaCounter = new DeltaCounter();
  private _nackCount: DeltaCounter = new DeltaCounter();
  private _pliCount: DeltaCounter = new DeltaCounter();

  private _statsBatchAudio: Array<StatisticAudio> = [];
  private _statsBatchVideo: Array<StatisticVideo> = [];
  //TODO: from setting, or at least a shared constant
  private _statsPollingInterval = 5;  //how often to save the stats
  private _statsBatchSize = 6; //how many saves before sending to server
  private _currentStatCount = 0;
  private _lastPairState: string = null;

  //TODO: these should belong on an aggregate Track object instead of in arrays like all the other variables. It'll just be wrong sometimes for now
  private _bitrateAudio = new Bitrate();
  private _bitrateVideo = new Bitrate();
  private _framerateVideo = new Framerate();

  public get audioBitrateMax(): number { return this._maxAudioBitrate; }
  public get media(): LocalMedia { return this._media; }
  public get mediaRejected(): EventOwnerAsync<MediaEvent> { return this._mediaRejected; }
  public get mediaReplaced(): EventOwnerAsync<MediaEvent> { return this._mediaReplaced; }
  public get mediaType(): MediaType { return this._mediaType; }
  public get priorityAudio(): LocalTrackPriority { return this._priorityAudio; }
  public get priorityVideo(): LocalTrackPriority { return this._priorityVideo; }
  public get videoBitrateMax(): number { return this._maxVideoBitrate; }
  
  public get videoFramerateMin(): number { return this._minVideoFramerate; }
  public get videoHeightMin(): number { return this._minVideoHeight; }
  public get videoWidthMin(): number { return this._minVideoWidth; }
  public get videoFramerateMax(): number { return this._maxVideoFramerate; }
  public get videoHeightMax(): number { return (this._maxRecordHeight > this._maxVideoHeight) ? this._maxRecordHeight : this._maxVideoHeight; }
  public get videoWidthMax(): number { return (this._maxRecordWidth > this._maxVideoWidth) ? this._maxRecordWidth : this._maxVideoWidth; }

  public constructor(init: ConnectionInit) {
    super({
      attendeeId: init.attendeeId,
      eventLogger: new EventLogger(init.apiClient, "OriginConnection", init.attendeeId, init.meetingId, init.clusterId),
      iceRestartEnabled: init.iceRestartEnabled,
      meetingId: init.meetingId,
      turnRequired: init.turnRequired,
      turnSession: init.turnSession,
      type: `Origin (${init.mediaType})`,
    });
    this._audioLevelInterval = init.audioLevelInterval;
    this._client = init.client;
    this._mediaOptions = init.mediaOptions;
    this._mediaOptions.webRtcDegradationPreferenceEnabled ??= false;
    this._mediaType = init.mediaType;
    this._replicationCount = init.replicationCount;
    this._statsBatchAudio = [];
    this._statsBatchVideo = [];
    this._bitrateAudio = new Bitrate();
    this._bitrateVideo = new Bitrate();
    this._framerateVideo = new Framerate();

    if (init.room) {
      const room = init.room;

      if (room.maxAudioBitrate) this._maxAudioBitrate = room.maxAudioBitrate;
      if (room.maxVideoBitrate) this._maxVideoBitrate = room.maxVideoBitrate;

      if (room.minAudioBitrate) this._minAudioBitrate = room.minAudioBitrate;
      if (room.minVideoBitrate) this._minVideoBitrate = room.minVideoBitrate;

      //continuing with pattern of flattening by type
      if (this._mediaType === "display") {

        if (room.minVideoFramerateDisplay) this._minVideoFramerate = room.minVideoFramerateDisplay;
        if (room.minVideoHeightDisplay) this._minVideoHeight = room.minVideoHeightDisplay;
        if (room.minVideoWidthDisplay) this._minVideoWidth = room.minVideoWidthDisplay;

        if (room.maxVideoFramerateDisplay) this._maxVideoFramerate = room.maxVideoFramerateDisplay;
        if (room.maxVideoHeightDisplay) this._maxVideoHeight = room.maxVideoHeightDisplay;
        if (room.maxVideoWidthDisplay) this._maxVideoWidth = room.maxVideoWidthDisplay;

        if (room.maxRecordHeightDisplay) this._maxRecordHeight = room.maxRecordHeightDisplay;
        if (room.maxRecordWidthDisplay) this._maxRecordWidth = room.maxRecordWidthDisplay;

      } else if (this._mediaType === "user") {

        if (room.minVideoFramerateUser) this._minVideoFramerate = room.minVideoFramerateUser;
        if (room.minVideoHeightUser) this._minVideoHeight = room.minVideoHeightUser;
        if (room.minVideoWidthUser) this._minVideoHeight = room.minVideoWidthUser;

        if (room.maxVideoFramerateUser) this._maxVideoFramerate = room.maxVideoFramerateUser;
        if (room.maxVideoHeightUser) this._maxVideoHeight = room.maxVideoHeightUser;
        if (room.maxVideoWidthUser) this._maxVideoWidth = room.maxVideoWidthUser;

        if (room.maxRecordHeightUser) this._maxRecordHeight = room.maxRecordHeightUser;
        if (room.maxRecordWidthUser) this._maxRecordWidth = room.maxRecordWidthUser;
      }


    }


    if (init.tenantSettings && init.tenantSettings.length > 0) {
      const settings = init.tenantSettings;

      //loads impairment thresholds from settings
      this._thresholds.parseSettings(settings, this._mediaType);

      settings.forEach(setting => {
        try {

          switch (setting.settingName) {
            //KB pulled from room not tenant setting
            //case "ORIGIN:BITRATE:MIN":
            //  if (this._minVideoBitrate == 0) {
            //    this._minVideoBitrate = Number.parseInt(setting.settingValue);
            //    //Log.debug("Set minBitrate from TenantSetting: Value=${setting.settingValue}");
            //  }
            case "ORIGIN:STATISTIC:BATCHSIZE":
              this._statsBatchSize = Number.parseInt(setting.settingValue);
              //Log.debug("Set statsBatchSize from TenantSetting: Value=${setting.settingValue}");
              break;
            //case "ORIGIN:RAMPUP:SECONDS":
            //  this._rampUpSeconds = Number.parseInt(setting.settingValue);
            //  //Log.debug("Set rampUpSeconds from TenantSetting: Value=${setting.settingValue}");
            //  break;
          }
        } catch (err: any) {
          //TODO: change to eventLogger
          //Log.error("Error parsing TenantSetting: ${setting.settingName}=${setting.settingValue} Type=${setting.settingType}", err);
        }
      });
    }

    //TODO: magic numbers
    if (this._minVideoBitrate == 0) this._minVideoBitrate = 200;
    if (this._minVideoFramerate == 0) this._minVideoFramerate = this._mediaType == "display" ? 5 : 15;
    if (this._jitterHigh == 0) this._jitterHigh = 120;
    if (this._jitterMedium == 0) this._jitterMedium = 79;
    if (this._jitterLow == 0) this._jitterLow = 30;
    if (this._packetLossHigh == 0) this._packetLossHigh = .28;
    if (this._packetLossMedium == 0) this._packetLossMedium = .08;
    if (this._packetLossLow == 0) this._packetLossLow = .03;

    this._mediaOptions.webRtcDegradationPreferenceEnabled ??= false;

    this._onAudioTrackStreamBound = this.onAudioTrackStreamBound.bind(Reactive.wrap(this));
    this._onAudioTrackStreamUnbound = this.onAudioTrackStreamUnbound.bind(Reactive.wrap(this));
    this._onMediaChannelClose = this.onMediaChannelClose.bind(Reactive.wrap(this));
    this._onMediaChannelClosing = this.onMediaChannelClosing.bind(Reactive.wrap(this));
    this._onMediaChannelError = this.onMediaChannelError.bind(Reactive.wrap(this));
    this._onMediaChannelMessage = this.onMediaChannelMessage.bind(Reactive.wrap(this));
    this._onMediaChannelOpen = this.onMediaChannelOpen.bind(Reactive.wrap(this));
    this._onMediaStateChanged = this.onMediaStateChanged.bind(Reactive.wrap(this));
    //this._onVideoTrackFrameSizeChanged = this.onVideoTrackFrameSizeChanged.bind(Reactive.wrap(this));
    this._onVideoTrackStreamBound = this.onVideoTrackStreamBound.bind(Reactive.wrap(this));
    this._onVideoTrackStreamUnbound = this.onVideoTrackStreamUnbound.bind(Reactive.wrap(this));

    this._mediaChannel = this.connection.createDataChannel("media");
    this._mediaChannel.binaryType = "arraybuffer";

    for (const trackType of trackTypes) {
      const trk = new Track();
      trk.status = "enabled";
      trk.mediaType = this._mediaType;
      trk.trackType = trackType;
      trk.index = this._transceivers.size;
      trk.priority = "high";
      trk.targetPriority = "high";
      trk.spatialLayerIndex = 0;
      trk.temporalLayerIndex = 0;
      trk.statAudio = new StatisticAudio();
      trk.statVideo = new StatisticVideo();
      trk.statPair = new StatisticConnection();

      const transceiver = this.connection.addTransceiver(trackType, { direction: "inactive" });
      trk.transceiver = transceiver;
      this._transceivers.set(trackType, trk);
      //this._senders.set(trackType, transceiver.sender);
      //this._senderStats.set(trackType, new TrackStats());
    }

    this.attachEventHandlers();
  }

  private getSenderEncodings(parameters: RTCRtpSendParameters): RTCRtpEncodingParameters[] {
    const encodings = parameters.encodings ?? [{}];
    parameters.encodings = encodings;
    return encodings;
  }

  private onAudioTrackStreamBound(): Promise<void> {
    const trackStream = this._media.audioTrack.stream;
    return this.eventQueue.dispatch(async () => {
      await this.tryReplaceSenderTrack("audio", trackStream);
      await this.updateTransceiverDirections();
    });
  }

  private onAudioTrackStreamUnbound(): void {
    void this.eventQueue.dispatch(async () => {
      if (this.isTerminated) return;
      await this.tryReplaceSenderTrack("audio", null);
      await this.updateTransceiverDirections();
    });
  }

  private onMediaChannelClose(): void {
    const message = `Origin ${this.mediaType} connection media channel has closed.`;
    void this.eventLogger.debug("onMediaChannelClose", message);
  }

  private onMediaChannelClosing(): void {
    const message = `Origin ${this.mediaType} connection media channel is closing.`;
    void this.eventLogger.debug("onMediaChannelClosing", message);
  }

  private onMediaChannelError(ev: RTCErrorEvent): void {
    const errDetail = ev.error?.errorDetail ?? ev.error ?? "";
    const message = `Origin ${this.mediaType} connection media channel has failed. ${errDetail}`.trimEnd();
    void this.eventLogger.debug("onMediaChannelError", message);
  }

  //TODO: in-progess deprecating
  private onMediaChannelMessage(ev: MessageEvent<any>): void {
    //const arrayBuffer = <ArrayBuffer>ev.data;
    //this._mediaChannelBytesReceived += arrayBuffer.byteLength;
    //const buffer = new Uint8Array(arrayBuffer);
    //if (buffer.length == 0) return;
    //const payloadType = buffer[0];
    //if (payloadType == 0) {
    //  if (buffer.length == 1) return;
    //  const audioLevel = buffer[1] / 255;
    //  this._media?.audioTrack?.updateLevel(audioLevel);
    //}
  }

  private onMediaChannelOpen(): void {
    const message = `Origin ${this.mediaType} connection media channel has opened.`;
    void this.eventLogger.debug("onMediaChannelOpen", message);
  }

  private onMediaStateChanged(): Promise<void> {
    return this.eventQueue.dispatch(async () => {
      await this.updateMediaBindings();
      await this.updateTransceiverDirections();
    });
  }

  //private onVideoTrackFrameSizeChanged(): void {
  //  void this.eventQueue.dispatch(async () => {
  //    await this.updateSenderParametersVideo();
  //  });
  //}

  private onVideoTrackStreamBound(): Promise<void> {
    const trackStream = this._media.videoTrack.stream;
    return this.eventQueue.dispatch(async () => {
      await this.tryReplaceSenderTrack("video", trackStream);
      await this.updateTransceiverDirections();
    });
  }

  private onVideoTrackStreamUnbound(): void {
    void this.eventQueue.dispatch(async () => {
      if (this.isTerminated) return;
      await this.tryReplaceSenderTrack("video", null);
      await this.updateTransceiverDirections();
    });
  }

  private async tryReplaceSenderTrack(trackType: TrackType, track: MediaStreamTrack): Promise<boolean> {
    const sender = this.getSender(trackType);
    try {
      if (this.state == "closed") return false;
      if (sender.track == track) return false;
      await sender.replaceTrack(track);
      if (trackType == "video") this.sendNotification({
        type: "frameRateUpdated",
        frameRate: track?.getSettings()?.frameRate ?? null,
      });
      return true;
    } catch (error: any) {
      const trkDetail = track == null ? "true" : "false";
      void this.eventLogger.warning(<Error>error, "tryReplaceSenderTrack", `Could not replace origin ${this._mediaType} ${trackType} sender track (null:${trkDetail}).`);
      return false;
    }
  }

  private trySetTransceiverDirection(trackType: TrackType, direction: RTCRtpTransceiverDirection): boolean {
    const transceiver = this.getTransceiver(trackType);
    try {
      if (this.state == "closed") return false;
      if (transceiver.direction == direction || transceiver.direction == "stopped" || (transceiver as any).stopped /* legacy */) return false;
      transceiver.direction = direction;
      return true;
    } catch (error: any) {
      void this.eventLogger.warning(<Error>error, "trySetTransceiverDirection", `Could not set origin ${this._mediaType} ${trackType} transceiver direction to ${direction}.`);
      return false;
    }
  }

  private async updateConstraints(constraints: Constraints): Promise<void> {
    void this.eventLogger.debug("updateConstraints", `Updating origin ${this._mediaType} constraints...`, null, {
      audioBitrateMax: constraints.audioBitrateMax,
      videoBitrateMax: constraints.videoBitrateMax,
      videoFramerateMax: constraints.videoFramerateMax,
      videoHeightMax: constraints.videoHeightMax,
      videoWidthMax: constraints.videoWidthMax
    });

    await this.updateSenderParameters();
  }

  private async updateMediaBindings(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", this._media?.audioTrack?.stream ?? null);
    await this.tryReplaceSenderTrack("video", this._media?.videoTrack?.stream ?? null);
  }

  private async updateSenderParametersAudio(): Promise<void> {
    const sender = this._transceivers.get("audio").transceiver.sender;
    const parameters = sender.getParameters();
    const encodings = this.getSenderEncodings(parameters);
    if (encodings && encodings.length > 0) {

      //audio is always high
      encodings[0].priority = "high";

      //unimpaired and low
      var maxBitrate = this._maxAudioBitrate;

      if (this._audioBitrateImpairment == ImpairmentLevel.high) {
        maxBitrate = this._minAudioBitrate;
      }
      else if (this._audioBitrateImpairment == ImpairmentLevel.medium) {
        //could get fancy with step downs, but for now it's either max, min, or halfway
        maxBitrate = ((this._maxAudioBitrate + this._minAudioBitrate) * 0.5);
      }

      maxBitrate = Math.round(maxBitrate * 1000);
      encodings[0].maxBitrate = maxBitrate

      //only if changed
      if (this._lastMaxAudioBitrate != maxBitrate) {

        this._lastMaxAudioBitrate = maxBitrate;

        try {
          await sender.setParameters(parameters);
        } catch (error: any) {
          void this.eventLogger.debug(<Error>error, "updateSenderParametersAudio", `Could not set origin ${this._mediaType} audio sender parameters.`);
        }
      } //changed

    }
  }

  private calculateScaleFactor(currentHeight: number, currentWidth: number, targetHeight: number, targetWidth: number) {

    // Calculate scaling factors for each dimension
    const widthScaleFactor = currentWidth / targetWidth;
    const heightScaleFactor = currentHeight / targetHeight;

    // Choose the minimum scaling factor
    const minimumScaleFactor = Math.max(widthScaleFactor, heightScaleFactor);

    return minimumScaleFactor;
  }

  private async updateSenderParametersVideo(): Promise<void> {
    const sender = this._transceivers.get("video").transceiver.sender;
    const parameters = sender.getParameters();

    if (this._mediaOptions.webRtcDegradationPreferenceEnabled) {
      parameters.degradationPreference = this._mediaOptions.degradationPreference;
    }
    const encodings = this.getSenderEncodings(parameters);
    if (encodings && encodings.length > 0) {

      if (this._priorityVideoImpairment != ImpairmentLevel.unimpaired) {
        encodings[0].priority = "medium";
      }
      else {
        encodings[0].priority = this._priorityVideo;
      }

      //bitrate unimpaired
      var maxBitrate = this._maxVideoBitrate;
      //TODO: all impairment adjusts logged to stats

      switch (this._videoBitrateImpairment) {
        case ImpairmentLevel.high:
          maxBitrate = this._minVideoBitrate;
          break;

        case ImpairmentLevel.medium:
          maxBitrate = ((this._maxVideoBitrate - this._minVideoBitrate) * 0.25) + this._minVideoBitrate;
          break;

        case ImpairmentLevel.low:
          maxBitrate = ((this._maxVideoBitrate - this._minVideoBitrate) * 0.75) + this._minVideoBitrate;
          break;

        case ImpairmentLevel.unimpaired:
        default:
          maxBitrate = this._maxVideoBitrate;
      }

      maxBitrate = Math.round(maxBitrate * 1000);
      encodings[0].maxBitrate = maxBitrate;

      //framerate unimpaired
      var maxFramerate = this._maxVideoFramerate;
      //TODO: all impairment adjustments logged to stats

      switch (this._videoFramerateImpairment) {
        case ImpairmentLevel.high:
          maxFramerate = this._minVideoFramerate;
          break;

        case ImpairmentLevel.medium:
          //TODO: magic numbers
          maxFramerate = ((this._maxVideoFramerate - this._minVideoFramerate) * 0.25) + this._minVideoFramerate;
          break;

        case ImpairmentLevel.low:
          maxFramerate = ((this._maxVideoFramerate - this._minVideoFramerate) * 0.75) + this._minVideoFramerate;
          break;

        case ImpairmentLevel.unimpaired:
        default:
          maxFramerate = this._maxVideoFramerate;
      }

      maxFramerate = Math.round(maxFramerate);
      encodings[0].maxFramerate = maxFramerate;


      //resolution unimpaired
      var maxHeight = this._maxVideoHeight;
      var maxWidth = this._maxVideoWidth;

      if (this._videoResolutionImpairment == ImpairmentLevel.high) {
        maxHeight = this._minVideoHeight;
        maxWidth = this._minVideoWidth;
      }
      else if (this._videoResolutionImpairment == ImpairmentLevel.medium) {
        //TODO: consider possible gradient. need to set to halfway and then deviate
        //TODO: all impairment adjusts logged to stats
        maxHeight = ((this._maxVideoHeight - this._minVideoHeight) * 0.25) + this._minVideoHeight;
        maxWidth = ((this._maxVideoWidth - this._minVideoWidth) * 0.25) + this._minVideoWidth;
      }
      else if (this._videoResolutionImpairment == ImpairmentLevel.low) {
        maxHeight = ((this._maxVideoHeight - this._minVideoHeight) * 0.5) + this._minVideoHeight;
        maxWidth = ((this._maxVideoWidth - this._minVideoWidth) * 0.5) + this._minVideoWidth;
      }

      var scaleResolutionDownBy: number = null;
      const settings = sender.track?.getSettings();
      if (settings && settings.width && settings.height) {

        //scale > 1 is good, < 1 no change

        scaleResolutionDownBy = this.calculateScaleFactor(settings.height, settings.width, maxHeight, maxWidth);
        if (scaleResolutionDownBy > 1.0) {
          encodings[0].scaleResolutionDownBy = scaleResolutionDownBy;
        }
        else {
          encodings[0].scaleResolutionDownBy = 1.0;
        }
      }
      encodings[0].active = this._encodingEnabled;

      //only if changed
      if ((this._lastResolutionScale != scaleResolutionDownBy)
        || (this._lastMaxVideoFramerate != maxFramerate)
        || (this._lastMaxVideoBitrate != maxBitrate)) {

        this._lastResolutionScale = scaleResolutionDownBy;
        this._lastMaxVideoFramerate = maxFramerate;
        this._lastMaxVideoBitrate = maxBitrate;

        try {
          await sender.setParameters(parameters);

         // Log.debug(`\r\nencoder: ${JSON.stringify(sender.getParameters())}\r\n`);

        } catch (error: any) {
          void this.eventLogger.debug(<Error>error, "updateSenderParametersVideo", `Could not set origin ${this._mediaType} video sender parameters.`);
        }

      } //changed

    }
  }

  private async updateSenderParameters(): Promise<void> {
    await this.updateSenderParametersAudio();
    await this.updateSenderParametersVideo();
  }

  private async updateTransceiverDirections(): Promise<void> {
    let updateOffer = false;
    if (this._media?.audioTrack?.stream) {
      if (this.trySetTransceiverDirection("audio", "sendonly")) updateOffer = true;
    } else {
      if (this.trySetTransceiverDirection("audio", "inactive")) updateOffer = true;
    }
    if (this._media?.videoTrack?.stream) {
      if (this.trySetTransceiverDirection("video", "sendonly")) updateOffer = true;
    } else {
      if (this.trySetTransceiverDirection("video", "inactive")) updateOffer = true;
    }
    if (!updateOffer || this.state == "new" || this.state == "closed") return;
    let offer = (await this.connection.createOffer()).sdp;
    offer = await this.mungeOffer(offer);
    await this.connection.setLocalDescription({
      sdp: offer,
      type: "offer"
    });
    //TODO: use sendRequest once the server honors requests
    this.sendNotification({
      type: "offerUpdated",
      offer: offer,
    });
    this._answerUpdated = new PromiseCompletionSource();
    try {
      let answer = (await this._answerUpdated.promise).answer;
      answer = await this.mungeAnswer(answer);
      await this.connection.setRemoteDescription({
        sdp: answer,
        type: "answer",
      });
      await this.updateSenderParameters();
    } catch (error) {
      if (this._mediaType == "display") return; // display media rejected
      else Log.error(`Couldn't update transceiver directions. ${error}`);
    }
  }

  /** @internal */
  public getSender(trackType: TrackType): RTCRtpSender {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver.sender;
  }

  public setMaxAudio(trackType: TrackType): RTCRtpSender {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver.sender;
  }

  /** @internal */
  public getTransceiver(trackType: TrackType): RTCRtpTransceiver {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver;
  }

  /** @internal */
  public async pauseInternal(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", null);
    await this.tryReplaceSenderTrack("video", null);
  }

  /** @internal */
  public async resumeInternal(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", this._media?.audioTrack?.stream ?? null);
    await this.tryReplaceSenderTrack("video", this._media?.videoTrack?.stream ?? null);
  }

  protected attachEventHandlers(): void {
    super.attachEventHandlers();
    this._mediaChannel.addEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.addEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.addEventListener("error", this._onMediaChannelError);
    this._mediaChannel.addEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.addEventListener("open", this._onMediaChannelOpen);
  }

  protected detachEventHandlers(): void {
    this._media?.stateChanged.unbind(this._onMediaStateChanged);
    this._media?.audioTrack?.streamBound.bind(this._onAudioTrackStreamBound);
    this._media?.audioTrack?.streamUnbound.bind(this._onAudioTrackStreamUnbound);
    this._media?.videoTrack?.streamBound.bind(this._onVideoTrackStreamBound);
    this._media?.videoTrack?.streamUnbound.bind(this._onVideoTrackStreamUnbound);
    this._mediaChannel.removeEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.removeEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.removeEventListener("error", this._onMediaChannelError);
    this._mediaChannel.removeEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.removeEventListener("open", this._onMediaChannelOpen);
    super.detachEventHandlers();
  }

  protected async negotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (await this._client.negotiate({
      audioLevelInterval: this._audioLevelInterval,
      mediaType: this._mediaType,
      offer: offer,
      replicationCount: this._replicationCount,
    }, abortSignal)).answer;
  }

  protected async onOpened(): Promise<void> {
    await super.onOpened();
    await this.updateMediaBindings();
    await this.updateTransceiverDirections();
    await this.updateSenderParameters();
  }

  protected onTerminated(): void {
    this._answerUpdated?.reject("Connection closed.");
    super.onTerminated();
  }

  protected processNotification(notification: Message): void {
    if (notification.type == "answerUpdated") {
      if (this._answerUpdated) this._answerUpdated.resolve(notification);
    } else if (notification.type == "constraintsUpdated") {
      void this._mediaNotificationQueue.dispatch(async () => {
        await this.updateConstraints(notification.constraints);
      });
    } else if (notification.type == "displayMediaRejected") {
      if (this._answerUpdated) this._answerUpdated.reject(new Error("Media rejected."));
      void this._mediaNotificationQueue.dispatch(async () => {
        await this._mediaRejected.dispatch({
          connection: this,
          media: this._media,
          mediaType: this._mediaType,
        });
      });
    } else if (notification.type == "displayMediaReplaced") {
      void this._mediaNotificationQueue.dispatch(async () => {
        await this._mediaReplaced.dispatch({
          connection: this,
          media: this._media,
          mediaType: this._mediaType,
        });
      });
    } else {
      void this.eventLogger.warning(null, "processNotification", `Unexpected ${notification.type} edge notification.`);
    }
  }

  protected async renegotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (await this._client.renegotiate({
      mediaType: this._mediaType,
      offer: offer,
    }, abortSignal)).answer;
  }

  public setMedia(media: LocalMedia): Promise<void> {
    return this.eventQueue.dispatch(async () => {
      if (this._media == media) return;
      if (media?.connection && media.connection != this) await media.connection.setMedia(null);
      if (this._media) {
        this._media.stateChanged.unbind(this._onMediaStateChanged);
        this._media.audioTrack?.streamBound.unbind(this._onAudioTrackStreamBound);
        this._media.audioTrack?.streamUnbound.unbind(this._onAudioTrackStreamUnbound);
        this._media.videoTrack?.streamBound.unbind(this._onVideoTrackStreamBound);
        this._media.videoTrack?.streamUnbound.unbind(this._onVideoTrackStreamUnbound);
        this._media.connection = null;
      }
      this._media = media;
      if (this._media) {
        this._media.stateChanged.bind(this._onMediaStateChanged);
        this._media.audioTrack?.streamBound.bind(this._onAudioTrackStreamBound);
        this._media.audioTrack?.streamUnbound.bind(this._onAudioTrackStreamUnbound);
        this._media.videoTrack?.streamBound.bind(this._onVideoTrackStreamBound);
        this._media.videoTrack?.streamUnbound.bind(this._onVideoTrackStreamUnbound);
        this._media.connection = this;
      }
      await this.updateMediaBindings();
      await this.updateTransceiverDirections();
    });
  }

  public setPriorityAudio(priorityAudio: LocalTrackPriority): Promise<void> {
    Guard.isNotNullOrUndefined(priorityAudio, "priorityAudio");
    return this.eventQueue.dispatch(async () => {
      if (this._priorityAudio == priorityAudio) return;
      this._priorityAudio = priorityAudio;
      await this.updateSenderParametersAudio();
    });
  }

  public setPriorityVideo(priorityVideo: LocalTrackPriority): Promise<void> {
    Guard.isNotNullOrUndefined(priorityVideo, "priorityVideo");
    return this.eventQueue.dispatch(async () => {
      if (this._priorityVideo == priorityVideo) return;
      this._priorityVideo = priorityVideo;
      await this.updateSenderParametersVideo();
    });
  }

  //KB: for testing only
  private _audioImpairmentOverride: ImpairmentLevel = null;
  private _videoImpairmentOverride: ImpairmentLevel = null;

  public setAudioImpairment(impLevel: ImpairmentLevel) {
    this._audioImpairmentOverride = impLevel;
  }

  public setVideoImpairment(impLevel: ImpairmentLevel) {
    this._videoImpairmentOverride = impLevel;
  }

  public async updateStats(): Promise<void> {
    if (this.isTerminated) return;

    //TODO: defensive cof
    const senderList = this.connection.getSenders();
    for (const sender of senderList) {

      if (sender.track != null) {
        
        //pull sender stats
        const statReport = await sender.getStats();

        if (sender.track.kind == "audio") {

          const audioStat = new StatisticAudio();
          audioStat.AttendeeId = this.attendeeId;
          //NOTE: on server: Attendee.DeviceId;
          audioStat.MediaType = this._mediaType;
          audioStat.CreatedOn = new Date();

          audioStat.TrackIndex = 0;
          audioStat.IsClient = true;

          // TODO: JV: muted is not the right prop to use. I believe it's track.enabled
          audioStat.IsMuted = sender.track.muted;
          //? audioStat.IsNoiseSuppressed = _isAudioNoiseSuppressed;
          //? audioStat.IsPaused = _isAudioPaused;

          audioStat.BitrateConstraint = this.audioBitrateMax;

          //TODO: BitrateAllocation, BitrateServer
          //FramerateActual, FramerateConstraint, FramerateEstimated, FramerateServer

          for (const [key, value] of statReport.entries()) {

            if (value.type == "media-source") {
              audioStat.Src_AudioLevel = value.audioLevel;
              audioStat.Src_TotalAudioEnergy = value.totalAudioEnergy;
              audioStat.Src_TotalSamplesDuration = value.totalSamplesDuration;

              if (audioStat.Src_AudioLevel != null) {
                this._media?.audioTrack?.updateLevel(audioStat.Src_AudioLevel);
              }
            }

            if (value.type == "codec") {
              audioStat.Codec_MimeType = value.mimeType;
              audioStat.Codec_Channels = value.channels;
              audioStat.Codec_ClockRate = value.clockRate;
            }

            if (value.type == "outbound-rtp") {
              audioStat.Rtp_Kind = value.kind;
              audioStat.Rtp_SSRC = value.ssrc;
              audioStat.TrackIdentifier = value.mediaSourceId;
              audioStat.TrackMid = value.mid;

              audioStat.Rtp_PacketsSent = value.packetsSent;
              audioStat.Rtp_RetransmittedPacketsSent = value.retransmittedPacketsSent;
              audioStat.Rtp_BytesSent = value.bytesSent;
              audioStat.Rtp_HeaderBytesSent = value.headerBytesSent;
              audioStat.Rtp_RetransmittedBytesSent = value.retransmittedBytesSent;

              audioStat.Rtp_TotalPacketSendDelay = value.totalPacketSendDelay;
              audioStat.Rtp_NackCount = value.nackCount;

              audioStat.BitrateEstimated = value.targetBitrate;
              audioStat.BitrateActual = this._bitrateAudio.calculate(audioStat.Rtp_BytesSent, value.timestamp);
            }

            if (value.type == "remote-inbound-rtp") {
              audioStat.Rtp_Timestamp = value.timestamp as DOMHighResTimeStamp;
              audioStat.Rtp_PacketsLost = value.packetsLost;
              audioStat.Rtp_Jitter = value.jitter;
              audioStat.Rtp_RoundTripTime = value.roundTripTime;
              audioStat.Rtp_RoundTripTimeMeasurements = value.roundTripTimeMeasurements;
              audioStat.Rtp_RoundTripTimeTotal = value.totalRoundTripTime;
            }

            if (value.type == "transport") {
              audioStat.Transport_Timestamp = value.timestamp as DOMHighResTimeStamp;
              audioStat.Transport_BytesSent = value.bytesSent;
              audioStat.Transport_BytesReceived = value.bytesReceived;
              audioStat.Transport_PacketsSent = value.packetsSent;
              audioStat.Transport_PacketsReceived = value.packetsReceived;
              audioStat.Transport_State = value.dtlsState;
            }

          } //foreach entry

          //impairment

          this._packetLoss.getDelta(audioStat.Rtp_PacketsLost ? audioStat.Rtp_PacketsLost : 0, audioStat.Rtp_PacketsSent ? audioStat.Rtp_PacketsSent : 0);
          var lossPercentage = this._packetLoss.getPercentage();
          audioStat.Int_PacketLoss = lossPercentage;

          this._nackCount.getDelta(audioStat.Rtp_NackCount ? audioStat.Rtp_NackCount : 0, audioStat.Rtp_PacketsSent ? audioStat.Rtp_PacketsSent : 0);
          var nackPercentage = this._nackCount.getPercentage();
          audioStat.Int_FramesLost = nackPercentage;

          //KB: for testing
          if (this._audioImpairmentOverride == null) {
            //allow it to be restored if no impairment detected
            this._audioBitrateImpairment = ImpairmentLevel.unimpaired;

            if ((lossPercentage > 0) || (nackPercentage > 0)) {
              //possible impairment
              if ((lossPercentage > this._thresholds.audio.packetLossThresholdHigh) || (nackPercentage > this._thresholds.audio.nackThresholdHigh)) {
                this._audioBitrateImpairment = ImpairmentLevel.high;
              }
              else if ((lossPercentage > this._thresholds.audio.packetLossThresholdMedium) || (nackPercentage > this._thresholds.audio.nackThresholdMedium)) {
                this._audioBitrateImpairment = ImpairmentLevel.medium;
              }
              else if ((lossPercentage > this._thresholds.audio.packetLossThresholdLow) || (nackPercentage > this._thresholds.audio.nackThresholdLow)) {
                this._audioBitrateImpairment = ImpairmentLevel.low;
              }
            } //impairment
          }
          else {
            this._audioBitrateImpairment = this._audioImpairmentOverride;
          }

          await this.updateSenderParametersAudio();

          //only save the stat every polling interval
          if ((this._statsPollingInterval % this._currentStatCount) == 0) {

            const sectionsAudio = [];
            for (const stats of statReport.values()) {
              if ((stats.type != "certificate") && (stats.type != "candidate-pair") && (stats.type != "local-candidate") && (stats.type != "remote-candidate")) {
                //NOTE: debugging only, makes huge results
                //sectionsVideo.push(stats);
                sectionsAudio.push(stats.type);
              }
            }
            audioStat.Json = JSON.stringify(sectionsAudio, null, 2);

            this._statsBatchAudio.push(audioStat);
          }

          //update internal values for state logic
          if (this._media.audioTrack != null) {
            this._media.audioTrack.updateStats(audioStat, null);
          }

        } //audio

        if (sender.track.kind == "video") {

          const videoStat = new StatisticVideo();
          videoStat.AttendeeId = this.attendeeId;
          //NOTE: on server: Attendee.DeviceId;
          videoStat.MediaType = this._mediaType;
          videoStat.CreatedOn = new Date();

          videoStat.TrackIndex = 0;
          videoStat.SpatialLayerIndex = 0; //only stats for the incoming
          videoStat.TemporalLayerIndex = 0;

          videoStat.IsClient = true;
          videoStat.IsMuted = sender.track.muted;
          //? videoStat.IsPaused = ;
          //? videoStat.IsDisabled = ;

          videoStat.BitrateConstraint = this.videoBitrateMax;
          videoStat.FramerateConstraint = this.videoFramerateMax;
          videoStat.PixelCountConstraint = this.videoHeightMax * this.videoWidthMax;

          //TODO:
          //BitrateAllocation, BitrateEstimated, BitrateServer
          //PixelCountEstimated, PixelCountServer
          //FramerateEstimated, FramerateServer

          for (const [key, value] of statReport.entries()) {

            if (value.type == "codec") {
              videoStat.Codec_MimeType = value.mimeType;
              videoStat.Codec_Channels = value.channels;
              videoStat.Codec_ClockRate = value.clockRate;
            }

            if (value.type == "outbound-rtp") {
              videoStat.Rtp_Kind = value.kind;
              videoStat.Rtp_SSRC = value.ssrc;
              videoStat.Rtp_IsRemote = value.isRemote;
              videoStat.TrackIdentifier = value.mediaSourceId;
              videoStat.TrackMid = value.mid;

              videoStat.Rtp_FirCount = value.firCount;
              videoStat.Rtp_PliCount = value.pliCount;
              videoStat.Rtp_NackCount = value.nackCount;

              videoStat.Track_FrameWidth = value.frameWidth;
              videoStat.Track_FrameHeight = value.frameHeight;

              if (videoStat.ResolutionHeight == null) videoStat.ResolutionHeight = value.frameHeight;
              if (videoStat.ResolutionWidth == null) videoStat.ResolutionWidth = value.frameWidth;

              videoStat.Rtp_PacketsSent = value.packetsSent;
              videoStat.Rtp_RetransmittedPacketsSent = value.retransmittedPacketsSent;
              videoStat.Rtp_BytesSent = value.bytesSent;
              videoStat.Rtp_HeaderBytesSent = value.headerBytesSent;
              videoStat.Rtp_RetransmittedBytesSent = value.retransmittedBytesSent;
              videoStat.Rtp_FramesEncoded = value.framesEncoded;
              videoStat.Rtp_KeyFramesEncoded = value.keyFramesEncoded;
              videoStat.Rtp_TotalEncodeTime = value.totalEncodeTime;
              videoStat.Rtp_TotalEncodedBytesTarget = value.totalEncodedBytesTarget;
              videoStat.Rtp_TotalPacketSendDelay = value.totalPacketSendDelay;
              videoStat.Rtp_FramesPerSecond = value.framesPerSecond;
              videoStat.Rtp_FramesSent = value.framesSent;
              videoStat.Rtp_HugeFramesSent = value.hugeFramesSent;
              videoStat.Rtp_QualityLimitationChanges = value.qualityLimitationResolutionChanges;
              videoStat.Rtp_QualityLimitationReason = value.qualityLimitationReason;
              //TODO: escape or encode, breaks deserialization
              //videoStat.Rtp_QualityLimitationDurations = value.qualityLimitationDurations;
              videoStat.Rtp_EncoderImplementation = value.encoderImplementation;
              videoStat.Rtp_QpSum = value.qpSum;
              videoStat.Rtp_IsPowerEfficient = value.powerEfficientEncoder;
              videoStat.Rtp_ScalabilityMode = value.scalabilityMode;

              videoStat.BitrateEstimated = value.targetBitrate;

              videoStat.BitrateActual = this._bitrateVideo.calculate(videoStat.Rtp_BytesSent, value.timestamp);

            }

            if (value.type == "remote-inbound-rtp") {
              videoStat.Rtp_Timestamp = value.timestamp as DOMHighResTimeStamp;
              videoStat.Rtp_PacketsLost = value.packetsLost;
              videoStat.Rtp_Jitter = value.jitter;
              videoStat.Rtp_RoundTripTime = value.roundTripTime;
              videoStat.Rtp_RoundTripTimeMeasurements = value.roundTripTimeMeasurements;
              videoStat.Rtp_RoundTripTimeTotal = value.totalRoundTripTime;
            }

            if (value.type == "media-source") {
              videoStat.Src_FramesPerSecond = value.framesPerSecond;
              videoStat.Src_Frames = value.frames;
              if (videoStat.ResolutionHeight == null) videoStat.ResolutionHeight = value.height;
              if (videoStat.ResolutionWidth == null) videoStat.ResolutionWidth = value.width;
            }

            if (value.type == "transport") {
              videoStat.Transport_Timestamp = value.timestamp as DOMHighResTimeStamp;
              videoStat.Transport_BytesSent = value.bytesSent;
              videoStat.Transport_BytesReceived = value.bytesReceived;
              videoStat.Transport_PacketsSent = value.packetsSent;
              videoStat.Transport_PacketsReceived = value.packetsReceived;
              videoStat.Transport_State = value.dtlsState;
            }

          } //foreach entry

          //hybrid for varied reporting by version
          if (videoStat.FramerateActual == null) {
            if (videoStat.Rtp_FramesPerSecond > 0) {
              videoStat.FramerateActual = videoStat.Rtp_FramesPerSecond;
            }
            else {
              if (videoStat.Src_FramesPerSecond > 0) {
                videoStat.FramerateActual = videoStat.Src_FramesPerSecond;
              }
              else {
                videoStat.FramerateActual = this._framerateVideo.calculate(videoStat.Track_FramesReceived, videoStat.Rtp_Timestamp);
              }
            }
          }

          //aggregations
          if (videoStat.ResolutionHeight == null) {
            videoStat.ResolutionHeight = videoStat.Track_FrameHeight;
          }
          if (videoStat.ResolutionWidth == null) {
            videoStat.ResolutionWidth = videoStat.Track_FrameWidth;
          }
          if ((videoStat.ResolutionHeight != null) && (videoStat.ResolutionWidth != null)) {
            videoStat.PixelCountActual = videoStat.ResolutionHeight * videoStat.ResolutionWidth
          }

          //impairment
          this._packetLoss.getDelta(videoStat.Rtp_PacketsLost ? videoStat.Rtp_PacketsLost : 0, videoStat.Rtp_PacketsSent ? videoStat.Rtp_PacketsSent : 0);
          var lossPercentage = this._packetLoss.getPercentage();
          videoStat.Int_PacketLoss = lossPercentage;

          this._nackCount.getDelta(videoStat.Rtp_NackCount ? videoStat.Rtp_NackCount : 0, videoStat.Rtp_PacketsSent ? videoStat.Rtp_PacketsSent : 0);
          var nackPercentage = this._nackCount.getPercentage();
          videoStat.Int_NackPercentage = nackPercentage;

          this._pliCount.getDelta(videoStat.Rtp_PliCount ? videoStat.Rtp_PliCount : 0, videoStat.Rtp_PacketsSent ? videoStat.Rtp_PacketsSent : 0);
          var pliPercentage = this._pliCount.getPercentage();
          videoStat.Int_FramesLost = pliPercentage;

          //KB: for testing
          if (this._videoImpairmentOverride == null) {

            //allow it to be restored if no impairment detected
            this._videoBitrateImpairment = ImpairmentLevel.unimpaired;
            this._videoFramerateImpairment = ImpairmentLevel.unimpaired;
            this._videoResolutionImpairment = ImpairmentLevel.unimpaired;

            if ((lossPercentage > 0) || (nackPercentage > 0)) {
              //possible impairment
              if ((lossPercentage > this._thresholds.video.packetLossThresholdHigh) || (nackPercentage > this._thresholds.video.nackThresholdHigh) || (pliPercentage > this._thresholds.video.pliThresholdHigh)) {
                this._videoBitrateImpairment = ImpairmentLevel.high;
                this._videoResolutionImpairment = ImpairmentLevel.high;
                videoStat.Rtp_QualityLimitationDurations = "HIGH impairment";
              }
              else if ((lossPercentage > this._thresholds.audio.packetLossThresholdMedium) || (nackPercentage > this._thresholds.audio.nackThresholdMedium) || (pliPercentage > this._thresholds.video.pliThresholdMedium)) {
                this._videoBitrateImpairment = ImpairmentLevel.medium;
                this._videoResolutionImpairment = ImpairmentLevel.medium;
                videoStat.Rtp_QualityLimitationDurations = "MEDIUM impairment";
              }
              else if ((lossPercentage > this._thresholds.audio.packetLossThresholdLow) || (nackPercentage > this._thresholds.audio.nackThresholdLow) || (pliPercentage > this._thresholds.video.pliThresholdLow)) {
                this._videoBitrateImpairment = ImpairmentLevel.low;
                this._videoResolutionImpairment = ImpairmentLevel.low;
                videoStat.Rtp_QualityLimitationDurations = "LOW impairment";
              }
            } //impairment
          }
          else {
            this._videoBitrateImpairment = this._videoImpairmentOverride;
            this._videoResolutionImpairment = this._videoImpairmentOverride;
            videoStat.Rtp_QualityLimitationDurations = "OVERRIDE impairment: " + this._videoImpairmentOverride.toString();
          }

          await this.updateSenderParametersVideo();

          //only save the stat every polling interval
          if ((this._statsPollingInterval % this._currentStatCount) == 0) {

            const sectionsVideo = [];
            for (const stats of statReport.values()) {
              if ((stats.type != "certificate") && (stats.type != "candidate-pair") && (stats.type != "local-candidate") && (stats.type != "remote-candidate")) {
                //NOTE: debugging only, makes huge results
                //sectionsVideo.push(stats);
                sectionsVideo.push(stats.type);
              }
            }
            videoStat.Json = JSON.stringify(sectionsVideo, null, 2);

            this._statsBatchVideo.push(videoStat);
          }

          //update internal values for state logic
          if (this._media.videoTrack != null) {
            this._media.videoTrack.updateStats(videoStat, null);
          }

        } //video

        this._currentStatCount++;

        //TODO: exception handling and timeout
        //push on batch size
        if (this._currentStatCount >= this._statsBatchSize) {
          const batchAudio = this._statsBatchAudio.splice(0);
          const batchVideo = this._statsBatchVideo.splice(0);
          this.sendNotification({
            type: "clientStats",
            audioStats: batchAudio,
            videoStats: batchVideo
          });
          this._currentStatCount = 0;
        }

      } //has track

    } //foreach sender

  } //updateStats

}