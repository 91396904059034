import Attendee from "../Attendee";
import Channel from "./Channel";
import ChatChannelAttendee from "../api/models/ChatChannelAttendee";
import ControlConnection from "../control/Connection";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import MemberEvent from "./models/MemberEvent";
import MemberInit from "./models/MemberInit";
import MemberRole from "./models/MemberRole";
import MemberUpdateOptions from "./models/MemberUpdateOptions";
import ApiClient from "../api/Client";

export default class Member {
  private readonly _attendee: Attendee;
  private readonly _channel: Channel;
  private readonly _controlConnection: ControlConnection;
  private readonly _updated = new EventOwnerAsync<MemberEvent>();
  private readonly _apiClient: ApiClient;

  private _model: ChatChannelAttendee;

  public get attendee(): Attendee {
    return this._attendee;
  }
  public get channel(): Channel {
    return this._channel;
  }
  public get id(): string {
    return this._model.id;
  }
  public get role(): MemberRole {
    return this._model.roleType;
  }
  /** @event */
  public get updated(): EventOwnerAsync<MemberEvent> {
    return this._updated;
  }

  /** @internal */
  public constructor(init: MemberInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.attendee, "init.attendee");
    Guard.isNotNullOrUndefined(init.channel, "init.channel");
    Guard.isNotNullOrUndefined(
      init.controlConnection,
      "init.controlConnection"
    );
    Guard.isNotNullOrUndefined(init.model, "init.model");
    this._attendee = init.attendee;
    this._channel = init.channel;
    this._controlConnection = init.controlConnection;
    this._model = init.model;
    this._apiClient = init.apiClient;
  }

  /** @internal */
  public async refreshModel(
    chatChannelAttendee?: ChatChannelAttendee
  ): Promise<void> {
    if (!chatChannelAttendee) {
      const response = await this._apiClient.getChatChannelAttendee(this.id);
      if (!response || response.value == null) return;

      chatChannelAttendee = response.value;
    }
    if (!chatChannelAttendee) return;

    this._model = chatChannelAttendee;
    await this._updated.dispatch({
      member: this,
    });
  }

  public async remove(): Promise<void> {
    const response = await this._controlConnection.sendChatChannelAttendeeNotification({
      channelId: this.channel.id,
      chatChannelAttendeeId: this._model.id,
      roleType: "DELETED",
    });

    if (response == null || response.chatChannelAttendeeNotification == null)
      throw new Error("Failed to remove chat channel member");

    await this.channel.removeMemberInternal(this._model.id);
  }

  public async update(options: MemberUpdateOptions) {
    Guard.isNotNullOrUndefined(options, "options");
    await this._controlConnection.sendChatChannelAttendeeNotification({
      channelId: this.channel.id,
      chatChannelAttendeeId: this._model.id,
      roleType: options.role,
    });

    const model = {...this._model};
    model.roleType = options.role;
    await this.refreshModel(model);
  }
}
