import ApiClient from "../api/Client";
import Attendee from "../Attendee";
import ChannelStatus from "./models/ChannelStatus";
import ChannelType from "./models/ChannelType";
import Chat from "./Chat";
import ChatChannelAttendee from "../api/models/ChatChannelAttendee";
import ChatMessageNotification from "../control/models/ChatMessageNotification";
import ChannelEvent from "./models/ChannelEvent";
import ChannelInit from "./models/ChannelInit";
import ChannelModel from "../api/models/ChatChannel";
import ChannelSearchOptions from "./models/ChannelSearchOptions";
import ChannelUpdateOptions from "./models/ChannelUpdateOptions";
import Collection from "../models/Collection";
import Constants from "../core/Constants";
import ControlConnection from "../control/Connection";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import Member from "./Member";
import MemberAddOptions from "./models/MemberAddOptions";
import MemberCollection from "./MemberCollection";
import MemberEvent from "./models/MemberEvent";
import Message from "./Message";
import MessageCollection from "./MessageCollection";
import MessageEvent from "./models/MessageEvent";
import MessageModel from "../api/models/ChatMessage";
import MessageSendOptions from "./models/MessageSendOptions";
import MessageType from "./models/MessageType";
import Reactive from "../core/Reactive";
import SubscribedView from "../SubscribedView";
import Utility from "../core/Utility";
import { UploadClient } from "@liveswitch/storage";

export default class Channel {
  private readonly _apiClient: ApiClient;
  private readonly _chat: Chat;
  private readonly _controlConnection: ControlConnection;
  private readonly _isDefault: boolean;
  private readonly _localAttendee: Attendee;
  private readonly _members: MemberCollection;
  private readonly _memberAdded = new EventOwnerAsync<MemberEvent>();
  private readonly _memberRemoved = new EventOwnerAsync<MemberEvent>();
  private readonly _messages: MessageCollection;
  private readonly _messageDeleted = new EventOwnerAsync<MessageEvent>();
  private readonly _messageReceived = new EventOwnerAsync<MessageEvent>();
  private readonly _messageSent = new EventOwnerAsync<MessageEvent>();
  private readonly _subscribedView: SubscribedView;
  private readonly _updated = new EventOwnerAsync<ChannelEvent>();
  private readonly _uploadClient: UploadClient;

  private _model: ChannelModel;

  public get chat(): Chat {
    return this._chat;
  }
  public get id(): string {
    return this._model.id;
  }
  public get isDefault(): boolean {
    return this._isDefault;
  }
  public get members(): MemberCollection {
    return this._members;
  }
  /** @event */
  public get memberAdded(): EventOwnerAsync<MemberEvent> {
    return this._memberAdded;
  }
  /** @event */
  public get memberRemoved(): EventOwnerAsync<MemberEvent> {
    return this._memberRemoved;
  }
  public get messages(): MessageCollection {
    return this._messages;
  }
  /** @event */
  public get messageDeleted(): EventOwnerAsync<MessageEvent> {
    return this._messageDeleted;
  }
  /** @event */
  public get messageReceived(): EventOwnerAsync<MessageEvent> {
    return this._messageReceived;
  }
  /** @event */
  public get messageSent(): EventOwnerAsync<MessageEvent> {
    return this._messageSent;
  }
  public get name(): string {
    return this._model.name;
  }
  public get status(): ChannelStatus {
    return this._model.status;
  }
  public get type(): ChannelType {
    return this._model.type;
  }
  /** @event */
  public get updated(): EventOwnerAsync<ChannelEvent> {
    return this._updated;
  }

  /** @internal */
  constructor(init: ChannelInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(init.chat, "init.chat");
    Guard.isNotNullOrUndefined(
      init.controlConnection,
      "init.controlConnection"
    );
    Guard.isNotNullOrUndefined(init.localAttendee, "init.localAttendee");
    Guard.isNotNullOrUndefined(init.model, "init.model");
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._apiClient = init.apiClient;
    this._chat = init.chat;
    this._controlConnection = init.controlConnection;
    this._isDefault = Utility.isNullOrUndefined(init.model.id);
    this._localAttendee = init.localAttendee;
    this._model = init.model;
    this._subscribedView = init.subscribedView;
    this._members = new MemberCollection(init.apiClient);
    this._messages = new MessageCollection(init.apiClient, init.uploadClient);
    this._uploadClient = init.uploadClient;
  }

  private async sendMessage(
    messageContent?: string,
    file?: File,
    options?: MessageSendOptions
  ): Promise<Message> {
    let notification: ChatMessageNotification;
    if (file) {
      let type: MessageType = "FILE";
      if (file.type?.startsWith("audio/")) type = "AUDIO";
      if (file.type?.startsWith("image/")) type = "IMAGE";
      if (file.type?.startsWith("video/")) type = "VIDEO";

      //upload file and get mediaId
      const uploadResult = await this._uploadClient.UploadAttendeeAttachmentAsync(this._localAttendee.id, file, type);
      
      //Simple or Multipart
      if(uploadResult.uploadPlan){
        const attachmentInfo = {
          fileName: file.name,
          contentLength: file.size,
          mimeType: file.type,
          downloadUrl: uploadResult.downloadUrl
        };
        notification = {
          messageType: type,
          sentBy: this._localAttendee.id,
          messageContent: JSON.stringify(attachmentInfo),
          channelId: this._model.id,
          messagePriority: options?.priority ?? "NORMAL",
          mediaId: uploadResult.uploadPlan.MediaId,
        };
      }else{
        notification = {
          messageType: type,
          sentBy: this._localAttendee.id,
          messageContent: uploadResult.inlineContent,
          channelId: this._model.id,
          messagePriority: options?.priority ?? "NORMAL",
          mediaId: uploadResult.mediaId.replaceAll("\"","")
        };
      }
    } else {
      notification = {
        messageType: "TEXT",
        sentBy: this._localAttendee.id,
        messageContent: messageContent,
        channelId: this._model.id,
        messagePriority: options?.priority ?? "NORMAL",
      };
    }

    const response = await this._controlConnection.sendChatMessageNotification(
      notification
    );

    if (!response)
      throw new Error(Constants.Errors.Meeting.Chat.cannotSendMessage);

    const message = new Message({
      apiClient: this._apiClient,
      attendee: this._localAttendee,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      uploadClient: this._uploadClient,
      model: {
        id: response.chatMessageNotification.messageId,
        meetingId: this._controlConnection.meetingId,
        channelId: this._model.id,
        createdBy: this._localAttendee.id,
        created: new Date(),
        mediaId: response.chatMessageNotification.mediaId,
        messageContent: response.chatMessageNotification.messageContent,
        messageType: response.chatMessageNotification.messageType,
        messagePriority: response.chatMessageNotification.messagePriority,
      },
    });

    if (file) {
      await message.refreshModel();
    }

    this._messages.tryAdd(message);
    await this._messageSent.dispatch({ message });
    return message;
  }

  /** @internal */
  public async addMemberInternal(
    memberId: string,
    chatChannelAttendee?: ChatChannelAttendee
  ): Promise<Member> {
    if (this._members.get(memberId)) return;

    if (!chatChannelAttendee) {
      const response = await this._apiClient.getChatChannelAttendee(memberId);

      if (response.errors || !response.value)
        throw new Error("Error getting chat channel attendee");

      const memberModel = response.value;
      if (!memberModel) return;
      chatChannelAttendee = memberModel;
    }

    const attendee = await this._subscribedView.subscribeToAttendee(
      chatChannelAttendee.attendeeId,
      "chatMemberPaging"
    );
    if (!attendee) return;

    const member = new Member({
      attendee: attendee,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      model: chatChannelAttendee,
      apiClient: this._apiClient,
    });
    this._members.tryAdd(member);
    await this._memberAdded.dispatch({ member });
    return member;
  }

  /** @internal */
  public async addMessageInternal(
    messageId: string,
    attendeeId: string,
    messageModel?: MessageModel
  ): Promise<Message> {
    const attendee = await this._subscribedView.subscribeToAttendee(
      attendeeId,
      "chatMessageReceived"
    );
    if (!attendee) {
      console.warn(Constants.Errors.Meeting.cannotSubscribe);
      return;
    }

    if (!messageModel) {
      const response = await this._apiClient.getChatMessage(messageId);
      if (response.errors || !response.value)
        throw new Error("Error getting chat message");

      messageModel = response.value;
    }

    if (messageModel == null)
      throw new Error(Constants.Errors.Meeting.Chat.cannotGetMessage);

    const message = new Message({
      apiClient: this._apiClient,
      attendee: attendee,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      model: messageModel,
      uploadClient: this._uploadClient,
    });
    await message.load();
    this._messages.tryAdd(message);
    await this._messageReceived.dispatch({ message });
    return message;
  }

  /** @internal */
  public async load(): Promise<void> {
    await this._messages.load({
      apiClient: this._apiClient,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      subscribedView: this._subscribedView,
    });
    if (this._isDefault) return;
    await this._members.load({
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      subscribedView: this._subscribedView,
    });
  }

  /** @internal */
  public async refreshModel(channelModel?: ChannelModel): Promise<void> {
    if (this._isDefault) return;

    if (!channelModel) {
      const response = await this._apiClient.getChatChannel(this._model.id);
      if (response.errors || !response.value)
        throw new Error(Constants.Errors.Meeting.Chat.cannotGetChannel);

      channelModel = response.value;
    }

    this._model = channelModel;
    await this._updated.dispatch({
      channel: this,
    });
  }

  /** @internal */
  public async removeMemberInternal(memberId: string): Promise<void> {
    const member = this._members.get(memberId);
    if (!member || !this._members.tryRemove(memberId)) return;
    await this._memberRemoved.dispatch({ member });
  }

  /** @internal */
  public async removeMessageInternal(messageId: string): Promise<void> {
    const message = this._messages.get(messageId);
    if (!message || !this._messages.tryRemove(messageId)) return;
    await this._messageDeleted.dispatch({ message });
  }

  /** @internal */
  public async updateMemberInternal(memberId: string): Promise<void> {
    await this._members.get(memberId)?.refreshModel();
  }

  /** @internal */
  public async updateMessageInternal(messageId: string): Promise<void> {
    await this._messages.get(messageId)?.refreshModel();
  }

  public async addMember(options: MemberAddOptions): Promise<Member> {
    if (this._isDefault) {
      console.warn(
        Constants.Errors.Meeting.Chat.cannotAddChatMemberToDefaultChannel
      );
      return;
    }

    const response =
      await this._controlConnection.sendChatChannelAttendeeNotification({
        attendeeId: options.attendeeId,
        channelId: this._model.id,
        roleType: options.role,
      });
    if (
      response == null ||
      !response.chatChannelAttendeeNotification.chatChannelAttendeeId
    )
      throw new Error("Could not add member.");

    const attendee = await this._subscribedView.subscribeToAttendee(
      options.attendeeId,
      "chatMemberPaging"
    );

    if (!attendee)
      throw new Error(
        `${Constants.Errors.Meeting.Chat.cannotAddChatMember} ${Constants.Errors.Meeting.cannotSubscribe}`
      );

    const member = new Member({
      attendee: attendee,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      model: {
        id: response.chatChannelAttendeeNotification.chatChannelAttendeeId,
        attendeeId: options.attendeeId,
        channelId: this._model.id,
        roleType: options.role,
      },
      apiClient: this._apiClient,
    });
    this._members.tryAdd(member);
    await this._memberAdded.dispatch({ member });
    return member;
  }

  public async delete(): Promise<void> {
    if (this._isDefault) {
      console.warn(Constants.Errors.Meeting.Chat.cannotDeleteDefaultChannel);
      return;
    }

    await this._controlConnection.sendChatChannelNotification({
      channelId: this._model.id,
      channelStatus: "DELETED",
    });
    await this._chat.removeChannelInternal(this._model.id);
  }

  public async search(
    options: ChannelSearchOptions
  ): Promise<Collection<Message>> {
    const response = await this._apiClient.listChatMessages({
      channelId: this._model.id,
      meetingId: this._controlConnection.meetingId,
      where: options.filter,
      limit: options.limit,
      offset: options.offset,
    });

    if (response.errors || !response.values)
      throw new Error("Error getting chat messages");

    const messages: Message[] = [];
    for (const messageModel of response.values) {
      const attendee = await this._subscribedView.subscribeToAttendee(
        messageModel.createdBy,
        "chatMessageReceived"
      );
      const message = new Message({
        apiClient: this._apiClient,
        attendee: attendee,
        channel: Reactive.wrap(this),
        controlConnection: this._controlConnection,
        model: messageModel,
        uploadClient: this._uploadClient,
      });
      await message.load();
      messages.push(message);
    }
    return {
      totalCount: response.totalCount,
      values: messages,
    };
  }

  public async send(
    content: string | File,
    options?: MessageSendOptions
  ): Promise<Message> {
    Guard.isNotNullOrUndefined(content, "content");
    options ??= {};
    if (Utility.isString(content)) {
      return await this.sendMessage(<string>content, undefined, options);
    }
    const file = <File>content;
    return await this.sendMessage(undefined, file, options);
  }

  public async update(options: ChannelUpdateOptions): Promise<void> {
    if (this._isDefault) throw new Error(Constants.Errors.Meeting.Chat.cannotUpdateDefaultChannel);

    Guard.isNotNullOrUndefined(options, "options");
    Guard.isNotNullOrUndefined(options.name, "options.name");
    Guard.isNotNullOrUndefined(options.type, "options.type");
    Guard.isNotNullOrUndefined(options.status, "options.status");

    const response = await this._controlConnection.sendChatChannelNotification({
      channelId: this._model.id,
      channelName: options.name,
      channelType: options.type,
      channelStatus: options.status,
    });

    void this.refreshModel({
      id: this._model.id,
      name: response.chatChannelNotification.channelName,
      type: response.chatChannelNotification.channelType,
      status: response.chatChannelNotification.channelStatus,
    });
  }
}
