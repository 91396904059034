import ApiClient from "../api/Client";
import Attendee from "../Attendee";
import Channel from "./Channel";
import ChannelCollection from "./ChannelCollection";
import ChannelCreateOptions from "./models/ChannelCreateOptions";
import ChannelEvent from "./models/ChannelEvent";
import ChatEvent from "../control/models/ChatEvent";
import ChatInit from "./models/ChatInit";
import ChatSearchOptions from "./models/ChatSearchOptions";
import ChatState from "./models/ChatState";
import ChatStateChangeEvent from "./models/ChatStateChangeEvent";
import ChatStateMachine from "./ChatStateMachine";
import Collection from "../models/Collection";
import ControlConnection from "../control/Connection";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import MemberEvent from "./models/MemberEvent";
import Message from "./Message";
import MessageEvent from "./models/MessageEvent";
import Reactive from "../core/Reactive";
import ReadOnlyCollectionEvent from "../core/models/ReadOnlyCollectionEvent";
import SubscribedView from "../SubscribedView";
import ChatChannelAttendeeNotification from "../control/models/ChatChannelAttendeeNotification";
import ChatMessageNotification from "../control/models/ChatMessageNotification";
import ChatChannelNotification from "../control/models/ChatChannelNotification";
import ChatChannel from "../api/models/ChatChannel";
import { UploadClient } from "@liveswitch/storage";
import AttendeeChatChannelListRequest from "../api/chatChannel/AttendeeChatMessageListRequest";

export default class Chat {
  private readonly _onChatMessageNotification: (
    noficiation: ChatMessageNotification
  ) => Promise<void>;
  private readonly _onChatChannelNotification: (
    notification: ChatChannelNotification
  ) => Promise<void>;
  private readonly _onChatChannelAttendeeNotification: (
    notification: ChatChannelAttendeeNotification
  ) => Promise<void>;
  private readonly _onLocalChannelAdded: (
    e: ReadOnlyCollectionEvent<Channel>
  ) => void;
  private readonly _onLocalChannelRemoved: (
    e: ReadOnlyCollectionEvent<Channel>
  ) => void;

  private readonly _onLocalMemberAdded: (e: MemberEvent) => Promise<void>;
  private readonly _onLocalMemberRemoved: (e: MemberEvent) => Promise<void>;
  private readonly _onLocalMessageDeleted: (e: MessageEvent) => Promise<void>;
  private readonly _onLocalMessageReceived: (e: MessageEvent) => Promise<void>;
  private readonly _onLocalMessageSent: (e: MessageEvent) => Promise<void>;

  private readonly _onMemberCreated: (e: ChatEvent) => Promise<void>;
  private readonly _onMemberDeleted: (e: ChatEvent) => Promise<void>;
  private readonly _onMemberUpdated: (e: ChatEvent) => Promise<void>;
  private readonly _onMessageCreated: (e: ChatEvent) => Promise<any>;
  private readonly _onMessageDeleted: (e: ChatEvent) => Promise<void>;
  private readonly _onMessageUpdated: (e: ChatEvent) => Promise<void>;

  private readonly _stateChanged: EventOwnerAsync<ChatStateChangeEvent> =
    new EventOwnerAsync<ChatStateChangeEvent>();
  private readonly _stateEvents = new Map<
    ChatState,
    EventOwnerAsync<ChatStateChangeEvent>
  >();
  private readonly _stateMachine = new ChatStateMachine();

  private _apiClient: ApiClient;
  private _channels: ChannelCollection;
  private _controlConnection: ControlConnection;
  private _defaultChannel: Channel;
  private _localAttendee: Attendee;
  private _subscribedView: SubscribedView;
  private _uploadClient: UploadClient;

  public get channels(): ChannelCollection {
    return this._channels;
  }
  public get defaultChannel(): Channel {
    return this._defaultChannel;
  }

  public get isBusy(): boolean {
    return this.state == "busy";
  }
  public get isError(): boolean {
    return this.state == "error";
  }
  public get isImpaired(): boolean {
    return this.state == "impaired";
  }
  public get isInitialized(): boolean {
    return this.state == "initialized";
  }
  public get isNew(): boolean {
    return this.state == "new";
  }
  public get isStarted(): boolean {
    return this.state == "started";
  }
  public get isStarting(): boolean {
    return this.state == "starting";
  }
  public get isStopped(): boolean {
    return this.state == "stopped";
  }
  public get isStopping(): boolean {
    return this.state == "stopping";
  }

  private readonly _channelAdded = new EventOwnerAsync<ChannelEvent>();
  private readonly _channelRemoved = new EventOwnerAsync<ChannelEvent>();
  private readonly _memberAdded = new EventOwnerAsync<MemberEvent>();
  private readonly _memberRemoved = new EventOwnerAsync<MemberEvent>();
  private readonly _messageDeleted = new EventOwnerAsync<MessageEvent>();
  private readonly _messageReceived = new EventOwnerAsync<MessageEvent>();
  private readonly _messageSent = new EventOwnerAsync<MessageEvent>();

  /** @event */
  public get channelAdded(): EventOwnerAsync<ChannelEvent> {
    return this._channelAdded;
  }
  /** @event */
  public get channelRemoved(): EventOwnerAsync<ChannelEvent> {
    return this._channelRemoved;
  }
  /** @event */
  public get memberAdded(): EventOwnerAsync<MemberEvent> {
    return this._memberAdded;
  }
  /** @event */
  public get memberRemoved(): EventOwnerAsync<MemberEvent> {
    return this._memberRemoved;
  }
  /** @event */
  public get messageDeleted(): EventOwnerAsync<MessageEvent> {
    return this._messageDeleted;
  }
  /** @event */
  public get messageReceived(): EventOwnerAsync<MessageEvent> {
    return this._messageReceived;
  }
  /** @event */
  public get messageSent(): EventOwnerAsync<MessageEvent> {
    return this._messageSent;
  }

  public get state(): ChatState {
    return this._stateMachine.state;
  }
  /** @event */
  public get stateChanged(): EventOwnerAsync<ChatStateChangeEvent> {
    return this._stateChanged;
  }

  /** @internal */
  constructor() {
    this._onChatMessageNotification = this.onChatMessageNotification.bind(
      Reactive.wrap(this)
    );
    this._onChatChannelNotification = this.onChatChannelNotification.bind(
      Reactive.wrap(this)
    );
    this._onChatChannelAttendeeNotification =
      this.onChatChannelAttendeeNotification.bind(Reactive.wrap(this));
    this._onLocalChannelAdded = this.onLocalChannelAdded.bind(
      Reactive.wrap(this)
    );
    this._onLocalChannelRemoved = this.onLocalChannelRemoved.bind(
      Reactive.wrap(this)
    );

    this._onLocalMemberAdded = this.onLocalMemberAdded.bind(
      Reactive.wrap(this)
    );
    this._onLocalMemberRemoved = this.onLocalMemberRemoved.bind(
      Reactive.wrap(this)
    );
    this._onLocalMessageDeleted = this.onLocalMessageDeleted.bind(
      Reactive.wrap(this)
    );
    this._onLocalMessageReceived = this.onLocalMessageReceived.bind(
      Reactive.wrap(this)
    );
    this._onLocalMessageSent = this.onLocalMessageSent.bind(
      Reactive.wrap(this)
    );

    this._stateEvents.set("busy", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("error", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set(
      "impaired",
      new EventOwnerAsync<ChatStateChangeEvent>()
    );
    this._stateEvents.set(
      "initialized",
      new EventOwnerAsync<ChatStateChangeEvent>()
    );
    this._stateEvents.set("new", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set(
      "started",
      new EventOwnerAsync<ChatStateChangeEvent>()
    );
    this._stateEvents.set(
      "starting",
      new EventOwnerAsync<ChatStateChangeEvent>()
    );
    this._stateEvents.set(
      "stopped",
      new EventOwnerAsync<ChatStateChangeEvent>()
    );
    this._stateEvents.set(
      "stopping",
      new EventOwnerAsync<ChatStateChangeEvent>()
    );
  }

  /** @internal */
  public init(init: ChatInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(
      init.controlConnection,
      "init.controlConnection"
    );
    Guard.isNotNullOrUndefined(init.localAttendee, "init.localAttendee");
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._apiClient = init.apiClient;
    this._controlConnection = init.controlConnection;
    this._localAttendee = init.localAttendee;
    this._subscribedView = init.subscribedView;
    this._uploadClient = init.uploadClient;

    this._defaultChannel = new Channel({
      apiClient: this._apiClient,
      chat: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      localAttendee: this._localAttendee,
      model: {
        createdBy: null,
        id: null,
        meetingId: this._controlConnection.meetingId,
        name: "Default",
        status: "ACTIVE",
        type: "PUBLIC",
        updatedBy: null,
        updatedOn: null,
      },
      subscribedView: this._subscribedView,
      uploadClient: this._uploadClient,
    });
    this._channels = new ChannelCollection();
    this._channels.tryAdd(this._defaultChannel);
    void this._defaultChannel.load();
    this._channels.added.bind(this._onLocalChannelAdded);
    this._channels.removed.bind(this._onLocalChannelRemoved);
    void this.setState("initialized");

    //Get Channel list from server and add to collection
    void this._apiClient.listAttendeeChatChannels(<AttendeeChatChannelListRequest>{
      attendeeId: this._controlConnection.attendeeId,
    }).then((response) => {
      if (response == null || response.values == null) return;
      for (const channel of response.values) {
        void this.addChannelInternal(channel.id, channel);
      }
    });
  }

  private async getChannel(channelId: string): Promise<Channel | null> {
    const channel =  channelId ? this._channels.get(channelId) : this._defaultChannel;

    if (!channel) {
      const response = await this._apiClient.getChatChannel(channelId);
      if (response == null || response.value == null) return null;
      return await this.addChannelInternal(channelId, response.value);
    }

    return channel;
  }

  private onLocalChannelAdded(e: ReadOnlyCollectionEvent<Channel>) {
    e.element.memberAdded.bind(this._onLocalMemberAdded);
    e.element.memberRemoved.bind(this._onLocalMemberRemoved);
    e.element.messageDeleted.bind(this._onLocalMessageDeleted);
    e.element.messageReceived.bind(this._onLocalMessageReceived);
    e.element.messageSent.bind(this._onLocalMessageSent);
  }

  private onLocalChannelRemoved(e: ReadOnlyCollectionEvent<Channel>) {
    e.element.memberAdded.unbind(this._onLocalMemberAdded);
    e.element.memberRemoved.unbind(this._onLocalMemberRemoved);
    e.element.messageDeleted.unbind(this._onLocalMessageDeleted);
    e.element.messageReceived.unbind(this._onLocalMessageReceived);
    e.element.messageSent.unbind(this._onLocalMessageSent);
  }

  private onLocalMemberAdded(e: MemberEvent): Promise<void> {
    return this._memberAdded.dispatch(e);
  }

  private onLocalMemberRemoved(e: MemberEvent): Promise<void> {
    return this._memberRemoved.dispatch(e);
  }

  private onLocalMessageDeleted(e: MessageEvent): Promise<void> {
    return this._messageDeleted.dispatch(e);
  }

  private onLocalMessageReceived(e: MessageEvent): Promise<void> {
    return this._messageReceived.dispatch(e);
  }

  private onLocalMessageSent(e: MessageEvent): Promise<void> {
    return this._messageSent.dispatch(e);
  }

  private async onChatChannelNotification(notification: ChatChannelNotification) : Promise<void> {
    const channelId = notification?.channelId;
    const channel = this._channels.get(channelId);
    if (notification.channelStatus == "DELETED") {
      //Delete record
      if (!channelId) return;
      void this.removeChannelInternal(channelId);
    } else if (channelId && !channel) {
      void this.addChannelInternal(channelId, {
        id: channelId,
        name: notification.channelName,
        status: notification.channelStatus,
        type: notification.channelType,
      });
    } else {
      //Update
      const channel = await this.getChannel(channelId);
      void channel.refreshModel({
        id: channelId,
        meetingId: this._controlConnection.meetingId,
        name: notification.channelName,
        status: notification.channelStatus,
        type: notification.channelType,
      });
    }
    return Promise.resolve();
  }

  private async onChatChannelAttendeeNotification(
    notification: ChatChannelAttendeeNotification
  ) : Promise<void> {
    const channelId = notification?.channelId;

    if (notification.roleType == "DELETED") {
      const attendeeId = notification?.attendeeId;
      const memberId = notification?.chatChannelAttendeeId;
      if (!attendeeId || !channelId || !memberId) return;

      if (attendeeId == this._controlConnection.attendeeId) {
        void this.removeChannelInternal(channelId);
      } else {
        const channel = await this.getChannel(channelId);
        if(channel)
          void channel.removeMemberInternal(memberId);
      }
    } else if (channelId) {
      const channel = await this.getChannel(channelId);
      if(channel)
        void channel.updateMemberInternal(
          notification.chatChannelAttendeeId
        );
    } else {
      //Add the role
      void this._defaultChannel.addMemberInternal(
        notification.chatChannelAttendeeId
      );
    }
    
    return Promise.resolve();
  }

  private onChatMessageNotification(notification: ChatMessageNotification) : Promise<void> {
    const channel = notification.channelId
      ? this._channels.get(notification.channelId)
      : this._defaultChannel;

    const messageId = notification.messageId;
    Guard.isNotNullOrUndefined(messageId, "messageId");

    if (notification.messageStatus == "DELETED") {
      //Delete Message
      void channel.removeMessageInternal(notification.messageId);
    } else if (notification.messageStatus == "FLAGGED") {
      //Flagged Message
    } else {
      void channel.addMessageInternal(
        notification.messageId,
        notification.sentBy,
        {
          id: notification.messageId,
          channelId: notification.channelId,
          meetingId: this._controlConnection.meetingId,
          mediaId: notification.mediaId,
          messageContent: notification.messageContent,
          messageType: notification.messageType,
          messagePriority: notification.messagePriority,
          messageStatus: notification.messageStatus,
        }
      );
    }

    return Promise.resolve();
  }

  /** @internal */
  private async addChannelInternal(
    channelId: string,
    channelModel?: ChatChannel
  ): Promise<Channel> {
    if (this._channels.get(channelId)) return;

    if (!channelModel) {
      const response = await this._apiClient.getChatChannel(channelId);
      if (response == null || response.value == null)
        throw new Error("Failed to get chat channel");
      channelModel = response.value;
    }

    const channel = new Channel({
      apiClient: this._apiClient,
      chat: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      localAttendee: this._localAttendee,
      model: channelModel,
      subscribedView: this._subscribedView,
      uploadClient: this._uploadClient,
    });
    await channel.load();
    this._channels.tryAdd(channel);
    await this._channelAdded.dispatch({ channel });
    return channel;
  }

  private async setState(state: ChatState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <ChatStateChangeEvent>{
      chat: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }

  /** @internal */
  public async removeChannelInternal(channelId: string): Promise<void> {
    const channel = this._channels.get(channelId);
    if (!channel || !this._channels.tryRemove(channelId)) return;
    await this._channelRemoved.dispatch({ channel });
  }

  public async createChannel(options: ChannelCreateOptions): Promise<Channel> {
    Guard.isNotNullOrUndefined(options, "options");
    Guard.isNotNullOrUndefined(options.name, "options.name");
    Guard.isNotNullOrUndefined(options.type, "options.type");

    const response = await this._controlConnection.sendChatChannelNotification({
      channelName: options.name,
      channelStatus: "ACTIVE",
      channelType: options.type,
    });

    if (
      response == null ||
      response.chatChannelNotification == null ||
      response.chatChannelNotification.channelId == null
    )
      throw new Error("Failed to create chat channel");

    const channel = new Channel({
      apiClient: this._apiClient,
      chat: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      localAttendee: this._localAttendee,
      model: {
        id: response.chatChannelNotification.channelId,
        meetingId: this._controlConnection.meetingId,
        name: response.chatChannelNotification.channelName,
        status: response.chatChannelNotification.channelStatus,
        type: response.chatChannelNotification.channelType,
      },
      subscribedView: this._subscribedView,
      uploadClient: this._uploadClient,
    });
    await channel.load();
    this._channels.tryAdd(channel);
    await this._channelAdded.dispatch({ channel });
    return channel;
  }

  public async search(
    options: ChatSearchOptions
  ): Promise<Collection<Message>> {
    const collection = await this._apiClient.listChatMessages({
      meetingId: this._controlConnection.meetingId,
      channelId: options.channelId,
      limit: options.limit,
      offset: options.offset,
      sortDirection: "DESC",
      where: options.filter,
    });

    const messages: Message[] = [];
    for (const messageModel of collection.values) {
      const attendee = await this._subscribedView.subscribeToAttendee(
        messageModel.createdBy,
        "chatMessageReceived"
      );
      const message = new Message({
        apiClient: this._apiClient,
        attendee: attendee,
        channel: await this.getChannel(messageModel.channelId),
        controlConnection: this._controlConnection,
        model: messageModel,
        uploadClient: this._uploadClient,
      });
      await message.load();
      messages.push(message);
    }
    return {
      totalCount: collection.totalCount,
      values: messages,
    };
  }

  public async start() {
    if (!this.isInitialized && !this.isStopped)
      throw Error("Can not start chat until meeting is joined.");
    await this.setState("starting");
    try {
      this._controlConnection.chatChannelNotification.bind(
        this._onChatChannelNotification
      );
      this._controlConnection.chatChannelAttendeeNotification.bind(
        this._onChatChannelAttendeeNotification
      );
      this._controlConnection.chatMessageNotification.bind(
        this._onChatMessageNotification
      );

      await this._channels.load({
        apiClient: this._apiClient,
        chat: Reactive.wrap(this),
        controlConnection: this._controlConnection,
        defaultChannel: this._defaultChannel,
        localAttendee: this._localAttendee,
        subscribedView: this._subscribedView,
        uploadClient: this._uploadClient,
      });
      await this.setState("started");
    } catch (error) {
      await this.setState("error");
      throw error;
    }
  }

  public async stop() {
    if (this.isInitialized || this.isStopped || this.isStopping || this.isNew)
      return;
    await this.setState("stopping");
    try {
      this._controlConnection.chatChannelNotification.unbind(
        this._onChatChannelNotification
      );
      this._controlConnection.chatChannelAttendeeNotification.unbind(
        this._onChatChannelAttendeeNotification
      );
      this._controlConnection.chatMessageNotification.unbind(
        this._onChatMessageNotification
      );
    } catch {
      /* best effort */
    } finally {
      await this.setState("stopped");
    }
  }
}
