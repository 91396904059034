
import { defineComponent, PropType } from "vue";
import { DisplayMedia, LocalMedia, Media, RemoteMedia, UserMedia } from "@liveswitch/sdk";

export default defineComponent({
  data(): {
      localDisplayMedia: DisplayMedia | null,
      localMedia: LocalMedia | null,
      localUserMedia: UserMedia | null,
      remoteMedia: RemoteMedia | null,
      showOverlay: boolean,
      styleDisplay: any,
      styleHeight: any,
      styleLeft: any,
      styleOpacity: any,
      stylePosition: any,
      styleTop: any,
      styleVisibility: any,
      styleWidth: any,
      videoFrameRates: number[],
      videoFrameSizes: { width: number, height: number, label: string }[]
  } {
    return {
      localDisplayMedia: this.media instanceof DisplayMedia ? <DisplayMedia>this.media : null,
      localMedia: this.media instanceof LocalMedia ? <LocalMedia>this.media : null,
      localUserMedia: this.media instanceof UserMedia ? <UserMedia>this.media : null,
      remoteMedia: this.media instanceof RemoteMedia ? <RemoteMedia>this.media : null,
      showOverlay: false,
      styleDisplay: undefined,
      styleHeight: undefined,
      styleLeft: undefined,
      styleOpacity: undefined,
      stylePosition: undefined,
      styleTop: undefined,
      styleVisibility: undefined,
      styleWidth: undefined,
      videoFrameRates: [12, 18, 25],
      videoFrameSizes: [
        { width: 640, height: 480, label: "480p" },
        { width: 1280, height: 720, label: "720p" },
        { width: 1920, height: 1080, label: "1080p" }
      ],
    }
  },
  methods: {
    reset() {
      this.styleDisplay = undefined;
      this.styleHeight = undefined;
      this.styleLeft = undefined;
      this.styleOpacity = undefined;
      this.stylePosition = undefined;
      this.styleTop = undefined;
      this.styleVisibility = undefined;
      this.styleWidth = undefined;
    },
    setDisplayNone() {
      this.styleDisplay = "none !important";
    },
    setOpacityZero() {
      this.styleOpacity = "0 !important";
    },
    setPositionNegative() {
      this.stylePosition = "absolute !important";
      this.styleLeft = "-10000px !important";
      this.styleTop = "-10000px !important";
    },
    setSizeZero() {
      this.styleHeight = "0px !important";
      this.styleWidth = "0px !important";
    },
    setVisibilityHidden() {
      this.styleVisibility = "hidden !important";
    },
  },
  props: {
    media: {
      type: Object as PropType<Media>,
    },
    overlay: Boolean,
    allOverlays: Boolean,
    first: Boolean,
  },
})
