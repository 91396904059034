//KB: this class calculates a bitrate over successive timestamps
export default class Bitrate {

  private _lastBitrate: number = null;
  private _lastBytesTransferred = 0;
  private _lastBytesTransferredTimestamp: DOMHighResTimeStamp = null;

  public calculate(bytesTransferred: number, timestamp: DOMHighResTimeStamp, ): number | null {
    let bitrate = this._lastBitrate;
    if (this._lastBytesTransferredTimestamp) {
      if (bytesTransferred < this._lastBytesTransferred) return bitrate;
      if (timestamp < this._lastBytesTransferredTimestamp) return bitrate;

      const deltaBytes = bytesTransferred - this._lastBytesTransferred;
      const deltaSeconds = (timestamp - this._lastBytesTransferredTimestamp) / 1000;

      if (deltaSeconds < 1) return bitrate;
      bitrate = Math.ceil(((deltaBytes * 8) / deltaSeconds) / 1024);
    }
    this._lastBitrate = bitrate;
    this._lastBytesTransferred = bytesTransferred;
    this._lastBytesTransferredTimestamp = timestamp;
    return bitrate;
  }
}