import RemoteMedia from "../media/RemoteMedia"
import TrackPriority from "../media/models/TrackPriority";
import Size from "../core/Size";
import TrackStatus from "../media/models/TrackStatus";
import TrackType from "../media/models/TrackType";
import MediaType from "../media/models/MediaType";
import Bitrate from "../core/Bitrate";
import Framerate from "../core/Framerate";
import StatisticConnection from "../models/StatisticConnection";
import StatisticVideo from "../models/StatisticVideo";
import StatisticAudio from "../models/StatisticAudio";
import DeltaCounter from "../core/DeltaCounter";

//TODO: this should be a class and contain base functionality for managing each track
export default class Track {
  index: number;
  status: TrackStatus;
  mediaType: MediaType;
  trackType: TrackType;
  priority: TrackPriority;
  targetPriority: TrackPriority;

  transceiver: RTCRtpTransceiver;
  media: RemoteMedia;

  spatialLayerIndex: number;
  temporalLayerIndex: number;

  statAudio: StatisticAudio;
  statVideo: StatisticVideo;
  statPair: StatisticConnection;

  //viewSize: Size;

  public BitrateAudio = new Bitrate();
  public BitrateVideo = new Bitrate();
  public FramerateVideo = new Framerate();

  public PacketLoss: DeltaCounter = new DeltaCounter();
  public NackCount: DeltaCounter = new DeltaCounter();
  public PliCount: DeltaCounter = new DeltaCounter();


}