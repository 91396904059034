import Attendee from "../api/models/Attendee";
import AttendeeEvent from "./models/AttendeeEvent";
import AttendeePermissionRequest from "../api/models/AttendeePermissionRequest";
import AttendeeSetting from "../AttendeeSetting";
import ChatChannel from "../api/models/ChatChannel";
import ChatChannelAttendee from "../api/models/ChatChannelAttendee";
import ChatChannelAttendeeNotification from "./models/ChatChannelAttendeeNotification";
import ChatChannelNotification from "./models/ChatChannelNotification";
import ChatMessage from "../api/models/ChatMessage";
import ChatMessageNotification from "./models/ChatMessageNotification";
import ChatNotification from "./models/ChatNotification";
import ClientModel from "./models/Client";
import ConnectionBase from "../Connection";
import ConnectionInit from "./models/ConnectionInit";
import Constants from "../core/Constants";
import ControlAttendeeRequest from "./models/ControlAttendeeRequest";
import EventLogger from "../event/Logger";
import EventOwnerAsync from "../core/EventOwnerAsync";
import DispatchQueue from "../core/DispatchQueue";
import Meeting from "../api/models/Meeting";
import MeetingEvent from "./models/MeetingEvent";
import Message from "./models/Message";
import SubscribedReason from "../models/SubscribedReason";
import Room from "../api/models/Room";

export default class Connection extends ConnectionBase<Message> {

  private readonly _attendeeAdmitted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeAudioMuted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeAudioUnmuted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeAudioUnmuteDisabled = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeAudioUnmuteEnabled = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeBlockedFromLobby = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeBlockedFromRoom = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeHandLowered = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeHandRaised = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeJoined = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeKicked = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeLeft = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeNoiseSuppressed = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeNoiseUnsuppressed = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeePausedUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeePermissionAdded = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeePermissionRemoved = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeSettingAdded = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeSettingRemoved = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeePinned = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeQualityEdgeUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeQualityOriginUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeUnpinned = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeVideoMuted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeVideoUnmuted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeVideoUnmuteDisabled = new EventOwnerAsync<AttendeeEvent>();
  private readonly _attendeeVideoUnmuteEnabled = new EventOwnerAsync<AttendeeEvent>();
  private readonly _canBlock: () => boolean;
  private readonly _canCreateChatChannel: () => boolean;
  private readonly _canDeleteChatChannel: () => boolean;
  private readonly _canEnableChat: () => boolean;
  private readonly _canKick: () => boolean;
  private readonly _canMute: () => boolean;
  private readonly _canRecord: () => boolean;
  private readonly _canSendChatMessage: () => boolean;
  private readonly _canDeleteChatMessage: () => boolean;
  private readonly _canUpdate: () => boolean;
  private readonly _canUnmute: () => boolean;
  private readonly _chatChannelNotification = new EventOwnerAsync<ChatChannelNotification>();
  private readonly _chatMessageNotification = new EventOwnerAsync<ChatMessageNotification>();
  private readonly _chatChannelAttendeeNotification = new EventOwnerAsync<ChatChannelAttendeeNotification>();
  private readonly _client: ClientModel;
  private readonly _meetingAudioMuted = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingAudioUnmuted = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingAudioUnmuteDisabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingAudioUnmuteEnabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingChatDisabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingChatEnabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingNoiseSuppressed = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingNoiseUnsuppressed = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingRecordingDisabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingRecordingEnabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingRecordingStarted = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingRecordingStopped = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingUpdated = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingVideoMuted = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingVideoUnmuted = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingVideoUnmuteDisabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _meetingVideoUnmuteEnabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _messageNotificationQueue = new DispatchQueue();
  private readonly _subscribedAttendees = new EventOwnerAsync<AttendeeEvent>();
 
  private _attendee: Attendee;
  private _meeting: Meeting;
  private _room: Room;
  private _tenantId: string;

  public get attendee(): Attendee { return this._attendee; }
  public get attendeeAdmitted(): EventOwnerAsync<AttendeeEvent> { return this._attendeeAdmitted; }
  public get attendeeAudioMuted(): EventOwnerAsync<AttendeeEvent> { return this._attendeeAudioMuted; }
  public get attendeeAudioUnmuted(): EventOwnerAsync<AttendeeEvent> { return this._attendeeAudioUnmuted; }
  public get attendeeAudioUnmuteDisabled(): EventOwnerAsync<AttendeeEvent> { return this._attendeeAudioUnmuteDisabled; }
  public get attendeeAudioUnmuteEnabled(): EventOwnerAsync<AttendeeEvent> { return this._attendeeAudioUnmuteEnabled; }
  public get attendeeBlockedFromLobby(): EventOwnerAsync<AttendeeEvent> { return this._attendeeBlockedFromLobby; }
  public get attendeeBlockedFromRoom(): EventOwnerAsync<AttendeeEvent> { return this._attendeeBlockedFromRoom; }
  public get attendeeHandLowered(): EventOwnerAsync<AttendeeEvent> { return this._attendeeHandLowered; }
  public get attendeeHandRaised(): EventOwnerAsync<AttendeeEvent> { return this._attendeeHandRaised; }
  public get attendeeJoined(): EventOwnerAsync<AttendeeEvent> { return this._attendeeJoined; }
  public get attendeeKicked(): EventOwnerAsync<AttendeeEvent> { return this._attendeeKicked; }
  public get attendeeLeft(): EventOwnerAsync<AttendeeEvent> { return this._attendeeLeft; }
  public get attendeeNoiseSuppressed(): EventOwnerAsync<AttendeeEvent> { return this._attendeeNoiseSuppressed; }
  public get attendeeNoiseUnsuppressed(): EventOwnerAsync<AttendeeEvent> { return this._attendeeNoiseUnsuppressed; }
  public get attendeePausedUpdated(): EventOwnerAsync<AttendeeEvent> { return this._attendeePausedUpdated; }
  public get attendeePermissionAdded(): EventOwnerAsync<AttendeeEvent> { return this._attendeePermissionAdded; }
  public get attendeePermissionRemoved(): EventOwnerAsync<AttendeeEvent> { return this._attendeePermissionRemoved; }
  public get attendeeSettingAdded(): EventOwnerAsync<AttendeeEvent> { return this._attendeeSettingAdded; }
  public get attendeeSettingRemoved(): EventOwnerAsync<AttendeeEvent> { return this._attendeeSettingRemoved; }
  public get attendeePinned(): EventOwnerAsync<AttendeeEvent> { return this._attendeePinned; }
  public get attendeeQualityEdgeUpdated(): EventOwnerAsync<AttendeeEvent> { return this._attendeeQualityEdgeUpdated; }
  public get attendeeQualityOriginUpdated(): EventOwnerAsync<AttendeeEvent> { return this._attendeeQualityOriginUpdated; }
  public get attendeeUnpinned(): EventOwnerAsync<AttendeeEvent> { return this._attendeeUnpinned; }
  public get attendeeUpdated(): EventOwnerAsync<AttendeeEvent> { return this._attendeeUpdated; }
  public get attendeeVideoMuted(): EventOwnerAsync<AttendeeEvent> { return this._attendeeVideoMuted; }
  public get attendeeVideoUnmuted(): EventOwnerAsync<AttendeeEvent> { return this._attendeeVideoUnmuted; }
  public get attendeeVideoUnmuteDisabled(): EventOwnerAsync<AttendeeEvent> { return this._attendeeVideoUnmuteDisabled; }
  public get attendeeVideoUnmuteEnabled(): EventOwnerAsync<AttendeeEvent> { return this._attendeeVideoUnmuteEnabled; }
  
  public get chatChannelNotification(): EventOwnerAsync<ChatChannelNotification> { return this._chatChannelNotification; }
  public get chatMessageNotification(): EventOwnerAsync<ChatMessageNotification> { return this._chatMessageNotification; }
  public get chatChannelAttendeeNotification(): EventOwnerAsync<ChatChannelAttendeeNotification> { return this._chatChannelAttendeeNotification; }
  
  public get meeting(): Meeting { return this._meeting; }
  public get meetingAudioMuted(): EventOwnerAsync<MeetingEvent> { return this._meetingAudioMuted; }
  public get meetingAudioUnmuted(): EventOwnerAsync<MeetingEvent> { return this._meetingAudioUnmuted; }
  public get meetingAudioUnmuteDisabled(): EventOwnerAsync<MeetingEvent> { return this._meetingAudioUnmuteDisabled; }
  public get meetingAudioUnmuteEnabled(): EventOwnerAsync<MeetingEvent> { return this._meetingAudioUnmuteEnabled; }
  public get meetingChatDisabled(): EventOwnerAsync<MeetingEvent> { return this._meetingChatDisabled; }
  public get meetingChatEnabled(): EventOwnerAsync<MeetingEvent> { return this._meetingChatEnabled; }
  public get meetingNoiseSuppressed(): EventOwnerAsync<MeetingEvent> { return this._meetingNoiseSuppressed; }
  public get meetingNoiseUnsuppressed(): EventOwnerAsync<MeetingEvent> { return this._meetingNoiseUnsuppressed; }
  public get meetingRecordingDisabled(): EventOwnerAsync<MeetingEvent> { return this._meetingRecordingDisabled; }
  public get meetingRecordingEnabled(): EventOwnerAsync<MeetingEvent> { return this._meetingRecordingEnabled; }
  public get meetingRecordingStarted(): EventOwnerAsync<MeetingEvent> { return this._meetingRecordingStarted; }
  public get meetingRecordingStopped(): EventOwnerAsync<MeetingEvent> { return this._meetingRecordingStopped; }
  public get meetingUpdated(): EventOwnerAsync<MeetingEvent> { return this._meetingUpdated; }
  public get meetingVideoMuted(): EventOwnerAsync<MeetingEvent> { return this._meetingVideoMuted; }
  public get meetingVideoUnmuted(): EventOwnerAsync<MeetingEvent> { return this._meetingVideoUnmuted; }
  public get meetingVideoUnmuteDisabled(): EventOwnerAsync<MeetingEvent> { return this._meetingVideoUnmuteDisabled; }
  public get meetingVideoUnmuteEnabled(): EventOwnerAsync<MeetingEvent> { return this._meetingVideoUnmuteEnabled; }
  public get room(): Room { return this._room; }
  public get subscribedAttendees(): EventOwnerAsync<AttendeeEvent> { return this._subscribedAttendees; }
  public get tenantId(): string { return this._tenantId; }

  public constructor(init: ConnectionInit) {
    super({
      attendeeId: init.attendeeId,
      eventLogger: new EventLogger(init.apiClient, "ControlConnection", init.attendeeId, init.meetingId, init.clusterId),
      iceRestartEnabled: init.iceRestartEnabled,
      meetingId: init.meetingId,
      turnRequired: init.turnRequired,
      turnSession: init.turnSession,
      type: "Control",
    });
    this._client = init.client;
    this._canBlock = init.canBlock;
    this._canCreateChatChannel = init.canCreateChatChannel;
    this._canDeleteChatChannel = init.canDeleteChatChannel;
    this._canDeleteChatMessage = init.canDeleteChatMessage;
    this._canEnableChat = init.canEnableChat;
    this._canKick = init.canKick;
    this._canMute = init.canMute;
    this._canRecord = init.canRecord;
    this._canSendChatMessage = init.canSendChatMessage;
    this._canUnmute = init.canUnmute;
    this._canUpdate = init.canUpdate;

    this.attachEventHandlers();
  }

  protected async negotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    const response = await this._client.negotiate({ offer }, abortSignal);
    this._attendee = response.attendee!;
    this._meeting = response.meeting!;
    this._room = response.room!;
    this._tenantId = response.tenantId!;
    return response.answer;
  }

  protected processNotification(notification: Message): void {
    if (notification.type == "attendeeAddPermission") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeePermissionAdded.dispatch({
          attendeePermission: notification.attendeePermissionRequest,
        });
      });
    } else if (notification.type == "attendeeAddSetting") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeSettingAdded.dispatch({
          attendeeSetting: notification.attendeeSetting,
        });
      });
    } else if (notification.type == "attendeeAdmitted") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeAdmitted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeBlockedFromLobby") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeBlockedFromLobby.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeBlockedFromRoom") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeBlockedFromRoom.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeDisableAudioUnmute") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeAudioUnmuteDisabled.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeDisableVideoUnmute") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeVideoUnmuteDisabled.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeEnableAudioUnmute") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeAudioUnmuteEnabled.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeEnableVideoUnmute") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeVideoUnmuteEnabled.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeJoined") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeJoined.dispatch({
          attendee: notification.attendee,
          attendeeNotification: notification.attendeeNotification,
        });
      });
    } else if (notification.type == "attendeeKicked") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeKicked.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeLeft") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeLeft.dispatch({
          attendee: notification.attendee,
          attendeeNotification: notification.attendeeNotification,
        });
      });
    } else if (notification.type == "attendeeLowerHand") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeHandLowered.dispatch({
          attendeeSetting: notification.attendeeSetting,
        });
      });
    } else if (notification.type == "attendeeMuteAudioDisplay") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeAudioMuted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeMuteAudioUser") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeAudioMuted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeMuteVideoDisplay") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeVideoMuted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeMuteVideoUser") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeVideoMuted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeePausedUpdated") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeePausedUpdated.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeQualityEdgeUpdated") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeQualityEdgeUpdated.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeQualityOriginUpdated") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeQualityOriginUpdated.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeRaiseHand") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeHandRaised.dispatch({
          attendeeSetting: notification.attendeeSetting,
        });
      });
    } else if (notification.type == "attendeeRemovePermission") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeePermissionRemoved.dispatch({
          attendeePermission: notification.attendeePermissionRequest,
        });
      });
    } else if (notification.type == "attendeeRemoveSetting") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeSettingRemoved.dispatch({
          attendeeSetting: notification.attendeeSetting,
        });
      });
    } else if (notification.type == "attendeeSuppressNoise") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeNoiseSuppressed.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeUnmuteAudioDisplay") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeAudioUnmuted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeUnmuteAudioUser") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeAudioUnmuted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeUnmuteVideoDisplay") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeVideoUnmuted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeUnmuteVideoUser") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeVideoUnmuted.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeUnsuppressNoise") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeNoiseUnsuppressed.dispatch({
          attendee: notification.attendee,
        });
      });
    } else if (notification.type == "attendeeUpdated" ||
      notification.type == "attendeeUpdateAvatarUrl" ||
      notification.type == "attendeeUpdateDisplayName" ||
      notification.type == "attendeeUpdateRole") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._attendeeUpdated.dispatch({
          attendee: notification.attendee,
          attendeeNotification: notification.attendeeNotification,
        });
      });
    } else if (notification.type == "chatChannelNotification") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._chatChannelNotification.dispatch(notification.chatChannelNotification);
      });
    } else if (notification.type == "chatChannelAttendeeNotification") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._chatChannelAttendeeNotification.dispatch(notification.chatChannelAttendeeNotification);
      });
    }else if (notification.type == "chatMessageNotification") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._chatMessageNotification.dispatch(notification.chatMessageNotification);
      });
    }else if (notification.type == "chatDisabled") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingChatDisabled.dispatch({
          attendee: notification.attendee,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "chatEnabled") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingChatEnabled.dispatch({
          attendee: notification.attendee,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingDisableAudioUnmute") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingAudioUnmuteDisabled.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingDisableVideoUnmute") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingVideoUnmuteDisabled.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingEnableAudioUnmute") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingAudioUnmuteEnabled.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingEnableVideoUnmute") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingVideoUnmuteEnabled.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingMuteAudio") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingAudioMuted.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingMuteVideo") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingVideoMuted.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingSuppressNoise") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingNoiseSuppressed.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingStartRecording") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingRecordingStarted.dispatch({
          attendee: notification.attendee,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingStopRecording") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingRecordingStopped.dispatch({
          attendee: notification.attendee,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingUnmuteAudio") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingAudioUnmuted.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingUnmuteVideo") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingVideoUnmuted.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingUnsuppressNoise") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingNoiseUnsuppressed.dispatch({
          attendee: notification.attendee,
          exclusions: notification.exclusions,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "meetingUpdated") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingUpdated.dispatch({
          attendee: notification.attendee,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "recordingDisabled") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingRecordingDisabled.dispatch({
          attendee: notification.attendee,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "recordingEnabled") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._meetingRecordingEnabled.dispatch({
          attendee: notification.attendee,
          meeting: notification.meeting,
        });
      });
    } else if (notification.type == "subscribedAttendees") {
      void this._messageNotificationQueue.dispatch(() => {
        return this._subscribedAttendees.dispatch({
          attendees: notification.attendees,
        });
      });
    } else {
      void this.eventLogger.warning("dispatchNotification", `Unexpected "${notification.type}" control notification.`);
    }
  }

  protected async renegotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (await this._client.renegotiate({
      offer: offer,
    }, abortSignal)).answer;
  }

  /** @internal */
  public async addAttendeePermission(attendeePermissionRequest: AttendeePermissionRequest) {
    if (!this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotAddPermission);

    return await this.sendRequest({
      type: "attendeeAddPermission",
      attendeePermissionRequest: attendeePermissionRequest
    });
  }

  /** @internal */
  public async addAttendeeSetting(attendee: Attendee, setting: AttendeeSetting): Promise<Message> {
    if (attendee.id != this.attendeeId && !this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUpdateAttendee);

    return await this.sendRequest({
      type: "attendeeAddSetting",
      attendeeSetting: setting,
      attendee: attendee,
    });
  }

  /** @internal */
  public async addChatChannelMember(chatChannel: ChatChannel, chatChannelMember: ChatChannelAttendee): Promise<Message> {
    if (chatChannel.createdBy != this.attendeeId && !this._canCreateChatChannel()) throw new Error(Constants.Errors.Meeting.Permissions.cannotCreateChatChannelMember);

    const response = await this.sendChatChannelAttendeeNotification({
      channelId: chatChannel.id,
      attendeeId: chatChannelMember.attendeeId,
      roleType: chatChannelMember.roleType,
    });

    if(!response || !response.chatChannelAttendeeNotification) throw new Error(Constants.Errors.Meeting.Chat.cannotAddChatMember);

    return response;
  }

  /** @internal */
  public async admitAttendee(attendee: Attendee) {
    if (!this._canBlock()) throw new Error(Constants.Errors.Meeting.Permissions.cannotAdmit);

    await this.sendRequest({
      type: "attendeeAdmit",
      attendee: attendee
    });
  }

  /** @internal */
  public async blockAttendeeFromLobby(attendee: Attendee) {
    if (!this._canBlock()) throw new Error(Constants.Errors.Meeting.Permissions.cannotBlock);

    await this.sendRequest({
      type: "attendeeBlockFromLobby",
      attendee: attendee
    });
  }

  /** @internal */
  public async blockAttendeeFromRoom(attendee: Attendee) {
    if (!this._canBlock()) throw new Error(Constants.Errors.Meeting.Permissions.cannotBlock);

    await this.sendRequest({
      type: "attendeeBlockFromRoom",
      attendee: attendee
    });
  }

  /** @internal */
  public async disableAttendeeAudioUnmute(attendee: Attendee) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotDisableUnmute);

    await this.sendRequest({
      type: "attendeeDisableAudioUnmute",
      attendee: attendee
    });
  }

  /** @internal */
  public async disableAttendeeVideoUnmute(attendee: Attendee) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotDisableUnmute);

    await this.sendRequest({
      type: "attendeeDisableVideoUnmute",
      attendee: attendee
    });
  }

  /** @internal */
  public async disableChat(meeting: Meeting) {
    if (!this._canEnableChat()) throw new Error(Constants.Errors.Meeting.Permissions.cannotDisableChat);

    const response = await this.sendRequest({
      type: "meetingDisableChat",
      meeting: meeting
    });
    if (response.meeting.allowChat) throw new Error(Constants.Errors.Meeting.Chat.cannotDisable);

    // is this for responsiveness? (we don't do this on enable)
    await this._messageNotificationQueue.dispatch(() => {
      return this._meetingChatDisabled.dispatch({ meeting: response.meeting });
    });
  }

  /** @internal */
  public async disableMeetingAudioUnmute(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotDisableUnmute);

    await this.sendRequest({
      type: "meetingDisableAudioUnmute",
      meeting: meeting
    });
  }

  /** @internal */
  public async disableMeetingAudioUnmuteOnJoin(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotDisableUnmute);

    await this.sendRequest({
      type: "meetingDisableAudioUnmuteOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async disableMeetingVideoUnmute(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotDisableUnmute);

    await this.sendRequest({
      type: "meetingDisableVideoUnmute",
      meeting: meeting
    });
  }

  /** @internal */
  public async disableMeetingVideoUnmuteOnJoin(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotDisableUnmute);

    await this.sendRequest({
      type: "meetingDisableVideoUnmuteOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async enableAttendeeAudioUnmute(attendee: Attendee) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotEnableUnmute);

    await this.sendRequest({
      type: "attendeeEnableAudioUnmute",
      attendee: attendee
    });
  }

  /** @internal */
  public async enableAttendeeVideoUnmute(attendee: Attendee) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotEnableUnmute);

    await this.sendRequest({
      type: "attendeeEnableVideoUnmute",
      attendee: attendee
    });
  }

  /** @internal */
  public async enableChat(meeting: Meeting) {
    if (!this._canEnableChat()) throw new Error(Constants.Errors.Meeting.Permissions.cannotEnableChat);

    const response = await this.sendRequest({
      type: "meetingEnableChat",
      meeting: meeting
    });
    if (!response.meeting.allowChat) throw new Error(Constants.Errors.Meeting.Chat.cannotEnable);
  }

  /** @internal */
  public async enableMeetingAudioUnmute(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotEnableUnmute);

    await this.sendRequest({
      type: "meetingEnableAudioUnmute",
      meeting: meeting
    });
  }

  /** @internal */
  public async enableMeetingAudioUnmuteOnJoin(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotEnableUnmute);

    await this.sendRequest({
      type: "meetingEnableAudioUnmuteOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async enableMeetingVideoUnmute(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotEnableUnmute);

    await this.sendRequest({
      type: "meetingEnableVideoUnmute",
      meeting: meeting
    });
  }

  /** @internal */
  public async enableMeetingVideoUnmuteOnJoin(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotEnableUnmute);

    await this.sendRequest({
      type: "meetingEnableVideoUnmuteOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async flagChatChannelMessage(chatMessage: ChatMessage, notification: ChatNotification): Promise<Message> {
    if (!this._canSendChatMessage()) throw new Error(Constants.Errors.Meeting.Permissions.cannotChat);
    
    const response =  await this.sendChatMessageNotification({
      messageId: chatMessage.id,
      messageStatus: "FLAGGED",
      sentBy: this.attendeeId,
    });
    if(!response || !response.chatMessageNotification) throw new Error(Constants.Errors.Meeting.Chat.cannotFlagMessage);

    return response;
  }


  /** @internal */
  public async kickAttendee(attendee: Attendee) {
    if (!this._canKick()) throw new Error(Constants.Errors.Meeting.Permissions.cannotKick);

    await this.sendRequest({
      type: "attendeeKick",
      attendee: attendee
    });
  }

  /** @internal */
  public async listAttendees(request: ControlAttendeeRequest): Promise<Message> {
    return await this.sendRequest({
      type: "attendeeRequest",
      attendeeRequest: request
    });
  }

  /** @internal */
  public async lowerAttendeeHand(attendee: Attendee, setting: AttendeeSetting): Promise<Message> {
    if (attendee.id != this.attendeeId && !this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUpdateAttendee);

    return await this.sendRequest({
      type: "attendeeLowerHand",
      attendeeSetting: setting,
      attendee: attendee
    });
  }

  /** @internal */
  public async muteAttendeeAudio(attendee: Attendee) {
    if (attendee.id != this.attendeeId && !this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotMute);

    await this.sendRequest({
      type: "attendeeMuteAudioUser",
      attendee: attendee
    });
  }

  /** @internal */
  public async muteAttendeeVideo(attendee: Attendee) {
    if (attendee.id != this.attendeeId && !this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotMute);

    await this.sendRequest({
      type: "attendeeMuteVideoUser",
      attendee: attendee
    });
  }

  /** @internal */
  public async muteMeetingAudio(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotMute);

    await this.sendRequest({
      type: "meetingMuteAudio",
      meeting: meeting
    });
  }

  /** @internal */
  public async muteMeetingAudioOnJoin(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotMute);

    await this.sendRequest({
      type: "meetingMuteAudioOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async muteMeetingVideo(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotMute);

    await this.sendRequest({
      type: "meetingMuteVideo",
      meeting: meeting
    });
  }

  /** @internal */
  public async muteMeetingVideoOnJoin(meeting: Meeting) {
    if (!this._canMute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotMute);

    await this.sendRequest({
      type: "meetingMuteVideoOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async pinAttendee(attendee: Attendee) {
    await this.sendRequest({
      type: "attendeePin",
      attendeeId: attendee.id,
    });
  }

  /** @internal */
  public async raiseAttendeeHand(attendee: Attendee, setting: AttendeeSetting): Promise<Message> {
    if (attendee.id != this.attendeeId && !this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUpdateAttendee);

    return await this.sendRequest({
      type: "attendeeRaiseHand",
      attendeeSetting: setting,
      attendee: attendee
    });
  }

  /** @internal */
  public async removeAttendeePermission(attendeePermissionRequest: AttendeePermissionRequest) {
    if (!this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotRemovePermission);

    return await this.sendRequest({
      type: "attendeeRemovePermission",
      attendeePermissionRequest: attendeePermissionRequest
    });
  }
  
  /** @internal */
  public async removeAttendeeSetting(attendee: Attendee, setting: AttendeeSetting): Promise<Message> {
    if (attendee.id != this.attendeeId && !this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUpdateAttendee);

    return await this.sendRequest({
      type: "attendeeRemoveSetting",
      attendeeSetting: setting,
      attendee: attendee,
    });
  }

  /** @internal */
  public async subscribeToAttendee(attendeeId: string, reason: SubscribedReason): Promise<Message> {
    return await this.sendRequest({
      type: "attendeeSubscribe",
      attendeeId: attendeeId,
      reason: reason
    });
  }

  /** @internal */
  public async startRecordingMeeting(meeting: Meeting) {
    if (!this._canRecord()) throw new Error(Constants.Errors.Meeting.Permissions.cannotRecordMeeting);

    const response = await this.sendRequest({
      type: "meetingStartRecording",
      meeting: meeting
    });
    if (!response.meeting.isRecording) throw new Error(Constants.Errors.Meeting.Recording.cannotStart);
    return response;
  }

  /** @internal */
  public async stopRecordingMeeting(meeting: Meeting) {
    if (!this._canRecord()) throw new Error(Constants.Errors.Meeting.Permissions.cannotRecordMeeting);

    const response = await this.sendRequest({
      type: "meetingStopRecording",
      meeting: meeting
    });
    if (response.meeting.isRecording) throw new Error(Constants.Errors.Meeting.Recording.cannotStop);
    return response;
  }

  /** @internal */
  public async suppressAttendeeNoise(attendee: Attendee) {
    if (attendee.id != this.attendeeId && !this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotSuppressNoise);

    await this.sendRequest({
      type: "attendeeSuppressNoise",
      attendee: attendee
    });
  }

  /** @internal */
  public async suppressMeetingNoise(meeting: Meeting) {
    if (!this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotSuppressNoise);

    await this.sendRequest({
      type: "meetingSuppressNoise",
      meeting: meeting
    });
  }

  /** @internal */
  public async suppressMeetingNoiseOnJoin(meeting: Meeting) {
    if (!this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotSuppressNoise);

    await this.sendRequest({
      type: "meetingSuppressNoiseOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async unmuteAttendeeAudio(attendee: Attendee) {
    if (attendee.id != this.attendeeId && !this._canUnmute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnmute);

    await this.sendRequest({
      type: "attendeeUnmuteAudioUser",
      attendee: attendee
    });
  }

  /** @internal */
  public async unmuteAttendeeVideo(attendee: Attendee) {
    if (attendee.id != this.attendeeId && !this._canUnmute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnmute);

    await this.sendRequest({
      type: "attendeeUnmuteVideoUser",
      attendee: attendee
    });
  }

  /** @internal */
  public async unmuteMeetingAudio(meeting: Meeting) {
    if (!this._canUnmute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnmute);

    await this.sendRequest({
      type: "meetingUnmuteAudio",
      meeting: meeting
    });
  }

  /** @internal */
  public async unmuteMeetingAudioOnJoin(meeting: Meeting) {
    if (!this._canUnmute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnmute);

    await this.sendRequest({
      type: "meetingUnmuteAudioOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async unmuteMeetingVideo(meeting: Meeting) {
    if (!this._canUnmute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnmute);

    await this.sendRequest({
      type: "meetingUnmuteVideo",
      meeting: meeting
    });
  }

  /** @internal */
  public async unmuteMeetingVideoOnJoin(meeting: Meeting) {
    if (!this._canUnmute()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnmute);

    await this.sendRequest({
      type: "meetingUnmuteVideoOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async unpinAttendee(attendee: Attendee) {
    await this.sendRequest({
      type: "attendeeUnpin",
      attendeeId: attendee.id,
    });
  }

  /** @internal */
  public async unsuppressAttendeeNoise(attendee: Attendee) {
    if (attendee.id != this.attendeeId && !this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnsuppressNoise);

    await this.sendRequest({
      type: "attendeeUnsuppressNoise",
      attendee: attendee
    });
  }

  /** @internal */
  public async unsuppressMeetingNoise(meeting: Meeting) {
    if (!this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnsuppressNoise);

    await this.sendRequest({
      type: "meetingUnsuppressNoise",
      meeting: meeting
    });
  }

  /** @internal */
  public async unsuppressMeetingNoiseOnJoin(meeting: Meeting) {
    if (!this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUnsuppressNoise);

    await this.sendRequest({
      type: "meetingUnsuppressNoiseOnJoin",
      meeting: meeting
    });
  }

  /** @internal */
  public async updateAttendeeAvatarUrl(attendee: Attendee) {
    if (attendee.id != this.attendeeId && !this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUpdateAttendee);

    await this.sendRequest({
      type: "attendeeUpdateAvatarUrl",
      attendee: attendee,
    });
  }

  /** @internal */
  public async updateAttendeeDisplayName(attendee: Attendee) {
    if (attendee.id != this.attendeeId && !this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUpdateAttendee);

    await this.sendRequest({
      type: "attendeeUpdateDisplayName",
      attendee: attendee,
    });
  }

  /** @internal */
  public async updateAttendeeRole(attendee: Attendee) {
    if (!this._canUpdate()) throw new Error(Constants.Errors.Meeting.Permissions.cannotUpdateAttendee);

    return await this.sendRequest({
      type: "attendeeUpdateRole",
      attendee: attendee,
    });
  }

  /** @internal */
  public async sendChatMessageNotification(notification: ChatMessageNotification): Promise<Message> {
    if(!this._canSendChatMessage()) throw new Error(Constants.Errors.Meeting.Chat.cannotSendMessage);

    return await this.sendRequest({
      type: "chatMessageNotification",
      chatMessageNotification: notification
    });
  }

  /** @internal */
  public async sendChatChannelNotification(notification: ChatChannelNotification): Promise<Message> {
    return await this.sendRequest({
      type: "chatChannelNotification",
      chatChannelNotification: notification
    });
  }

  /** @internal */
  public async sendChatChannelAttendeeNotification(notification: ChatChannelAttendeeNotification): Promise<Message> {
    return await this.sendRequest({
      type: "chatChannelAttendeeNotification",
      chatChannelAttendeeNotification: notification
    });
  }

  //protected processConnectionStats(stats: Map<string, any>): void {
  //  this._stats.updateFromConnection(stats);
  //}

  public updateStats(): Promise<void> {
    return Promise.resolve();
  }
}