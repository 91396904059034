import LocalVideoTrack from "./LocalVideoTrack";

export default class DisplayVideoTrack extends LocalVideoTrack {
  private static _contentHint: string = "detail";

  public static get contentHint(): string { return this._contentHint; }
  public static set contentHint(value: string) { this._contentHint = value; }

  public get isMuted(): boolean { return false; }
  public get isPaused(): boolean { return false; }

  protected onEnded(): void {
    if (this.media.isStarted) void this.media.stop();
    super.onEnded();
  }

  protected prepareStream(stream: MediaStreamTrack): void {
    try {
      if ("contentHint" in stream) stream.contentHint = DisplayVideoTrack.contentHint;
    } catch { /* best effort */ }
  }

  public getConstraints(): MediaTrackConstraints {
    const room = this.media.room;
    const constraints = super.getConstraints(true);
    if (room) {

      const videoHeightMax = (room.maxRecordHeightDisplay > room.maxVideoHeightDisplay) ? room.maxRecordHeightDisplay : room.maxVideoHeightDisplay;
      const videoWidthMax = (room.maxRecordWidthDisplay > room.maxVideoWidthDisplay) ? room.maxRecordWidthDisplay : room.maxVideoWidthDisplay;

      constraints.frameRate = { ideal: room.maxVideoFramerateDisplay };
      constraints.height = { max: videoHeightMax, ideal: videoHeightMax };
      constraints.width = { max: videoWidthMax, ideal: videoWidthMax };
    }

    return constraints;
  }
}
