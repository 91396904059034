import Meeting from "../Meeting";
import { AccountPasswordHashLength, AccountPasswordSaltLength } from "./validation/Account";
import { ApiKeyNameLength, ApiKeySecretLength, ApiKeyTypeLength, ApiKeyValueLength } from "./validation/ApiKey";
import { AttendeeClientTypeLength, AttendeeDisplayNameLength, AttendeeSdkVersionLength, AttendeeSipConnectorIdLength, AttendeeSourceLanguageLength } from "./validation/Attendee";
import { ChatChannelNameLength } from "./validation/ChatChannel";
import { ChatMessageContentLength, ChatMessageFileNameLength } from "./validation/ChatMessage";
import { ChatMessageReactionContentLength } from "./validation/ChatMessageReaction";
import { ClusterAdminApiKeyLength, ClusterAdminApiUrlLength, ClusterKeyLength, ClusterMeetingUrlLength, ClusterNameLength, ClusterSecretLength, ClusterSipSecretLength, ClusterSipUrlLength, ClusterUrlLength } from "./validation/Cluster";
import { ClusterSettingNameLength, ClusterSettingTypeLength, ClusterSettingValueLength } from "./validation/ClusterSetting";
import { ClusterTagTagLength } from "./validation/ClusterTag";
import { CountryCodeLength, CountryNameLength, CountryNumericLength } from "./validation/Country";
import { DatabaseLogMessageLength, DatabaseLogNameLength, DatabaseLogTypeLength } from "./validation/DatabaseLog";
import { DeviceIdentifierLength, DeviceIpNumberLength, DevicePlatformLength, DevicePlatformVersionLength } from "./validation/Device";
import { LocationNameLength } from "./validation/Location";
import { MeetingInviteEmailLength } from "./validation/MeetingInvite";
import { MeetingSettingNameLength, MeetingSettingValueLength } from "./validation/MeetingSetting";
import { PlanSettingNameLength, PlanSettingValueLength } from "./validation/PlanSetting";
import { PlanTemplateNameLength } from "./validation/PlanTemplate";
import { RegionAbbreviationLength, RegionCodeLength, RegionNameLength } from "./validation/Region";
import { RoomKeyLength } from "./validation/Room";
import { RoomScheduleRecurrenceLength } from "./validation/RoomSchedule";
import { RoomSettingNameLength, RoomSettingValueLength } from "./validation/RoomSetting";
import { RoomSipInboundNumberPasscodeHostLength, RoomSipInboundNumberPasscodeLength } from "./validation/RoomSipInboundNumber";
import { RoomStorageKeyLength, RoomStorageUrlLength } from "./validation/RoomStorage";
import { RoomTagTagLength } from "./validation/RoomTag";
import { ServerAppVersionLength, ServerArchitectureLength, ServerExternalHostLength, ServerExternalProtocolLength, ServerInternalHostLength, ServerInternalProtocolLength, ServerMediaHostLength, ServerMediaProtocolLength, ServerNameLength, ServerOperatingSystemLength, ServerOperatingSystemVersionLength } from "./validation/Server";
import { ServerSettingNameLength, ServerSettingValueLength } from "./validation/ServerSetting";
import { ServerTagTagLength } from "./validation/ServerTag";
import { StorageKeyLength, StorageRetentionUnitLength, StorageUrlLength } from "./validation/Storage";
import { TenantBannerLength, TenantImageLength, TenantNameLength, TenantUrlLength } from "./validation/Tenant";
import { TenantSettingNameLength, TenantSettingValueLength } from "./validation/TenantSetting";
import { TenantTagTagLength } from "./validation/TenantTag";
import { TimezoneNameLength } from "./validation/Timezone";
import { TraceLogComponentLength, TraceLogEnvironmentLength, TraceLogOperationLength } from "./validation/TraceLog";
import { UserAccountAvatarUrlLength, UserAccountDisplayNameLength, UserAccountUserNameLength } from "./validation/UserAccount";
import { UserAccountSettingNameLength, UserAccountSettingValueLength } from "./validation/UserAccountSetting";
import { UserAccountTagTagLength } from "./validation/UserAccountTag";
import { WebhookKeyLength, WebhookUrlLength } from "./validation/Webhook";

export default class Constants {
  public static Errors = {
    Account: {
      invalidPasswordHashLength: `Password hash is not valid. It must be less than ${AccountPasswordHashLength.Max} characters.`,
      invalidPasswordSaltLength: `Password salt is not valid. It must be less than ${AccountPasswordSaltLength.Max} characters.`,
    },
    ApiKey: {
      invalidNameLength: `Name is not valid. It must be less than ${ApiKeyNameLength.Max} characters.`,
      invalidSecretLength: `Secret is not valid. It must be less than ${ApiKeySecretLength.Max} characters.`,
      invalidTypeLength: `Type is not valid. It must be less than ${ApiKeyTypeLength.Max} characters.`,
      invalidValueLength: `Value is not valid. It must be less than ${ApiKeyValueLength.Max} characters.`,
    },
    Attendee: {
      cannotLowerOtherAttendeesHand: "Cannot lower other attendees hand.",
      cannotRaiseOtherAttendeesHand: "Cannot raise other attendees hand.",
      cannotRefreshAttendeeList: "Attendee list cannot be refreshed until a meeting has been joined.",
      invalidClientTypeLength: `Client type is not valid. It must be less than ${AttendeeClientTypeLength.Max} characters.`,
      invalidDisplayNameLength: `Display name is not valid. It must be less than ${AttendeeDisplayNameLength.Max} characters.`,
      invalidSDKVersionLength: `SDK version is not valid. It must be less than ${AttendeeSdkVersionLength.Max} characters.`,
      invalidSipConnectorIDLength: `Sip connector ID is not valid. It must be less than ${AttendeeSipConnectorIdLength.Max} characters.`,
      invalidSourceLanguageLength: `Source language is not valid. It must be less than ${AttendeeSourceLanguageLength.Max} characters.`,
      Permissions: {
        update: "Insufficient permissions to update attendee.",
      }
    },
    ChatChannel: {
      invalidNameLength: `Name is not valid. It must be less than ${ChatChannelNameLength.Max} characters.`,
    },
    ChatMessage: {
      invalidContentLength: `Content is not valid. It must be less than ${ChatMessageContentLength.Max} characters.`,
      invalidFileNameLength: `Filename is not valid. It must be less than ${ChatMessageFileNameLength.Max} characters.`,
    },
    ChatMessageReaction: {
      invalidContentLength: `Content is not valid. It must be less than ${ChatMessageReactionContentLength.Max} characters.`,
    },
    Cluster: {
      invalidAdminAPIKeyLength: `Admin API Key is not valid. It must be less than ${ClusterAdminApiKeyLength.Max} characters.`,
      invalidAdminAPIUrlLength: `Admin API Url is not valid. It must be less than ${ClusterAdminApiUrlLength.Max} characters.`,
      invalidNameLength: `Name is not valid. It must be less than ${ClusterNameLength.Max} characters.`,
      invalidKeyLength: `Key is not valid. It must be less than ${ClusterKeyLength.Max} characters.`,
      invalidSecretLength: `Secret is not valid. It must be less than ${ClusterSecretLength.Max} characters.`,
      invalidUrlLength: `Url is not valid. It must be less than ${ClusterUrlLength.Max} characters.`,
      invalidMeetingUrlLength: `Meeting Url is not valid. It must be less than ${ClusterMeetingUrlLength.Max} characters.`,
      invalidSipUrlLength: `Sip Url is not valid. It must be less than ${ClusterSipUrlLength.Max} characters.`,
      invalidSipSecretLength: `Sip Secret is not valid. It must be less than ${ClusterSipSecretLength.Max} characters.`,
    },
    ClusterSetting: {
      invalidNameLength: `Name is not valid. It must be less than ${ClusterSettingNameLength.Max} characters.`,
      invalidTypeLength: `Type is not valid. It must be less than ${ClusterSettingTypeLength.Max} characters.`,
      invalidValueLength: `Value is not valid. It must be less than ${ClusterSettingValueLength.Max} characters.`,
    },
    ClusterTag: {
      invalidTagLength: `Tag is not valid. It must be less than ${ClusterTagTagLength.Max} characters.`,
    },
    Country: {
      invalidCodeLength: `Code is not valid. It must be less than ${CountryCodeLength.Max} characters.`,
      invalidNameLength: `Name is not valid. It must be less than ${CountryNameLength.Max} characters.`,
      invalidNumericLength: `Numeric is not valid. It must be less than ${CountryNumericLength.Max} characters.`,
    },
    DatabaseLog: {
      invalidEventMessageLength: `Event message is not valid. It must be less than ${DatabaseLogMessageLength.Max} characters.`,
      invalidEventNameLength: `Event name is not valid. It must be less than ${DatabaseLogNameLength.Max} characters.`,
      invalidEventTypeLength: `Event type is not valid. It must be less than ${DatabaseLogTypeLength.Max} characters.`,
    },
    Device: {
      invalidIdentifierLength: `Identifier is not valid. It must be less than ${DeviceIdentifierLength.Max} characters.`,
      invalidIPNumberLength: `IP number is not valid. It must be less than ${DeviceIpNumberLength.Max} characters.`,
      invalidPlatformLength: `Platform is not valid. It must be less than ${DevicePlatformLength.Max} characters.`,
      invalidPlatformVersionLength: `Platform Version is not valid. It must be less than ${DevicePlatformVersionLength.Max} characters.`,
    },
    General: {
      Operation: {
        aborted: "Operation aborted.",
      }
    },
    Location: {
      invalidNameLength: `Name is not valid. It must be less than ${LocationNameLength.Max} characters.`,
    },
    Media: {
      Destroy: {
        isDestroying: "Media is already destroying.",
        notInitialized: "Media cannot be destroyed until it is initialized.",
      },
      Devices: {
        Audio: {
          remoteCannotSet: "Remote tracks cannot set an audio device.",
          noTrack: "No audio track.",
        },
        Video: {
          remoteCannotSet: "Remote tracks cannot set a video device.",
          noTrack: "No video track.",
        }
      },
      Initialize: {
        isInitializing: "Media is already initializing.",
      },
      Permissions: {
        record: "Insufficient permissions to record.",
        canStart: "Insufficient permissions to start media.",
      },                
      Start: {
        isDestroyed: "Media cannot be started after it is destroyed.",
        isStarting: "Media is already starting.",
        isStopping: "Media cannot be started while it is stopping.",
        notInitialized: "Media cannot be started until it is initialized.",
      },                
      Stop: {
        isDestroyed: "Media cannot be stopped after it is destroyed.",
        isStarting: "Media cannot be stopped while it is starting.",
        isStopping: "Media is already stopping.",
        notInitialized: "Media cannot be stopped until it is initialized.",
      },
      Transform: {
        notSupported: "Transforms are not supported on this device.",
      },
    },
    Meeting: {
      notJoined: "Haven't joined the meeting.",
      noLobby: "Meeting does not have a lobby.",
      cannotSubscribe: "Could not subscribe to attendee.",
      Chat: {
        cannotUpdateDefaultChannel: "Cannot update the default channel.",
        cannotDeleteDefaultChannel: "Cannot delete the default channel.",
        cannotDisable: "Chat could not be disabled.",
        cannotEnable: "Chat could not be enabled.",
        cannotAddChatMember: "Unable to add member to chat.",
        cannotAddChatMemberToDefaultChannel: "Cannot add members to the default channel.",
        cannotFlagMessage: "Unable to flag chat message.",
        cannotSendMessage: "Could not send message.",
        cannotGetMessage: "Could not get message.",
        cannotGetChannel: "Error getting chat channel."
      },
      Permissions: {
        cannotAddPermission: "Insufficient permissions to add permission.",
        cannotAdmit: "Insufficient permissions to admit attendee.",
        cannotAdministerChat: "Insufficient permissions to administer chat.",
        cannotBlock: "Insufficient permissions to block attendee.",
        cannotChat: "Insufficient permissions to chat.",
        cannotCreateChatChannel: "Insufficient permissions to create chat channel.",
        cannotDeleteChatChannel: "Insufficient permissions to delete chat channel.",
        cannotUpdateChatChannel: "Insufficient permissions to update chat channel.",
        cannotCreateChatChannelMember: "Insufficient permissions to add member to chat channel.",
        cannotDeleteChatChannelMember: "Insufficient permissions to remove member from chat channel.",
        cannotUpdateChatChannelMember: "Insufficient permissions to update member in chat channel.",
        cannotDeleteChatMessage: "Insufficient permissions to delete chat message.",
        cannotUpdateChatMessage: "Insufficient permissions to update chat message.",
        cannotUpdateChatMessageNotOwner: "Cannot update message. You did not create it.",
        cannotDisableUnmute: "Insufficient permissions to disable unmute.",
        cannotDisableChat: "Insufficient permissions to disable chat.",
        cannotEnableChat: "Insufficient permissions to enable chat.",
        cannotEnableUnmute: "Insufficient permissions to enable unmute.",
        cannotKick: "Insufficient permissions to kick attendee.",
        cannotMute: "Insufficient permissions to mute attendee.",         
        cannotRemovePermission: "Insufficient permissions to remove permission.",
        cannotSuppressNoise: "Insufficient permission to suppress audio noise for attendee.",  
        cannotUpdateAttendee: "Insufficient permissions to update attendee.",
        cannotUpdateMeeting: "Insufficient permissions to update meeting.",
        cannotUnmute: "Insufficient permissions to unmute attendee.",  
        cannotUnsuppressNoise: "Insufficient permission to unsuppress audio noise for attendee.",   
        cannotRecordMeeting: "Insufficient permissions to start/stop recording.",
      },
      Join: {
        isJoining: "Already joining meeting.",
        isLeaving: "Cannot join meeting while you are leaving.",
      },
      Leave: {
        isJoining: "Cannot leave meeting while you are joining.",
        isLeaving: "Already leaving meeting.",
      },
      Recording: {
        cannotDisable: "Recording could not be disabled.",
        cannotEnable: "Recording could not be enabled.",
        cannotStart: "Recording could not be started.",
        cannotStop: "Recording could not be stopped.",
        isStarted: "Recording already started.",
        isStopped: "Recording already stopped.",
      }
    },
    MeetingInvite: {
      invalidEmailLength: `E-mail is not valid. It must be less than ${MeetingInviteEmailLength.Max} characters.`,
    },
    MeetingSetting: {
      invalidNameLength: `Name is not valid. It must be less than ${MeetingSettingNameLength.Max} characters.`,
      invalidValueLength: `Value is not valid. It must be less than ${MeetingSettingValueLength.Max} characters.`,
    },
    PlanSetting: {
      invalidNameLength: `Name is not valid. It must be less than ${PlanSettingNameLength.Max} characters.`,
      invalidValueLength: `Value is not valid. It must be less than ${PlanSettingValueLength.Max} characters.`,
    },
    PlanTemplate: {
      invalidNameLength: `Name is not valid. It must be less than ${PlanTemplateNameLength.Max} characters.`,
    },
    ReadOnlyCollection: {
      copyWithin: "copyWithin not allowed.",
      fill: "fill not allowed.",
      missingElement: "Missing required parameter 'element'.",
      missingKey: "Missing required parameter 'key'.",
      pop: "pop not allowed.",
      push: "push not allowed.",
      reverse: "reverse not allowed.",
      shift: "shift not allowed.",
      sort: "sort not allowed.",
      splice: "splice not allowed.",
      unshift: "unshift not allowed.",
    },        
    Region: {
      invalidAbbreviationLength: `Abbreviation is not valid. It must be less than ${RegionAbbreviationLength.Max} characters.`,
      invalidCodeLength: `Code is not valid. It must be less than ${RegionCodeLength.Max} characters.`,
      invalidNameLength: `Name is not valid. It must be less than ${RegionNameLength.Max} characters.`,
    },
    Room: {
      invalidKeyLength: `Key is not valid. It must be between ${RoomKeyLength.Min} - ${RoomKeyLength.Max} characters.`,
    },
    RoomSchedule: {
      invalidRecurrenceLength: `Recurrence is not valid. It must be less than ${RoomScheduleRecurrenceLength.Max} characters.`,
    },
    RoomSetting: {
      invalidNameLength: `Name is not valid. It must be less than ${RoomSettingNameLength.Max} characters.`,
      invalidValueLength: `Value is not valid. It must be less than ${RoomSettingValueLength.Max} characters.`,
    },
    RoomSipInboundNumber: {
      invalidPasscodeLength: `Passcode is not valid. It must be less than ${RoomSipInboundNumberPasscodeLength.Max} characters.`,
      invalidPasscodeHostLength: `Passcode host is not valid. It must be less than ${RoomSipInboundNumberPasscodeHostLength.Max} characters.`,
    },
    RoomStorage: {
      invalidKeyLength: `Key is not valid. It must be less than ${RoomStorageKeyLength.Max} characters.`,
      invalidUrlLength: `Url is not valid. It must be less than ${RoomStorageUrlLength.Max} characters.`,
    },
    RoomTag: {
      invalidTagLength: `Tag is not valid. It must be less than ${RoomTagTagLength.Max} characters.`,
    },
    Server: {
      invalidNameLength: `Name is not valid. It must be less than ${ServerNameLength.Max} characters.`,
      invalidAppVersionLength: `App version is not valid. It must be less than ${ServerAppVersionLength.Max} characters.`,
      invalidArchitectureLength: `Architecture is not valid. It must be less than ${ServerArchitectureLength.Max} characters.`,
      invalidInternalHostLength: `Internal host is not valid. It must be less than ${ServerInternalHostLength.Max} characters.`,
      invalidInternalProtocolLength: `Internal protocol is not valid. It must be less than ${ServerInternalProtocolLength.Max} characters.`,
      invalidMediaHostLength: `Media host is not valid. It must be less than ${ServerMediaHostLength.Max} characters.`,
      invalidMediaProtocolLength: `Media protocol is not valid. It must be less than ${ServerMediaProtocolLength.Max} characters.`,
      invalidOperatingSystemLength: `Operating system is not valid. It must be less than ${ServerOperatingSystemLength.Max} characters.`,
      invalidOperatingSystemVersionLength: `Operating system version is not valid. It must be less than ${ServerOperatingSystemVersionLength.Max} characters.`,
      invalidExternalHostLength: `External host is not valid. It must be less than ${ServerExternalHostLength.Max} characters.`,
      invalidExternalProtocolLength: `External protocol is not valid. It must be less than ${ServerExternalProtocolLength.Max} characters.`,
    },
    ServerSetting: {
      invalidNameLength: `Name is not valid. It must be less than ${ServerSettingNameLength.Max} characters.`,
      invalidValueLength: `Value is not valid. It must be less than ${ServerSettingValueLength.Max} characters.`,
    },
    ServerTag: {
      invalidTagLength: `Tag is not valid. It must be less than ${ServerTagTagLength.Max} characters.`,
    },
    Storage: {
      invalidKeyLength: `Key is not valid. It must be less than ${StorageKeyLength.Max} characters.`,
      invalidUrlLength: `Url is not valid. It must be less than ${StorageUrlLength.Max} characters.`,
      invalidRetentionUnitLength: `Retention unit is not valid. It must be less than ${StorageRetentionUnitLength.Max} characters.`,
    },  
    Tenant: {
      invalidBannerLength: `Banner is not valid. It must be less than ${TenantBannerLength.Max} characters.`,
      invalidImageLength: `Image is not valid. It must be less than ${TenantImageLength.Max} characters.`,
      invalidNameLength: `Name is not valid. It must be less than ${TenantNameLength.Max} characters.`,
      invalidUrlLength: `Url is not valid. It must be less than ${TenantUrlLength.Max} characters.`,
    },
    TenantSetting: {
      invalidNameLength: `Name is not valid. It must be less than ${TenantSettingNameLength.Max} characters.`,
      invalidValueLength: `Value is not valid. It must be less than ${TenantSettingValueLength.Max} characters.`,
    },
    TenantTag: {
      invalidTagLength: `Tag is not valid. It must be less than ${TenantTagTagLength.Max} characters.`,
    },
    Timezone: {
      invalidTimezoneNameLength: `Timezone name is not valid. It must be less than ${TimezoneNameLength.Max} characters.`,
    },
    TraceLog: {
      invalidEnvironmentLength: `Environment is not valid. It must be less than ${TraceLogEnvironmentLength.Max} characters.`,
      invalidComponentLength: `Component is not valid. It must be less than ${TraceLogComponentLength.Max} characters.`,
      invalidOperationLength: `Operation is not valid. It must be less than ${TraceLogOperationLength.Max} characters.`,
    },
    Track: {
      Destroy: {
        isDestroying: "Track is already destroying.",
        isStarting: "Track cannot be destroyed while it is starting.",
        isStopping: "Track cannot be destroyed while it is stopping.",
        isStarted: "Track cannot be destroyed until it is stopped.",
        notInitialized: "Track cannot be destroyed until it is initialized.",
      },
      Initialize: {
        isInitializing: "Track is already initializing.",
      },
      Start: {
        isDestroyed: "Track cannot be started after it is destroyed.",
        isStarting: "Track is already starting.",
        isStopping: "Track cannot be started while it is stopping.",
        notInitialized: "Track cannot be started until it is initialized.",
      },
      Stop: {
        isDestroyed: "Track cannot be stopped after it is destroyed.",
        isStarting: "Track cannot be stopped while it is starting.",
        isStopping: "Track is already stopping.",
        notInitialized: "Track cannot be stopped until it is initialized.",
      },
    },
    UserAccount: {
      invalidAvatarUrlLength: `Avatar Url is not valid. It must be less than ${UserAccountAvatarUrlLength.Max} characters.`,
      invalidDisplayNameLength: `Display name is not valid. It must be less than ${UserAccountDisplayNameLength.Max} characters.`,
      invalidUserNameLength: `Username is not valid. It must be less than ${UserAccountUserNameLength.Max} characters.`,
    },
    UserAccountSetting: {
      invalidNameLength: `Name is not valid. It must be less than ${UserAccountSettingNameLength.Max} characters.`,
      invalidValueLength: `Value is not valid. It must be less than ${UserAccountSettingValueLength.Max} characters.`,
    },
    UserAccountTag: {
      invalidTagLength: `Tag is not valid. It must be less than ${UserAccountTagTagLength.Max} characters.`,
    },
    Webhook: {
      invalidKeyLength: `Key is not valid. It must be less than ${WebhookKeyLength.Max} characters.`,
      invalidUrlLength: `Url is not valid. It must be less than ${WebhookUrlLength.Max} characters.`,
    },
  };
  public static Settings = {
    Meeting:{
      Chat:{
        DefaultMessageCollectionLoadLimit: 100,
      }
    }
  };
}