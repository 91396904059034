//KB: this class calculates a delta value over successive timestamps
export default class DeltaCounter {


  private _lastPercentage: number = null;
  private _lastDelta: number = null;
  private _lastValue: number = null;
  private _lastTotal: number = null;

  public getDelta(currentValue: number, currentTotal: number): number {
    if ((currentValue == 0) || (currentTotal == 0)) return 0;
    if ((currentValue == this._lastValue) && (currentTotal == this._lastTotal)) return 0;

    var currentTotalDelta = currentTotal - this._lastTotal;
    if (currentTotalDelta < 1) return 0;

    var currentDelta = currentValue - this._lastValue;

    if (currentDelta < 1) {
      this._lastPercentage = 0;
    }
    else {
      this._lastPercentage = currentValue / currentTotal;
    }

    this._lastDelta = currentDelta;
    this._lastValue = currentValue;
    this._lastTotal = currentTotal;

    return this._lastDelta;
  }

  public getPercentage(): number {
    return this._lastPercentage;
  }

  public reset() {
    this._lastPercentage = 0;
    this._lastDelta = 0;
    this._lastValue = 0;
    this._lastTotal = 0;
  }

}