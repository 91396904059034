import ApiClient from "../api/Client";
import Constants from "../core/Constants";
import { DatabaseLogMessageLength, DatabaseLogNameLength } from "../core/validation/DatabaseLog";
import Guard from "../core/Guard";
import Log from "../logging/Log";
import LoggerModel from "./models/Logger";
import LogEventSeverity from "../api/models/LogEventSeverity";
import LogLevel from "../logging/Level";
import Utility from "../core/Utility";
import ApplicationInsightsLogProvider from "./ApplicationInsightsLogProvider";

export default class Logger implements LoggerModel {
  private readonly _attendeeId: string;
  private readonly _component: string;
  private readonly _meetingId: string;
    
  public constructor(client: ApiClient, component: string, attendeeId: string, meetingId: string, clusterId: string) {
    Guard.isNotNullOrUndefined(client, "client");
    Guard.isNotNullOrUndefined(component, "component");
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    Guard.isNotNullOrUndefined(meetingId, "meetingId");
    this._component = component;
    this._attendeeId = attendeeId;
    this._meetingId = meetingId;
    void ApplicationInsightsLogProvider.initialize(client, clusterId);
  }

  private static getLevelFromEventSeverity(eventSeverity: LogEventSeverity): LogLevel {
    if (eventSeverity == "FATAL") return "fatal";
    if (eventSeverity == "ERROR") return "error";
    if (eventSeverity == "WARNING") return "warn";
    if (eventSeverity == "INFORMATION") return "info";
    if (eventSeverity == "DEBUG") return "debug";
    return "verbose";
  }

  private static getIndexOfLevel(level: LogLevel): number {
    if (level == "none") return 6;
    if (level == "fatal") return 5;
    if (level == "error") return 4;
    if (level == "warn") return 3;
    if (level == "info") return 2;
    if (level == "debug") return 1;
    return 0; // verbose
  }

  private async doLog(severity: LogEventSeverity, param1: any, param2: any, param3?: any, param4?: any, param5?: any): Promise<void> {
    const level = Logger.getLevelFromEventSeverity(severity);
    if (Logger.getIndexOfLevel(level) < Logger.getIndexOfLevel(Log.level)) return;

    const debug: string[] = [];
    const errorProvided = Utility.isString(param3);
    if (errorProvided && !(param1 instanceof Error)) param1 = new Error(param1);
    const error = (errorProvided ? param1 : undefined) as Error;
    const operation = (errorProvided ? param2 : param1) as string;
    const message = (errorProvided ? param3 : param2) as string;
    const duration = (errorProvided ? param4 : param3) as number;
    const eventName = `${this._component}.${operation}`;
    this.validate(message, eventName);
    const parameters = {} as { [key: string]: string | number };

    Object.assign(parameters, errorProvided ? param5 : param4);
    
    if (parameters) Object.entries(parameters).forEach(([key, value]) => debug.push(`${key}=${value}`))
    parameters["attendeeId"] = this._attendeeId;
    parameters["meetingId"] = this._meetingId;
    parameters["message"] = message;
    if (duration) parameters["duration"] = Utility.isNumber(duration) ? Math.round(duration) : duration;

    this.writeToLocalLogger(severity, eventName, message, debug.join("\n"), duration, error);

    if (errorProvided) ApplicationInsightsLogProvider.logException(error, parameters);
    else ApplicationInsightsLogProvider.logEvent(eventName, parameters);
  }

  private validate(eventMessage: string, eventName: string): void {
    //if (!Utility.isNullOrUndefined(eventMessage) && eventMessage.length > DatabaseLogMessageLength.Max) {
    //  throw new Error(Constants.Errors.DatabaseLog.invalidEventMessageLength);
    //}
    //if (!Utility.isNullOrUndefined(eventName) && eventName.length > DatabaseLogNameLength.Max) {
    //  throw new Error(Constants.Errors.DatabaseLog.invalidEventNameLength);
    //}
  }

  private writeToLocalLogger(eventSeverity: LogEventSeverity, eventName: string, eventMessage: string, eventDebug: string, eventDuration: number, error?: Error): void {
    Log.write({
      debug: eventDebug,
      error: error,
      level: Logger.getLevelFromEventSeverity(eventSeverity),
      message: `[${eventName}] ${eventMessage} ${!Utility.isNullOrUndefined(eventDuration) ? `[Duration: ${eventDuration}]` : "" }`.trimEnd(),
      timestamp: new Date(),
    });
  }
  
  debug(operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  debug(error: Error, operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  public debug(param1: any, param2: any, param3?: any, param4?: any, param5?: any): Promise<void> {
    Guard.isNotNullOrUndefined(param1, "operation/error");
    Guard.isNotNullOrUndefined(param2, "message/operation");
    return this.doLog("DEBUG", param1, param2, param3, param4, param5);
  }

  error(operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  error(error: Error, operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  public error(param1: any, param2: any, param3?: any, param4?: any, param5?: any): Promise<void> {
    Guard.isNotNullOrUndefined(param1, "operation/error");
    Guard.isNotNullOrUndefined(param2, "message/operation");
    return this.doLog("ERROR", param1, param2, param3, param4, param5);
  }

  fatal(operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  fatal(error: Error, operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  public fatal(param1: any, param2: any, param3?: any, param4?: any, param5?: any): Promise<void> {
    Guard.isNotNullOrUndefined(param1, "operation/error");
    Guard.isNotNullOrUndefined(param2, "message/operation");
    return this.doLog("FATAL", param1, param2, param3, param4, param5);
  }

  information(operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  information(error: Error, operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  public information(param1: any, param2: any, param3?: any, param4?: any, param5?: any): Promise<void> {
    Guard.isNotNullOrUndefined(param1, "operation/error");
    Guard.isNotNullOrUndefined(param2, "message/operation");
    return this.doLog("INFORMATION", param1, param2, param3, param4, param5);
  }

  warning(operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  warning(error: Error, operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  public warning(param1: any, param2: any, param3?: any, param4?: any, param5?: any): Promise<void> {
    Guard.isNotNullOrUndefined(param1, "operation/error");
    Guard.isNotNullOrUndefined(param2, "message/operation");
    return this.doLog("WARNING", param1, param2, param3, param4, param5);
  }

  verbose(operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  verbose(error: Error, operation: string, message: string, duration?: number, parameters?: { [key: string]: string | number }): Promise<void>;
  public verbose(param1: any, param2: any, param3?: any, param4?: any, param5?: any): Promise<void> {
    Guard.isNotNullOrUndefined(param1, "operation/error");
    Guard.isNotNullOrUndefined(param2, "message/operation");
    return this.doLog("VERBOSE", param1, param2, param3, param4, param5);
  }
}