import AudioTrack from "./AudioTrack";
import AudioTrackEvent from "./models/AudioTrackEvent";
import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import LocalAudioTrackEvent from "./models/LocalAudioTrackEvent";
import LocalAudioTrackStateChangeEvent from "./models/LocalAudioTrackStateChangeEvent";
import LocalAudioTrackState from "./models/LocalAudioTrackState";
import LocalAudioTrackStateMachine from "./LocalAudioTrackStateMachine";
import LocalMedia from "./LocalMedia";
import Log from "../logging/Log";
import Reactive from "../core/Reactive";

export default abstract class LocalAudioTrack extends AudioTrack {
  private readonly _onEnded: () => void;
  private readonly _onPaused: () => void;
  private readonly _onResumed: () => void;
  private readonly _ended = new EventOwner<LocalAudioTrackEvent>();
  private readonly _paused = new EventOwner<LocalAudioTrackEvent>();
  private readonly _resumed = new EventOwner<LocalAudioTrackEvent>();
  private readonly _stateChanged: EventOwnerAsync<LocalAudioTrackStateChangeEvent> = new EventOwnerAsync<LocalAudioTrackStateChangeEvent>();
  private readonly _stateEvents = new Map<LocalAudioTrackState, EventOwnerAsync<LocalAudioTrackStateChangeEvent>>();
  private readonly _stateMachine = new LocalAudioTrackStateMachine();
  private readonly _streamBound = new EventOwnerAsync<AudioTrackEvent>();
  private readonly _streamUnbound = new EventOwner<AudioTrackEvent>();

  private _fallbackReason: string | null;
  private _isInFallbackMode: boolean = false;
  private _isReplacingStream: any;
  private _media: LocalMedia = null;
  private _stream: MediaStreamTrack = null;

  /** @internal */
  public set media(value: LocalMedia) { this._media = value; }

  public get bitrateMax(): number { return this._media?.connection?.audioBitrateMax; }
  public get fallbackReason(): string { return this._fallbackReason; }
  public get isInFallbackMode(): boolean { return this._isInFallbackMode; }
  public get isRemote(): boolean { return false; }
  public get isStarted(): boolean { return this.state == "started"; }
  public get isStarting(): boolean { return this.state == "starting"; }
  public get isStopped(): boolean { return this.state == "stopped" || this.state == "new"; }
  public get isStopping(): boolean { return this.state == "stopping"; }
  public get media(): LocalMedia { return this._media; }
  public get state(): LocalAudioTrackState { return this._stateMachine.state; }
  public get stream(): MediaStreamTrack { return this._stream; }
  

  /** @event */
  public get ended(): EventOwner<LocalAudioTrackEvent> { return this._ended; }
  /** @event */
  public get paused(): EventOwner<LocalAudioTrackEvent> { return this._paused; }
  /** @event */
  public get resumed(): EventOwner<LocalAudioTrackEvent> { return this._resumed; }
  /** @event */
  public get started(): EventOwnerAsync<LocalAudioTrackStateChangeEvent> { return this._stateEvents.get("started"); }
  /** @event */
  public get starting(): EventOwnerAsync<LocalAudioTrackStateChangeEvent> { return this._stateEvents.get("starting"); }
  /** @event */
  public get stateChanged(): EventOwnerAsync<LocalAudioTrackStateChangeEvent> { return this._stateChanged; }
  /** @event */
  public get stopped(): EventOwnerAsync<LocalAudioTrackStateChangeEvent> { return this._stateEvents.get("stopped"); }
  /** @event */
  public get stopping(): EventOwnerAsync<LocalAudioTrackStateChangeEvent> { return this._stateEvents.get("stopping"); }
  /** @event */
  public get streamBound(): EventOwnerAsync<AudioTrackEvent> { return this._streamBound; }
  /** @event */
  public get streamUnbound(): EventOwner<AudioTrackEvent> { return this._streamUnbound; }

  public constructor() {
    super();
    this._onEnded = this.onEnded.bind(Reactive.wrap(this));
    this._onPaused = this.onPaused.bind(Reactive.wrap(this));
    this._onResumed = this.onResumed.bind(Reactive.wrap(this));

    this._stateEvents.set("started", new EventOwnerAsync<LocalAudioTrackStateChangeEvent>());
    this._stateEvents.set("starting", new EventOwnerAsync<LocalAudioTrackStateChangeEvent>());
    this._stateEvents.set("stopped", new EventOwnerAsync<LocalAudioTrackStateChangeEvent>());
    this._stateEvents.set("stopping", new EventOwnerAsync<LocalAudioTrackStateChangeEvent>());
  }

  private async bindStream(stream: MediaStreamTrack): Promise<void> {
    if (!stream) return;
    if (this._stream == stream) return;
    if (this._stream) this.unbindStream();
    this.prepareStream(stream);
    this._stream = stream;
    this._stream.addEventListener("ended", this._onEnded);
    this._stream.addEventListener("mute", this._onPaused);
    this._stream.addEventListener("unmute", this._onResumed);
    this._stream.enabled = !this.isMuted;
    await this._streamBound.dispatch({
      track: this
    });
  }

  private async setState(state: LocalAudioTrackState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <LocalAudioTrackStateChangeEvent>{
      audioTrack: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }

  private unbindStream(): void {
    if (!this._stream) return;
    this._stream.stop();
    this._stream.removeEventListener("ended", this._onEnded);
    this._stream.removeEventListener("mute", this._onPaused);
    this._stream.removeEventListener("unmute", this._onResumed);
    this._stream = null;
    this._streamUnbound.dispatch({
      track: this
    });
  }

  protected onEnded(): void {
    Log.warn(`Local ${this.media.type} audio track has ended.`);
    this._ended.dispatch({
      track: this
    });
   }

  protected onPaused(): void {
    Log.debug(`Local ${this.media.type} audio track has paused.`);
    this._paused.dispatch({
      track: this
    });
  }

  protected onResumed(): void {
    Log.debug(`Local ${this.media.type} audio track has resumed.`);
    this._resumed.dispatch({
      track: this
    });
  }

  protected async onStarted(): Promise<void> { }
  
  protected async onStarting(): Promise<void> { }

  protected async onStopped(): Promise<void> { }

  protected async onStopping(): Promise<void> { }
  
  protected prepareStream(stream: MediaStreamTrack): void { }

  protected async replaceStream(): Promise<void> {
    if (this._isReplacingStream) {
      Log.warn('Ignoring request to replace audio stream as a previous request is still in progress.');
      return;
    }
    if (!this._media?.stream) return;
    
    try {
      if (this._stream) {
        this._media.stream.removeTrack(this._stream);
        this.unbindStream();
      }
      const mediaStream = await this._media.getStreamInternal({ audio: this.getConstraints()}, this._media.fallbackIfDeviceNotAvailable);
      const [trackStream] = mediaStream.getAudioTracks();
      this._media.stream.addTrack(trackStream);
      await this.bindStream(trackStream);
    } finally {
      this._isReplacingStream = false;
    }
  }

  /** @internal */
  public clearFallback() {
    this._isInFallbackMode = false;
    this._fallbackReason = null;
  }

  /** @internal */
  public setFallback(errorName: string) {
    this._isInFallbackMode = true;
    this._fallbackReason = errorName;
  }
  
  /**
   * For testing only.
   */
  /** @internal */
  public end(): void {
    this.stream?.stop();
    this.stream?.dispatchEvent(new Event("ended"));
  }

  /**
   * For testing only.
   */
  /** @internal */
  public pause(): void {
    this.stream?.dispatchEvent(new Event("mute"));
  }

  /**
   * For testing only.
   */
  /** @internal */
  public resume(): void {
    this.stream?.dispatchEvent(new Event("unmute"));
  }

  /** @internal */
  public async startInternal(stream: MediaStreamTrack): Promise<void> {
    if (this.state == "started") return;
    try {
      await this.setState("starting");
      await this.onStarting();
      await this.bindStream(stream);
      await this.onStarted();
      await this.setState("started");
    } catch (error) {
      await this.setState("stopped");
      await this.onStopped();
      throw error;
    }
  }

  /** @internal */
  public async stopInternal(): Promise<void> {
    if (this.state == "new") await this.setState("stopped");
    if (this.state == "stopped") return;
    try {
      await this.setState("stopping");
      await this.onStopping();
      this.unbindStream();
    } finally {
      await this.setState("stopped");
      await this.onStopped();
    }
  }
  
  public getConstraints(): MediaTrackConstraints {
    //TODO: should pull from room including channelCount and sampleSize
    return { };
  }

  public reload(): Promise<void> {
    return this.replaceStream();
  }

  public start() {
    return this._media.startAudio();
  }

  public async stop() {
    return this._media.stopAudio();
  }
}
