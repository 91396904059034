import DisplayVideoTrack from "./DisplayVideoTrack";
import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import RemoteMedia from "./RemoteMedia";
import TrackPriority from "../media/models/TrackPriority";
import TrackStatus from "../media/models/TrackStatus";
import RemoteVideoTrackEvent from "./models/RemoteVideoTrackEvent";
import VideoTrack from "./VideoTrack";
import VideoTrackEvent from "./models/VideoTrackEvent";
import UserVideoTrack from "./UserVideoTrack";
import Utility from "../core/Utility";

export default class RemoteVideoTrack extends VideoTrack {
  private readonly _media: RemoteMedia = null;
  private readonly _statusUpdated = new EventOwnerAsync<RemoteVideoTrackEvent>();
  private readonly _streamBound = new EventOwnerAsync<VideoTrackEvent>();
  private readonly _streamUnbound = new EventOwner<VideoTrackEvent>();

  private _stream: MediaStreamTrack = null;

  public get isDisabled(): boolean { return this.status == "disabled"; }
  public get isMuted(): boolean { return (this._media.type == "user" && this._media.attendee?.isVideoMuted) ?? false; }
  public get isPaused(): boolean { return (this._media.type == "user" && this._media.attendee?.isVideoPaused) ?? false; }
  public get isRemote(): boolean { return true; }
  public get media(): RemoteMedia { return this._media; }
  public get priority(): TrackPriority | null {
    const trackIndex = this.getTrackIndex();
    if (Utility.isNullOrUndefined(trackIndex)) return null;
    return this._media.connection.getTrackPriority(this._media.type, "video", trackIndex);
  }
  public get spatialLayerIndex(): number {
    const trackIndex = this.getTrackIndex();
    if (Utility.isNullOrUndefined(trackIndex)) return null;
    return this._media.connection.getTrackSpatialLayerIndex(this._media.type, "video", trackIndex);
  }
  public get status(): TrackStatus | null {
    const trackIndex = this.getTrackIndex();
    if (Utility.isNullOrUndefined(trackIndex)) return null;
    return this._media.connection.getTrackStatus(this._media.type, "video", trackIndex);
  }
  public get stream(): MediaStreamTrack { return this._stream; }
  public get temporalLayerIndex(): number {
    const trackIndex = this.getTrackIndex();
    if (Utility.isNullOrUndefined(trackIndex)) return null;
    return this._media.connection.getTrackTemporalLayerIndex(this._media.type, "video", trackIndex);
  }

  /** @event */
  public get statusUpdated(): EventOwnerAsync<RemoteVideoTrackEvent> { return this._statusUpdated; }
  /** @event */
  public get streamBound(): EventOwnerAsync<VideoTrackEvent> { return this._streamBound; }
  /** @event */
  public get streamUnbound(): EventOwner<VideoTrackEvent> { return this._streamUnbound; }

  /** @internal */
  public constructor(media: RemoteMedia) {
    super();
    this._media = media;
  }

  private async bindStream(stream: MediaStreamTrack): Promise<void> {
    Guard.isNotNullOrUndefined(stream, "stream");
    this.prepareStream(stream);
    this._stream = stream;
    this._stream.enabled = !this.isMuted;
    await this._streamBound.dispatch({
      track: this
    });
  }

  private getTrackIndex(): number {
    if (!Utility.isNullOrUndefined(this._media.videoTrackIndex)) return this._media.videoTrackIndex;
    return this._media.linkedRemoteVideo?.videoTrackIndex;
  }

  private unbindStream(): void {
    this._stream = null;
    this._streamUnbound.dispatch({
      track: this
    });
  }

  /** @internal */
  public processStatusUpdated(): Promise<void> {
    return this._statusUpdated.dispatch({
      track: this,
    });
  }

  /** @internal */
  public async start(stream: MediaStreamTrack): Promise<void> {
    await this.onStarting();
    await this.bindStream(stream);
    await this.onStarted();
  }

  /** @internal */
  public stop(): void {
    this.onStopping();
    this.unbindStream();
    this.onStopped();
  }

  protected async onStarted(): Promise<void> { }
  protected async onStarting(): Promise<void> { }
  protected onStopped(): void { }
  protected onStopping(): void { }

  protected prepareStream(stream: MediaStreamTrack): void {
    if (this._media.type == "display") {
      try {
        if ("contentHint" in stream) stream.contentHint = DisplayVideoTrack.contentHint;
      } catch { /* best effort */ }
    } else {
      try {
        if ("contentHint" in stream) stream.contentHint = UserVideoTrack.contentHint;
      } catch { /* best effort */ }
    }
  }

  public async setPriority(priority: TrackPriority): Promise<void> {
    const trackIndex = this.getTrackIndex();
    if (Utility.isNullOrUndefined(trackIndex)) return;
    await this._media.connection.setTrackTargetPriority(priority, this._media.type, "video", trackIndex);
  }
}
