import ApiClient from "../api/Client";
import Channel from "./Channel";
import ControlConnection from "../control/Connection";
import Guard from "../core/Guard";
import Message from "./Message";
import MessageCollectionInit from "./models/MessageCollectionInit";
import ReadOnlyCollection from "../core/ReadOnlyCollection";
import SubscribedView from "../SubscribedView";
import Constants from "../core/Constants";
import { UploadClient } from "@liveswitch/storage";

export default class MessageCollection extends ReadOnlyCollection<Message> {
  private _apiClient: ApiClient;
  private _channel: Channel;
  private _controlConnection: ControlConnection;
  private _hasMore = true;
  private _loading = false;
  private _offsetId: string;
  private _subscribedView: SubscribedView;
  private _uploadClient: UploadClient;

  public get hasMore(): boolean {
    return this._hasMore;
  }

  /** @internal */
  constructor(apiClient: ApiClient, uploadClient: UploadClient) {
    super((message) => message.id);
    this._apiClient = apiClient;
    this._uploadClient = uploadClient;
  }

  private async getOlderMessages(count: number): Promise<boolean> {
    if (this._loading) return false;
    this._loading = true;
    try {
      const response = await this._apiClient.listChatMessages({
        attendeeId: this._controlConnection.attendeeId,
        meetingId: this._controlConnection.meetingId,
        channelId: this._channel.id,
        limit: count,
        offset: 0,
        sortDirection: "DESC",
      });

      const messageModels = response.values;
      this._hasMore = response.totalCount > messageModels.length;

      if (messageModels.length == 0) return false;
      this._offsetId = messageModels[messageModels.length - 1].id;

      const attendees = await Promise.all(
        messageModels.map((m) =>
          this._subscribedView.subscribeToAttendee(
            m.createdBy,
            "chatMessageReceived"
          )
        )
      );
      const messages = messageModels.map(
        (mm, i) =>
          new Message({
            apiClient: this._apiClient,
            attendee: attendees[i],
            channel: this._channel,
            controlConnection: this._controlConnection,
            model: mm,
            uploadClient: this._uploadClient,
          })
      );
      await Promise.all(messages.map((m) => m.load()));
      for (const message of messages) this.tryInsert(message, 0);
      return true;
    } finally {
      this._loading = false;
    }
  }

  /** @internal */
  public async load(
    init: MessageCollectionInit,
    count = Constants.Settings.Meeting.Chat.DefaultMessageCollectionLoadLimit
  ): Promise<boolean> {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(init.channel, "init.channel");
    Guard.isNotNullOrUndefined(
      init.controlConnection,
      "init.controlConnection"
    );
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._apiClient = init.apiClient;
    this._channel = init.channel;
    this._controlConnection = init.controlConnection;
    this._subscribedView = init.subscribedView;
    return await this.getOlderMessages(count);
  }

  public async loadMore(
    count = Constants.Settings.Meeting.Chat.DefaultMessageCollectionLoadLimit
  ): Promise<boolean> {
    return await this.getOlderMessages(count);
  }
}
