
export default class StatisticConnection {
  public AttendeeId: string = null;
  public DeviceId: number = null;
  public ServerId: number = null;
  public MediaType: string = null;
  public CreatedOn: Date = null;

  //candidate-pair
  public Pair_State: string = null;
  public Pair_Timestamp: number = null;
  public Pair_IsNominated: boolean = null;
  public Pair_BytesReceived: number = null;
  public Pair_BytesSent: number = null;
  public Pair_RoundTripTimeCurrent: number = null;
  public Pair_RoundTripTimeTotal: number = null;
  public Pair_AvailableOutgoing: number = null;
  public Pair_RequestsReceived: number = null;
  public Pair_RequestsSent: number = null;
  public Pair_ResponsesReceived: number = null;
  public Pair_ResponsesSent: number = null;
  public Pair_ConsentRequestsSent: number = null;

  //remote-candidate
  public Rmt_IsRemote: boolean = null;
  public Rmt_Ip: string = null;
  public Rmt_Port: number = null;
  public Rmt_Protocol: string = null;
  public Rmt_CandidateType: string = null;
  public Rmt_IsDeleted: boolean = null;

  //local-candidate
  public Lcl_IsRemote: boolean = null;
  public Lcl_Ip: string = null;
  public Lcl_Port: number = null;
  public Lcl_Protocol: string = null;
  public Lcl_CandidateType: string = null;
  public Lcl_Deleted: boolean = null;


  //codec
  public Codec_MimeType: string = null;
  public Codec_Channels: number = null;
  public Codec_ClockRate: number = null;

}