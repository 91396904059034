import Conference from "@/views/Conference.vue";
import Devices from "@/views/Devices.vue";
import ErrorHandling from "@/views/ErrorHandling.vue";
import HealthCheck from "@/views/HealthCheck.vue";
import JoinTimings from "@/views/JoinTimings.vue";
import QRMediaTest from "@/views/QRMediaTest.vue";
import QRTest from "@/views/QRTest.vue";
import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "LiveSwitch Conference",
    component: Conference,
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices,
  },
  {
    path: "/errorhandling",
    name: "ErrorHandling",
    component: ErrorHandling,
  },
  {
    path: "/healthcheck",
    name: "LiveSwitch Health Check",
    component: HealthCheck,
  },
  {
    path: "/qrmediatest",
    name: "LiveSwitch QR Media Test",
    component: QRMediaTest,
  },
  {
    path: "/qrtest",
    name: "LiveSwitch QR Test",
    component: QRTest,
  },
  {
    path: "/joinTimings",
    name: "JoinTimings",
    component: JoinTimings,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
