import DeviceManager from "./DeviceManager";
import DevicesEvent from "./models/DevicesEvent";
import LocalVideoTrack from "./LocalVideoTrack";
import Log from "../logging/Log";
import Reactive from "../core/Reactive";

export default class UserVideoTrack extends LocalVideoTrack {
  private static _contentHint: string = "motion";

  public static get contentHint(): string { return this._contentHint; }
  public static set contentHint(value: string) { this._contentHint = value; }

  private readonly _onVideoInputsUpdated: (e: DevicesEvent) => Promise<void>;

  private _deviceId: string = null;
  private _facingMode: string = null;
  private _isMuted = false;
  private _isSettingDevice: boolean = false;
  private _deviceLabel: string = null;
  private _requestedDeviceId: string = null;
  private _requestedDeviceRequired: boolean = false;

  public get deviceId(): string { return this._deviceId; }
  public get deviceLabel(): string { return this._deviceLabel; }
  public get facingMode(): string { return this._facingMode; }
  public get isMuted(): boolean { return this._isMuted; }
  public get isPaused(): boolean { return this.media?.attendee?.isVideoPaused ?? false; }
  public get requestedDeviceId(): string { return this._requestedDeviceId; }
  public get requestedDeviceRequired(): boolean { return this._requestedDeviceRequired; }

  public constructor() {
    super();
    this._onVideoInputsUpdated = this.onVideoInputsUpdated.bind(Reactive.wrap(this));
  }

  private async onVideoInputsUpdated(e: DevicesEvent): Promise<void> {
    // if we have one added device, with no label, and 1 total device, we have a camera but no permissions
    if (e.added.length && e.added[0].label == '' && DeviceManager.shared.videoInputs.length == 1) {
      // if track is started, prompt for permission in case
      if (this.isStarted && this.isInFallbackMode) await DeviceManager.shared.promptForVideoPermissions();
    }

    if (this._deviceId == "default" || e.removed.find(d => d.id == this._deviceId) || e.added.find(d => d.id == this._requestedDeviceId) || this._deviceId == null) {
      this.clearDeviceInfo();
      try {
        await this.replaceStream();
        this.updateDeviceInfo();
      } catch (error: any) {
        Log.warn("Could not replace video stream after device change.", error);
      }
    }
  }

  private clearDeviceInfo(): void {
    this._deviceId = null;
    this._facingMode = null;
    this._deviceLabel = null;
  }

  private updateDeviceInfo(): void {
    if (!this.stream) return;
    if (this.isInFallbackMode) return; // we dont want to set device details from canvas
    this._deviceId = this._requestedDeviceId;
    if (this.stream.label) this._deviceLabel = this.stream.label;

    const settings = this.stream.getSettings();
    if (settings.deviceId) this._deviceId = settings.deviceId;
    if (settings.facingMode) this._facingMode = settings.facingMode;
  }

  /** @internal */
  public muteInternal(): void {
    this._isMuted = true;
    if (this.stream) this.stream.enabled = false;
  }

  /** @internal */
  public unmuteInternal(): void {
    this._isMuted = false;
    if (this.stream) this.stream.enabled = true;
  }

  protected async onStarted(): Promise<void> {
    await DeviceManager.shared.refresh();
    DeviceManager.shared.videoInputsUpdated.bind(this._onVideoInputsUpdated);
    this.updateDeviceInfo();
  }

  protected async onStarting(): Promise<void> {
    await DeviceManager.shared.start();
  }

  protected async onStopping(): Promise<void> {
    DeviceManager.shared.videoInputsUpdated.unbind(this._onVideoInputsUpdated);
  }

  protected prepareStream(stream: MediaStreamTrack): void {
    try {
      if ("contentHint" in stream) stream.contentHint = UserVideoTrack.contentHint;
    } catch { /* best effort */ }
  }

  public getConstraints(): MediaTrackConstraints {
    //TODO: this should be moved
    const constraints = super.getConstraints(false);
    // Assuming this is still needed
    if (this._requestedDeviceId) {
      if (this._requestedDeviceRequired) {
        constraints.deviceId = { exact: this._requestedDeviceId };
      } else {
        constraints.deviceId = { ideal: this._requestedDeviceId };
      }
    }

    //NOTE: unnecessary because the super pulls in the values and overrides
    //const room = this.media.room;
    //if (room) {

    //  const videoHeightMax = (room.maxRecordHeightUser > room.maxVideoHeightUser) ? room.maxRecordHeightUser : room.maxVideoHeightUser;
    //  const videoWidthMax = (room.maxRecordWidthUser > room.maxVideoWidthUser) ? room.maxRecordWidthUser : room.maxVideoWidthUser;

    //  constraints.frameRate = { ideal: room.maxVideoFramerateUser };
    //  constraints.height = { min: room.minVideoHeightUser, max: videoHeightMax, ideal: videoHeightMax };
    //  constraints.width = { min: room.minVideoWidthUser, max: videoWidthMax, ideal: videoWidthMax };

    //}

    return constraints;
  }

  public async mute(): Promise<void> {
    const attendee = this.media.attendee;
    if (attendee) await attendee.muteVideo();
    else this.muteInternal();
  }

  public async setDevice(deviceId?: string, required?: boolean): Promise<void> {
    if (this._isSettingDevice) {
      Log.warn('Ignoring request to set video device as a previous request is still in progress.');
      return;
    };

    this._isSettingDevice = true;

    try {
      this._deviceId = null;
      this._requestedDeviceId = deviceId ?? null;
      this._requestedDeviceRequired = required ?? false;
      await this.replaceStream();
      this.updateDeviceInfo();
    } finally {
      this._isSettingDevice = false;
      if (deviceId && deviceId != this._deviceId) {
        Log.warn(`Could not set video device to id of ${deviceId}. Device may not support requested given constraints.`);
      } else {
        Log.info(`Set video device id to ${this._deviceId}.`);
      }
    }
  }

  public async unmute(): Promise<void> {
    const attendee = this.media.attendee;
    if (attendee) await attendee.unmuteVideo();
    else this.unmuteInternal();
  }

  public async useNextDevice(manager?: DeviceManager): Promise<void> {
    manager ??= DeviceManager.shared;
    await manager.start();
    const devices = manager.videoInputs;
    const currentDeviceId = this._deviceId ?? this._requestedDeviceId;
    const device = devices.next(currentDeviceId);
    if (device == null || device.id == currentDeviceId) return;
    await this.setDevice(device.id, this._requestedDeviceRequired);
  }

  public async usePreviousDevice(manager?: DeviceManager): Promise<void> {
    manager ??= DeviceManager.shared;
    await manager.start();
    const devices = manager.videoInputs;
    const currentDeviceId = this._deviceId ?? this._requestedDeviceId;
    const device = devices.previous(currentDeviceId);
    if (device == null || device.id == currentDeviceId) return;
    await this.setDevice(device.id, this._requestedDeviceRequired);
  }
}
