
import { defineComponent, PropType } from "vue";
import { ChatChannel, Meeting } from "@liveswitch/sdk"
import ErrorDisplay from "./ErrorDisplay.vue"

export default defineComponent({
  components: {
    ErrorDisplay
  },
  data(): {
    attendeeToInvite: string | undefined;
    channelName: string;
    error?: Error;
  } {
    return {
      attendeeToInvite: undefined,
      error: undefined,
      channelName: ""
    }
  },
  props: {    
    channel: {
      required: true,
      type: Object as PropType<ChatChannel>
    },            
    meeting: {
      required: true,
      type: Object as PropType<Meeting>
    }, 
  },  
  methods: {
    async deleteChannel() {
      try {
        await this.channel.delete();
        this.$emit('delete');
        this.$emit('close');
      } catch (error: any) {
        this.error = !(error instanceof Error) ? new Error(error) : error;
      }
    },
    async invite() {
      if (this.attendeeToInvite) {
        try {
          this.error = undefined;
          await this.channel.addMember({attendeeId: this.attendeeToInvite, role: "MEMBER"});
        } catch (error: any) {
          this.error = !(error instanceof Error) ? new Error(error) : error;
        }
      }
    },
    async removeMember(memberId: string) {
      const memberToRemove = this.channel.members.find(m => m.id == memberId);
      if (memberToRemove) {
        try {
          this.error = undefined;
          await memberToRemove.remove();
        } catch (error: any) {
          this.error = !(error instanceof Error) ? new Error(error) : error;
        }
      }
    },
    async updateChannel() {
      try {
        this.error = undefined;
        await this.channel.update({
          name: this.channelName,
          status: this.channel.status,
          type: this.channel.type
        });
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        this.error = error;
      }
    },
  },
  watch: {
    channel() {
      this.channelName = this.channel.name ?? null;
      this.error = undefined;
    }
  },
  mounted() {
    this.channelName = this.channel.name;
  }
})
