
import { defineComponent, PropType } from "vue";
import { ChatChannel, ChatMessage, Meeting } from "@liveswitch/sdk";
import ChatBox from "./ChatBox.vue";
import ChatBubble from "./ChatBubble.vue";
import ChatChannelManagement from "./ChatChannelManagement.vue";
import Message from "@liveswitch/sdk/chat/Message";

export default defineComponent({
  components: {
    ChatBox,
    ChatBubble,
    ChatChannelManagement
  },
  data(): {
      currentPage: number;
      fetchThreshold: number;
      editCurrentChannel: boolean;
      notFound: boolean;
      pageSize: number;
      searchText: string;
      searchMessages: ChatMessage[];
      totalPages: number;
  } {
    return {
      currentPage: 1,
      fetchThreshold: 60,
      editCurrentChannel: false,
      notFound: false,
      pageSize: 20,
      searchText: "",
      searchMessages: [],
      totalPages: 0,
    }
  },
  props: {
    currentChatChannel: {
      type: Object as PropType<ChatChannel>,
      default: null,
    },
    meeting: {
      type: Object as PropType<Meeting>,
      default: null,
    }
  },
  methods: {
    editChatChannel(value: boolean) {
      this.editCurrentChannel = value;
    },
    async sendMessage(complete: () => any) {
      const chatBox: any = this.$refs.chatBox;
      if (chatBox.chatText) {
        try {
          await this.currentChatChannel?.send(chatBox.chatText);
        } catch (error: any) {
          if (!(error instanceof Error)) error = new Error(error);
          console.error(error);
          alert(error);
        }
      }
      for (const chatFile of chatBox.chatFiles) {
        try {
          await this.currentChatChannel?.send(chatFile);
        } catch (error: any) {
          if (!(error instanceof Error)) error = new Error(error);
          console.error(error);
          alert(error);
        }
      }
      if (complete) complete();
      this.$nextTick(() => {
        const chats = document.getElementsByClassName("chat-display");
        for (let i = 0; i < chats.length; i++) {          
          chats[i].scrollTop = chats[i].scrollHeight;
        }
      });
    },
    canDeleteMessage(msg: ChatMessage) {
      if (msg.attendee.id == this.meeting.localAttendee.id) return true;
      return this.meeting.localAttendee.hasPermission("DeleteMessage");
    },
    async onDeleteChatMessage(msg: Message) {
      await msg.delete();
    },
    async onDownloadFile(msg: Message) {
      if (msg.file) {
        const url = URL.createObjectURL(msg.file);
        const a = document.createElement("a");
        a.href = url;
        a.download = msg.file.name;
        a.click();
        URL.revokeObjectURL(url);
      }
      else if(msg.inlineContent?.startsWith("data:")) {
        const a = document.createElement("a");
        a.href = msg.inlineContent;
        a.download = 'filename'; // Replace with your desired filename
        a.click();
      }
    },
    async scroll(event: any) {
      if (!this.currentChatChannel) return;
      if (event.target.scrollTop > this.fetchThreshold) return;
      if (!await this.currentChatChannel.messages.loadMore(100)) return;
      event.target.scrollTop += this.fetchThreshold;
    },
    closeSearch() {
      let chatId = this.currentChatChannel.id ?? "default";
      document.getElementById(chatId)!.style.display = "block";
      this.searchMessages = [];
      this.searchText = "";
      this.notFound = false;
      this.currentPage = 1;
    },
    async searchChat() {
      if (!this.searchText || !this.currentChatChannel) return;
      const searchResponse = await this.currentChatChannel!.search({
        filter: this.searchText,
        offset: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
      });
      this.totalPages = Math.ceil(searchResponse.totalCount / this.pageSize);
      this.searchMessages = searchResponse.values;

      let chatId = this.currentChatChannel.id ?? "default";
      document.getElementById(chatId)!.style.display = "none";
      
      if (!this.searchMessages.length) {
        this.notFound = true;
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.searchChat();
      }
    },
    async prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.searchChat();
      }
    },
  },
})
