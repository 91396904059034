import Attendee from "../Attendee";
import UserMediaOptions from "./UserMediaOptions";
import DispatchQueue from "../core/DispatchQueue";
import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import LocalAttendeeUpdateEvent from "./models/LocalAttendeeUpdateEvent";
import LocalAudioTrack from "./LocalAudioTrack";
import LocalMediaEvent from "./models/LocalMediaEvent";
import LocalMediaState from "./models/LocalMediaState";
import LocalMediaStateChangeEvent from "./models/LocalMediaStateChangeEvent";
import LocalMediaStateMachine from "./LocalMediaStateMachine"
import LocalVideoTrack from "./LocalVideoTrack";
import Log from "../logging/Log";
import Media from "./Media";
import MediaType from "./models/MediaType";
import OriginConnection from "../origin/Connection";
import Room from "../api/models/Room";
import Utility from "../core/Utility";

export default abstract class LocalMedia extends Media {
  private readonly _attendeeBound = new EventOwnerAsync<LocalAttendeeUpdateEvent>();
  private readonly _attendeeUnbound = new EventOwner<LocalAttendeeUpdateEvent>();
  private readonly _audioTrack: LocalAudioTrack;
  private readonly _connected = new EventOwnerAsync<LocalMediaEvent>();
  private readonly _disconnected = new EventOwner<LocalMediaEvent>();
  private readonly _eventQueue = new DispatchQueue();
  private readonly _fallbackIfDeviceNotAvailable: boolean;
  private readonly _stateChanged: EventOwnerAsync<LocalMediaStateChangeEvent> = new EventOwnerAsync<LocalMediaStateChangeEvent>();
  private readonly _stateEvents = new Map<LocalMediaState, EventOwnerAsync<LocalMediaStateChangeEvent>>();
  private readonly _stateMachine = new LocalMediaStateMachine();
  private readonly _videoTrack: LocalVideoTrack;

  private _attendee: Attendee = null;
  private _connection: OriginConnection = null;
  private _stream: MediaStream = null;
  private _room: Room = null;
  
  /** @internal */
  public get connection(): OriginConnection { return this._connection; }
    
  /** @internal */
  public set connection(value: OriginConnection) { this._connection = value; }

  public get attendee(): Attendee { return this._attendee; }
  public get audioTrack(): LocalAudioTrack { return this._audioTrack; }
  public get fallbackIfDeviceNotAvailable(): boolean { return this._fallbackIfDeviceNotAvailable; }
  public get isConnected(): boolean { return this._attendee != null; }
  public get isDisconnected(): boolean { return !this.isConnected; }
  public get isRemote(): boolean { return false; }
  public get isStarted(): boolean { return this.state == "started"; }
  public get isStarting(): boolean { return this.state == "starting"; }
  public get isStopped(): boolean { return this.state == "stopped" || this.state == "new"; }
  public get isStopping(): boolean { return this.state == "stopping"; }
  public get state(): LocalMediaState { return this._stateMachine.state; }
  public get stream(): MediaStream { return this._stream; }
  public get videoTrack(): LocalVideoTrack { return this._videoTrack; }
  public get room(): Room { return this._room; }
  public set room(value: Room) { this._room = value; }

  /** @event */
  public get attendeeBound(): EventOwnerAsync<LocalAttendeeUpdateEvent> { return this._attendeeBound; }
  /** @event */
  public get attendeeUnbound(): EventOwner<LocalAttendeeUpdateEvent> { return this._attendeeUnbound; }
  /** @event */
  public get connected(): EventOwnerAsync<LocalMediaEvent> { return this._connected; }
  /** @event */
  public get disconnected(): EventOwner<LocalMediaEvent> { return this._disconnected; }
  /** @event */
  public get started(): EventOwnerAsync<LocalMediaStateChangeEvent> { return this._stateEvents.get("started"); }
  /** @event */
  public get starting(): EventOwnerAsync<LocalMediaStateChangeEvent> { return this._stateEvents.get("starting"); }
  /** @event */
  public get stateChanged(): EventOwnerAsync<LocalMediaStateChangeEvent> { return this._stateChanged; }
  /** @event */
  public get stopped(): EventOwnerAsync<LocalMediaStateChangeEvent> { return this._stateEvents.get("stopped"); }
  /** @event */
  public get stopping(): EventOwnerAsync<LocalMediaStateChangeEvent> { return this._stateEvents.get("stopping"); }

  public constructor(audioTrack: LocalAudioTrack, type: MediaType, videoTrack: LocalVideoTrack, deviceOptions?: UserMediaOptions) {
    super(type);
    if (Utility.isNullOrUndefined(audioTrack) && Utility.isNullOrUndefined(videoTrack)) throw new Error("Parameters audioTrack and videoTrack cannot both be null or undefined.");
    this._fallbackIfDeviceNotAvailable = deviceOptions?.fallbackIfDeviceNotAvailable;
    this._audioTrack = audioTrack;
    this._videoTrack = videoTrack;
    if (this._audioTrack) this._audioTrack.media = this;
    if (this._videoTrack) this._videoTrack.media = this;    

    this._stateEvents.set("started", new EventOwnerAsync<LocalMediaStateChangeEvent>());
    this._stateEvents.set("starting", new EventOwnerAsync<LocalMediaStateChangeEvent>());
    this._stateEvents.set("stopped", new EventOwnerAsync<LocalMediaStateChangeEvent>());
    this._stateEvents.set("stopping", new EventOwnerAsync<LocalMediaStateChangeEvent>());
  }

  private static getDeviceIdConstraint(constraints: MediaTrackConstraints): ConstrainDOMStringParameters {
    const deviceId = constraints?.deviceId;
    if (Utility.isNullOrUndefined(deviceId) || Utility.isString(deviceId)) return null;
    return <ConstrainDOMStringParameters>deviceId;
  }

  private static getTrackConstraints(constraints: boolean | MediaTrackConstraints): MediaTrackConstraints {
    if (Utility.isNullOrUndefined(constraints) || Utility.isBoolean(constraints)) return null;
    return <MediaTrackConstraints>constraints;
  }

  private async setState(state: LocalMediaState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <LocalMediaStateChangeEvent>{
      media: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }

  private async startInternal(audio: boolean, video: boolean): Promise<void> {
    if (this.state == "started") return;
    try {
      await this.setState("starting");
      await this.onStarting();
      const stream = await this.getStreamInternal({
        audio: audio && this.audioTrack ? this.audioTrack.getConstraints() : false,
        video: video && this.videoTrack ? this.videoTrack.getConstraints(false) : false,
      }, this._fallbackIfDeviceNotAvailable);
      this._stream = stream;
      const [audioTrackStream] = stream.getAudioTracks();
      const [videoTrackStream] = stream.getVideoTracks();
      if (audioTrackStream) await this.audioTrack?.startInternal(audioTrackStream);
      if (videoTrackStream) await this.videoTrack?.startInternal(videoTrackStream);
      await this.setState("started");
      await this.onStarted();
    } catch (error) {
      await this.setState("stopped");
      await this.onStopped();
      throw error;
    }
  }

  private async testAudioConstraints(constraints: MediaTrackConstraints): Promise<boolean> {
    try {
      const stream = await this.getStream({ audio: constraints });
      stream.getAudioTracks()[0].stop();
      return true;
    } catch {
      return false;
    }
  }

  private async testVideoConstraints(constraints: MediaTrackConstraints): Promise<boolean> {
    try {
      const stream = await this.getStream({ video: constraints });
      stream.getVideoTracks()[0].stop();
      return true;
    } catch {
      return false;
    }
  }

  protected abstract getStream(constraints: MediaStreamConstraints): Promise<MediaStream>;

  protected async onStarted(): Promise<void> { }
  
  protected async onStarting(): Promise<void> { }

  protected async onStopped(): Promise<void> { }

  protected async onStopping(): Promise<void> { }

  /** @internal */
  public async bindAttendee(attendee: Attendee): Promise<void> {
    Guard.isNotNullOrUndefined(attendee, "attendee");
    if (this._attendee == attendee) return;
    const previousAttendee = this._attendee;
    this._attendee = attendee;
    if (this.type == "user") await this._attendee.bindMedia(this);
    await this._attendeeBound.dispatch({
      attendee: attendee,
      media: this,
      previousAttendee: previousAttendee,
    });
    await this._connected.dispatch({
      media: this,
    });
  }

  /** @internal */
  public async getStreamInternal(constraints: MediaStreamConstraints, fallbackIfDeviceNotAvailable: boolean = false): Promise<MediaStream> {
    try {
      if (constraints.audio) this._audioTrack?.clearFallback();
      if (constraints.video) this._videoTrack?.clearFallback();
      return await this.getStream(constraints); 
    } catch (error: any) {
      if ('name' in error) { // we're expecting an instanceof DOMException, but using "error instanceof DOMException" check doesnt work in Safari
        switch (error.name) {
          case "AbortError": // some problem occurred which prevented the device from being used
          case "NotAllowedError": // one or more of the requested source devices cannot be used at this time
          case "NotFoundError": // no media tracks of the type specified were found that satisfy the given constraints
          case "NotReadableError": // a hardware error occurred at the operating system, browser, or Web page level which prevented access to the device
          case "OverconstrainedError": // the specified constraints resulted in no candidate devices which met the criteria requested
            const audioConstraints = LocalMedia.getTrackConstraints(constraints.audio);
            const videoConstraints = LocalMedia.getTrackConstraints(constraints.video);
            const audioDeviceIdConstraint = LocalMedia.getDeviceIdConstraint(audioConstraints);
            const videoDeviceIdConstraint = LocalMedia.getDeviceIdConstraint(videoConstraints);
            if (audioDeviceIdConstraint?.ideal && videoDeviceIdConstraint?.ideal) {
              Log.debug(`Could not get local ${this.type} media with optional audio/video device IDs. Retrying without audio/video device IDs...`, error);
              if (!await this.testAudioConstraints(audioConstraints)) {
                delete audioConstraints.deviceId;
                if (!await this.testAudioConstraints(audioConstraints) && !fallbackIfDeviceNotAvailable) throw error;
              }
              if (!await this.testVideoConstraints(videoConstraints)) {
                delete videoConstraints.deviceId;
                if (!await this.testVideoConstraints(videoConstraints) && !fallbackIfDeviceNotAvailable) throw error;
              }
              return await this.getStreamInternal(constraints, fallbackIfDeviceNotAvailable);
            }
            if (audioDeviceIdConstraint?.ideal) {
              Log.info(`Could not get local ${this.type} media with optional audio device ID. Retrying without audio device ID...`, error);
              delete audioConstraints.deviceId;
              return await this.getStreamInternal(constraints, fallbackIfDeviceNotAvailable);
            }
            if (videoDeviceIdConstraint?.ideal) {
              Log.info(`Could not get local ${this.type} media with optional video device ID. Retrying without video device ID...`, error);
              delete videoConstraints.deviceId;
              return await this.getStreamInternal(constraints, fallbackIfDeviceNotAvailable);
            }
            if (fallbackIfDeviceNotAvailable) {
              let stream : MediaStream = new MediaStream();

              if (this._audioTrack != null) {
                try {
                  const audioConstraints = this._audioTrack.getConstraints();
                  const audioStream = await this.getStreamInternal({ audio: audioConstraints}, false);
                  const [audioTrack] = audioStream.getAudioTracks();
                  stream.addTrack(audioTrack);
                } catch (audioError: any) {
                  Log.warn('Falling back to audio context for audio...', audioError);
                  stream.addTrack(this.createFallbackAudioTrack());
                  this._audioTrack.setFallback(audioError.name);
                }
              }

              if (this._videoTrack != null) {
                try {
                  const videoConstraints = this._videoTrack.getConstraints(false);
                  const videoStream = await this.getStreamInternal({ video: videoConstraints}, false);
                  const [videoTrack] = videoStream.getVideoTracks();
                  stream.addTrack(videoTrack);
                } catch (videoError: any) {
                  Log.warn('Falling back to canvas for video...', videoError);
                  stream.addTrack(this.createFallbackVideoTrack());
                  this._videoTrack.setFallback(videoError.name);
                }
              }
              return stream;
            }
            break;
        }
      }
      throw error;
    }
  }

  private createFallbackAudioTrack(): MediaStreamTrack {
    const audioContext = new AudioContext();
    const silentAudioNode = audioContext.createBufferSource();
    const buffer = audioContext.createBuffer(1, 1, audioContext.sampleRate);
    silentAudioNode.buffer = buffer;
    silentAudioNode.connect(audioContext.destination);
    return audioContext.createMediaStreamDestination().stream.getAudioTracks()[0];
  }

  private createFallbackVideoTrack(): MediaStreamTrack {
    const canvas = document.createElement('canvas');
    return canvas.captureStream().getVideoTracks()[0];
  }

  public async startAudio(): Promise<void> {
    if (!this._audioTrack) throw new Error("Audio is disabled.");
    await this._eventQueue.dispatch(async () => {
      if (this.state == "started") {
        if (this._audioTrack.stream) return;
        const stream = await this.getStreamInternal({ audio: this._audioTrack.getConstraints() }, this._fallbackIfDeviceNotAvailable);
        const [trackStream] = stream.getAudioTracks();
        this._stream.addTrack(trackStream);
        await this._audioTrack.startInternal(trackStream);
      } else {
        await this.startInternal(true, false);
      }
    });
  }

  public async startVideo(): Promise<void> {
    if (!this._videoTrack) throw new Error("Video is disabled.");
    await this._eventQueue.dispatch(async () => {
      if (this.state == "started") {
        if (this._videoTrack.stream) return;
        const stream = await this.getStreamInternal({ video: this._videoTrack.getConstraints(false) }, this._fallbackIfDeviceNotAvailable);
        const [trackStream] = stream.getVideoTracks();
        this._stream.addTrack(trackStream);
        await this._videoTrack.startInternal(trackStream);
      } else {
        await this.startInternal(false, true);
      }
    });
  }

  public async stopAudio(): Promise<void> {
    if (!this._audioTrack) throw new Error("Audio is disabled.");
    await this._eventQueue.dispatch(async () => {
      if (this.state == "stopped") return;
      if (!this._audioTrack.stream) return;
      await this._audioTrack.stopInternal();
      this._stream.getAudioTracks().forEach(track => this.stream.removeTrack(track));
    });
  }

  public async stopVideo(): Promise<void> {
    if (!this._videoTrack) throw new Error("Video is disabled.");
    await this._eventQueue.dispatch(async () => {
      if (this.state == "stopped") return;
      if (!this._videoTrack.stream) return;
      await this._videoTrack.stopInternal();
      this._stream.getVideoTracks().forEach(track => this.stream.removeTrack(track));
    });
  }

  /** @internal */
  public unbindAttendee(): void {
    if (!this._attendee) return;
    const previousAttendee = this._attendee;
    if (this.type == "user") this._attendee.unbindMedia();
    this._attendee = null;
    this._attendeeUnbound.dispatch({
      attendee: null,
      media: this,
      previousAttendee: previousAttendee,
    });
    this._disconnected.dispatch({
      media: this,
    });
  }

  public async start(): Promise<void> {
    await this._eventQueue.dispatch(() => {
      return this.startInternal(this._audioTrack ? true : false, this._videoTrack ? true : false);
    });
  }

  public async stop(): Promise<void> {
    await this._eventQueue.dispatch(async () => {
      if (this.state == "new") await this.setState("stopped");
      if (this.state == "stopped") return;
      try {
        await this.setState("stopping");
        await this.onStopping();
        if (this._audioTrack?.isStarted) await this._audioTrack.stopInternal();
        if (this._videoTrack?.isStarted) await this._videoTrack.stopInternal();
        this._stream = null;
      } finally {
        await this.setState("stopped");
        await this.onStopped();
      }
    });
  }
}
