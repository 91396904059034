import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Media from "./Media";
import Size from "../core/Size";
import VideoTrackEvent from "./models/VideoTrackEvent";
import StatisticVideo from "../models/StatisticVideo";
import StatisticConnection from "../models/StatisticConnection";

export default abstract class VideoTrack {
  
  private _statVideo: StatisticVideo;
  private _statPair: StatisticConnection;

  public get bitrate(): number { return this._statVideo?.BitrateActual; }
  public get bytesReceived(): number { return this._statVideo?.Rtp_BytesReceived; }
  public get bytesSent(): number { return this._statVideo?.Rtp_BytesSent; }
  public get candidateAddress(): string { return this._statPair?.Lcl_Ip; }
  public get candidateRelayProtocol(): string { return this._statPair?.Lcl_Protocol; }
  public get candidateType(): string { return this._statPair?.Lcl_CandidateType; }
  public get frameRate(): number { return this._statVideo?.FramerateActual; }
  public get frameSize(): Size { return { height: this._statVideo?.ResolutionHeight, width: this._statVideo?.ResolutionWidth }; }
  public abstract get isDisabled(): boolean;
  public get isLocal(): boolean { return !this.isRemote; }
  public abstract get isMuted(): boolean;
  public abstract get isPaused(): boolean;
  public abstract get isRemote(): boolean;
  public get jitter(): number { return this._statVideo?.Rtp_Jitter; }
  public get keepAliveRequestsReceived(): number { return this._statPair?.Pair_RequestsReceived; }
  public get keepAliveRequestsSent(): number { return this._statPair?.Pair_RequestsSent; }
  public get keepAliveResponsesReceived(): number { return this._statPair?.Pair_ResponsesReceived; }
  public get keepAliveResponsesSent(): number { return this._statPair?.Pair_ResponsesSent; }

  public abstract get media(): Media;
  public get nacksReceived(): number { return this._statVideo?.Rtp_NackCount; }
  public get packetLoss(): number {
    var result = 0;
    if ((this._statVideo?.Rtp_PacketsReceived != null) && (this._statVideo?.Rtp_PacketsLost != null)) {
      result = 100 * (this._statVideo?.Rtp_PacketsLost / this._statVideo?.Rtp_PacketsReceived);
    }
    return result;
  }
  public get packetsLost(): number { return this._statVideo?.Rtp_PacketsLost; }
  public get packetsReceived(): number { return this._statVideo?.Rtp_PacketsReceived; }
  public get packetsSent(): number { return this._statVideo?.Rtp_PacketsSent; }
  public get remoteCandidateAddress(): string { return this._statPair?.Rmt_Ip; }
  public get remoteCandidateType(): string { return this._statPair?.Rmt_CandidateType; }
  public get roundTripTime(): number { return this._statPair?.Pair_RoundTripTimeCurrent; }
  public abstract get spatialLayerIndex(): number;
  public abstract get stream(): MediaStreamTrack;
  public abstract get temporalLayerIndex(): number;

  /** @event */
  public abstract get streamBound(): EventOwnerAsync<VideoTrackEvent>;
  /** @event */
  public abstract get streamUnbound(): EventOwner<VideoTrackEvent>;

  
  /** @internal */
  public updateStats(statVideo: StatisticVideo, statPair: StatisticConnection): void {
    this._statVideo = statVideo;
    if (statPair != null) this._statPair = statPair;

  }
}
