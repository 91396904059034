
//KB: this class calculates a framerate over successive timestamps
export default class Framerate
{

  private _lastFrameRate: number = null;
  private _lastFramesTransferred = 0;
  private _lastFramesTransferredTimestamp: DOMHighResTimeStamp = null;

  public calculate(framesTransferred: number, timestamp: DOMHighResTimeStamp): number | null {
    let frameRate = this._lastFrameRate;
    if (this._lastFramesTransferredTimestamp) {
      if (framesTransferred < this._lastFramesTransferred) return frameRate;
      if (timestamp < this._lastFramesTransferredTimestamp) return frameRate;

      const deltaFrames = framesTransferred - this._lastFramesTransferred;
      const deltaSeconds = (timestamp - this._lastFramesTransferredTimestamp) / 1000;

      if (deltaSeconds < 1) return frameRate;

      frameRate = Math.ceil(deltaFrames / deltaSeconds);
    }

    this._lastFrameRate = frameRate;
    this._lastFramesTransferred = framesTransferred;
    this._lastFramesTransferredTimestamp = timestamp;
    return frameRate;
  }

}