import ApiClient from "./api/Client";
import ApiCollectionResponse from "./api/models/ApiCollectionResponse";
import Attendee from "./Attendee";
import AttendeeCollection from "./AttendeeCollection";
import AttendeeModel from "./api/models/Attendee";
import Chat from "./chat/Chat";
import ConnectionStateChangeEvent from "./models/ConnectionStateChangeEvent";
import Constants from "./core/Constants";
import ControlClient from "./control/Client";
import ControlConnection from "./control/Connection";
import ControlMeetingAttendeeEvent from "./control/models/AttendeeEvent";
import ControlMeetingEvent from "./control/models/MeetingEvent";
import DispatchQueue from "./core/DispatchQueue";
import DisplayMedia from "./media/DisplayMedia";
import EdgeManager from "./edge/Manager";
import EventLogger from "./event/Logger";
import EventOwnerAsync from "./core/EventOwnerAsync";
import ExternalTurnSession from "./turn/ExternalSession";
import FilterModeDisplay from "./models/FilterModeDisplay";
import FilterModeUser from "./models/FilterModeUser";
import Guard from "./core/Guard";
import Identity from "./identity/models/Identity";
import JoinOptions from "./models/JoinOptions";
import LocalMedia from "./media/LocalMedia";
import Media from "./media/Media";
import MediaCollection from "./media/MediaCollection";
import MediaType from "./media/models/MediaType";
import MeetingAttendeeEvent from "./models/MeetingAttendeeEvent";
import MeetingEvent from "./models/MeetingEvent";
import MeetingInit from "./models/MeetingInit";
import MeetingModel from "./api/models/Meeting";
import MeetingSession from "./meeting/Session";
import MeetingState from "./models/MeetingState";
import MeetingStateChangeEvent from "./models/MeetingStateChangeEvent";
import MeetingStateMachine from "./MeetingStateMachine";
import MeetingType from "./models/MeetingType";
import MetricLogger, { AppCustomMeetingMetric, AppMeetingMetric, SdkMeetingMetric } from "./logging/MetricLogger";
import OriginConnection from "./origin/Connection";
import OriginManager from "./origin/Manager";
import PermissionName from "./models/PermissionName";
import PromiseCompletionSource from "./core/PromiseCompletionSource";
import RTCMissingError from "./core/RTCMissingError";
import Reactive from "./core/Reactive";
import ReadOnlyCollectionEvent from "./core/models/ReadOnlyCollectionEvent";
import SubscribedView from "./SubscribedView";
import TenantSetting from "./api/models/TenantSetting";
import Timings from "./meeting/Timings";
import TokenResponse from "./meeting/models/TokenResponse";
import TurnSession from "./turn/Session";
import TurnSessionModel from "./turn/models/Session"
import UserMedia from "./media/UserMedia";
import Utility from "./core/Utility";
import { UploadClient } from "@liveswitch/storage";
import ImpairmentLevel from "./models/ImpairmentLevel";

const mediaTypes: MediaType[] = ["display", "user"];

export default class Meeting {
  private readonly _apiClient: ApiClient;
  private readonly _metricLogger: MetricLogger;
  private readonly _attendees: AttendeeCollection;
  private readonly _attendeeJoined = new EventOwnerAsync<MeetingAttendeeEvent>();
  private readonly _attendeeLeft = new EventOwnerAsync<MeetingAttendeeEvent>();
  private readonly _audibleDisplayMedias = new MediaCollection();
  private readonly _audibleMedias = new MediaCollection();
  private readonly _audibleUserMedias = new MediaCollection();
  private readonly _audioUnmuteDisabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _audioUnmuteEnabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _blocked = new EventOwnerAsync<MeetingEvent>();
  private readonly _eventQueue = new DispatchQueue();
  private readonly _identity: Identity;
  private readonly _kicked = new EventOwnerAsync<MeetingEvent>();
  private readonly _localMedias = new Map<MediaType, LocalMedia>();
  private readonly _originManagers = new Map<MediaType, OriginManager>();
  private readonly _recordingFailed = new EventOwnerAsync<MeetingEvent>();
  private readonly _recordingStarted = new EventOwnerAsync<MeetingEvent>();
  private readonly _recordingStopped = new EventOwnerAsync<MeetingEvent>();
  private readonly _stateChanged: EventOwnerAsync<MeetingStateChangeEvent> = new EventOwnerAsync<MeetingStateChangeEvent>();
  private readonly _stateEvents = new Map<MeetingState, EventOwnerAsync<MeetingStateChangeEvent>>();
  private readonly _stateMachine = new MeetingStateMachine();
  private readonly _subscribedView: SubscribedView;
  private readonly _videoUnmuteDisabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _videoUnmuteEnabled = new EventOwnerAsync<MeetingEvent>();
  private readonly _visibleDisplayMedias = new MediaCollection();
  private readonly _visibleMedias = new MediaCollection();
  private readonly _visibleUserMedias = new MediaCollection();
  private readonly _timings = <Timings>{};

  private _chat: Chat = new Chat();
  private _controlConnection: ControlConnection = null;
  private _edgeManager: EdgeManager = new EdgeManager();
  private _eventLogger: EventLogger = null;
  private _hasFailed = false;
  // TODO: remove this when issues with eden vue app can be sorted: showing components, mainly attendees, before isReady (everything connected) causes huge delays
  private _isReady: boolean | null = null;
  private _joinOptions: JoinOptions = null;
  private _localAttendee: Attendee = null;
  private _maxRetries = 2;
  private _maxVisibleDisplayMedias: number = null;
  private _maxVisibleUserMedias: number = null;
  private _maxVisibleUserMediasDuringDisplayMedia: number = null;
  private _model: MeetingModel = null;
  private _remoteMediaDisabled: boolean = false;
  private _requestTimeout = 30000;
  private _session: MeetingSession = null;
  private _statsInterval = 1000; //this always need to be a second, state machine logic requires it
  private _statsTimeoutId: any = 0;
  private _tenantSettings: TenantSetting[];
  private _tokenResponse: TokenResponse = null;
  private _turnSession: TurnSessionModel = null;
  private _uploadClient: UploadClient = null;

  /** @internal */
  public get metricLogger(): MetricLogger { return this._metricLogger; }

  /** @event */
  public get attendeeJoined(): EventOwnerAsync<MeetingAttendeeEvent> { return this._attendeeJoined; }
  /** @event */
  public get attendeeLeft(): EventOwnerAsync<MeetingAttendeeEvent> { return this._attendeeLeft; }
  /** @event */
  public get audioUnmuteDisabled(): EventOwnerAsync<MeetingEvent> { return this._audioUnmuteDisabled; }
  /** @event */
  public get audioUnmuteEnabled(): EventOwnerAsync<MeetingEvent> { return this._audioUnmuteEnabled; }
  /** @event */
  public get blocked(): EventOwnerAsync<MeetingEvent> { return this._blocked; }
  /** @event */
  public get joined(): EventOwnerAsync<MeetingStateChangeEvent> { return this._stateEvents.get("joined"); }
  /** @event */
  public get joining(): EventOwnerAsync<MeetingStateChangeEvent> { return this._stateEvents.get("joining"); }
  /** @event */
  public get kicked(): EventOwnerAsync<MeetingEvent> { return this._kicked; }
  /** @event */
  public get leaving(): EventOwnerAsync<MeetingStateChangeEvent> { return this._stateEvents.get("leaving"); }
  /** @event */
  public get left(): EventOwnerAsync<MeetingStateChangeEvent> { return this._stateEvents.get("left"); }
  /** @event */
  public get reconnecting(): EventOwnerAsync<MeetingStateChangeEvent> { return this._stateEvents.get("reconnecting"); }
  /** @event */
  public get recordingFailed(): EventOwnerAsync<MeetingEvent> { return this._recordingFailed; }
  /** @event */
  public get recordingStarted(): EventOwnerAsync<MeetingEvent> { return this._recordingStarted; }
  /** @event */
  public get recordingStopped(): EventOwnerAsync<MeetingEvent> { return this._recordingStopped; }
  /** @event */
  public get stateChanged(): EventOwnerAsync<MeetingStateChangeEvent> { return this._stateChanged; }
  /** @event */
  public get videoUnmuteDisabled(): EventOwnerAsync<MeetingEvent> { return this._videoUnmuteDisabled; }
  /** @event */
  public get videoUnmuteEnabled(): EventOwnerAsync<MeetingEvent> { return this._videoUnmuteEnabled; }

  public get attendees(): AttendeeCollection { return this._attendees; }
  public get audibleDisplayMedias(): MediaCollection { return this._audibleDisplayMedias; }
  public get audibleMedias(): MediaCollection { return this._audibleMedias; }
  public get audibleUserMedias(): MediaCollection { return this._audibleUserMedias; }
  public get audioInputDeviceId(): string { return this.localUserMedia?.audioDeviceId ?? null; }
  public get audioOutputDeviceId(): string { return this._edgeManager?.audioDeviceId ?? null; }
  public get canReceiveMedia(): boolean { return this._localAttendee?.canReceiveMedia ?? false; }
  public get canSendDisplayMedia(): boolean {
    if (this.filterModeDisplay == "REJECT" && this._model && this._visibleDisplayMedias.length >= this._model.maxVideoDisplay) return false;
    return this._localAttendee?.canSendDisplayMedia ?? false;
  }

  public get canSendUserMedia(): boolean { return this._localAttendee?.canSendUserMedia ?? false; }
  public get chat(): Chat { return this._chat; }
  public get edgeManager(): EdgeManager { return this._edgeManager; }
  public get filterModeDisplay(): FilterModeDisplay { return this._model?.filterModeDisplay ?? null; }
  public get filterModeUser(): FilterModeUser { return this._model?.filterModeUser ?? null; }
  public get hasFailed(): boolean { return this._hasFailed; }
  public get hasLobby(): boolean { return !(this._model?.lobbyType == "NONE"); }
  public get hasSip(): boolean { return this._model?.hasSip ?? false; }
  public get id(): string { return this._model?.id ?? null; }
  public get isAudioMutedOnJoin(): boolean { return this._model?.muteAudioOnJoin ?? false; }
  public get isAudioUnmuteDisabledOnJoin(): boolean { return !(this._model?.allowUnmuteAudioOnJoin == true); }
  public get isChatEnabled(): boolean { return this._model?.allowChat ?? false; }
  public get isJoined(): boolean { return this.state == "joined"; }
  public get isJoining(): boolean { return this.state == "joining"; }
  public get isLeaving(): boolean { return this.state == "leaving"; }
  public get isLeft(): boolean { return this.state == "left"; }
  public get isNoiseSuppressedOnJoin(): boolean { return this._model?.suppressNoiseOnJoin ?? false; }
  public get isReady(): boolean { return this._isReady; }
  public get isReconnecting(): boolean { return this.state == "reconnecting"; }
  public get isRecording(): boolean { return this._model?.isRecording ?? false; }
  public get isRecordingEnabled(): boolean { return this._model?.allowRecording ?? false; }
  public get isSipEnabled(): boolean { return this._model?.allowSip ?? false; }
  public get isVideoMutedOnJoin(): boolean { return this._model?.muteVideoOnJoin ?? false; }
  public get isVideoUnmuteDisabledOnJoin(): boolean { return !(this._model?.allowUnmuteVideoOnJoin == true); }
  public get joinOptions(): JoinOptions { return this._joinOptions; }
  public get localAttendee(): Attendee { return this._localAttendee ?? null; }
  public get localDisplayMedia(): DisplayMedia { return <DisplayMedia>this._localMedias.get("display") ?? null; }
  public get localUserMedia(): UserMedia { return <UserMedia>this._localMedias.get("user") ?? null; }
  public get maxRetries(): number { return this._maxRetries; }
  public set maxRetries(value: number) { this._maxRetries = value; }
  public get maxVisibleDisplayMedias(): number { return this._maxVisibleDisplayMedias ?? null; }
  public set maxVisibleDisplayMedias(value: number) { this._maxVisibleDisplayMedias = value; }
  public get maxVisibleUserMedias(): number { return this._maxVisibleUserMedias ?? null; }
  public set maxVisibleUserMedias(value: number) { this._maxVisibleUserMedias = value; }
  public get maxVisibleUserMediasDuringDisplayMedia(): number { return this._maxVisibleUserMediasDuringDisplayMedia; }
  public set maxVisibleUserMediasDuringDisplayMedia(value: number) { this._maxVisibleUserMediasDuringDisplayMedia = value; }
  public get originDisplayManager(): OriginManager { return this._originManagers.get("display"); }
  public get originUserManager(): OriginManager { return this._originManagers.get("user"); }
  public get remoteMediaDisabled(): boolean { return this._remoteMediaDisabled; }
  public get requestTimeout(): number { return this._requestTimeout; }
  public set requestTimeout(value: number) { this._requestTimeout = value; }
  public get requestedAudioInputDeviceId(): string { return this.localUserMedia?.requestedAudioDeviceId ?? null; }
  public get requestedAudioInputDeviceRequired(): boolean { return this.localUserMedia?.requestedAudioDeviceRequired ?? false; }
  public get requestedAudioOutputDeviceId(): string { return this._edgeManager?.requestedAudioDeviceId ?? null; }
  public get requestedVideoInputDeviceId(): string { return this.localUserMedia?.requestedVideoDeviceId ?? null; }
  public get requestedVideoInputDeviceRequired(): boolean { return this.localUserMedia?.requestedVideoDeviceRequired ?? false; }
  public get roomId(): string { return this._model?.roomId ?? null; }
  public get roomKey(): string { return this._model?.roomKey ?? null; }
  public get state(): MeetingState { return this._stateMachine.state; }
  public get statsInterval(): number { return this._statsInterval; }
  public get timings(): Timings { return this._timings; }
  public get type(): MeetingType { return this._model?.type ?? null; }
  public get videoInputDeviceId(): string { return this.localUserMedia?.videoDeviceId ?? null; }
  public get videoInputFacingMode(): string { return this.localUserMedia?.videoFacingMode ?? null; }
  public get visibleDisplayMedias(): MediaCollection { return this._visibleDisplayMedias; }
  public get visibleMedias(): MediaCollection { return this._visibleMedias; }
  public get visibleUserMedias(): MediaCollection { return this._visibleUserMedias; }

  public constructor(init: MeetingInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.identity, "init.identity");
    this._identity = init.identity;
    this._apiClient = new ApiClient({
      identity: this._identity
    });
    this._metricLogger = new MetricLogger({
      identity: this._identity
    });
    this._uploadClient = new UploadClient({
      configuration: init.uploadClientConfiguration,
      tokenFactory: async ()=> {
        const token = await this._identity.token();
        return token.token;
      }
    });

    this._attendees = new AttendeeCollection();
    this._subscribedView = new SubscribedView();

    this._stateEvents.set("joined", new EventOwnerAsync<MeetingStateChangeEvent>());
    this._stateEvents.set("joining", new EventOwnerAsync<MeetingStateChangeEvent>());
    this._stateEvents.set("leaving", new EventOwnerAsync<MeetingStateChangeEvent>());
    this._stateEvents.set("left", new EventOwnerAsync<MeetingStateChangeEvent>());
    this._stateEvents.set("reconnecting", new EventOwnerAsync<MeetingStateChangeEvent>());

    this._originManagers.set("display", new OriginManager());
    this._originManagers.set("user", new OriginManager());

    this._audibleDisplayMedias.added.bind(this.onAudibleDisplayMediaAdded.bind(Reactive.wrap(this)));
    this._audibleDisplayMedias.removed.bind(this.onAudibleDisplayMediaRemoved.bind(Reactive.wrap(this)));
    this._audibleUserMedias.added.bind(this.onAudibleUserMediaAdded.bind(Reactive.wrap(this)));
    this._audibleUserMedias.removed.bind(this.onAudibleUserMediaRemoved.bind(Reactive.wrap(this)));
    this._visibleDisplayMedias.added.bind(this.onVisibleDisplayMediaAdded.bind(Reactive.wrap(this)));
    this._visibleDisplayMedias.removed.bind(this.onVisibleDisplayMediaRemoved.bind(Reactive.wrap(this)));
    this._visibleUserMedias.added.bind(this.onVisibleUserMediaAdded.bind(Reactive.wrap(this)));
    this._visibleUserMedias.removed.bind(this.onVisibleUserMediaRemoved.bind(Reactive.wrap(this)));
  }

  private stopChatInternal(): void {
    void this._chat?.stop();
  }

  private async startChatInternal(): Promise<void> {
    if (!this._model.allowChat) return;
    await this._chat.start();
  }

  private async setState(state: MeetingState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <MeetingStateChangeEvent>{
      meeting: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }

  private trySetState(state: MeetingState): boolean {
    const previousState = this._stateMachine.state;
    if (!this._stateMachine.trySetState(state)) return false;
    const e = <MeetingStateChangeEvent>{
      meeting: this,
      previousState: previousState,
      state: state,
    };
    void this._stateEvents.get(state).dispatch(e);
    void this._stateChanged.dispatch(e);
    return true;
  }

  private async unjoin(abortSignal?: AbortSignal): Promise<void> {
    this._attendees.removeAll();
    this._subscribedView.removeAll();

    this.tryStopStats();

    await this._chat?.stop();

    // unbind local media from edge, if applicable
    for (const mediaType of mediaTypes) {
      this._edgeManager?.setLocalMedia(mediaType, null);
    }

    // unbind local media from origin, if applicable
    const setMediaPromises: Promise<void>[] = [];
    for (const mediaType of mediaTypes) {
      const originManager = this._originManagers.get(mediaType);
      if (!originManager) continue;
      void this._eventLogger?.debug("join", `Unbinding local ${mediaType} media from origin...`);
      setMediaPromises.push(originManager.setMedia(null, null, true, abortSignal));
    }
    await Promise.allSettled(setMediaPromises);

    // disconnect edge and, if applicable, origin
    void this._eventLogger?.debug("join", `Disconnecting...`);
    const stopPromises: Promise<void>[] = [];
    if (this._edgeManager) stopPromises.push(this._edgeManager.stop("Attendee has left."));
    for (const mediaType of mediaTypes) {
      const localMedia = this._localMedias.get(mediaType);
      if (localMedia) localMedia.unbindAttendee();
      const originManager = this._originManagers.get(mediaType);
      if (!originManager) continue;
      stopPromises.push(originManager.stop("Attendee has left."));
    }
    await Promise.allSettled(stopPromises);

    // unlink data model
    this._localAttendee = null;
    this._model = null;

    // close control
    this._controlConnection?.close("Attendee has left.");

    // destroy CEOT
    this._originManagers.delete("display");
    this._originManagers.delete("user");
    this._edgeManager = null;
    this._controlConnection = null;

    // leave session
    this._session?.leave();
    this._session = null;
  }

  public join(options?: JoinOptions, abortSignal?: AbortSignal): Promise<void> {

    this._joinOptions = initializeJoinOptions(options);

    return this._eventQueue.dispatch(async () => {
      if (this.state == "joined" || this.state == "reconnecting") return;
      const joinStart = performance.now();
      void this.setState("joining");
      this._hasFailed = false;
      try {
        if (Utility.isNullOrUndefined(globalThis.RTCPeerConnection)) throw new RTCMissingError("WebRTC is disabled or not implemented.");

        // initialize session
        this._session = new MeetingSession({
          identity: this._identity,
        });

        this._joinOptions.onProgress({ meeting: this, progress: 0.1 });

        await this.getMeetingTokenAndInitializeLoggers(abortSignal);
        void this.logClientMetric({eventName: "joinAttempt"});

        this._joinOptions.onProgress({ meeting: this, progress: 0.4 });

        if (this._joinOptions.turnOptions.enabled) {
          await this.configureTurn();
        }

        this._joinOptions.onProgress({ meeting: this, progress: 0.7 });

        await this.connectControl();

        this._localAttendee = new Attendee({
          controlConnection: this._controlConnection,
          isLocal: true,
          meeting: Reactive.wrap(this),
          model: this._controlConnection.attendee,
        });

        // everything relies on this (attendee list, chat, remote media, etc)
        this._subscribedView.load({
          controlConnection: this._controlConnection,
          meeting: Reactive.wrap(this),
        });

        // if permissions change, take appropriate action
        this._localAttendee.permissionsUpdated.bind(async (e) => {
          await this.processLocalPermissionUpdate();
        });

        //TODO: factor in device capabilities?
        if (Utility.isNullOrUndefined(this._maxVisibleDisplayMedias)) this._maxVisibleDisplayMedias = this._model.maxVideoDisplay;
        if (Utility.isNullOrUndefined(this._maxVisibleUserMedias)) this._maxVisibleUserMedias = this._model.maxVideoUser;
        if (Utility.isNullOrUndefined(this._maxVisibleUserMediasDuringDisplayMedia)) this._maxVisibleUserMediasDuringDisplayMedia = this._model.maxVideoUser;

        this._joinOptions.onProgress({ meeting: this, progress: 1.0 });
        void this.setState("joined");

        const duration = Math.round(performance.now() - joinStart)
        void this._eventLogger.information("join.succeeded", `Joined meeting ${this.id} in room ${this.roomId} as attendee ${this.localAttendee.id}.`, duration);
        void this.logClientMetric({ eventDuration: duration, eventName: "joinSuccess" });
        this._timings.meetingJoinCompleted = duration;
        // we don't await this as we want the join to complete here and return to the caller
        void this.loadOptionalServices();
      } catch (error: any) {
        void this._eventLogger?.error(<Error>error, "join.failed", "Could not join meeting.");
        void this.logClientMetric({ eventDuration: performance.now() - joinStart, eventName: "joinError", eventDebug: { "error": error.message } });
        void this.setState("leaving");
        void this._eventLogger?.debug(<Error>error, "join", "Leaving meeting (failed to join)...");
        await this.unjoin();
        void this.setState("left");
        void this._eventLogger?.debug(<Error>error, "join", "Left meeting (failed to join).");
        throw error;
      }
    });
  }

  private async loadOptionalServices(abortSignal?: AbortSignal) {
    const start = performance.now();
    if (this._model.allowChat) {
      this._chat.init({
        apiClient: this._apiClient,
        controlConnection: this._controlConnection,
        localAttendee: this._localAttendee,
        subscribedView: this._subscribedView,
        uploadClient: this._uploadClient,
      });
    }

    void this._attendees.init({
      controlConnection: this._controlConnection,
      localAttendee: this._localAttendee,
      subscribedView: this._subscribedView,
      attendeePageSize: this._joinOptions.attendeePageSize,
      attendeeSortType: this._joinOptions.attendeeSortType,
    });

    const mediaPromises: Promise<void>[] = [];

    this.initEdgeManager(this._tokenResponse.edgeServerUrl);
    let edgeConnectionPromise: Promise<void>;
    if (this._joinOptions.useRemoteMedia) {
      edgeConnectionPromise = this.connectEdge();
      mediaPromises.push(edgeConnectionPromise);
    }

    await this.initializeOriginManagers();

    if (this._joinOptions.useScreenShare) mediaPromises.push(this.connectOriginDisplay());
    if (this._joinOptions.useCamera || this._joinOptions.useMicrophone) mediaPromises.push(this.connectOriginUser());

    const optionalServicesPromises: Promise<void>[] = [];
    if (this._joinOptions.useAttendeeList) optionalServicesPromises.push(this.startAttendeeList(this.joinOptions, abortSignal));
    if (this._joinOptions.useChat) optionalServicesPromises.push(this.startChat(abortSignal));

    if (this._joinOptions.linkLocalAndRemoteMedia) {
      await Promise.all(mediaPromises);
    }

    // we need to bind local media regardless of if edge/origin are started on join, so that they're ready for later if required
    const mediaBindingPromises: Promise<void>[] = [];
    mediaBindingPromises.push(this.bindEdgeLocalMedia(edgeConnectionPromise)); // replace local media in the visible media collection
    mediaBindingPromises.push(this.bindOriginLocalMedia("display", abortSignal));
    mediaBindingPromises.push(this.bindOriginLocalMedia("user", abortSignal));

    void this._eventLogger.debug("join", `Starting stats...`);
    this.tryStartStats();

    await Promise.all([
      Promise.all(optionalServicesPromises),
      Promise.all(mediaPromises),
      Promise.all(mediaBindingPromises)]);

    this._timings.optionalServicesCompleted = Math.round(performance.now() - start);
    this._isReady = true;
  }

  public async getMeetingTokenAndInitializeLoggers(abortSignal?: AbortSignal) {
    const start = performance.now();
    try {
      this._tokenResponse = await this._session.getToken(this._joinOptions, abortSignal);
    } catch (error) {
      // special case: since we're failing when getting the meeting token, the joinAttempt metric needs to be logged here and will wind up in the userAccountLogs table since we don't have an attendee yet
      // otherwise, this join attempt will be logged in the main join function and log to the attendeeLogs table
      void this.logClientMetric({eventName: "joinAttempt"});
      throw error;
    }

    // init loggers
    this._eventLogger = new EventLogger(this._apiClient, "Meeting", this._tokenResponse.attendeeId, this._tokenResponse.meetingId, this._session.clusterId);
    this._metricLogger.meetingSession = this._session;

    void this._eventLogger.information("join.tokenReceived", `Received token for meeting ${this._tokenResponse.meetingId}.`, performance.now() - start);
    this._timings.meetingTokenReceived = Math.round(performance.now() - start);

      void this._eventLogger.debug("join", `Control server URL is ${new URL(this._tokenResponse.controlServerUrl).toString() }.`);
      void this._eventLogger.debug("join", `Edge server URL is ${new URL(this._tokenResponse.edgeServerUrl).toString() }`);
      void this._eventLogger.debug("join", `Origin server URL is ${new URL(this._tokenResponse.originServerUrl).toString() }`);
      void this._eventLogger.debug("join", `TURN server URL is ${new URL(this._tokenResponse.turnServerUrl).toString() }`);
  }

  private async configureTurn() {
    const start = performance.now();
    // initialize TURN session and get initial credentials

    if (this._joinOptions.turnOptions.externalCredentials) {
      this._turnSession = new ExternalTurnSession({
        credentials: this._joinOptions.turnOptions.externalCredentials,
      });
    } else {
      this._turnSession = new TurnSession({
        meetingSession: this._session,
        turnServerUrl: this._tokenResponse.turnServerUrl,
        tcpAllowed: this._joinOptions.turnOptions.tcpAllowed,
        tlsAllowed: this._joinOptions.turnOptions.tlsAllowed,
        udpAllowed: this._joinOptions.turnOptions.udpAllowed,
      });
    }

    if (this._turnSession) {
      const iceServer = await this._turnSession.iceServer();
      const duration = Math.round(performance.now() - start);
      this._timings.turnCredentialsReceived = duration;
      void this._eventLogger.information("join.turnConfigured", `Configured TURN: ${(<string[]>iceServer?.urls ?? []).join(", ")}`, duration);
    }
  }

  private async connectControl(abortSignal?: AbortSignal) {
    // initialize control client
    const controlClient = new ControlClient({
      controlServerUrl: this._tokenResponse.controlServerUrl,
      meetingSession: this._session,
    });
    controlClient.maxRetries = this._maxRetries;
    controlClient.requestTimeout = this._requestTimeout;

    // initialize control connection
    this._controlConnection = new ControlConnection({
      apiClient: this._apiClient,
      attendeeId: this._tokenResponse.attendeeId,
      canBlock: () => this._localAttendee?.canBlock ?? false,
      canCreateChatChannel: () => this._localAttendee?.canCreateChatChannel ?? false,
      canDeleteChatChannel: () => this._localAttendee?.canDeleteChatChannel ?? false,
      canDeleteChatMessage: () => this._localAttendee?.canDeleteChatMessage ?? false,
      canEnableChat: () => this._localAttendee?.canEnableChat ?? false,
      canKick: () => this._localAttendee?.canKick ?? false,
      canMute: () => this._localAttendee?.canMute ?? false,
      canRecord: () => this._localAttendee?.canRecord ?? false,
      canSendChatMessage: () => this._localAttendee?.canSendChatMessage ?? false,
      canUnmute: () => this._localAttendee?.canUnmute ?? false,
      canUpdate: () => this._localAttendee?.canUpdate ?? false,
      client: controlClient,
      clusterId: this._session.clusterId,
      iceRestartEnabled: this._joinOptions.iceOptions.restartEnabled,
      meetingId: this._tokenResponse.meetingId,
      turnRequired: this._joinOptions.turnOptions.required,
      turnSession: this._turnSession,
    });

    this._controlConnection.attendeeAdmitted.bind(this.processAttendeeAdmitted.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeAudioMuted.bind(this.processAttendeeAudioMuted.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeAudioUnmuted.bind(this.processAttendeeAudioUnmuted.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeAudioUnmuteDisabled.bind(this.processAttendeeAudioUnmuteDisabled.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeAudioUnmuteEnabled.bind(this.processAttendeeAudioUnmuteEnabled.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeBlockedFromLobby.bind(this.processAttendeeBlockedFromLobby.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeBlockedFromRoom.bind(this.processAttendeeBlockedFromRoom.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeHandLowered.bind(this.processAttendeeHandLowered.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeHandRaised.bind(this.processAttendeeHandRaised.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeJoined.bind(this.processAttendeeJoined.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeKicked.bind(this.processAttendeeKicked.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeLeft.bind(this.processAttendeeLeft.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeNoiseSuppressed.bind(this.processAttendeeNoiseSuppressed.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeNoiseUnsuppressed.bind(this.processAttendeeNoiseUnsuppressed.bind(Reactive.wrap(this)));
    this._controlConnection.attendeePausedUpdated.bind(this.processAttendeePausedUpdated.bind(Reactive.wrap(this)));
    this._controlConnection.attendeePermissionAdded.bind(this.processAttendeePermissionAdded.bind(Reactive.wrap(this)));
    this._controlConnection.attendeePermissionRemoved.bind(this.processAttendeePermissionRemoved.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeSettingAdded.bind(this.processAttendeeSettingAdded.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeSettingRemoved.bind(this.processAttendeeSettingRemoved.bind(Reactive.wrap(this)));
    this._controlConnection.attendeePinned.bind(this.processAttendeePinned.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeQualityEdgeUpdated.bind(this.processAttendeeQualityEdgeUpdated.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeQualityOriginUpdated.bind(this.processAttendeeQualityOriginUpdated.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeUnpinned.bind(this.processAttendeeUnpinned.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeUpdated.bind(this.processAttendeeUpdated.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeVideoMuted.bind(this.processAttendeeVideoMuted.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeVideoUnmuted.bind(this.processAttendeeVideoUnmuted.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeVideoUnmuteDisabled.bind(this.processAttendeeVideoUnmuteDisabled.bind(Reactive.wrap(this)));
    this._controlConnection.attendeeVideoUnmuteEnabled.bind(this.processAttendeeVideoUnmuteEnabled.bind(Reactive.wrap(this)));
    this._controlConnection.meetingAudioMuted.bind(this.processMeetingAudioMuted.bind(Reactive.wrap(this)));
    this._controlConnection.meetingAudioUnmuted.bind(this.processMeetingAudioUnmuted.bind(Reactive.wrap(this)));
    this._controlConnection.meetingAudioUnmuteDisabled.bind(this.processMeetingAudioUnmuteDisabled.bind(Reactive.wrap(this)));
    this._controlConnection.meetingAudioUnmuteEnabled.bind(this.processMeetingAudioUnmuteEnabled.bind(Reactive.wrap(this)));
    this._controlConnection.meetingChatDisabled.bind(this.processMeetingChatDisabled.bind(Reactive.wrap(this)));
    this._controlConnection.meetingChatEnabled.bind(this.processMeetingChatEnabled.bind(Reactive.wrap(this)));
    this._controlConnection.meetingNoiseSuppressed.bind(this.processMeetingNoiseSuppressed.bind(Reactive.wrap(this)));
    this._controlConnection.meetingNoiseUnsuppressed.bind(this.processMeetingNoiseUnsuppressed.bind(Reactive.wrap(this)));
    this._controlConnection.meetingRecordingDisabled.bind(this.processMeetingRecordingDisabled.bind(Reactive.wrap(this)));
    this._controlConnection.meetingRecordingEnabled.bind(this.processMeetingRecordingEnabled.bind(Reactive.wrap(this)));
    this._controlConnection.meetingRecordingStarted.bind(this.processMeetingRecordingStarted.bind(Reactive.wrap(this)));
    this._controlConnection.meetingRecordingStopped.bind(this.processMeetingRecordingStopped.bind(Reactive.wrap(this)));
    this._controlConnection.meetingVideoMuted.bind(this.processMeetingVideoMuted.bind(Reactive.wrap(this)));
    this._controlConnection.meetingUpdated.bind(this.processMeetingUpdated.bind(Reactive.wrap(this)));
    this._controlConnection.meetingVideoUnmuted.bind(this.processMeetingVideoUnmuted.bind(Reactive.wrap(this)));
    this._controlConnection.meetingVideoUnmuteDisabled.bind(this.processMeetingVideoUnmuteDisabled.bind(Reactive.wrap(this)));
    this._controlConnection.meetingVideoUnmuteEnabled.bind(this.processMeetingVideoUnmuteEnabled.bind(Reactive.wrap(this)));
    this._controlConnection.stateChanged.bind(this.onControlConnectionStateChanged.bind(Reactive.wrap(this)));
    this._controlConnection.subscribedAttendees.bind(this.processSubscribedAttendees.bind(Reactive.wrap(this)));

    // connect control
    const start = performance.now();
    void this._eventLogger.debug("join", `Connecting...`);
    try {
      void this.logClientMetric({ eventName: "connectionAttemptControl", eventDebug: { "serverUrl": this._tokenResponse.controlServerUrl } });
      await this._controlConnection.open(abortSignal);
      this._model = this._controlConnection.meeting;
      void this.logClientMetric({ eventName: "connectionSuccessControl", eventDuration: performance.now() - start });
    } catch (error) {
      void this.logClientMetric({ eventName: "connectionErrorControl", eventDebug: { "error": (error as Error).message }, eventDuration: performance.now() - start });
      throw (error);
    }
    this._timings.controlConnectionOpened = Math.round(performance.now() - start);
    void this._eventLogger.information("join.controlConnected", `Connected to control server.`, performance.now() - start);
  }

  private initEdgeManager = (edgeServerUrl: string) => {
    this._edgeManager.init({
      apiClient: this._apiClient,
      attendeeId: this._tokenResponse.attendeeId,
      audibleDisplayMedias: this._audibleDisplayMedias,
      audibleUserMedias: this._audibleUserMedias,
      audioLevelIntervalDisplay: this._joinOptions.audioLevelInterval,
      audioLevelIntervalUser: this._joinOptions.audioLevelInterval,
      clusterId: this._session.clusterId,
      compatibilityMode: this._joinOptions.remoteCompatibilityMode,
      discardAudio: this._joinOptions.remoteDiscardAudio,
      discardVideo: this._joinOptions.remoteDiscardVideo,
      edgeServerUrl: edgeServerUrl,
      iceRestartEnabled: this._joinOptions.iceOptions.restartEnabled,
      localLoopbackAudio: this._joinOptions.localLoopbackAudio,
      localLoopbackVideo: this._joinOptions.localLoopbackVideo,
      maxAudibleDisplay: this._model.maxAudioDisplay,
      maxAudibleUser: this._model.maxAudioUser,
      maxVisibleDisplay: Math.min(this._model.maxVideoDisplay, this._maxVisibleDisplayMedias),
      maxVisibleUser: Math.min(this._model.maxVideoUser, this._maxVisibleUserMedias),
      meetingId: this._tokenResponse.meetingId,
      meetingSession: this._session,
      //pixelFeedback: this._joinOptions.remotePixelFeedback,
      subscribedView: this._subscribedView,
      turnRequired: this._joinOptions.turnOptions.required,
      turnSession: this._turnSession,
      visibleDisplayMedias: this._visibleDisplayMedias,
      visibleUserMedias: this._visibleUserMedias,
    });
    this._edgeManager.connectionStateChanged.bind(this.onEdgeConnectionStateChanged.bind(Reactive.wrap(this)));
    this._edgeManager.maxRetries = this._maxRetries;
    this._edgeManager.requestTimeout = this._requestTimeout;
    // return edgeManager;
  };

  private async connectEdge(abortSignal?: AbortSignal): Promise<void> {
    if (!this.canReceiveMedia || this._remoteMediaDisabled) return;

    const start = performance.now();
    this.initEdgeManager(this._tokenResponse.edgeServerUrl);

    return new Promise(async (resolve, reject) => {
      try {
        while (this._edgeManager && this._edgeManager.state != "started") {
          let attempts = 0;
          // fail out if aborted
          if (abortSignal?.aborted == true) {
            if (abortSignal.reason) throw new Error(`Abort signalled. (${abortSignal.reason})`);
            throw new Error("Abort signalled.");
          }
          try {
            void this.logClientMetric({ eventName: "connectionAttemptEdge", eventDebug: { "serverUrl": this._edgeManager.url, "attemptIndex": attempts++ } });
            await this._edgeManager.start(abortSignal);
            this._timings.edgeConnectionOpened = Math.round(performance.now() - start);
            void this.logClientMetric({ eventName: "connectionSuccessEdge", eventDuration: performance.now() - start });
            void this._eventLogger.information("join.edgeConnected", `Connected to edge server.`, performance.now() - start);
            resolve();
          } catch (error) {
            void this.logClientMetric({ eventName: "connectionErrorEdge", eventDebug: { "error": (error as Error).message }, eventDuration: performance.now() - start });
            void this._eventLogger.warning(<Error>error, "join", `Reassigning...`);
            const edgeServerUrl = (await this._session.reassignEdge()).serverUrl;
            if (!edgeServerUrl) {
              this._eventLogger.debug("join", `Reassignment failed. No other servers available.`);
              throw error;
            }
              this._eventLogger.debug("join", `Edge server URL is now ${new URL(edgeServerUrl).toString() }.`);
            this.initEdgeManager(edgeServerUrl);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  private async connectOriginDisplay(abortSignal?: AbortSignal): Promise<void> {
    if (this.canSendDisplayMedia) {
      const start = performance.now();
      const originManager = this._originManagers.get("display");
      if (originManager) {
        void this.logClientMetric({ eventName: "connectionAttemptOriginDisplay", eventDebug: { "serverUrl": originManager.url } });
        const originPromise = originManager.start(abortSignal);
        originPromise
          .then(() => {
            const duration = Math.round(performance.now() - start);
            this._timings.originDisplayConnection = duration;
            void this.logClientMetric({ eventName: "connectionSuccessOriginDisplay", eventDuration: duration });
            void this._eventLogger.information("join.originDisplayConnected", `Connected to origin (display) server.`, duration);
          })
          .catch((error) => { void this.logClientMetric({ eventName: "connectionErrorOriginDisplay", eventDebug: { "error": error.message }, eventDuration: performance.now() - start }); });
        return originPromise;
      }
    }
  }

  private async connectOriginUser(abortSignal?: AbortSignal): Promise<void> {
    if (this.canSendUserMedia) {
      const start = performance.now();
      const originManager = this._originManagers.get("user");
      if (originManager) {
        void this.logClientMetric({ eventName: "connectionAttemptOriginUser", eventDebug: { "serverUrl": originManager.url } });
        const originPromise = originManager.start(abortSignal);
        return originPromise
          .then(() => {
            const duration = Math.round(performance.now() - start);
            this._timings.originUserConnectionOpened = duration;
            void this.logClientMetric({ eventName: "connectionSuccessOriginUser", eventDuration: duration });
            void this._eventLogger.information("join.originUserConnected", `Connected to origin (user) server.`, duration);
          })
          .catch((error) => { void this.logClientMetric({ eventName: "connectionErrorOriginUser", eventDebug: { "error": error.message }, eventDuration: performance.now() - start }); });
      }
    }
  }

  private async bindEdgeLocalMedia(edgeConnectionPromise: Promise<void>): Promise<void> {
    await edgeConnectionPromise;
    const start = performance.now();
    //TODO: bind but paused
    let mediaBound = false;
    for (const mediaType of mediaTypes) {
      const localMedia = this._localMedias.get(mediaType);
      if (!localMedia) continue;
      mediaBound = true;
      void this._eventLogger.debug("join", `Binding local ${mediaType} media to edge...`);
      this._edgeManager?.setLocalMedia(mediaType, localMedia);
    }
    if (mediaBound) {
      const duration = Math.round(performance.now() - start);
      this._timings.edgeLocalMediaBound = duration;
      void this._eventLogger.information("join.edgeLocalMediaBound", `Bound local media to edge manager.`, duration);
    }
  }

  private async bindOriginLocalMedia(mediaType: MediaType, abortSignal?: AbortSignal): Promise<void> {
    const start = performance.now();
    const localMedia = this._localMedias.get(mediaType);
    if (!localMedia) return;
    const originManager = this._originManagers.get(mediaType);
    if (!originManager) return;

    const setMediaPromises: Promise<void>[] = [];

    void this._eventLogger.debug("join", `Binding local ${mediaType} media to origin...`);
    await originManager.setMedia(localMedia, this._localAttendee, false, abortSignal);
    await this.validateUserMedia(localMedia, mediaType);

    const duration = Math.round(performance.now() - start);
    if (mediaType == "display") {
      this._timings.originDisplayMediaBound = duration;
    }
    if (mediaType == "user") this._timings.originUserMediaBound = duration;
    void this._eventLogger.information(`join.originLocalMediaBound.${mediaType}`, `Bound local ${mediaType} media to origin manager.`, duration);
  }


  private async startAttendeeList(joinOptions: JoinOptions, abortSignal?: AbortSignal): Promise<void> {
    let start = performance.now();
    try {
      await this._attendees.start();
      const duration = Math.round(performance.now() - start);
      this._timings.attendeeListStarted = duration;
      void this._eventLogger.information("join.attendeeListLoaded", `Loaded attendee list.`, duration, {
        "pageSize": this.attendees.pageSize,
        "attendeesLoaded": this.attendees.count,
        "attendeesTotal": this.attendees.totalCount
      });
    }
    catch (error) {
      void this._eventLogger.error(<Error>error, "join.attendeeListFailed", "Attendee list failed to load.", performance.now() - start);
    }
  }

  private async startChat(abortSignal?: AbortSignal): Promise<void> {
    const start = performance.now();
    try {
      await this.startChatInternal();
      const duration = Math.round(performance.now() - start);
      this._timings.chatStarted = duration;
      if (this.isChatEnabled) void this._eventLogger.information("join.chatLoaded", `Loaded chat.`, duration);
    }
    catch (error) {
      void this._eventLogger.error(<Error>error, "join.chatFailed", "Chat failed to load.", performance.now() - start);
    }
  }

  private async initializeOriginManagers() {
    // TODO: This should be done earlier. Identity token has a tenantId. Seems the tenantSettings endpoint not set up to use the token.
    const tenantSettingStart = performance.now();
    const tenantSettingsResponse: ApiCollectionResponse<TenantSetting> = await this._apiClient.listTenantSettingsByTenantId(this._controlConnection.tenantId);

    if (tenantSettingsResponse.values) {
      this._tenantSettings = tenantSettingsResponse.values;
      void this._eventLogger.information("join.clusterSettingsLoaded", `Loaded ${this._tenantSettings.length} tenant settings.`, performance.now() - tenantSettingStart);
    }

    for (const mediaType of mediaTypes) {
      if (mediaType == "display" && !this._model.maxAudioDisplay && !this._model.maxVideoDisplay) continue;
      if (mediaType == "user" && !this._model.maxAudioUser && !this._model.maxVideoUser) continue;

      this._originManagers.get(mediaType).init({
        apiClient: this._apiClient,
        attendeeId: this._tokenResponse.attendeeId,
        audioLevelInterval: this._joinOptions.audioLevelInterval,
        clusterId: this._session.clusterId,
        iceRestartEnabled: this._joinOptions.iceOptions.restartEnabled,
        mediaOptions: mediaType == "display" ? this._joinOptions.localDisplayOptions : this._joinOptions.localUserOptions,
        mediaType: mediaType,
        meetingId: this._tokenResponse.meetingId,
        meetingSession: this._session,
        originServerUrl: this._tokenResponse.originServerUrl,
        replicationCount: mediaType == "user" ? this._joinOptions.userReplicationCount : undefined,
        room: this._controlConnection.room,
        tenantSettings: this._tenantSettings,
        turnRequired: this._joinOptions.turnOptions.required,
        turnSession: this._turnSession,
      });
      this._originManagers.get(mediaType).connectionStateChanged.bind(this.onOriginConnectionStateChanged.bind(Reactive.wrap(this)));
      this._originManagers.get(mediaType).maxRetries = this._maxRetries;
      this._originManagers.get(mediaType).requestTimeout = this._requestTimeout;
    }
  }

  public leave(abortSignal?: AbortSignal): Promise<void> {
    return this._eventQueue.dispatch(async () => {
      if (this.state == "left" || this.state == "new") return;
      this.setState("leaving");
      void this._eventLogger?.debug("leave", "Leaving meeting...");
      await this.unjoin(abortSignal);
      this.setState("left");
      void this._eventLogger?.debug("leave", "Left meeting.");
    });
  }

  public async log(event: AppMeetingMetric | AppCustomMeetingMetric) {
    await this._metricLogger.log({
      eventDebug: event.eventDebug,
      eventDuration: event.eventDuration,
      eventMessage: event.eventMessage,
      eventName: event.eventName,
      metricType: "app",
      timestamp: new Date
    });
  }

  /** @ internal */
  public async logClientMetric(event: SdkMeetingMetric) {
    await this._metricLogger.log({
      eventDebug: event.eventDebug,
      eventDuration: event.eventDuration,
      eventMessage: event.eventMessage,
      eventName: event.eventName,
      metricType: "sdk",
      timestamp: new Date
    });
  }

  //#region Connection Event Handlers

  private allConnected(): boolean {
    if (this._controlConnection && this._controlConnection.state != "connected") return false;
    if (this._edgeManager && this._edgeManager.connectionState != "connected") return false;
    for (const mediaType of mediaTypes) {
      const originManager = this._originManagers.get(mediaType);
        if (originManager && originManager.isStarted && originManager.connectionState != "connected") return false;
    }
    return true;
  }

  private onControlConnectionStateChanged(e: ConnectionStateChangeEvent): void {
    void this._eventQueue.dispatch(async () => {
      if (e.state == "reconnecting") {
        void this.logClientMetric({ eventName: "reconnectAttemptControl", eventDuration: e.previousStateDuration });
        void this._eventLogger?.debug("onControlConnectionStateChanged", "Control connection is reconnecting...");
        if (this.trySetState("reconnecting")) {
          await this.tryPauseOriginMedia();
        }
      }
      if (e.state == "connected" && e.previousState == "reconnecting") {
        void this.logClientMetric({ eventName: "reconnectSuccessControl", eventDuration: e.previousStateDuration });
        void this._eventLogger?.debug("onControlConnectionStateChanged", "Control connection has reconnected.");
        if (this.allConnected() && this.trySetState("joined")) await this.tryResumeOriginMedia();
      }
      if (e.state == "failed" && this.state != "leaving" && this.state != "left") {
        if (e.previousState == "reconnecting") void this.logClientMetric({ eventName: "reconnectErrorControl", eventDuration: e.previousStateDuration });
        else void this.logClientMetric({ eventName: "connectionDroppedControl", eventDuration: e.previousStateDuration });
        this._hasFailed = true;
        this.setState("leaving");
        void this._eventLogger?.debug("onControlConnectionStateChanged", `Leaving meeting (control connection ${e.state})...`);
        await this.unjoin();
        this.setState("left");
        void this._eventLogger?.debug("onControlConnectionStateChanged", `Left meeting (control connection ${e.state}).`);
      }
      if (e.state == "closed" && this.state != "leaving" && this.state != "left") {
        this.setState("leaving");
        void this._eventLogger?.debug("onControlConnectionStateChanged", `Leaving meeting (control connection ${e.state})...`);
        await this.unjoin();
        this.setState("left");
        void this._eventLogger?.debug("onControlConnectionStateChanged", `Left meeting (control connection ${e.state}).`);
      }
    });
  }

  private onEdgeConnectionStateChanged(e: ConnectionStateChangeEvent): void {
    void this._eventQueue.dispatch(async () => {
      if (e.state == "reconnecting") {
        void this.logClientMetric({ eventName: "reconnectAttemptEdge", eventDuration: e.previousStateDuration });
        void this._eventLogger?.debug("onEdgeConnectionStateChanged", "Edge connection is reconnecting...");
        if (this.trySetState("reconnecting")) {
          await this.tryPauseOriginMedia();
        }
      }
      if (e.state == "connected" && e.previousState == "reconnecting") {
        void this.logClientMetric({ eventName: "reconnectSuccessEdge", eventDuration: e.previousStateDuration });
        void this._eventLogger?.debug("onEdgeConnectionStateChanged", "Edge connection has reconnected.");
        if (this.allConnected() && this.trySetState("joined")) await this.tryResumeOriginMedia();
      }
      if (e.state == "failed" && this.state != "leaving" && this.state != "left") {
        if (e.previousState == "reconnecting") void this.logClientMetric({ eventName: "reconnectErrorEdge", eventDuration: e.previousStateDuration });
        else void this.logClientMetric({ eventName: "connectionDroppedEdge", eventDuration: e.previousStateDuration });
        this._hasFailed = true;
        this.setState("leaving");
        void this._eventLogger?.debug("onEdgeConnectionStateChanged", `Leaving meeting (edge connection ${e.state})...`);
        await this.unjoin();
        this.setState("left");
        void this._eventLogger?.debug("onEdgeConnectionStateChanged", `Left meeting (edge connection ${e.state}).`);
      }
    });
  }

  private onOriginConnectionStateChanged(e: ConnectionStateChangeEvent): void {
    void this._eventQueue.dispatch(async () => {
      const mediaType = (e.connection as OriginConnection).mediaType;
      if (e.state == "reconnecting") {
        void this.logClientMetric({ eventName: mediaType == "user" ? "reconnectAttemptOriginUser" : "reconnectAttemptOriginDisplay", eventDuration: e.previousStateDuration });
        void this._eventLogger?.debug("onOriginConnectionStateChanged", `Origin (${mediaType}) connection is reconnecting...`);
        if (this.trySetState("reconnecting")) {
          await this.tryPauseOriginMedia();
        }
      }
      if (e.state == "connected" && e.previousState == "reconnecting") {
        void this.logClientMetric({ eventName: mediaType == "user" ? "reconnectSuccessOriginUser" : "reconnectSuccessOriginDisplay", eventDuration: e.previousStateDuration });
        void this._eventLogger?.debug("onOriginConnectionStateChanged", `Origin (${mediaType}) connection has reconnected.`);
        if (this.allConnected() && this.trySetState("joined")) await this.tryResumeOriginMedia();
      }
      if (e.state == "failed" && this.state != "leaving" && this.state != "left") {
        if (e.previousState == "reconnecting") void this.logClientMetric({ eventName: mediaType == "user" ? "reconnectErrorOriginUser" : "reconnectErrorOriginDisplay", eventDuration: e.previousStateDuration });
        else void this.logClientMetric({ eventName: mediaType == "user" ? "connectionDroppedOriginUser" : "connectionDroppedOriginDisplay", eventDuration: e.previousStateDuration });
        this._hasFailed = true;
        this.setState("leaving");
        void this._eventLogger?.debug("onOriginConnectionStateChanged", `Leaving meeting (origin connection ${e.state})...`);
        await this.unjoin();
        this.setState("left");
        void this._eventLogger?.debug("onOriginConnectionStateChanged", `Left meeting (origin connection ${e.state}).`);
      }
    });
  }

  private async tryPauseOriginMedia(): Promise<void> {
    void this._eventLogger.debug("tryPauseLocalUserMedia", `Pausing origin media...`);
    await this._originManagers.get("display")?.pauseInternal();
    await this._originManagers.get("user")?.pauseInternal();
  }

  private async tryResumeOriginMedia(): Promise<void> {
    void this._eventLogger.debug("tryResumeLocalUserMedia", `Resuming origin media...`);
    await this._originManagers.get("display")?.resumeInternal();
    await this._originManagers.get("user")?.resumeInternal();
  }

  //#endregion

  //#region Control Operations

  public async disableAudioUnmute(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.disableMeetingAudioUnmute(this._model); // TODO: why send the whole model?
    await this.processMeetingAudioUnmuteDisabled({
      attendee: this._localAttendee.model,
      exclusions: [this._localAttendee.id],
      meeting: this._model,
    });
  }

  public async disableAudioUnmuteOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ allowUnmuteAudioOnJoin: false });
    await controlConnection.disableMeetingAudioUnmuteOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  /**
   * Disables chat on a meeting level, for all attendees.
   */
  public async disableChat(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.disableChat(this._model); // TODO: why send the whole model?
    this._model.allowChat = false;
    await this._chat.stop();
  }

  public async disableVideoUnmute(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.disableMeetingVideoUnmute(this._model); // TODO: why send the whole model?
    await this.processMeetingVideoUnmuteDisabled({
      attendee: this._localAttendee.model,
      exclusions: [this._localAttendee.id],
      meeting: this._model,
    });
  }

  public async disableVideoUnmuteOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ allowUnmuteVideoOnJoin: false });
    await controlConnection.disableMeetingVideoUnmuteOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  public async enableAudioUnmute(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.enableMeetingAudioUnmute(this._model); // TODO: why send the whole model?
    await this.processMeetingAudioUnmuteEnabled({
      attendee: this._localAttendee.model,
      exclusions: [this._localAttendee.id],
      meeting: this._model,
    });
  }

  public async enableAudioUnmuteOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ allowUnmuteAudioOnJoin: true });
    await controlConnection.enableMeetingAudioUnmuteOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  /**
   * Enables chat at the meeting level, for all attendees.
   */
  public async enableChat(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.enableChat(this._model); // TODO: why send the whole model?
    this._model.allowChat = true;
    await this.startChatInternal();
  }

  public async enableVideoUnmute(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.enableMeetingVideoUnmute(this._model); // TODO: why send the whole model?
    await this.processMeetingVideoUnmuteEnabled({
      attendee: this._localAttendee.model,
      exclusions: [this._localAttendee.id],
      meeting: this._model,
    });
  }

  public async enableVideoUnmuteOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ allowUnmuteVideoOnJoin: true });
    await controlConnection.enableMeetingVideoUnmuteOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  public async muteAudio(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.muteMeetingAudio(this._model); // TODO: why send the whole model?
    await this.processMeetingAudioMuted({
      attendee: this._localAttendee.model,
      exclusions: [this._localAttendee.id],
      meeting: this._model,
    });
  }

  public async muteAudioOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ muteAudioOnJoin: true });
    await controlConnection.muteMeetingAudioOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  public async muteVideo(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.muteMeetingVideo(this._model); // TODO: why send the whole model?
    await this.processMeetingVideoMuted({
      attendee: this._localAttendee.model,
      exclusions: [this._localAttendee.id],
      meeting: this._model,
    });
  }

  public async muteVideoOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ muteVideoOnJoin: true });
    await controlConnection.muteMeetingVideoOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  public async startRecording(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const response = await controlConnection.startRecordingMeeting(this._model); // TODO: why send the whole model?
    await this.processMeetingRecordingStarted({ meeting: response.meeting });
  }

  public async stopRecording(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const response = await controlConnection.stopRecordingMeeting(this._model); // TODO: why send the whole model?
    await this.processMeetingRecordingStopped({ meeting: response.meeting });
  }

  public async suppressNoise(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.suppressMeetingNoise(this._model); // TODO: why send the whole model?
    await this.processMeetingNoiseSuppressed({
      attendee: this._localAttendee.model,
      exclusions: [],
      meeting: this._model,
    });
  }

  public async suppressNoiseOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ suppressNoiseOnJoin: true });
    await controlConnection.suppressMeetingNoiseOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  public async unmuteAudio(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.unmuteMeetingAudio(this._model); // TODO: why send the whole model?
    await this.processMeetingAudioUnmuted({
      attendee: this._localAttendee.model,
      exclusions: [this._localAttendee.id],
      meeting: this._model,
    });
  }

  public async unmuteAudioOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ muteAudioOnJoin: false });
    await controlConnection.unmuteMeetingAudioOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  public async unmuteVideo(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.unmuteMeetingVideo(this._model); // TODO: why send the whole model?
    await this.processMeetingVideoUnmuted({
      attendee: this._localAttendee.model,
      exclusions: [this._localAttendee.id],
      meeting: this._model,
    });
  }

  public async unmuteVideoOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ muteVideoOnJoin: false });
    await controlConnection.unmuteMeetingVideoOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  public async unsuppressNoise(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    await controlConnection.unsuppressMeetingNoise(this._model); // TODO: why send the whole model?
    await this.processMeetingNoiseUnsuppressed({
      attendee: this._localAttendee.model,
      exclusions: [],
      meeting: this._model,
    });
  }

  public async unsuppressNoiseOnJoin(): Promise<void> {
    const controlConnection = this._controlConnection;
    if (!controlConnection) throw new Error(Constants.Errors.Meeting.notJoined);
    const sendModel = Object.assign({}, this._model, <MeetingModel>{ suppressNoiseOnJoin: false });
    await controlConnection.unsuppressMeetingNoiseOnJoin(sendModel); // TODO: why send the whole model?
    await this.processMeetingUpdated({ meeting: sendModel });
  }

  //#endregion

  //#region Device Operations

  public setAudioInputDevice(deviceId?: string, required?: boolean): Promise<void> {
    return this.localUserMedia?.setAudioDevice(deviceId, required);
  }

  public setAudioOutputDevice(deviceId?: string): Promise<void> {
    return this._edgeManager?.setAudioDevice(deviceId);
  }

  public setVideoInputDevice(deviceId?: string, required?: boolean): Promise<void> {
    return this.localUserMedia?.setVideoDevice(deviceId, required);
  }

  public useNextAudioInputDevice(): Promise<void> {
    return this.localUserMedia?.useNextAudioDevice();
  }

  public useNextAudioOutputDevice(): Promise<void> {
    return this._edgeManager?.useNextAudioDevice();
  }

  public async useNextVideoInputDevice(): Promise<void> {
    return this.localUserMedia?.useNextVideoDevice();
  }

  public async usePreviousAudioInputDevice(): Promise<void> {
    return this.localUserMedia?.usePreviousAudioDevice();
  }

  public async usePreviousAudioOutputDevice(): Promise<void> {
    return this._edgeManager?.usePreviousAudioDevice();
  }

  public async usePreviousVideoInputDevice(): Promise<void> {
    return this.localUserMedia?.usePreviousVideoDevice();
  }

  //#endregion

  //#region Media Operations

  private onAudibleDisplayMediaAdded(e: ReadOnlyCollectionEvent<Media>) {
    this._audibleMedias.tryAdd(e.element);
  }

  private onAudibleDisplayMediaRemoved(e: ReadOnlyCollectionEvent<Media>) {
    this._audibleMedias.tryRemove(e.element.id);
  }

  private onAudibleUserMediaAdded(e: ReadOnlyCollectionEvent<Media>) {
    this._audibleMedias.tryAdd(e.element);
  }

  private onAudibleUserMediaRemoved(e: ReadOnlyCollectionEvent<Media>) {
    this._audibleMedias.tryRemove(e.element.id);
  }

  private onVisibleDisplayMediaAdded(e: ReadOnlyCollectionEvent<Media>) {
    this._visibleMedias.tryAdd(e.element);
    if (!this._maxVisibleUserMediasDuringDisplayMedia) return;
    void this.setMaxVisibleUser(this._maxVisibleUserMediasDuringDisplayMedia);
  }

  private onVisibleDisplayMediaRemoved(e: ReadOnlyCollectionEvent<Media>) {
    this._visibleMedias.tryRemove(e.element.id);
    if (this._visibleDisplayMedias.length > 0) return;
    void this.setMaxVisibleUser(this._maxVisibleUserMedias);
  }

  private onVisibleUserMediaAdded(e: ReadOnlyCollectionEvent<Media>) {
    this._visibleMedias.tryAdd(e.element);
  }

  private onVisibleUserMediaRemoved(e: ReadOnlyCollectionEvent<Media>) {
    this._visibleMedias.tryRemove(e.element.id);
  }

  private async setLocalMedia(mediaType: MediaType, localMedia: LocalMedia, abortSignal?: AbortSignal): Promise<void> {
    const originManager = this._originManagers.get(mediaType);
    if (originManager.isInitialized || originManager.isStarted) {
      if (localMedia) {
        // why do we start it here?
        // TODO: uncomment this?
        // if ((mediaType == "display" && this.canSendDisplayMedia) || (mediaType == "user" && this.canSendUserMedia)) await originManager.start();
        await this.validateUserMedia(localMedia, mediaType);
        await originManager.setMedia(localMedia, this._localAttendee, false, abortSignal);
      } else {
        await originManager.setMedia(null, null, false, abortSignal);

        // why do we stop here? commenting out for now
        //await originManager.stop("Local media has been disabled.");
      }
    }
    this._edgeManager?.setLocalMedia(mediaType, localMedia);
    this._localMedias.set(mediaType, localMedia);
    localMedia?.started.bind(async () => {
      await this.validateUserMedia(localMedia, mediaType);
    });
  }

  private async setMaxVisibleUser(maxVisibleUser: number) {
    await this._edgeManager?.setMaxVisibleUser(maxVisibleUser);
  }

  public setAudioImpairment(impLevel: ImpairmentLevel) {
    const originManager = this._originManagers.get("user");
    //if (originManager.isInitialized || originManager.isStarted) {      
      originManager.setAudioImpairment(impLevel);     
    //}
  }

  public setVideoImpairment(impLevel: ImpairmentLevel) {
    const originManager = this._originManagers.get("user");
    //if (originManager.isInitialized || originManager.isStarted) {      
    originManager.setVideoImpairment(impLevel);
    //}
  }

  //public decreaseRemoteBitrate(step?: number): boolean {
  //  if (!this._edgeManager) return false;
  //  this._edgeManager.decreaseBitrate(step);
  //  return true;
  //}

  // public async disableRemoteMedia(): Promise<void> {
  //   return this._eventQueue.dispatch(async () => {
  //     if (this._remoteMediaDisabled) return;
  //     await this._edgeManager?.stop("Remote media has been disabled.");
  //     this._remoteMediaDisabled = true;
  //   });
  // }

  // public async enableRemoteMedia(): Promise<void> {
  //   return this._eventQueue.dispatch(async () => {
  //     if (!this._remoteMediaDisabled) return;
  //     await this._edgeManager?.start();
  //     this._remoteMediaDisabled = false;
  //   });
  // }

  //public increaseRemoteBitrate(step?: number): boolean {
  //  if (!this._edgeManager) return false;
  //  this._edgeManager.increaseBitrate(step);
  //  return true;
  //}

  public setLocalDisplayMedia(localDisplayMedia: DisplayMedia | null, abortSignal?: AbortSignal): Promise<void> {
    return this._eventQueue.dispatch(async () => {
      return this.setLocalMedia("display", localDisplayMedia, abortSignal);
    });
  }

  public setLocalUserMedia(localUserMedia: UserMedia | null, abortSignal?: AbortSignal): Promise<void> {
    return this._eventQueue.dispatch(async () => {
      return this.setLocalMedia("user", localUserMedia, abortSignal);
    });
  }

  //#endregion

  //#region Stats

  private tryStartStats() {
    if (this.state != "joined" && this.state != "reconnecting") return;
    if (this._statsTimeoutId) return;
    this._statsTimeoutId = setTimeout(this.updateStats.bind(Reactive.wrap(this)), this.statsInterval);
  }

  private tryStopStats() {
    if (this._statsTimeoutId) clearTimeout(this._statsTimeoutId);
  }

  private async updateStats() {
    const startMillis = performance.now();
    this._statsTimeoutId = 0;
    const originManagerDisplay = this._originManagers.get("display");
    const originManagerUser = this._originManagers.get("user");
    try {
      const timestamp = performance.now();
      //await this._controlConnection?.updateStats();

      await originManagerUser?.updateStats();
      await originManagerDisplay?.updateStats();
      await this._edgeManager?.updateStats();

    } catch (error: any) {
      void this._eventLogger?.warning(<Error>error, "updateStats", "Could not update stats.");
    }
    this.tryStartStats();
  }

  //#endregion

  //#region Control Event Handlers

  private async processAttendeeAdmitted(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e && e.attendee.id == this._localAttendee.id) {
      // TODO: what do we do?
    }
  }

  /** @internal */
  public async processAttendeeAudioMuted(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processMuteAudio();
      if (this._localAttendee.id == e.attendee.id) {
        await this._localAttendee.processMuteAudio();
        this.localUserMedia?.muteAudioInternal();
      }
    }
  }

  /** @internal */
  public async processAttendeeAudioUnmuted(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processUnmuteAudio();
      if (this._localAttendee.id == e.attendee.id) {
        await this._localAttendee.processUnmuteAudio();
        this.localUserMedia?.unmuteAudioInternal();
      }
    }
  }

  private async processAttendeeBlockedFromLobby(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e && e.attendee.id == this._localAttendee.id) {
      await this._blocked.dispatch({
        meeting: this
      });
    }
  }

  private async processAttendeeBlockedFromRoom(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e && e.attendee?.id == this._localAttendee.id) {
      await this.leave();
      await this._blocked.dispatch({
        meeting: this
      });
    } else if (e && this._attendees.get(e.attendee.id)) {
      await this._attendees.tryRemoveAndUpdate(e.attendee.id);
    }
  }

  /** @internal */
  public async processAttendeeHandLowered(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendeeSetting) {
      await this._subscribedView.get(e.attendeeSetting.attendeeId)?.setHandLowered();
      if (e.attendeeSetting.attendeeId == this._localAttendee.id) {
        await this._localAttendee.setHandLowered();
      }
    }
  }

  /** @internal */
  public async processAttendeeSettingAdded(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendeeSetting) {
      await this._subscribedView.get(e.attendeeSetting.attendeeId)?.processSettingAdded(e.attendeeSetting);
      if (e.attendeeSetting.attendeeId == this._localAttendee.id) {
        await this._localAttendee.processSettingAdded(e.attendeeSetting);
      }
    }
  }

    /** @internal */
    public async processAttendeeSettingRemoved(e: ControlMeetingAttendeeEvent): Promise<void> {
      if (e.attendeeSetting) {
        await this._subscribedView.get(e.attendeeSetting.attendeeId)?.processSettingRemoved(e.attendeeSetting);
        if (e.attendeeSetting.attendeeId == this._localAttendee.id) {
          await this._localAttendee.processSettingRemoved(e.attendeeSetting);
        }
      }
    }

  /** @internal */
  public async processAttendeeHandRaised(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendeeSetting) {
      await this._subscribedView.get(e.attendeeSetting.attendeeId)?.setHandRaised();
      if (e.attendeeSetting.attendeeId == this._localAttendee.id) {
        await this._localAttendee.setHandRaised();
      }
    }
  }

  private async processAttendeeJoined(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (this._attendees.statusFilter != "ACTIVE") return;
    if (e.attendeeNotification) {
      this._attendees.totalCount = e.attendeeNotification.attendeeCount;
    }
    if (e.attendee) {
      let joinedAttendee = this._subscribedView.get(e.attendee.id);
      if (!joinedAttendee) {
        joinedAttendee = new Attendee({
          controlConnection: this._controlConnection,
          isLocal: this._localAttendee.id == e.attendee.id,
          meeting: Reactive.wrap(this),
          model: e.attendee,
        });
        this._subscribedView.tryAdd(joinedAttendee);
      } else {
        joinedAttendee.model = e.attendee;
      }

      // only add them to the visible attendee list if there is room on the current page
      if (this._attendees.count < this._attendees.pageSize) {
        this._attendees.tryAdd(joinedAttendee);
      }

      await this._attendeeJoined.dispatch({
        meeting: this,
        attendee: joinedAttendee
      });
    }
  }

  private async processAttendeeKicked(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e && e.attendee?.id == this._localAttendee.id) {
      await this.leave();
      await this._kicked.dispatch({
        meeting: this
      });
    }
  }

  private async processAttendeeLeft(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendeeNotification) {
      this._attendees.totalCount = e.attendeeNotification.attendeeCount;
    }
    if (e.attendee) {
      this._subscribedView.tryRemove(e.attendee.id);
      const leftAttendee = this._attendees.get(e.attendee.id);
      if (leftAttendee && await this._attendees.tryRemoveAndUpdate(leftAttendee.id)) {
        await this._attendeeLeft.dispatch({
          meeting: this,
          attendee: leftAttendee,
        });
      }
    }
  }

  private async processAttendeeNoiseSuppressed(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processSuppressNoise();
      if (this._localAttendee.id == e.attendee.id) {
        await this._localAttendee.processSuppressNoise();
      }
    }
  }

  private async processAttendeeNoiseUnsuppressed(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processUnsuppressNoise();
      if (this._localAttendee.id == e.attendee.id) {
        await this._localAttendee.processUnsuppressNoise();
      }
    }
  }

  /** @internal */
  public async processAttendeePausedUpdated(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee) {
      const attendee = this._subscribedView.get(e.attendee.id);
      if (attendee) {
        await attendee.processPausedUpdated(e.attendee);
      }
      if (e.attendee.id == this._localAttendee.id) {
        await this._localAttendee.processPausedUpdated(e.attendee);
      }
    }
  }

  private async processLocalPermissionUpdate() {
    if (this.canReceiveMedia) await this._edgeManager?.start();
    else await this._edgeManager?.stop("Permission to receive media has been removed.");
    if (this.localDisplayMedia && this.canSendDisplayMedia) await this._originManagers.get("display")?.start();
    else await this._originManagers.get("display")?.stop("Permission to send display media has been removed.");
    if (this.localUserMedia && this.canSendUserMedia) await this._originManagers.get("user")?.start();
    else await this._originManagers.get("user")?.stop("Permission to send user media has been removed.");
  }

  /** @internal */
  public async processAttendeePermissionAdded(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendeePermission &&
      e.attendeePermission.attendeePermission.attendeeId &&
      e.attendeePermission.attendeePermission.permissionId) {
      const permission = e.attendeePermission;
      const attendee = this._subscribedView.get(permission.attendeePermission.attendeeId);
      await attendee.processPermissionAdded(permission.permissionName as PermissionName);
      if (this._localAttendee.id == permission.attendeePermission.attendeeId) {
        await this._localAttendee.processPermissionAdded(permission.permissionName as PermissionName);
        await this.processLocalPermissionUpdate();
      }
    }
  }

  /** @internal */
  public async processAttendeePermissionRemoved(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendeePermission &&
      e.attendeePermission.attendeePermission.attendeeId &&
      e.attendeePermission.permissionName &&
      e.attendeePermission.permissionType) {
      const permission = e.attendeePermission;
      const attendee = this._subscribedView.get(permission.attendeePermission.attendeeId);
      if (attendee) {
        await attendee.processPermissionRemoved(permission.permissionName as PermissionName);
      }
      if (this._localAttendee.id == permission.attendeePermission.attendeeId) {
        await this._localAttendee.processPermissionRemoved(permission.permissionName as PermissionName);
        await this.processLocalPermissionUpdate();
      }
    }
  }

  /** @internal */
  public async processAttendeePinned(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee) {
      this._subscribedView.pinAttendee(e.attendee);
    }
  }

  /** @internal */
  public async processAttendeeQualityEdgeUpdated(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee) {
      const attendee = this._subscribedView.get(e.attendee.id);
      if (attendee) {
        await attendee.processQualityEdgeUpdate(e.attendee);
      }
      if (e.attendee.id == this._localAttendee.id) {
        await this._localAttendee.processQualityEdgeUpdate(e.attendee);
      }
    }
  }

  /** @internal */
  public async processAttendeeQualityOriginUpdated(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee) {
      const attendee = this._subscribedView.get(e.attendee.id);
      if (attendee) {
        await attendee.processQualityOriginUpdate(e.attendee);
      }
      if (e.attendee.id == this._localAttendee.id) {
        await this._localAttendee.processQualityOriginUpdate(e.attendee);
      }
    }
  }

  /** @internal */
  public async processAttendeeUnpinned(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee) {
      this._subscribedView.unpinAttendee(e.attendee.id);
    }
  }

  /** @internal */
  public async processAttendeeUpdated(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee) {
      const attendee = this._subscribedView.get(e.attendee.id);
      if (attendee) {
        await attendee.processUpdate(e.attendee);
      }
      if (e.attendee.id == this._localAttendee.id) {
        await this._localAttendee.processUpdate(e.attendee);
      }
    }
    if (e.attendeeNotification && e.attendeeNotification.attendeeCount) {
      this._attendees.totalCount = e.attendeeNotification.attendeeCount;
    }
  }

  /** @internal */
  public async processAttendeeVideoMuted(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processMuteVideo();
      if (this._localAttendee.id == e.attendee.id) {
        await this._localAttendee.processMuteVideo();
        this.localUserMedia?.muteVideoInternal();
      }
    }
  }

  /** @internal */
  public async processAttendeeVideoUnmuted(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processUnmuteVideo();
      if (this._localAttendee.id == e.attendee.id) {
        await this._localAttendee.processUnmuteVideo();
        this.localUserMedia?.unmuteVideoInternal();
      }
    }
  }

  /** @internal */
  public async processAttendeeAudioUnmuteDisabled(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processDisableAudioUnmute();
      if (e.attendee.id == this._localAttendee.id) {
        await this._localAttendee.processDisableAudioUnmute();
      }
    }
  }

  /** @internal */
  public async processAttendeeAudioUnmuteEnabled(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processEnableAudioUnmute();
      if (e.attendee.id == this._localAttendee.id) {
        await this._localAttendee.processEnableAudioUnmute();
      }
    }
  }

  /** @internal */
  public async processAttendeeVideoUnmuteDisabled(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processDisableVideoUnmute();
      if (e.attendee.id == this._localAttendee.id) {
        await this._localAttendee.processDisableVideoUnmute();
      }
    }
  }

  /** @internal */
  public async processAttendeeVideoUnmuteEnabled(e: ControlMeetingAttendeeEvent): Promise<void> {
    if (e.attendee.id) {
      await this._subscribedView.get(e.attendee.id)?.processEnableVideoUnmute();
      if (e.attendee.id == this._localAttendee.id) {
        await this._localAttendee.processEnableVideoUnmute();
      }
    }
  }

  private async processMeetingAudioMuted(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) {
      await this._localAttendee.processMuteAudio();
      this.localUserMedia?.muteAudioInternal();
    }
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processMuteAudio();
    });
  }

  private async processMeetingAudioUnmuted(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) {
      await this._localAttendee.processUnmuteAudio();
      this.localUserMedia?.unmuteAudioInternal();
    }
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processUnmuteAudio();
    });
  }

  private async processMeetingAudioUnmuteDisabled(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) await this._localAttendee.processDisableAudioUnmute();
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processDisableAudioUnmute()
    });
  }

  private async processMeetingAudioUnmuteEnabled(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) await this._localAttendee.processEnableAudioUnmute();
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processEnableAudioUnmute();
    });
  }

  private async processMeetingChatDisabled(e: ControlMeetingEvent): Promise<void> {
    this._model.allowChat = e.meeting.allowChat;
    this.stopChatInternal();
  }

  private async processMeetingChatEnabled(e: ControlMeetingEvent): Promise<void> {
    this._model.allowChat = e.meeting.allowChat;
    await this.startChatInternal();
  }

  private async processMeetingNoiseSuppressed(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) await this._localAttendee.processSuppressNoise();
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processSuppressNoise();
    });
  }

  private async processMeetingNoiseUnsuppressed(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) await this._localAttendee.processUnsuppressNoise();
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processUnsuppressNoise();
    });
  }

  private async processMeetingRecordingDisabled(e: ControlMeetingEvent): Promise<void> {
    this._model.allowRecording = e.meeting.allowRecording;
  }

  private async processMeetingRecordingEnabled(e: ControlMeetingEvent): Promise<void> {
    this._model.allowRecording = e.meeting.allowRecording;
  }

  private async processMeetingRecordingStarted(e: ControlMeetingEvent): Promise<void> {
    this._model.isRecording = e.meeting["isRecording"] != undefined;
    await this._recordingStarted.dispatch({ meeting: this });
  }

  private async processMeetingRecordingStopped(e: ControlMeetingEvent): Promise<void> {
    this._model.isRecording = e.meeting["isRecording"] != undefined;
    await this._recordingStopped.dispatch({ meeting: this });
  }

  private async processMeetingVideoMuted(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) {
      await this._localAttendee.processMuteVideo();
      this.localUserMedia?.muteVideoInternal();
    }
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processMuteVideo();
    });
  }

  private async processMeetingVideoUnmuted(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) {
      await this._localAttendee.processUnmuteVideo();
      this.localUserMedia?.unmuteVideoInternal();
    }
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processUnmuteVideo();
    });
  }

  private async processMeetingVideoUnmuteDisabled(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) await this._localAttendee.processDisableVideoUnmute();
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processDisableVideoUnmute();
    });
  }

  private async processMeetingVideoUnmuteEnabled(e: ControlMeetingEvent): Promise<void> {
    const exclusions = new Map(e.exclusions.map(e => [e, e]));
    if (!exclusions.has(this._localAttendee.id)) await this._localAttendee.processEnableVideoUnmute();
    this._subscribedView.forEach(async (a) => {
      if (!exclusions.has(a.id)) await a.processEnableVideoUnmute();
    });
  }

  /** @internal */
  public async processMeetingUpdated(e: ControlMeetingEvent): Promise<any> {
    const allowAudioUnmute = e.meeting.allowUnmuteAudioOnJoin;
    if (allowAudioUnmute === true || allowAudioUnmute === false) {
      if (allowAudioUnmute) {
        await this._audioUnmuteEnabled.dispatch({
          meeting: this
        });
      } else {
        await this._audioUnmuteDisabled.dispatch({
          meeting: this
        });
      }
    }

    const allowVideoUnmute = e.meeting.allowUnmuteVideoOnJoin;
    if (allowVideoUnmute === true || allowVideoUnmute === false) {
      if (allowVideoUnmute) {
        await this._videoUnmuteEnabled.dispatch({
          meeting: this
        });
      } else {
        await this._videoUnmuteDisabled.dispatch({
          meeting: this
        });
      }
    }

    this._model.muteAudioOnJoin = e.meeting["muteAudioOnJoin"] ?? false;
    this._model.muteVideoOnJoin = e.meeting["muteVideoOnJoin"] ?? false;
    this._model.allowUnmuteAudioOnJoin = e.meeting["allowUnmuteAudioOnJoin"] ?? false;
    this._model.allowUnmuteVideoOnJoin = e.meeting["allowUnmuteVideoOnJoin"] ?? false;
    this._model.suppressNoiseOnJoin = e.meeting["suppressNoiseOnJoin"] ?? false;
  }

  private async processSubscribedAttendees(e: ControlMeetingAttendeeEvent): Promise<void> {
    this._subscribedView.processResponse(e.attendees);
  }

  private async validateUserMedia(media: LocalMedia, type: MediaType): Promise<void> {
    if (!media || !this._model) return;

    if (type === "display") {
      await media.videoTrack?.setFrameRate(true, this._model.maxVideoFramerateDisplay);
      await media.videoTrack?.setFrameSize(true, { height: this._model.maxVideoHeightDisplay, width: this._model.maxVideoWidthDisplay });
    }
    
    if (this._joinOptions.advancedOptions.restartVideoTrackIos && Utility.isMobileSafari() && type === "user" && media.videoTrack.isStarted) {
      // iOS requires a video track restart for framerate / framesize changes to take effect
      // however, there are other side effects seen when routing through a canvas, ex: blur
      // thus, this feature will be toggleable with the advanced join option
      await media.videoTrack?.reload();
    }
  }

  //#endregion
}

function initializeJoinOptions(options: JoinOptions): JoinOptions {
  // sanitize input
  options ??= {};
  options.advancedOptions ??= {};
  options.iceOptions ??= {};
  options.localDisplayOptions ??= {};
  options.localUserOptions ??= {};
  options.onLobby ??= () => { };
  options.onProgress ??= () => { };
  options.turnOptions ??= {};

  if (Utility.isNullOrUndefined(options.advancedOptions.restartVideoTrackIos)) options.advancedOptions.restartVideoTrackIos = false;
  if (Utility.isNullOrUndefined(options.iceOptions.restartEnabled)) options.iceOptions.restartEnabled = false;
  if (Utility.isNullOrUndefined(options.linkLocalAndRemoteMedia)) options.linkLocalAndRemoteMedia = true;
  if (Utility.isNullOrUndefined(options.localDisplayOptions.degradationPreference)) options.localDisplayOptions.degradationPreference = "maintain-resolution";
  if (Utility.isNullOrUndefined(options.localLoopbackAudio)) options.localLoopbackAudio = false;
  if (Utility.isNullOrUndefined(options.localLoopbackVideo)) options.localLoopbackVideo = false;
  if (Utility.isNullOrUndefined(options.localUserOptions.degradationPreference)) options.localUserOptions.degradationPreference = "maintain-framerate";
  if (Utility.isNullOrUndefined(options.receiveMedia)) options.receiveMedia = true;
  if (Utility.isNullOrUndefined(options.remoteCompatibilityMode)) options.remoteCompatibilityMode = false;
  if (Utility.isNullOrUndefined(options.remoteDiscardAudio)) options.remoteDiscardAudio = false;
  if (Utility.isNullOrUndefined(options.remoteDiscardVideo)) options.remoteDiscardVideo = false;
  //if (Utility.isNullOrUndefined(options.remotePixelFeedback)) options.remotePixelFeedback = false;
  if (Utility.isNullOrUndefined(options.sendUserMedia)) options.sendUserMedia = true;
  if (Utility.isNullOrUndefined(options.turnOptions.enabled)) options.turnOptions.enabled = true;
  if (Utility.isNullOrUndefined(options.turnOptions.required)) options.turnOptions.required = false;
  if (Utility.isNullOrUndefined(options.turnOptions.tcpAllowed)) options.turnOptions.tcpAllowed = false;
  if (Utility.isNullOrUndefined(options.turnOptions.tlsAllowed)) options.turnOptions.tlsAllowed = true;
  if (Utility.isNullOrUndefined(options.turnOptions.udpAllowed)) options.turnOptions.udpAllowed = true;
  if (Utility.isNullOrUndefined(options.useAttendeeList)) options.useAttendeeList = true;
  if (Utility.isNullOrUndefined(options.useCamera)) options.useCamera = true;
  if (Utility.isNullOrUndefined(options.useChat)) options.useChat = true;
  if (Utility.isNullOrUndefined(options.useMicrophone)) options.useMicrophone = true;
  if (Utility.isNullOrUndefined(options.useRemoteMedia)) options.useRemoteMedia = true;
  if (Utility.isNullOrUndefined(options.useScreenShare)) options.useScreenShare = true;
  return options;
}
