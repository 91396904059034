import Account from "./models/Account";
import AccountCreateRequest from "./account/AccountCreateRequest";
import AccountCreateResponse from "./account/AccountCreateResponse";
import AccountUpdateRequest from "./account/AccountUpdateRequest";
import AccountUpdateResponse from "./account/AccountUpdateResponse";
import ApiKey from "./models/ApiKey";
import ApiKeyCreateRequest from "./apiKey/ApiKeyCreateRequest";
import ApiKeyCreateResponse from "./apiKey/ApiKeyCreateResponse";
import ApiKeyUpdateRequest from "./apiKey/ApiKeyUpdateRequest";
import ApiKeyUpdateResponse from "./apiKey/ApiKeyUpdateResponse";
import ApiCollectionResponse from "./models/ApiCollectionResponse";
import ApiResponse from "./models/ApiResponse";
import Attendee from "../Attendee";
import AttendeePermission from "./models/AttendeePermission";
import AttendeePermissionCreateByNameRequest from "./attendeePermission/AttendeePermissionCreateByNameRequest";
import AttendeePermissionCreateRequest from "./attendeePermission/AttendeePermissionCreateRequest";
import AttendeePermissionCreateResponse from "./attendeePermission/AttendeePermissionCreateResponse";
import AttendeePermissionUpdateRequest from "./attendeePermission/AttendeePermissionUpdateRequest";
import AttendeePermissionUpdateResponse from "./attendeePermission/AttendeePermissionUpdateResponse";
import AttendeeCreateRequest from "./attendee/AttendeeCreateRequest";
import AttendeeCreateResponse from "./attendee/AttendeeCreateResponse";
import AttendeeListRequest from "./attendee/AttendeeListRequest";
import AttendeeRole from "../models/AttendeeRole";
import BaseListRequest from "./models/BaseListRequest";
import ChatChannelAttendee from "./models/ChatChannelAttendee";
import ChatChannel from "./models/ChatChannel";
import ChatMessageReaction from "./models/ChatMessageReaction";
import ChatMessageListRequest from "./chatMessage/ChatMessageListRequest";
import ChatMessage from "./models/ChatMessage";
import ClientInit from "./models/ClientInit";
import ClusterCreateRequest from "./cluster/ClusterCreateRequest";
import ClusterCreateResponse from "./cluster/ClusterCreateResponse";
import Cluster from "./models/Cluster";
import ClusterLogCreateRequest from "./clusterLog/ClusterLogCreateRequest";
import ClusterLogCreateResponse from "./clusterLog/ClusterLogCreateResponse";
import ClusterSetting from "./models/ClusterSetting";
import ClusterSettingCreateRequest from "./clusterSetting/ClusterSettingCreateRequest";
import ClusterSettingCreateResponse from "./clusterSetting/ClusterSettingCreateResponse";
import ClusterSettingUpdateRequest from "./clusterSetting/ClusterSettingUpdateRequest";
import Country from "./models/Country";
import CountryCreateRequest from "./country/CountryCreateRequest";
import CountryCreateResponse from "./country/CountryCreateResponse";
import DeviceCreateRequest from "./device/DeviceCreateRequest";
import DeviceCreateResponse from "./device/DeviceCreateResponse";
import Device from "./models/Device";
import DeviceListRequest from "./device/DeviceListRequest";
import FileCreateRequest from "./file/FileCreateRequest";
import FileCreateResponse from "./file/FileCreateResponse";
import Guard from "../core/Guard";
import HttpClient from "../core/HttpClient";
import MeetingInviteCreateRequest from "./meetingInvite/MeetingInviteCreateRequest";
import MeetingInviteCreateResponse from "./meetingInvite/MeetingInviteCreateResponse";
import MeetingInvite from "./models/MeetingInvite";
import MeetingPlanCreateRequest from "./meetingPlan/MeetingPlanCreateRequest";
import MeetingPlanCreateResponse from "./meetingPlan/MeetingPlanCreateResponse";
import MeetingPlan from "./models/MeetingPlan";
import MeetingPlanServerCreateRequest from "./meetingPlanServer/MeetingPlanServerCreateRequest";
import MeetingPlanServerCreateResponse from "./meetingPlanServer/MeetingPlanServerCreateResponse";
import MeetingPlanServer from "./models/MeetingPlanServer";
import MeetingPlanSlotCreateRequest from "./meetingPlanSlot/MeetingPlanSlotCreateRequest";
import MeetingPlanSlotCreateResponse from "./meetingPlanSlot/MeetingPlanSlotCreateResponse";
import MeetingPlanSlot from "./models/MeetingPlanSlot";
import MeetingRecordingListRequest from "./meetingRecording/MeetingRecordingListRequest";
import MeetingRecording from "./models/MeetingRecording";
import MeetingCreateRequest from "./meeting/MeetingCreateRequest";
import MeetingCreateResponse from "./meeting/MeetingCreateResponse";
import Meeting from "./models/Meeting";
import MeetingListRequest from "./meeting/MeetingListRequest";
import MeetingSettingCreateRequest from "./meetingSetting/MeetingSettingCreateRequest";
import MeetingSettingCreateResponse from "./meetingSetting/MeetingSettingCreateResponse";
import MeetingSetting from "./models/MeetingSetting";
import PermissionCreateRequest from "./permission/PermissionCreateRequest";
import PermissionCreateResponse from "./permission/PermissionCreateResponse";
import Permission from "./models/Permission";
import PlannedMeetingCreateRequest from "./meeting/PlannedMeetingCreateRequest";
import PlanTemplateCreateRequest from "./planTemplate/PlanTemplateCreateRequest";
import PlanTemplateCreateResponse from "./planTemplate/PlanTemplateCreateResponse";
import PlanTemplate from "./models/PlanTemplate";
import RoomCreateRequest from "./room/RoomCreateRequest";
import RoomCreateResponse from "./room/RoomCreateResponse";
import Room from "./models/Room";
import RoomInfo from "./room/RoomInfo";
import RoomListRequest from "./room/RoomListRequest";
import RoomScheduleCreateRequest from "./roomSchedule/RoomScheduleCreateRequest";
import RoomScheduleCreateResponse from "./roomSchedule/RoomScheduleCreateResponse";
import RoomSchedule from "./models/RoomSchedule";
import RoomScheduleListRequest from "./roomSchedule/RoomScheduleListRequest";
import RoomSipInboundNumberCreateRequest from "./roomSipInboundNumber/RoomSipInboundNumberCreateRequest";
import RoomSipInboundNumberCreateResponse from "./roomSipInboundNumber/RoomSipInboundNumberCreateResponse";
import RoomSipInboundNumber from "./models/RoomSipInboundNumber";
import RoomSipInboundNumberListRequest from "./roomSipInboundNumber/RoomSipInboundNumberListRequest";
import RoomUserAccountCreateRequest from "./roomUserAccount/RoomUserAccountCreateRequest";
import RoomUserAccountCreateResponse from "./roomUserAccount/RoomUserAccountCreateResponse";
import RoomUserAccountListRequest from "./roomUserAccount/RoomUserAccountListRequest";
import RoomUserAccount from "./models/RoomUserAccount";
import ServerCreateRequest from "./server/ServerCreateRequest";
import ServerCreateResponse from "./server/ServerCreateResponse";
import Server from "./models/Server";
import ServerSetting from "./models/ServerSetting";
import ServerSettingCreateRequest from "./serverSetting/ServerSettingCreateRequest";
import ServerSettingCreateResponse from "./serverSetting/ServerSettingCreateResponse";
import SipInboundNumber from "./models/SipInboundNumber";
import SipInboundNumberCreateRequest from "./sipInboundNumber/SipInboundNumberCreateRequest";
import SipInboundNumberCreateResponse from "./sipInboundNumber/SipInboundNumberCreateResponse";
import SipInboundNumberListRequest from "./sipInboundNumber/SipInboundNumberListRequest";
import SipRequest from "./models/SipRequest";
import StorageRequest from "./models/StorageRequest";
import TenantCreateRequest from "./tenant/TenantCreateRequest";
import TenantCreateResponse from "./tenant/TenantCreateResponse";
import Tenant from "./models/Tenant";
import TenantSetting from "./models/TenantSetting";
import TenantSettingCreateRequest from "./tenantSetting/TenantSettingCreateRequest";
import TenantSettingCreateResponse from "./tenantSetting/TenantSettingCreateResponse";
import TenantUserAccountCreateRequest from "./tenantUserAccount/TenantUserAccountCreateRequest";
import TenantUserAccountCreateResponse from "./tenantUserAccount/TenantUserAccountCreateResponse";
import TenantUserAccount from "./models/TenantUserAccount";
import UserAccountCreateRequest from "./userAccount/UserAccountCreateRequest";
import UserAccountCreateResponse from "./userAccount/UserAccountCreateResponse";
import UserAccount from "./models/UserAccount";
import UserAccountPermission from "./models/UserAccountPermission";
import Utility from "../core/Utility";
import WebhookCreateRequest from "./webhook/WebhookCreateRequest";
import WebhookCreateResponse from "./webhook/WebhookCreateResponse";
import WebhookListRequest from "./webhook/WebhookListRequest";
import Webhook from "./models/Webhook";
import { ApiKeyNameLength, ApiKeySecretLength, ApiKeyTypeLength, ApiKeyValueLength } from "../core/validation/ApiKey";
import { AttendeeClientTypeLength, AttendeeDisplayNameLength, AttendeeSdkVersionLength, AttendeeSipConnectorIdLength, AttendeeSourceLanguageLength } from "../core/validation/Attendee";
import { ClusterAdminApiKeyLength, ClusterAdminApiUrlLength, ClusterKeyLength, ClusterMeetingUrlLength, ClusterNameLength, ClusterSecretLength, ClusterSipSecretLength, ClusterSipUrlLength } from "../core/validation/Cluster";
import { DeviceIdentifierLength, DeviceIpNumberLength, DevicePlatformLength } from "../core/validation/Device";
import { MeetingInviteEmailLength } from "../core/validation/MeetingInvite";
import { MeetingSettingValueLength } from "../core/validation/MeetingSetting";
import { PlanTemplateNameLength } from "../core/validation/PlanTemplate";
import { RoomKeyLength } from "../core/validation/Room";
import { RoomScheduleRecurrenceLength } from "../core/validation/RoomSchedule";
import { ServerNameLength } from "../core/validation/Server";
import { TenantBannerLength, TenantImageLength, TenantNameLength, TenantUrlLength } from "../core/validation/Tenant";
import { ClusterSettingNameLength, ClusterSettingTypeLength, ClusterSettingValueLength } from "../core/validation/ClusterSetting";
import { ServerSettingNameLength, ServerSettingValueLength } from "../core/validation/ServerSetting";
import { TenantSettingNameLength, TenantSettingValueLength } from "../core/validation/TenantSetting";
import { UserAccountAvatarUrlLength, UserAccountDisplayNameLength, UserAccountUserNameLength } from "../core/validation/UserAccount";
import { WebhookKeyLength, WebhookUrlLength } from "../core/validation/Webhook";
import AttendeeChatChannelListRequest from "./chatChannel/AttendeeChatMessageListRequest";

export default class Client {
  private readonly _accountsPath = "/accounts";
  private readonly _apiKeysPath = "/apikeys";
  private readonly _attendeePermissionsPath = "/attendeepermissions";
  private readonly _attendeesPath = "/attendees";
  private readonly _chatChannelAttendeesPath = "/chatchannelattendees";
  private readonly _chatChannelsPath = "/chatchannels";
  private readonly _chatMessageReactionsPath = "/chatmessagereactions";
  private readonly _chatMessagesPath = "/chatmessages";
  private readonly _clustersPath = "/clusters";
  private readonly _clusterLogsPath = "/clusterlogs";
  private readonly _clusterSettingsPath = "/clusters/settings";
  private readonly _countiresPath = "/countries";
  private readonly _devicesPath = "/devices";
  private readonly _filesPath = "/files";
  private readonly _httpClient: HttpClient;
  private readonly _init: ClientInit;
  private readonly _meetingInvitesPath = "/meetinginvites";
  private readonly _meetingPlansPath = "/meetingplans";
  private readonly _meetingPlanServersPath = "/meetingplanservers";
  private readonly _meetingPlanSlotsPath = "/meetingplanslots";
  private readonly _meetingRecordingsPath = "/meetingrecordings";
  private readonly _meetingsPath = "/meetings";
  private readonly _meetingSettingsPath = "/meetingsettings";
  private readonly _permissionsPath = "/permissions";
  private readonly _planTemplatesPath = "/plantemplates";
  private readonly _roomsPath = "/rooms";
  private readonly _roomSchedulesPath = "/roomschedules";
  private readonly _roomSipInboundNumbersPath = "/roomsipinboundnumbers";
  private readonly _roomUserAccountsPath = "/roomusers";
  private readonly _serversPath = "/servers";
  private readonly _serverSettingsPath = "/serversettings";
  private readonly _sipInboundNumbersPath = "/sipinboundnumbers";
  private readonly _tenantsPath = "/tenants";
  private readonly _tenantSettingsPath = "/tenantsettings";
  private readonly _tenantUserAccountsPath = "/tenantusers";
  private readonly _userAccountsPath = "/users";
  private readonly _webhooksPath = "/webhooks";

  public get maxRetries(): number { return this._httpClient.maxRetries; }
  public set maxRetries(value: number) { this._httpClient.maxRetries = value; }
  public get requestTimeout(): number { return this._httpClient.requestTimeout; }
  public set requestTimeout(value: number) { this._httpClient.requestTimeout = value; }

  public constructor(init: ClientInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.identity, "init.identity");
    this._init = init;
    this._httpClient = HttpClient.withTokenFactory(async () => {
      const token = await this._init.identity.token();
      return {
        baseUrl: new URL(token.clusterAssignment.clusterUrl).origin,
        value: token.token
      };
    });
  }

  public async admitAttendeeToMeeting(attendeeId: string, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    return await this._httpClient.post(`${this._attendeesPath}/${attendeeId}/admit`, null, abortSignal);
  }

  public async blockAttendeeFromLobby(attendeeId: string, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    return await this._httpClient.post(`${this._attendeesPath}/${attendeeId}/block-from-lobby`, null, abortSignal);
  }

  public async blockAttendeeFromRoom(attendeeId: string, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    return await this._httpClient.post(`${this._attendeesPath}/${attendeeId}/block-from-room`, null, abortSignal);
  }
  
  public async createAccount(account: AccountCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<AccountCreateResponse>> {
    Guard.isNotNullOrUndefined(account, "account");
    return await this._httpClient.post(`${this._accountsPath}`, account, abortSignal);
  }

  public async createApiKey(apiKey: ApiKeyCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<ApiKeyCreateResponse>> {
    Guard.isNotNullOrUndefined(apiKey, "apiKey");
    Guard.isLengthWithinBounds(apiKey.name, "name", ApiKeyNameLength.Max);
    Guard.isLengthWithinBounds(apiKey.secret, "secret", ApiKeySecretLength.Max);
    Guard.isLengthWithinBounds(apiKey.type, "type", ApiKeyTypeLength.Max);
    Guard.isLengthWithinBounds(apiKey.value, "value", ApiKeyValueLength.Max);
    return await this._httpClient.post(`${this._apiKeysPath}`, apiKey, abortSignal);
  }

  public async createAttendee(attendee: AttendeeCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<AttendeeCreateResponse>> {
    Guard.isNotNullOrUndefined(attendee, "attendee");
    Guard.isLengthWithinBounds(attendee.clientType, "clientType", AttendeeClientTypeLength.Max);
    Guard.isLengthWithinBounds(attendee.displayName, "displayName", AttendeeDisplayNameLength.Max);
    Guard.isLengthWithinBounds(attendee.sdkVersion, "sdkVersion", AttendeeSdkVersionLength.Max);
    Guard.isLengthWithinBounds(attendee.sipConnectorId, "sipConnectorId", AttendeeSipConnectorIdLength.Max);
    Guard.isLengthWithinBounds(attendee.sourceLanguage, "sourceLanguage", AttendeeSourceLanguageLength.Max);
    return await this._httpClient.post(`${this._attendeesPath}`, attendee, abortSignal);
  }

  public async createAttendeePermission(attendeePermission: AttendeePermissionCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<AttendeePermissionCreateResponse>> {
    Guard.isNotNullOrUndefined(attendeePermission, "attendeePermission");
    return await this._httpClient.post(`${this._attendeePermissionsPath}`, attendeePermission, abortSignal);
  }

  public async createAttendeePermissionByName(permissionName: AttendeePermissionCreateByNameRequest, abortSignal?: AbortSignal): Promise<ApiResponse<AttendeePermissionCreateResponse>> {
    Guard.isNotNullOrUndefined(permissionName, "permissionName");
    return await this._httpClient.post(`${this._attendeePermissionsPath}/permissionname`, permissionName, abortSignal);
  }

  public async createCluster(cluster: ClusterCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<ClusterCreateResponse>> {
    Guard.isNotNullOrUndefined(cluster, "cluster");
    Guard.isLengthWithinBounds(cluster.adminApiKey, "adminApiKey", ClusterAdminApiKeyLength.Max);
    Guard.isLengthWithinBounds(cluster.adminApiUrl, "adminApiUrl", ClusterAdminApiUrlLength.Max);
    Guard.isLengthWithinBounds(cluster.name, "name", ClusterNameLength.Max);
    Guard.isLengthWithinBounds(cluster.key, "key", ClusterKeyLength.Max);
    Guard.isLengthWithinBounds(cluster.secret, "secret", ClusterSecretLength.Max);
    Guard.isLengthWithinBounds(cluster.meetingServiceUrl, "meetingServiceUrl", ClusterMeetingUrlLength.Max);
    Guard.isLengthWithinBounds(cluster.sipUrl, "sipUrl", ClusterSipUrlLength.Max);
    Guard.isLengthWithinBounds(cluster.sipSecret, "sipSecret", ClusterSipSecretLength.Max);
    return await this._httpClient.post(`${this._clustersPath}`, cluster, abortSignal);
  }

  public async createClusterLog(clusterLog: ClusterLogCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<ClusterLogCreateResponse>> {
    Guard.isNotNullOrUndefined(clusterLog, "clusterLog");
    return await this._httpClient.post(`${this._clusterLogsPath}`, clusterLog, abortSignal);
  }

  public async createClusterSetting(clusterSetting: ClusterSettingCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<ClusterSettingCreateResponse>> {
    Guard.isNotNullOrUndefined(clusterSetting, "clusterSetting");
    Guard.isLengthWithinBounds(clusterSetting.name, "name", ClusterSettingNameLength.Max);
    Guard.isLengthWithinBounds(clusterSetting.type, "type", ClusterSettingTypeLength.Max);
    Guard.isLengthWithinBounds(clusterSetting.value, "value", ClusterSettingValueLength.Max);
    return await this._httpClient.post(`${this._clusterSettingsPath}`, clusterSetting, abortSignal);
  }
  
  public async createCountry(country: CountryCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<CountryCreateResponse>> {
    Guard.isNotNullOrUndefined(country, "country");
    return await this._httpClient.post(`${this._countiresPath}`, country, abortSignal);
  }

  public async createDevice(device: DeviceCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<DeviceCreateResponse>> {
    Guard.isNotNullOrUndefined(device, "device");
    Guard.isLengthWithinBounds(device.deviceIdentifier, "deviceIdentifier", DeviceIdentifierLength.Max);
    Guard.isLengthWithinBounds(device.deviceIPNumber, "deviceIPNumber", DeviceIpNumberLength.Max);
    Guard.isLengthWithinBounds(device.devicePlatform, "devicePlatform", DevicePlatformLength.Max);
    return await this._httpClient.post(`${this._devicesPath}`, device, abortSignal);
  }

  public async createFile(request: FileCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<FileCreateResponse>> {
    Guard.isNotNullOrUndefined(request, "request");
    Guard.isNotNullOrUndefined(request.file, "request.file");
    Guard.isNotNullOrUndefined(request.meetingId, "request.meetingId");
    const formData = new FormData();
    formData.append("file", request.file);
    return await this._httpClient.postForm(`${this._filesPath}/${request.meetingId}`, formData, abortSignal);
  }

  public async createMeeting(meeting: MeetingCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingCreateResponse>> {
    Guard.isNotNullOrUndefined(meeting, "meeting");
    return await this._httpClient.post(`${this._meetingsPath}`, meeting, abortSignal);
  }

  public async createMeetingInvite(meetingInvite: MeetingInviteCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingInviteCreateResponse>> {
    Guard.isNotNullOrUndefined(meetingInvite, "meetingInvite");
    Guard.isLengthWithinBounds(meetingInvite.emailAddress, "emailAddress", MeetingInviteEmailLength.Max);
    return await this._httpClient.post(`${this._meetingInvitesPath}`, meetingInvite, abortSignal);
  }

  public async createMeetingPlan(meetingPlan: MeetingPlanCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlanCreateResponse>> {
    Guard.isNotNullOrUndefined(meetingPlan, "meetingPlan");
    return await this._httpClient.post(`${this._meetingPlansPath}`, meetingPlan, abortSignal);
  }

  public async createMeetingPlanServer(meetingPlanServer: MeetingPlanServerCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlanServerCreateResponse>> {
    Guard.isNotNullOrUndefined(meetingPlanServer, "meetingPlanServer");
    return await this._httpClient.post(`${this._meetingPlanServersPath}`, meetingPlanServer, abortSignal);
  }

  public async createMeetingPlanSlot(meetingPlanSlot: MeetingPlanSlotCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlanSlotCreateResponse>> {
    Guard.isNotNullOrUndefined(meetingPlanSlot, "meetingPlanSlot");
    return await this._httpClient.post(`${this._meetingPlanSlotsPath}`, meetingPlanSlot, abortSignal);
  }

  public async createMeetingSetting(meetingSetting: MeetingSettingCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingSettingCreateResponse>> {
    Guard.isNotNullOrUndefined(meetingSetting, "meetingSetting");
    Guard.isLengthWithinBounds(meetingSetting.name, "name", MeetingSettingValueLength.Max);
    Guard.isLengthWithinBounds(meetingSetting.value, "value", MeetingSettingValueLength.Max);
    return await this._httpClient.post(`${this._meetingSettingsPath}`, meetingSetting, abortSignal);
  }

  public async createPermission(permission: PermissionCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<PermissionCreateResponse>> {
    Guard.isNotNullOrUndefined(permission, "permission");
    return await this._httpClient.post(`${this._permissionsPath}`, permission, abortSignal);
  }

  public async createPlannedMeeting(plannedMeeting: PlannedMeetingCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<string>> {
    Guard.isNotNullOrUndefined(plannedMeeting, "plannedMeeting");
    return await this._httpClient.post(`${this._meetingsPath}/planned`, plannedMeeting, abortSignal);
  }

  public async createPlanTemplate(planTemplate: PlanTemplateCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<PlanTemplateCreateResponse>> {
    Guard.isNotNullOrUndefined(planTemplate, "planTemplate");
    Guard.isLengthWithinBounds(planTemplate.name, "name", PlanTemplateNameLength.Max);
    return await this._httpClient.post(`${this._planTemplatesPath}`, planTemplate, abortSignal);
  }

  public async createRoom(room: RoomCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<RoomCreateResponse>> {
    Guard.isNotNullOrUndefined(room, "room");
    Guard.isLengthWithinBounds(room.key, "key", RoomKeyLength.Max, RoomKeyLength.Min);
    room.planType ??= "2TIER";
    room.roomType ??= "PERMANENT";
    room.meetingType ??= "CONFERENCE";
    return await this._httpClient.post(`${this._roomsPath}`, room, abortSignal);
  }

  public async createRoomSchedule(roomschedule: RoomScheduleCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<RoomScheduleCreateResponse>> {
    Guard.isNotNullOrUndefined(roomschedule, "roomschedule");
    Guard.isLengthWithinBounds(roomschedule.recurrence, "recurrence", RoomScheduleRecurrenceLength.Max);
    return await this._httpClient.post(`${this._roomSchedulesPath}`, roomschedule, abortSignal);
  }

  public async createRoomSipInboundNumber(roomSipInboundNumber: RoomSipInboundNumberCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<RoomSipInboundNumberCreateResponse>> {
    Guard.isNotNullOrUndefined(roomSipInboundNumber, "roomSipInboundNumber");
    return await this._httpClient.post(`${this._roomSipInboundNumbersPath}`, roomSipInboundNumber, abortSignal);
  }

  public async createRoomUserAccount(roomUserAccount: RoomUserAccountCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<RoomUserAccountCreateResponse>> {
    Guard.isNotNullOrUndefined(roomUserAccount, "roomUserAccount");
    return await this._httpClient.post(`${this._roomUserAccountsPath}`, roomUserAccount, abortSignal);
  }

  public async createServer(server: ServerCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<ServerCreateResponse>> {
    Guard.isNotNullOrUndefined(server, "server");
    Guard.isLengthWithinBounds(server.name, "name", ServerNameLength.Max);
    return await this._httpClient.post(`${this._serversPath}`, server, abortSignal);
  }

  public async createServerSetting(serverSetting: ServerSettingCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<ServerSettingCreateResponse>> {
    Guard.isNotNullOrUndefined(serverSetting, "serverSetting");
    Guard.isLengthWithinBounds(serverSetting.settingName, "settingName", ServerSettingNameLength.Max);
    Guard.isLengthWithinBounds(serverSetting.settingValue, "settingValue", ServerSettingValueLength.Max);
    return await this._httpClient.post(`${this._serverSettingsPath}`, serverSetting, abortSignal);
  }

  public async createSipInboundNumber(sipInboundNumber: SipInboundNumberCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<SipInboundNumberCreateResponse>> {
    Guard.isNotNullOrUndefined(sipInboundNumber, "sipInboundNumber");
    return await this._httpClient.post(`${this._sipInboundNumbersPath}`, sipInboundNumber, abortSignal);
  }

  public async createTenant(tenant: TenantCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<TenantCreateResponse>> {
    Guard.isNotNullOrUndefined(tenant, "tenant");
    Guard.isLengthWithinBounds(tenant.banner, "banner", TenantBannerLength.Max);
    Guard.isLengthWithinBounds(tenant.image, "image", TenantImageLength.Max);
    Guard.isLengthWithinBounds(tenant.name, "name", TenantNameLength.Max);
    Guard.isLengthWithinBounds(tenant.url, "url", TenantUrlLength.Max);
    return await this._httpClient.post(`${this._tenantsPath}`, tenant, abortSignal);
  }

  public async createTenantSetting(tenantSetting: TenantSettingCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<TenantSettingCreateResponse>> {
    Guard.isNotNullOrUndefined(tenantSetting, "tenantSetting");
    Guard.isLengthWithinBounds(tenantSetting.settingName, "settingName", TenantSettingNameLength.Max);
    Guard.isLengthWithinBounds(tenantSetting.settingValue, "settingValue", TenantSettingValueLength.Max);
    return await this._httpClient.post(`${this._tenantSettingsPath}`, tenantSetting, abortSignal);
  }

  public async createTenantUserAccount(tenantUserAccount: TenantUserAccountCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<TenantUserAccountCreateResponse>> {
    Guard.isNotNullOrUndefined(tenantUserAccount, "tenantUserAccount");
    return await this._httpClient.post(`${this._tenantUserAccountsPath}`, tenantUserAccount, abortSignal);
  }

  public async createUserAccount(userAccount: UserAccountCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<UserAccountCreateResponse>> {
    Guard.isNotNullOrUndefined(userAccount, "userAccount");
    Guard.isLengthWithinBounds(userAccount.avatarUrl, "avatarUrl", UserAccountAvatarUrlLength.Max);
    Guard.isLengthWithinBounds(userAccount.displayName, "displayName", UserAccountDisplayNameLength.Max);
    Guard.isLengthWithinBounds(userAccount.username, "username", UserAccountUserNameLength.Max);
    return await this._httpClient.post(`${this._userAccountsPath}`, userAccount, abortSignal);
  }

  public async createWebhook(webhook: WebhookCreateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<WebhookCreateResponse>> {
    Guard.isNotNullOrUndefined(webhook, "webhook");
    Guard.isLengthWithinBounds(webhook.key, "key", WebhookKeyLength.Max);
    Guard.isLengthWithinBounds(webhook.url, "url", WebhookUrlLength.Max);
    return await this._httpClient.post(`${this._webhooksPath}`, webhook, abortSignal);
  }
  
  public async deleteAccount(accountId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(accountId, "accountId");
    return await this._httpClient.delete(`${this._accountsPath}/${accountId}`, abortSignal);
  }

  public async deleteApiKey(apiKeyId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(apiKeyId, "apiKeyId");
    return await this._httpClient.delete(`${this._apiKeysPath}/${apiKeyId}`, abortSignal);
  }

  public async deleteAttendee(attendeeId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    return await this._httpClient.delete(`${this._attendeesPath}/${attendeeId}`, abortSignal);
  }

  public async deleteAttendeePermission(attendeePermissionId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(attendeePermissionId, "attendeePermissionId");
    return await this._httpClient.delete(`${this._attendeePermissionsPath}/${attendeePermissionId}`, abortSignal);
  }

  public async deleteAttendeePermissionByPermissionId(permissionId: string, attendeeId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(permissionId, "permissionId");
    return await this._httpClient.delete(`${this._attendeePermissionsPath}/permissionId?permissionId=${permissionId}&attendeeId=${attendeeId}`, abortSignal);
  }

  public async deleteAttendeePermissionByPermissionName(permissionName: string, permissionType: string, attendeeId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(permissionName, "permissionName");
    Guard.isNotNullOrUndefined(permissionType, "permissionType");
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    return await this._httpClient.delete(`${this._attendeePermissionsPath}/permissionname?permissionName=${permissionName}&permissionType=${permissionType}&attendeeId=${attendeeId}`, abortSignal);
  }

  public async deleteCluster(clusterId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(clusterId, "clusterId");
    return await this._httpClient.delete(`${this._clustersPath}/${clusterId}`, abortSignal);
  }
  
  public async deleteCountry(countryId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(countryId, "countryId");
    return await this._httpClient.delete(`${this._countiresPath}/${countryId}`, abortSignal);
  }

  public async deleteDevice(deviceId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(deviceId, "deviceId");
    return await this._httpClient.delete(`${this._devicesPath}/${deviceId}`, abortSignal);
  }

  public async deleteMeeting(meetingId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(meetingId, "meetingId");
    return await this._httpClient.delete(`${this._meetingsPath}/${meetingId}`, abortSignal);
  }

  public async deleteMeetingInvite(meetingInviteId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(meetingInviteId, "meetingInviteId");
    return await this._httpClient.delete(`${this._meetingInvitesPath}/${meetingInviteId}`, abortSignal);
  }

  public async deleteMeetingPlan(meetingPlanId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(meetingPlanId, "meetingPlanId");
    return await this._httpClient.delete(`${this._meetingPlansPath}/${meetingPlanId}`, abortSignal);
  }

  public async deleteMeetingPlanServer(meetingPlanServerId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(meetingPlanServerId, "meetingPlanServerId");
    return await this._httpClient.delete(`${this._meetingPlanServersPath}/${meetingPlanServerId}`, abortSignal);
  }

  public async deleteMeetingPlanSlot(meetingPlanSlotId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(meetingPlanSlotId, "meetingPlanSlotId");
    return await this._httpClient.delete(`${this._meetingPlanSlotsPath}/${meetingPlanSlotId}`, abortSignal);
  }

  public async deleteMeetingRecording(meetingRecordingId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(meetingRecordingId, "meetingRecordingId");
    return await this._httpClient.delete(`${this._meetingRecordingsPath}/${meetingRecordingId}`, abortSignal);
  }
  
  public async deleteMeetingSetting(meetingSettingId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(meetingSettingId, "meetingSettingId");
    return await this._httpClient.delete(`${this._meetingSettingsPath}/${meetingSettingId}`, abortSignal);
  }
  
  public async deletePermission(permissionId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(permissionId, "permissionId");
    return await this._httpClient.delete(`${this._permissionsPath}/${permissionId}`, abortSignal);
  }
  
  public async deletePlanTemplate(planTemplateId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(planTemplateId, "planTemplateId");
    return await this._httpClient.delete(`${this._planTemplatesPath}/${planTemplateId}`, abortSignal);
  }
  
  public async deleteRoom(roomId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(roomId, "roomId");
    return await this._httpClient.delete(`${this._roomsPath}/${roomId}`, abortSignal);
  }
  
  public async deleteRoomSchedule(roomScheduleId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(roomScheduleId, "roomScheduleId");
    return await this._httpClient.delete(`${this._roomSchedulesPath}/${roomScheduleId}`, abortSignal);
  }
  
  public async deleteRoomSipInboundNumber(roomSipInboundNumberId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(roomSipInboundNumberId, "roomSipInboundNumberId");
    return await this._httpClient.delete(`${this._roomSipInboundNumbersPath}/${roomSipInboundNumberId}`, abortSignal);
  }
  
  public async deleteRoomUserAccount(roomUserAccountId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(roomUserAccountId, "roomUserAccountId");
    return await this._httpClient.delete(`${this._roomUserAccountsPath}/${roomUserAccountId}`, abortSignal);
  }
  
  public async deleteServer(serverId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(serverId, "serverId");
    return await this._httpClient.delete(`${this._serversPath}/${serverId}`, abortSignal);
  }
  
  public async deleteServerSetting(serverSettingId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(serverSettingId, "serverSettingId");
    return await this._httpClient.delete(`${this._serverSettingsPath}/${serverSettingId}`, abortSignal);
  }
  
  public async deleteSipInboundNumber(sipInboundNumberId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(sipInboundNumberId, "sipInboundNumberId");
    return await this._httpClient.delete(`${this._sipInboundNumbersPath}/${sipInboundNumberId}`, abortSignal);
  }
  
  public async deleteTenant(tenantId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(tenantId, "tenantId");
    return await this._httpClient.delete(`${this._tenantsPath}/${tenantId}`, abortSignal);
  }
  
  public async deleteTenantSetting(tenantSettingId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(tenantSettingId, "tenantSettingId");
    return await this._httpClient.delete(`${this._tenantSettingsPath}/${tenantSettingId}`, abortSignal);
  }
  
  public async deleteTenantUserAccount(tenantUserAccountId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(tenantUserAccountId, "tenantUserAccountId");
    return await this._httpClient.delete(`${this._tenantUserAccountsPath}/${tenantUserAccountId}`, abortSignal);
  }
  
  public async deleteUserAccount(userAccountId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(userAccountId, "userAccountId");
    return await this._httpClient.delete(`${this._userAccountsPath}/${userAccountId}`, abortSignal);
  }
  
  public async deleteWebhook(webhookId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(webhookId, "webhookId");
    return await this._httpClient.delete(`${this._webhooksPath}/${webhookId}`, abortSignal);
  }
  
  public async disableSipByRoomId(roomId: string, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    return await this._httpClient.post("room/disable-sip", <SipRequest>{ roomId: roomId }, abortSignal);
  }

  public async disableStorageByRoomId(roomId: string, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    return await this._httpClient.post("room/disable-storage", <StorageRequest>{ roomId: roomId }, abortSignal);
  }

  public async enableSipByRoomId(roomId: string, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    return await this._httpClient.post("room/enable-sip", <SipRequest>{ roomId: roomId }, abortSignal);
  }
  
  public async enableStorageByRoomId(roomId: string, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    return await this._httpClient.post("room/enable-storage", <StorageRequest>{ roomId: roomId }, abortSignal);
  }

  public async getAccount(accountId: string, abortSignal?: AbortSignal): Promise<ApiResponse<Account>> {
    Guard.isNotNullOrUndefined(accountId, "accountId");
    return await this._httpClient.get(`${this._accountsPath}/${accountId}`, abortSignal);
  }

  public async getApiKey(apiKeyId: string, abortSignal?: AbortSignal): Promise<ApiResponse<ApiKey>> {
    Guard.isNotNullOrUndefined(apiKeyId, "apiKeyId");
    return await this._httpClient.get(`${this._apiKeysPath}/${apiKeyId}`, abortSignal);
  }

  public async getAttendee(attendeeId: string, abortSignal?: AbortSignal): Promise<ApiResponse<Attendee>> {
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    return await this._httpClient.get(`${this._attendeesPath}/${attendeeId}`, abortSignal);
  }

  public async getChatChannel(chatChannelId: string, abortSignal?: AbortSignal): Promise<ApiResponse<ChatChannel>> {
    Guard.isNotNullOrUndefined(chatChannelId, "chatChannelId");
    return await this._httpClient.get(`${this._chatChannelsPath}/${chatChannelId}`, abortSignal);
  }

  public async getChatChannelAttendee(chatChannelAttendeeId: string, abortSignal?: AbortSignal): Promise<ApiResponse<ChatChannelAttendee>> {
    Guard.isNotNullOrUndefined(chatChannelAttendeeId, "chatChannelAttendeeId");
    return await this._httpClient.get(`${this._chatChannelAttendeesPath}/${chatChannelAttendeeId}`, abortSignal);
  }

  public async getChatMessage(chatMessageId: string, abortSignal?: AbortSignal): Promise<ApiResponse<ChatMessage>> {
    Guard.isNotNullOrUndefined(chatMessageId, "chatMessageId");
    return await this._httpClient.get(`${this._chatMessagesPath}/${chatMessageId}`, abortSignal);
  }

  public async getCluster(clusterId: string, abortSignal?: AbortSignal): Promise<ApiResponse<Cluster>> {
    Guard.isNotNullOrUndefined(clusterId, "clusterId");
    return await this._httpClient.get(`${this._clustersPath}/${clusterId}`, abortSignal);
  }

  public async getClusterSetting(clusterId: string, settingName: string, abortSignal?: AbortSignal): Promise<ApiResponse<ClusterSetting>> {
    Guard.isNotNullOrUndefined(clusterId, "clusterId");
    Guard.isNotNullOrUndefined(settingName, "settingName");
    return await this._httpClient.get(`${this._clustersPath}/${clusterId}/settings/${settingName}`, abortSignal);
  }

  public async getFileData(meetingId: string, fileId: string, abortSignal?: AbortSignal): Promise<Blob> {
    Guard.isNotNullOrUndefined(meetingId, "meetingId");
    Guard.isNotNullOrUndefined(fileId, "fileId");
    return await this._httpClient.getBlob(`${this._filesPath}/${meetingId}/${fileId}`, abortSignal);
  }

  public async getFileDataUrl(meetingId: string, fileId: string, abortSignal?: AbortSignal): Promise<string> {
    return await Utility.blobToDataUrl(await this.getFileData(meetingId, fileId, abortSignal));
  }

  public async getMeeting(meetingId: string, abortSignal?: AbortSignal): Promise<ApiResponse<Meeting>> {
    Guard.isNotNullOrUndefined(meetingId, "meetingId");
    return await this._httpClient.get(`${this._meetingsPath}/${meetingId}`, abortSignal);
  }

  public async getMeetingInvite(meetingInviteId: string, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingInvite>> {
    Guard.isNotNullOrUndefined(meetingInviteId, "meetingInviteId");
    return await this._httpClient.get(`${this._meetingInvitesPath}/${meetingInviteId}`, abortSignal);
  }

  public async getMeetingPlan(meetingPlanId: string, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlan>> {
    Guard.isNotNullOrUndefined(meetingPlanId, "meetingPlanId");
    return await this._httpClient.get(`${this._meetingPlansPath}/${meetingPlanId}`, abortSignal);
  }

  public async getMeetingPlanServer(meetingPlanServerId: string, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlanServer>> {
    Guard.isNotNullOrUndefined(meetingPlanServerId, "meetingPlanServerId");
    return await this._httpClient.get(`${this._meetingPlanServersPath}/${meetingPlanServerId}`, abortSignal);
  }

  public async getMeetingPlanSlot(meetingPlanSlotId: string, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlanSlot>> {
    Guard.isNotNullOrUndefined(meetingPlanSlotId, "meetingPlanSlotId");
    return await this._httpClient.get(`${this._meetingPlanSlotsPath}/${meetingPlanSlotId}`, abortSignal);
  }

  public async getMeetingRecording(meetingRecordingId: string, recordingUrlTtl?: number, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingRecording>> {
    Guard.isNotNullOrUndefined(meetingRecordingId, "meetingRecordingId");
    const query = Utility.isNullOrUndefined(recordingUrlTtl) ? "" : `?recordingUrlTtl=${recordingUrlTtl}`;
    return await this._httpClient.get(`${this._meetingRecordingsPath}/${meetingRecordingId}${query}`, abortSignal);
  }

  public async getMeetingSetting(meetingSettingId: string, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingSetting>> {
    Guard.isNotNullOrUndefined(meetingSettingId, "meetingSettingId");
    return await this._httpClient.get(`${this._meetingSettingsPath}/${meetingSettingId}`, abortSignal);
  }

  public async getPlanTemplate(planTemplateId: string, abortSignal?: AbortSignal): Promise<ApiResponse<PlanTemplate>> {
    Guard.isNotNullOrUndefined(planTemplateId, "planTemplateId");
    return await this._httpClient.get(`${this._planTemplatesPath}/${planTemplateId}`, abortSignal);
  }

  public async getRoom(roomId: string, abortSignal?: AbortSignal): Promise<ApiResponse<Room>> {
    Guard.isNotNullOrUndefined(roomId, "roomId");
    return await this._httpClient.get(`${this._roomsPath}/${roomId}`, abortSignal);
  }

  public async getRoomInfoByKey(roomKey: string, abortSignal?: AbortSignal): Promise<ApiResponse<RoomInfo>> {
    Guard.isNotNullOrUndefined(roomKey, "roomKey");
    Guard.isLengthWithinBounds(roomKey, "roomkey", 20);
    return await this._httpClient.get(`${this._roomsPath}/${roomKey}/info`, abortSignal);
  }

  public async getRoomSipInboundNumber(roomSipInboundNumberId: string, abortSignal?: AbortSignal): Promise<ApiResponse<RoomSipInboundNumber>> {
    Guard.isNotNullOrUndefined(roomSipInboundNumberId, "roomSipInboundNumberId");
    return await this._httpClient.get(`${this._roomSipInboundNumbersPath}/${roomSipInboundNumberId}`, abortSignal);
  }

  public async getRoomUserAccount(roomUserAccountId: string, abortSignal?: AbortSignal): Promise<ApiResponse<RoomUserAccount>> {
    Guard.isNotNullOrUndefined(roomUserAccountId, "roomUserAccountId");
    return await this._httpClient.get(`${this._roomUserAccountsPath}/${roomUserAccountId}`, abortSignal);
  }

  public async getServer(serverId: string, abortSignal?: AbortSignal): Promise<ApiResponse<Server>> {
    Guard.isNotNullOrUndefined(serverId, "serverId");
    return await this._httpClient.get(`${this._serversPath}/${serverId}`, abortSignal);
  }

  public async getServerSetting(serverSettingId: string, abortSignal?: AbortSignal): Promise<ApiResponse<ServerSetting>> {
    Guard.isNotNullOrUndefined(serverSettingId, "serverSettingId");
    return await this._httpClient.get(`${this._serverSettingsPath}/${serverSettingId}`, abortSignal);
  }

  public async getTenant(tenantId: string, abortSignal?: AbortSignal): Promise<ApiResponse<Server>> {
    Guard.isNotNullOrUndefined(tenantId, "tenantId");
    return await this._httpClient.get(`${this._tenantsPath}/${tenantId}`, abortSignal);
  }

  public async getTenantSetting(tenantSettingId: string, abortSignal?: AbortSignal): Promise<ApiResponse<TenantSetting>> {
    Guard.isNotNullOrUndefined(tenantSettingId, "tenantSettingId");
    return await this._httpClient.get(`${this._tenantSettingsPath}/${tenantSettingId}`, abortSignal);
  }

  public async getUserAccount(userAccountId: string, abortSignal?: AbortSignal): Promise<ApiResponse<UserAccount>> {
    Guard.isNotNullOrUndefined(userAccountId, "userAccountId");
    return await this._httpClient.get(`${this._userAccountsPath}/${userAccountId}`, abortSignal);
  }

  public async getUserAccountByUserName(userName: string, abortSignal?: AbortSignal): Promise<ApiResponse<UserAccount>> {
    Guard.isNotNullOrUndefined(userName, "userName");
    return await this._httpClient.post(`${this._userAccountsPath}/username`, { value: userName }, abortSignal);
  }
  
  public async kickAttendee(attendeeId: string, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    return await this._httpClient.post(`${this._attendeesPath}/${attendeeId}/kick`, null, abortSignal);
  }
  
  public async listAccounts(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Account>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._accountsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listApiKeys(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ApiKey>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._apiKeysPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listAttendeePermissionByAttendeeId(attendeeId: string, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<AttendeePermission>> {
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    return await this._httpClient.get(`${this._attendeePermissionsPath}/${attendeeId}`, abortSignal);
  }

  public async listAttendees(request: AttendeeListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Attendee>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.meetingId) { query += `&meetingId=${request.meetingId}`; }
    if (request.userAccountId) { query += `&userAccountId=${request.userAccountId}`; }
    if (request.idsToExclude && request.idsToExclude.length > 0) {
      for(let i = 0; i < request.idsToExclude.length; i++) {
        query += `&idsToExclude=${request.idsToExclude[i]}`;
      }
    }
    return await this._httpClient.get(`${this._attendeesPath}?${query}`, abortSignal);
  }

  public async listChatChannelAttendeesByChannelId(channelId: string, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ChatChannelAttendee>> {
    Guard.isNotNullOrUndefined(channelId, "channelId");
    return await this._httpClient.get(`${this._chatChannelAttendeesPath}?channelId=${channelId}`, abortSignal);
  }

  public async listChatChannels(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ChatChannel>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._chatChannelsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listAttendeeChatChannels(request: AttendeeChatChannelListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ChatChannel>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._chatChannelsPath}/attendee?limit=100&attendeeId=${request.attendeeId}${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listChatMessageReactionsByMessageId(messageId: string, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ChatMessageReaction>> {
    Guard.isNotNullOrUndefined(messageId, "messageId");
    return await this._httpClient.get(`${this._chatMessageReactionsPath}/${messageId}`, abortSignal);
  }

  public async listChatMessages(request: ChatMessageListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ChatMessage>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.meetingId) { query += `&meetingId=${request.meetingId}`; }
    if (request.attendeeId) { query += `&attendeeId=${request.attendeeId}`; }
    if (request.channelId) { query += `&channelId=${request.channelId}`; }
    if (request.createdBy) { query += `&createdBy=${request.createdBy}`; }
    if (request.flaggedBy) { query += `&flaggedBy=${request.flaggedBy}`; }
    if (request.sortDirection) { query += `&sortDirection=${request.sortDirection}`; }
    
    return await this._httpClient.get(`${this._chatMessagesPath}?${query}`, abortSignal);
  }

  public async listChatMessagesAsJson(request: ChatMessageListRequest, abortSignal?: AbortSignal): Promise<ApiResponse<string>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.meetingId) { query += `&meetingId=${request.meetingId}`; }
    if (request.attendeeId) { query += `&attendeeId=${request.attendeeId}`; }
    if (request.channelId) { query += `&channelId=${request.channelId}`; }
    if (request.createdBy) { query += `&createdBy=${request.createdBy}`; }
    if (request.flaggedBy) { query += `&flaggedBy=${request.flaggedBy}`; }
    if (request.sortDirection) { query += `&sortDirection=${request.sortDirection}`; }
    return await this._httpClient.get(`${this._chatMessagesPath}/json?${query}`, abortSignal);
  }

  public async listClusters(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Cluster>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._clustersPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listClusterSettingsByClusterId(clusterId: string, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ClusterSetting>> {
    Guard.isNotNullOrUndefined(clusterId, "clusterId");
    return await this._httpClient.get(`${this._clustersPath}/${clusterId}/settings`, abortSignal);
  }
  
  public async listCountries(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Country>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._countiresPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listDevices(request: DeviceListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Device>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.userAccountId) { query += `&userAccountId=${request.userAccountId}`; }    
    return await this._httpClient.get(`${this._devicesPath}?${query}`, abortSignal);
  }

  public async listMeetingInvites(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<MeetingInvite>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._meetingInvitesPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listMeetingPlans(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<MeetingPlan>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._meetingPlansPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listMeetingPlanServers(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<MeetingPlanServer>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._meetingPlanServersPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listMeetingPlanSlots(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<MeetingPlanSlot>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._meetingPlanSlotsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }

  public async listMeetingRecordings(request: MeetingRecordingListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<MeetingRecording>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.meetingId) { query += `&meetingId=${request.meetingId}`; }
    if (request.serverId) { query += `&serverId=${request.serverId}`; }
    if (request.attendeeId) { query += `&attendeeId=${request.attendeeId}`; }
    if (request.recordingUrlTtl) query += `&recordingUrlTtl=${request.recordingUrlTtl}`;
    return await this._httpClient.get(`${this._meetingRecordingsPath}?${query}`, abortSignal);
  }

  public async listMeetingRecordingsByUserAccountId(userAccountId: string, request: MeetingRecordingListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<MeetingRecording>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.meetingId) { query += `&meetingId=${request.meetingId}`; }
    if (request.serverId) { query += `&serverId=${request.serverId}`; }
    if (request.attendeeId) { query += `&attendeeId=${request.attendeeId}`; }
    if (request.recordingUrlTtl) query += `&recordingUrlTtl=${request.recordingUrlTtl}`;
    return await this._httpClient.get(`${this._meetingRecordingsPath}/userAccount/${userAccountId}?${query}`, abortSignal);
  }

  public async listMeetings(request: MeetingListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Meeting>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.roomId) { query += `&roomId=${request.roomId}`; }
    if (request.clusterId) { query += `&clusterId=${request.clusterId}`; }
    if (request.scheduleId) { query += `&scheduleId=${request.scheduleId}`; }
    if (request.userAccountId) { query += `&userAccountId=${request.userAccountId}`; }
    if (request.filterTerm) { query += `&filterTerm=${request.filterTerm}`; }
    if (request.filterListType && request.filterListType.length > 0) {
      for(let i = 0; i < request.filterListType.length; i++) {
        query += `&filterListType=${request.filterListType[i]}`;
      }
    }
    if (request.filterListStatus && request.filterListStatus.length > 0) {
      for(let i = 0; i < request.filterListStatus.length; i++) {
        query += `&filterListStatus=${request.filterListStatus[i]}`;
      }
    }
    if (request.status && request.status.length > 0) {
      for(let i = 0; i < request.status.length; i++) {
        query += `&status=${request.status[i]}`;
      }
    }
    return await this._httpClient.get(`${this._meetingsPath}?${query}`, abortSignal);
  }

  public async listMeetingsByUserAccountId(userAccountId: string, request: MeetingListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Meeting>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.roomId) { query += `&roomId=${request.roomId}`; }
    if (request.clusterId) { query += `&clusterId=${request.clusterId}`; }
    if (request.scheduleId) { query += `&scheduleId=${request.scheduleId}`; }
    if (request.userAccountId) { query += `&userAccountId=${request.userAccountId}`; }
    if (request.filterTerm) { query += `&filterTerm=${request.filterTerm}`; }
    if (request.filterListType && request.filterListType.length > 0) {
      for(let i = 0; i < request.filterListType.length; i++) {
        query += `&filterListType=${request.filterListType[i]}`;
      }
    }
    if (request.filterListStatus && request.filterListStatus.length > 0) {
      for(let i = 0; i < request.filterListStatus.length; i++) {
        query += `&filterListStatus=${request.filterListStatus[i]}`;
      }
    }
    if (request.status && request.status.length > 0) {
      for(let i = 0; i < request.status.length; i++) {
        query += `&status=${request.status[i]}`;
      }
    }
    return await this._httpClient.get(`${this._meetingsPath}/useraccount/${userAccountId}?${query}`, abortSignal);
  }
  
  public async listMeetingSettings(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<MeetingSetting>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._meetingSettingsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }
  
  public async listPermissions(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Permission>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._permissionsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }
  
  public async listRooms(request: RoomListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Room>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.key) { query += `&key=${request.key}`; }
    if (request.clusterId) { query += `&clusterId=${request.clusterId}`; }
    if (request.userAccountId) { query += `&userAccountId=${request.userAccountId}`; }
    return await this._httpClient.get(`${this._roomsPath}?${query}`, abortSignal);
  }
  
  public async listRoomSchedules(request: RoomScheduleListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<RoomSchedule>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.roomId) { query += `&roomId=${request.roomId}`; }
    return await this._httpClient.get(`${this._roomSchedulesPath}?${query}`, abortSignal);
  }
  
  public async listRoomSipInboundNumbers(request: RoomSipInboundNumberListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<RoomSipInboundNumber>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.roomId) { query += `&roomId=${request.roomId}`; }
    return await this._httpClient.get(`${this._roomSipInboundNumbersPath}?${query}`, abortSignal);
  }
  
  public async listRoomUserAccounts(request: RoomUserAccountListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<RoomUserAccount>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.roomId) { query += `&roomId=${request.roomId}`; }
    if (request.userAccountId) { query += `&userAccountId=${request.userAccountId}`; }
    if (request.filterListType && request.filterListType.length > 0) {
      for(let i = 0; i < request.filterListType.length; i++) {
        query += `&filterListType=${request.filterListType[i]}`;
      }
    }
    if (request.filterListStatus && request.filterListStatus.length > 0) {
      for(let i = 0; i < request.filterListStatus.length; i++) {
        query += `&filterListStatus=${request.filterListStatus[i]}`;
      }
    }
    return await this._httpClient.get(`${this._roomUserAccountsPath}?${query}`, abortSignal);
  }
  
  public async listServers(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Server>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._serversPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }
  
  public async listServerSettings(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ServerSetting>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._serverSettingsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }
  
  public async listServerSettingsByServerId(serverId: string, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<ServerSetting>> {
    Guard.isNotNullOrUndefined(serverId, "serverId");
    return await this._httpClient.get(`${this._serversPath}/${serverId}/settings`, abortSignal);
  }
  
  public async listSipInboundNumbers(request: SipInboundNumberListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<SipInboundNumber>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.clusterId) { query += `&clusterId=${request.clusterId}`; }
    if (request.countryId) { query += `&countryId=${request.countryId}`; }
    if (request.filterListType && request.filterListType.length > 0) {
      for(let i = 0; i < request.filterListType.length; i++) {
        query += `&filterListType=${request.filterListType[i]}`;
      }
    }
    if (request.filterListStatus && request.filterListStatus.length > 0) {
      for(let i = 0; i < request.filterListStatus.length; i++) {
        query += `&filterListStatus=${request.filterListStatus[i]}`;
      }
    }
    return await this._httpClient.get(`${this._sipInboundNumbersPath}?${query}`, abortSignal);
  }
  
  public async listTenants(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Tenant>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._tenantsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }
  
  public async listTenantSettings(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<TenantSetting>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._tenantSettingsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }
  
  public async listTenantSettingsByTenantId(tenantId: string, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<TenantSetting>> {
    Guard.isNotNullOrUndefined(tenantId, "tenantId");
    return await this._httpClient.get(`${this._tenantsPath}/${tenantId}/settings`, abortSignal);
  }
  
  public async listTenantUserAccounts(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<TenantUserAccount>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._tenantUserAccountsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }
  
  public async listUserAccounts(request: BaseListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<UserAccount>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.get(`${this._userAccountsPath}?${this.getBaseListRequestString(request)}`, abortSignal);
  }
  
  public async listUserAccountPermissionsByUserAccountId(userAccountId: string, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<UserAccountPermission>> {
    Guard.isNotNullOrUndefined(userAccountId, "userAccountId");
    return await this._httpClient.get(`${this._userAccountsPath}/${userAccountId}/permissions`, abortSignal);
  }
  
  public async listUserAccountSettingsByUserAccountId(userAccountId: string, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<UserAccountPermission>> {
    Guard.isNotNullOrUndefined(userAccountId, "userAccountId");
    return await this._httpClient.get(`${this._userAccountsPath}/${userAccountId}/settings`, abortSignal);
  } 
  
  public async listWebhooks(request: WebhookListRequest, abortSignal?: AbortSignal): Promise<ApiCollectionResponse<Webhook>> {
    Guard.isNotNullOrUndefined(request, "request");
    let query = this.getBaseListRequestString(request);
    if (request.tenantId) { query += `&tenantId=${request.tenantId}`; }
    if (request.userAccountId) { query += `&userAccountId=${request.userAccountId}`; }
    return await this._httpClient.get(`${this._webhooksPath}?${query}`, abortSignal);
  }
  
  public async startMeetingRecording(meetingRecordingId: string, request: MeetingRecording, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingRecording>> {
    Guard.isNotNullOrUndefined(meetingRecordingId, "meetingRecordingId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._meetingRecordingsPath}/startrecording/${meetingRecordingId}`, request, abortSignal);
  }

  public async stopMeetingRecording(meetingRecordingId: string, request: MeetingRecording, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingRecording>> {
    Guard.isNotNullOrUndefined(meetingRecordingId, "meetingRecordingId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._meetingRecordingsPath}/stoprecording/${meetingRecordingId}`, request, abortSignal);
  }
  
  public async updateAccount(accountId: string, request: AccountUpdateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<AccountUpdateResponse>> {
    Guard.isNotNullOrUndefined(accountId, "accountId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._accountsPath}/${accountId}`, request, abortSignal);
  }

  public async updateApiKey(apiKeyId: string, request: ApiKeyUpdateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<ApiKeyUpdateResponse>> {
    Guard.isNotNullOrUndefined(apiKeyId, "apiKeyId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._apiKeysPath}/${apiKeyId}`, request, abortSignal);
  }

  public async updateAttendeePermission(attendeePermissionId: string, request: AttendeePermissionUpdateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<AttendeePermissionUpdateResponse>> {
    Guard.isNotNullOrUndefined(attendeePermissionId, "attendeePermissionId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._attendeePermissionsPath}/${attendeePermissionId}`, request, abortSignal);
  }

  public async updateAttendeeRole(attendeeId: string, role: AttendeeRole, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    Guard.isNotNullOrUndefined(attendeeId, "attendeeId");
    Guard.isNotNullOrUndefined(role, "role");
    return await this._httpClient.post(`${this._attendeesPath}/${attendeeId}/update-attendeerole`, { value: `${role as string}` }, abortSignal);
  }

  public async updateCluster(clusterId: string, request: Cluster, abortSignal?: AbortSignal): Promise<ApiResponse<Cluster>> {
    Guard.isNotNullOrUndefined(clusterId, "clusterId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._clustersPath}/${clusterId}`, request, abortSignal);
  }

  public async updateClusterSetting(request: ClusterSettingUpdateRequest, abortSignal?: AbortSignal): Promise<ApiResponse<void>> {
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._clusterSettingsPath}`, request, abortSignal);
  }
  
  public async updateCountry(countryId: string, request: Country, abortSignal?: AbortSignal): Promise<ApiResponse<Country>> {
    Guard.isNotNullOrUndefined(countryId, "countryId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._countiresPath}/${countryId}`, request, abortSignal);
  }

  public async updateMeetingInvite(meetingInviteId: string, request: MeetingInvite, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingInvite>> {
    Guard.isNotNullOrUndefined(meetingInviteId, "meetingInviteId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._meetingInvitesPath}/${meetingInviteId}`, request, abortSignal);
  }

  public async updateMeetingPlan(meetingPlanId: string, request: MeetingPlan, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlan>> {
    Guard.isNotNullOrUndefined(meetingPlanId, "meetingPlanId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._meetingPlansPath}/${meetingPlanId}`, request, abortSignal);
  }

  public async updateMeetingPlanServer(meetingPlanServerId: string, request: MeetingPlanServer, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlanServer>> {
    Guard.isNotNullOrUndefined(meetingPlanServerId, "meetingPlanServerId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._meetingPlanServersPath}/${meetingPlanServerId}`, request, abortSignal);
  }

  public async updateMeetingPlanSlot(meetingPlanSlotId: string, request: MeetingPlanSlot, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingPlanSlot>> {
    Guard.isNotNullOrUndefined(meetingPlanSlotId, "meetingPlanSlotId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._meetingPlanSlotsPath}/${meetingPlanSlotId}`, request, abortSignal);
  }

  public async updateMeetingSetting(meetingSettingId: string, request: MeetingSetting, abortSignal?: AbortSignal): Promise<ApiResponse<MeetingSetting>> {
    Guard.isNotNullOrUndefined(meetingSettingId, "meetingSettingId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._meetingSettingsPath}/${meetingSettingId}`, request, abortSignal);
  }

  public async updatePermission(permissionId: string, request: Permission, abortSignal?: AbortSignal): Promise<ApiResponse<Permission>> {
    Guard.isNotNullOrUndefined(permissionId, "permissionId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._permissionsPath}/${permissionId}`, request, abortSignal);
  }

  public async updatePlanTemplate(planTemplateId: string, request: PlanTemplate, abortSignal?: AbortSignal): Promise<ApiResponse<PlanTemplate>> {
    Guard.isNotNullOrUndefined(planTemplateId, "planTemplateId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._planTemplatesPath}/${planTemplateId}`, request, abortSignal);
  }

  public async updateRoom(roomId: string, request: Room, abortSignal?: AbortSignal): Promise<ApiResponse<Room>> {
    Guard.isNotNullOrUndefined(roomId, "roomId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._roomsPath}/${roomId}`, request, abortSignal);
  }

  public async updateRoomSchedule(roomScheduleId: string, request: RoomSchedule, abortSignal?: AbortSignal): Promise<ApiResponse<RoomSchedule>> {
    Guard.isNotNullOrUndefined(roomScheduleId, "roomScheduleId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._roomSchedulesPath}/${roomScheduleId}`, request, abortSignal);
  }

  public async updateRoomSipInboundNumber(roomSipInboundNumberId: string, request: RoomSipInboundNumber, abortSignal?: AbortSignal): Promise<ApiResponse<RoomSipInboundNumber>> {
    Guard.isNotNullOrUndefined(roomSipInboundNumberId, "roomSipInboundNumberId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._roomSipInboundNumbersPath}/${roomSipInboundNumberId}`, request, abortSignal);
  }

  public async updateRoomUserAccount(roomUserAccountId: string, request: RoomUserAccount, abortSignal?: AbortSignal): Promise<ApiResponse<RoomUserAccount>> {
    Guard.isNotNullOrUndefined(roomUserAccountId, "roomUserAccountId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._roomUserAccountsPath}/${roomUserAccountId}`, request, abortSignal);
  }

  public async updateServer(serverId: string, request: Server, abortSignal?: AbortSignal): Promise<ApiResponse<Server>> {
    Guard.isNotNullOrUndefined(serverId, "serverId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._serversPath}/${serverId}`, request, abortSignal);
  }

  public async updateServerSetting(serverSettingId: string, request: ServerSetting, abortSignal?: AbortSignal): Promise<ApiResponse<ServerSetting>> {
    Guard.isNotNullOrUndefined(serverSettingId, "serverSettingId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._serverSettingsPath}/${serverSettingId}`, request, abortSignal);
  }

  public async updateTenant(tenantId: string, request: Tenant, abortSignal?: AbortSignal): Promise<ApiResponse<Tenant>> {
    Guard.isNotNullOrUndefined(tenantId, "tenantId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._tenantsPath}/${tenantId}`, request, abortSignal);
  }

  public async updateTenantSetting(tenantSettingId: string, request: TenantSetting, abortSignal?: AbortSignal): Promise<ApiResponse<TenantSetting>> {
    Guard.isNotNullOrUndefined(tenantSettingId, "tenantSettingId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._tenantSettingsPath}/${tenantSettingId}`, request, abortSignal);
  }

  public async updateTenantUserAccount(tenantUserAccountId: string, request: TenantUserAccount, abortSignal?: AbortSignal): Promise<ApiResponse<TenantUserAccount>> {
    Guard.isNotNullOrUndefined(tenantUserAccountId, "tenantUserAccountId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._tenantUserAccountsPath}/${tenantUserAccountId}`, request, abortSignal);
  }

  public async updateUserAccount(userAccountId: string, request: UserAccount, abortSignal?: AbortSignal): Promise<ApiResponse<UserAccount>> {
    Guard.isNotNullOrUndefined(userAccountId, "userAccountId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._userAccountsPath}/${userAccountId}`, request, abortSignal);
  }

  public async updateWebhook(webhookId: string, request: Webhook, abortSignal?: AbortSignal): Promise<ApiResponse<Webhook>> {
    Guard.isNotNullOrUndefined(webhookId, "userAccountId");
    Guard.isNotNullOrUndefined(request, "request");
    return await this._httpClient.put(`${this._webhooksPath}/${webhookId}`, request, abortSignal);
  }

  private getBaseListRequestString(request: BaseListRequest): string {
    let query = "";
    if (request.where) { query += `&where=${request.where}`; }
    if (request.orderBy) { query += `&orderby=${request.orderBy}`; }
    if (request.limit) { query += `&limit=${request.limit}`; }
    if (request.offset) { query += `&offset=${request.offset}`; }
    return query;
  }
}
